import React from 'react';
import { styled } from '@glitz/react';
import { FooterType } from 'Shared/State';
import { NewsLetter, SafeECommerce, CustomerService, MagazineGuides } from './components';
import isSectionEmpty from './utils';
import { pixelsToUnit } from 'Shared/Style';

type PropType = {
  footer: FooterType;
};

const Groups = styled.div({
  maxWidth: pixelsToUnit(1252),
  width: '100%',
  display: 'flex',
  padding: {
    top: pixelsToUnit(48),
    right: 0,
    bottom: pixelsToUnit(20),
    left: pixelsToUnit(20),
  },
  margin: {
    y: 0,
    x: 'auto',
  },
});

const FooterDesktop = ({ footer }: PropType) => {
  const {
    newsletterTitle = '',
    newsletterText = '',
    secondColumnTitle = '',
    secondColumnText,
    thirdColumnLinks,
    fourthColumnLinks,
  } = footer;

  const newsLetter = { header: newsletterTitle, body: newsletterText };
  const safeECommerce = { header: secondColumnTitle, body: secondColumnText };
  const [customerService = { header: '', links: [] }] = thirdColumnLinks; // get first element in array only, set default values
  const [magazineGuides = { header: '', links: [] }] = fourthColumnLinks; // get first element in array, set default values

  // save browser space. hide empty footer sections
  return (
    <Groups>
      {!isSectionEmpty(newsLetter) && <NewsLetter newsLetter={newsLetter} />}
      {!isSectionEmpty(safeECommerce) && <SafeECommerce safeECommerce={safeECommerce} />}
      {!isSectionEmpty(customerService) && <CustomerService customerService={customerService} />}
      {!isSectionEmpty(magazineGuides) && <MagazineGuides magazineGuides={magazineGuides} />}
    </Groups>
  );
};

export default FooterDesktop;
