import State from 'Shared/State';
import { componentNames } from 'Shared/component-registry';

export default (state: State) => {
  return componentIsSearch(state.currentPage.componentName);
};

export function componentIsSearch(component: string) {
  return component === componentNames.Avensia_Common_Features_Search_SearchPageViewModel;
}
