import { URLX, PAGE_LOAD_DONE, PageLoadDoneAction, currentUrl } from '@avensia/scope';
import { QuickSearchType } from 'Shared/State';
import { componentIsSearch } from 'Search/current-page-is-search';
import { QuickSearchCompletionListType, PageType, Action } from 'Shared/State';
import {
  HIDE_QUICKSEARCH,
  SHOW_QUICKSEARCH,
  UPDATE_QUICKSEARCH_TEXT,
  UPDATE_QUICKSEARCH_COMPLETIONS,
  UPDATE_SELECTED_QUICKSEARCH_COMPLETION,
  UpdateQuicksearchTextAction,
  UpdateQuicksearchCompletionsAction,
  UpdateSelectedQuicksearchCompletionAction,
  HideQuicksearchAction,
  SEARCH_QUERY_NAME,
} from './action-creators';

export default function(state: QuickSearchType = null, action: Action, currentPage: PageType): QuickSearchType {
  if (state === null) {
    state = {
      isOpen: false,
      searchText: getSearchTextFromCurrentPage(currentPage) || '',
      suggestion: null,
      completionsLists: [],
      selectedCompletionIndex: null,
      originalSearchTerm: null,
    };
  }
  switch (action.type) {
    case HIDE_QUICKSEARCH: {
      const hideAction = action as HideQuicksearchAction;
      const suggestion = hideAction.setFirstSuggestionAsSearchText
        ? getQuerySuggestion(state.searchText, state.completionsLists)
        : state.searchText;
      return {
        ...state,
        isOpen: false,
        searchText: suggestion || state.searchText,
        suggestion: null,
      };
    }
    case SHOW_QUICKSEARCH: {
      return { ...state, isOpen: true };
    }
    case PAGE_LOAD_DONE: {
      const loadDoneAction = action as PageLoadDoneAction;
      const query = getSearchTextFromCurrentPage(currentPage);
      if (query !== null && !state.isOpen) {
        return { ...state, searchText: query, suggestion: null };
      } else if (!componentIsSearch(currentPage.componentName) && !loadDoneAction.serverRedirected) {
        // We don't want to clear the search text if the current component isn't the search page
        // because the server might redirect us from the search page to a brand page if the user
        // searched for a brand
        return { ...state, searchText: '', suggestion: null };
      }
      break;
    }
    case UPDATE_SELECTED_QUICKSEARCH_COMPLETION: {
      const index = (action as UpdateSelectedQuicksearchCompletionAction).index;

      const text =
        index !== null
          ? getCompletionAtIndex(index, state.completionsLists)
          : state.originalSearchTerm || state.searchText;

      const suggestion = getQuerySuggestion(text, state.completionsLists);
      return {
        ...state,
        selectedCompletionIndex: index,
        searchText: text,
        suggestion: suggestion || text,
      };
    }

    case UPDATE_QUICKSEARCH_TEXT: {
      const { searchText } = action as UpdateQuicksearchTextAction;
      const suggestion = getQuerySuggestion(searchText, state.completionsLists);
      return {
        ...state,
        searchText,
        suggestion,
        selectedCompletionIndex: null,
        originalSearchTerm: searchText,
      };
    }
    case UPDATE_QUICKSEARCH_COMPLETIONS: {
      const completionsAction = action as UpdateQuicksearchCompletionsAction;
      const newState = { ...state, completionsLists: completionsAction.lists };
      const newListIds = completionsAction.lists.map(l => l.id);
      newState.completionsLists = newState.completionsLists
        .concat(state.completionsLists.filter(l => newListIds.indexOf(l.id) === -1))
        .sort(sortLists);
      return newState;
    }
    default:
      break;
  }
  return state;
}

export function getQuerySuggestion(currentQuery: string, lists: QuickSearchCompletionListType[]) {
  const firstNonEmptyList = lists.filter(l => l.items.length > 0)[0];
  if (!firstNonEmptyList) {
    return null;
  }
  const firstAutocomplete = firstNonEmptyList.items[0].text;

  if (!firstAutocomplete || !currentQuery || currentQuery[currentQuery.length - 1] === ' ') {
    return currentQuery;
  }

  const currentWords = currentQuery.split(' ').filter(s => !!s);
  const autocompleteWords = firstAutocomplete.split(' ').filter(s => !!s);

  if (currentWords.length > autocompleteWords.length) {
    return currentQuery;
  }

  const lastIndex = currentWords.length - 1;
  for (let i = 0; i < lastIndex; i++) {
    if (currentWords[i].toLowerCase() !== autocompleteWords[i].toLowerCase()) {
      return currentQuery;
    }
  }

  if (!autocompleteWords[lastIndex].toLowerCase().startsWith(currentWords[lastIndex].toLowerCase())) {
    return currentQuery;
  }

  return currentQuery + autocompleteWords[lastIndex].substr(currentWords[lastIndex].length);
}

function sortLists(a: QuickSearchCompletionListType, b: QuickSearchCompletionListType) {
  return a.sortIndex - a.sortIndex;
}

function getCompletionAtIndex(index: number, lists: QuickSearchCompletionListType[]): string {
  if (index == null) {
    return '';
  }
  return lists.reduce((a, b) => a.concat(b.items), [])[index].text;
}

function getSearchTextFromCurrentPage(currentPage: PageType) {
  if (componentIsSearch(currentPage.componentName)) {
    return getSearchTextFromUrl(currentUrl());
  } else {
    return null;
  }
}

export function getSearchTextFromUrl(url: URLX) {
  return url && url.searchParams ? url.searchParams.get(SEARCH_QUERY_NAME) || '' : '';
}
