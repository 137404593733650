import React from 'react';
import { translate } from '@avensia/scope';
import { styled, StyledProps } from '@glitz/react';
import { pseudo } from '@glitz/core';
import { Basic, Appearance as PageAppearance } from 'Shared/PageLayout';
import NavigationItemType from 'Shared/NavigationItem/NavigationItem.type';
import MenuButtonItemType from 'Shared/NavigationItem/MenuButtonItem.type';
import { pixelsToUnit, depth, sigma, small } from 'Shared/Style';
import Button from 'Shared/Button/index';
import { Appearance as ButtonAppearance } from 'Shared/Button';
import Link from 'Shared/Link';
import SubCategories from './MegaMenuItem';
import { transition } from 'Shared/Style';

type PropType = StyledProps & {
  item: NavigationItemType;
  isOpen: boolean;
  mainMenuButtons: MenuButtonItemType[];
};

type TogglePropType = {
  enabled: boolean;
  height: number;
  children?: (promise: () => Promise<{}>) => React.ReactNode;
};

type ToggleStateType = {
  transitionEnded: boolean;
};

class Toggle extends React.Component<TogglePropType, ToggleStateType> {
  closeRestResolve: () => void;
  closeRestPromise: Promise<{}>;
  constructor(props: TogglePropType) {
    super(props);
    this.state = {
      transitionEnded: false,
    };
  }
  componentDidMount() {
    if (this.isOpen()) {
      this.createRestPromise();
    }
  }
  componentDidUpdate() {
    if (this.props.enabled && this.isOpen(this.props)) {
      this.createRestPromise();
    }
  }
  createRestPromise() {
    this.closeRestPromise = new Promise((resolve) => (this.closeRestResolve = resolve));
  }

  isOpen(props = this.props) {
    return props.height > 0;
  }
  toggleHeight = () => {
    if ((!this.state.transitionEnded && this.props.enabled) || (this.state.transitionEnded && !this.props.enabled)) {
      this.setState({
        transitionEnded: !this.state.transitionEnded,
      });
    }
  };
  render() {
    return (
      <ToggleBase
        css={{
          maxHeight: this.state.transitionEnded && this.props.enabled ? 'none' : this.props.height,
        }}
        onTransitionEnd={this.toggleHeight}
      >
        {this.props.children(() => this.closeRestPromise)}
      </ToggleBase>
    );
  }
}

type StateType = {
  height: number;
};

export default styled(
  class MegaMenu extends React.Component<PropType, StateType> {
    myReviewRef: HTMLDivElement;
    constructor(props: PropType) {
      super(props);
      this.state = {
        height: 0,
      };
    }
    componentDidUpdate(prevProps: PropType) {
      if (prevProps.isOpen && !this.props.isOpen) {
        this.updateMenuHeight(0);
      }
    }
    updateMenuHeight = (height: number) => {
      this.setState({
        height,
      });
    };
    render() {
      const { item, isOpen, mainMenuButtons = [] } = this.props;
      const categoryColors = ['#F3E0e0', '#EADFF7', '#D6E4DA', '#D7E1EC', '#D4E8EC', '#F2E8B7', '#E9D3F3', '#D5EDAC'];
      return (
        <Toggle enabled={isOpen} height={this.state.height}>
          {(getCloseRestPromise: () => Promise<{}>) =>
            item && (
              <MenuBase
                appearance={[PageAppearance.Full, PageAppearance.Mixed]}
                elementRef={(el) => {
                  if (el && el.clientHeight !== this.state.height && this.props.isOpen) {
                    this.updateMenuHeight(el.clientHeight);
                  }
                  this.myReviewRef = el;
                }}
              >
                <Header>
                  <ShowAll to={item.url}>
                    {translate('/MainMenu/ShowAll')}
                    <MainCategory>{item.name}</MainCategory>
                  </ShowAll>
                </Header>
                <Categories
                  css={
                    item.children && item.children.length > 5
                      ? { justifyContent: 'flex-start' }
                      : { justifyContent: 'center' }
                  }
                >
                  {item.children &&
                    item.children.map((category, i) => (
                      <Category key={category.url}>
                        <CategoryLink to={category.url}>
                          <CategoryName css={{ backgroundColor: categoryColors[i % 8] }}>{category.name}</CategoryName>
                        </CategoryLink>
                        {category.children.length > 0 && <SubCategories items={category.children} />}
                      </Category>
                    ))}
                </Categories>
                {mainMenuButtons.length > 0 && (
                  <Buttons>
                    {mainMenuButtons.map((menuButton, index) => (
                      <MenuButton to={menuButton.link} key={index} appearance={ButtonAppearance.Secondary}>
                        {menuButton.text}
                      </MenuButton>
                    ))}
                  </Buttons>
                )}
              </MenuBase>
            )
          }
        </Toggle>
      );
    }
  },
);

const ToggleBase = styled.div({
  zIndex: 1,
  borderRadius: '0 0 1rem 1rem',
  position: 'relative',
  overflowY: 'hidden',
  backgroundColor: (theme) => theme.megaMenuBackgroundColor,
  maxHeight: 0,
  ...depth(),
  ...transition({
    property: 'max-height',
    duration: '300ms',
    timingFunction: 'ease-in-out',
  }),
});

const MenuBase = styled(Basic, {
  width: 'unset',
  padding: {
    xy: pixelsToUnit(25),
  },
  borderRadius: '1rem',
  margin: {
    xy: 0,
  },
  overflow: 'auto',
  maxHeight: '85vh',
});

const Header = styled.div({
  marginBottom: pixelsToUnit(30),
});

const ShowAll = styled(Link, {
  textAlign: 'center',
  color: '#515151',
  display: 'block',
  fontSize: sigma,
  ...pseudo(':hover span', {
    textDecoration: 'underline',
  }),
});

const MainCategory = styled.span({
  fontWeight: 'bold',
  paddingLeft: pixelsToUnit(5),
});

const Categories = styled.ul({
  listStyle: 'none',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  justifyContent: 'center',
  marginBottom: pixelsToUnit(30),
});

const Category = styled.li({
  display: 'inline-block',
  paddingBottom: small,
  maxWidth: pixelsToUnit(200),
  marginRight: pixelsToUnit(18),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const CategoryLink = styled(Link, {
  height: 'auto',
  lineHeight: '1.3em',
  color: '#4c4c4c',
  padding: {
    y: pixelsToUnit(6),
    x: 0,
  },
  ':hover': {
    textDecoration: 'underline',
  },
});

const CategoryName = styled.div({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: sigma,
  width: pixelsToUnit(200),
  lineHeight: '2.5rem',
  borderRadius: '0.15rem',
  fontWeight: 'bold',
  padding: {
    y: 0,
    x: pixelsToUnit(10),
  },
  backgroundColor: (theme) => theme.megaMenuCategoryNameBackgroundColor,
});

const Buttons = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  alignContent: 'flex-start',
});

const MenuButton = styled(Button, {
  fontSize: sigma,
  letterSpacing: pixelsToUnit(0.2),
  padding: {
    y: 0,
    x: pixelsToUnit(30),
  },
  textTransform: 'none',
  ...pseudo(':not(:last-child)', {
    marginRight: pixelsToUnit(15),
  }),
});
