import { Action, Dispatch } from 'Shared/State';
import { getJson, URLX } from '@avensia/scope';
import LastViewedProductViewModel from 'LastViewedProducts/LastViewedProductViewModel.type';

export type RecentlyViewedAction = Action & {
  payload: LastViewedProductViewModel[];
};

export const TOGGLE_RECENTLY_VIEWED = 'TOGGLE_RECENTLY_VIEWED';
export const FETCH_RECENTLY_VIEWED_REQUEST = 'FETCH_RECENTLY_VIEWED_REQUEST';
export const FETCH_RECENTLY_VIEWED_SUCCESS = 'FETCH_RECENTLY_VIEWED_SUCCESS';
export const FETCH_RECENTLY_VIEWED_FAILURE = 'FETCH_RECENTLY_VIEWED_FAILURE';

export function toggle() {
  return {
    type: TOGGLE_RECENTLY_VIEWED,
  } as Action;
}

export function fetch(url: URLX) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: FETCH_RECENTLY_VIEWED_REQUEST,
    });

    try {
      const data = await getJson(url);

      dispatch({
        type: FETCH_RECENTLY_VIEWED_SUCCESS,
        payload: data.products,
      } as RecentlyViewedAction);
    } catch (e) {
      dispatch({
        type: FETCH_RECENTLY_VIEWED_FAILURE,
      });

      return Promise.reject(e);
    }
  };
}
