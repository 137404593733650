import React from 'react';
import { translate, postJson } from '@avensia/scope';
import { Style } from '@glitz/type';
import { styled } from '@glitz/react';
import Input from 'Shared/Fields/Text';
import FeedbackButton, { Appearance } from 'Shared/Button/Feedback';
import { styles } from './Shared';
import { pixelsToUnit, minMediumMediaQuery, large, zeta, theta, delta, letterSpacingSmall } from 'Shared/Style';
import { identify } from 'TrackingInformation/Klaviyo';

type PropType = {
  newsLetter: {
    header: string;
    body: string;
  };
};

type StateType = {
  email: string;
  valid: boolean;
  error: boolean;
};

const SUBSCRIBE_URL = '/dosubscribe';

const Header = styled.h5(styles.header);
const Section = styled.section({
  marginRight: 0,
  textAlign: 'center',
  padding: {
    x: pixelsToUnit(15),
    y: 0,
  },
  [minMediumMediaQuery]: {
    order: 1,
    textAlign: 'left',
    flex: {
      basis: pixelsToUnit(300),
      grow: 0,
      shrink: 1,
    },
    margin: {
      bottom: large,
      right: pixelsToUnit(60),
    },
    padding: {
      x: 0,
    },
  },
});

const Body = styled.div({
  marginTop: pixelsToUnit(-10),
  padding: {
    bottom: pixelsToUnit(10),
    left: 0,
    right: 0,
    top: 0,
  },
  [minMediumMediaQuery]: {
    ...styles.body,
  },
});

const Caption = styled.p({
  fontSize: zeta,
  lineHeight: 1.4,
  margin: {
    x: 0,
    y: pixelsToUnit(15),
  },
  [minMediumMediaQuery]: {
    fontSize: theta,
  },
});

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
});

const EmailInput = styled(Input, {
  backgroundColor: theme => theme.inputEmailFooterBackgroundColor,
  borderRadius: '0.15rem',
  border: {
    xy: {
      color: theme => theme.inputEmailFooterBorderColor,
    },
  },
  color: theme => theme.inputEmailFooterTextColor,
  padding: {
    y: 0,
    x: pixelsToUnit(15),
  },
  textAlign: 'center',
  '::placeholder': {
    color: theme => theme.inputEmailFooterPlaceholderTextColor,
  },
  ':focus': {
    border: {
      xy: {
        color: theme => theme.inputEmailFooterFocusBorderColor,
      },
    },
  },
  [minMediumMediaQuery]: {
    textAlign: 'left',
  },
});

const Subscribe = styled(FeedbackButton, {
  fontSize: delta,
  letterSpacing: letterSpacingSmall,
  marginTop: pixelsToUnit(14),
});

const ErrorMessage = styled.span({
  marginTop: pixelsToUnit(16),
});

export default class NewsLetter extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = { email: '', valid: true, error: false };
  }

  validateInput(input: string) {
    const pattern = /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/;
    return pattern.test(input);
  }

  inputEmailAddress = (e: React.FormEvent<HTMLInputElement>) => {
    const email = (e.target as HTMLInputElement).value;
    this.setState({
      email,
      valid: this.validateInput(email),
    });
  };

  subscribeToNewsLetter = async () => {
    const { email } = this.state;
    try {
      const success: boolean = await postJson(SUBSCRIBE_URL, { email });
      if (success) {
        identify(email);
        this.setState(() => ({
          email: '',
          error: false,
        }));
      }
    } catch (error) {
      this.setState(() => ({
        email: '',
        error: true,
      }));
      return Promise.reject(null);
    }
  };

  render() {
    const { header = '', body = '' } = this.props.newsLetter;
    const inputInvalidStyle: Style = {
      border: {
        xy: {
          color: theme => theme.inputEmailFooterInvalidBorderColor,
        },
      },
    };
    const buttonDisabledStyle: Style = {
      backgroundColor: theme => theme.buttonDisabledBackgroundColor,
      backgroundImage: theme => theme.buttonDisabledBackgroundImage,
      color: theme => theme.buttonDisabledTextColor,
      pointerEvents: 'none',
    };

    return (
      <Section>
        {this.props.newsLetter && <Header>{header}</Header>}
        {this.props.newsLetter && (
          <Body>
            <Caption>{body}</Caption>
            <Form>
              <EmailInput
                css={!this.state.valid && inputInvalidStyle}
                autoComplete="email"
                invalid={!this.state.valid}
                name="email"
                placeholder={translate('/Footer/NewsLetter/Placeholder')}
                required
                type="email"
                value={this.state.email}
                onChange={this.inputEmailAddress}
              />
              {this.state.error && <ErrorMessage>{translate('/Footer/NewsLetter/RequestError')}</ErrorMessage>}
              <Subscribe
                css={!this.state.valid && buttonDisabledStyle}
                appearance={Appearance.Tertiary}
                onClick={this.subscribeToNewsLetter}
                noRippleEffect
              >
                {translate('/Footer/NewsLetter/Submit')}
              </Subscribe>
            </Form>
          </Body>
        )}
      </Section>
    );
  }
}
