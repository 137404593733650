import { useRef } from 'react';
import { URLX } from '@avensia/scope';

function assumeCachedByBrowser(image: HTMLImageElement) {
  if (!(image && image.currentSrc)) {
    return false;
  }

  const pathname = new URLX(image.currentSrc).pathname;
  if (pathname.endsWith('.svg')) {
    return false;
  }

  return image.complete && image.naturalWidth > 0;
}

export type ResourceType = {
  width: number;
  height: number;
  src: string;
};

type SubscriptionsType = { [key: string]: (() => void)[] };
const browserCacheSubscriptions: SubscriptionsType = {};

export function subscribeToBrowserCache(src: string, cb: () => void) {
  if (browserCacheSubscriptions[src]) {
    browserCacheSubscriptions[src].push(cb);
  } else {
    browserCacheSubscriptions[src] = [cb];
  }

  return () => {
    const index = browserCacheSubscriptions[src]?.indexOf(cb);
    if (index > -1) {
      browserCacheSubscriptions[src].splice(index, 1);
    }
  };
}

const browserCachedResources: { [src: string]: ResourceType } = {};

export function findBrowserCachedResource(originalSrc: string) {
  return browserCachedResources[originalSrc];
}

function rememberBrowserCachedResource(image: HTMLImageElement, originalSrc: string) {
  // Firefox has an ugly tendency to report naturalWidth as 0 when a SW is active.
  // But if we wait just a little bit, the sizes have been calculated. Broken
  // images should be uncommon, so we can afford the setTimeout here
  requestAnimationFrame(() => {
    if (image && assumeCachedByBrowser(image)) {
      const cached = findBrowserCachedResource(originalSrc);
      if (!cached || image.naturalWidth > cached.width) {
        browserCachedResources[originalSrc] = {
          width: image.naturalWidth,
          height: image.naturalHeight,
          src: image.currentSrc,
        };
        const subscription = browserCacheSubscriptions[originalSrc];
        if (subscription) {
          for (let i = 0; i < subscription.length; i++) {
            subscription[i]();
          }
          delete browserCacheSubscriptions[originalSrc];
        }
      }
    }
  });
}

function loadImage(src: string, srcset?: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();

    if (srcset) {
      Object.assign(image, {
        onload: () => resolve(image),
        onerror: () => reject(),
        src,
        srcset,
      });
    } else {
      Object.assign(image, {
        onload: () => resolve(image),
        onerror: () => reject(),
        src,
        // Defining `srcset` as `undefined` causes the image to crash
      });
    }

    if (image.complete) {
      if (image.naturalWidth > 0) {
        resolve(image);
      } else {
        reject();
      }
    }
  });
}

export function useLoadResource() {
  const currentLoadRef = useRef<Promise<HTMLImageElement>>(null);
  return async function loadResource(originalSrc: string, src: string, srcset?: string) {
    const promise = (currentLoadRef.current = loadImage(src, srcset));

    try {
      const image = await promise;
      rememberBrowserCachedResource(image, originalSrc);
      return { isFulfilled: true, isCurrentLoad: currentLoadRef.current === promise };
    } catch (e) {
      return { isFulfilled: false, isCurrentLoad: currentLoadRef.current === promise };
    }
  };
}

export function isImageUrl(url: Scope.UrlViewModel): url is Scope.UrlViewModel {
  const imageUrl = url as Scope.UrlViewModel;
  return !!imageUrl.url;
}
