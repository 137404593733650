import { MainMenuType, Action } from 'Shared/State';
import { TOGGLE_MAINMENU, TOGGLE_STICKY_NAVBAR, CURRENT_VISIBLE_KEY } from './action-creators';
import { UpdateAppShellDataAction, UPDATE_APP_SHELL_DATA } from '@avensia/scope';
import { PAGE_LOAD } from '@avensia/scope/CurrentPage/action-creators';
import AppShellDataType from 'AppShell/AppShellData.type';
import { ROOT_KEY } from './item';

export default function(state: MainMenuType = null, action: Action, appShellData: AppShellDataType): MainMenuType {
  if (state === null) {
    state = {
      isOpen: false,
      isStickyNavbarOpen: false,
      visibleKey: ROOT_KEY,
      primaryMenuItems: appShellData.mainMenu,
    };
  }
  switch (action.type) {
    case TOGGLE_MAINMENU: {
      return { ...state, isOpen: !state.isOpen };
    }

    case TOGGLE_STICKY_NAVBAR: {
      return { ...state, isStickyNavbarOpen: !state.isStickyNavbarOpen };
    }

    case CURRENT_VISIBLE_KEY: {
      return {
        ...state,
        visibleKey: (action as any).key,
      };
    }
    case UPDATE_APP_SHELL_DATA: {
      const updateAppShellDataAction = action as UpdateAppShellDataAction<AppShellDataType>;
      return {
        isOpen: state.isOpen,
        isStickyNavbarOpen: state.isStickyNavbarOpen,
        visibleKey: state.visibleKey,
        primaryMenuItems: updateAppShellDataAction.appShellData.mainMenu,
      };
    }
    case PAGE_LOAD: {
      if (state.isOpen || state.isStickyNavbarOpen) {
        return {
          ...state,
          isOpen: false,
          isStickyNavbarOpen: false,
        };
      } else {
        return state;
      }
    }

    default:
      return state;
  }
}
