import consts from './constants';
import { pixelsToUnit } from './unit';

const { BASE_FONT_SIZE } = consts;

// Border
export const thin = pixelsToUnit(1);
export const thick = pixelsToUnit(2);

// Margin & padding
export const tiny = pixelsToUnit(Math.ceil(BASE_FONT_SIZE * 0.125));
export const small = pixelsToUnit(15);
export const medium = pixelsToUnit(Math.ceil(BASE_FONT_SIZE * 0.5));
export const large = pixelsToUnit(BASE_FONT_SIZE);
export const huge = pixelsToUnit(BASE_FONT_SIZE * 2);
export const gigantic = pixelsToUnit(BASE_FONT_SIZE * 3);
export const moderate = large; // This is default spacing, e.g. margin between `<p>`

export const letterSpacingTiny = pixelsToUnit(0.4);
export const letterSpacingSmall = pixelsToUnit(0.5);
export const letterSpacing = pixelsToUnit(1);
export const borderRadius = pixelsToUnit(10);
