import React from 'react';
import { Style } from '@glitz/type';
import { styled, StyledProps } from '@glitz/react';
import connect from 'Shared/connect';
import { formatPrice } from 'Shared/number-format';
import {
  epsilon,
  sigma,
  delta,
  iota,
  kilo,
  gamma,
  beta,
  mega,
  wildwatermelon,
  gray,
  lightBlack,
  white,
} from 'Shared/Style';

// TODO:
// 1. integrate current & original prices in this one component
// 2. for styling prices, pass object containing price & styling. can then remove getStyles()

export enum Variant {
  Default,
  Small,
  Medium,
  Large,
  Huge,
  Gigantic,
}

export enum Type {
  Regular,
  New,
  Old,
  Discount,
}

export enum Page {
  ProductDetails,
}

type ConnectStateType = {
  culture: string;
};

type PropType = {
  current: number;
  currency: string;
  currentPage?: Page;
  microData?: boolean;
  priceType?: Type;
  variant?: Variant;
} & StyledProps &
  ConnectStateType;

// TODO: delete function. used only in product details.
function getExtraStyles(variant: Variant): Style {
  let fontSize = epsilon;
  switch (variant) {
    case Variant.Small:
      fontSize = sigma;
      break;
    case Variant.Medium:
      fontSize = delta;
      break;
    case Variant.Large:
      fontSize = iota;
      break;
    case Variant.Huge:
      fontSize = kilo;
      break;
    default:
      break;
  }
  return {
    fontSize,
  };
}

function getStyles(variant: Variant, priceType: Type): Style {
  let fontSize;
  switch (variant) {
    case Variant.Small:
      fontSize = delta;
      break;
    case Variant.Medium:
      fontSize = gamma;
      break;
    case Variant.Large:
      fontSize = beta;
      break;
    case Variant.Huge:
      fontSize = kilo;
      break;
    case Variant.Gigantic:
      fontSize = mega;
      break;
    default:
      break;
  }
  return {
    color:
      priceType === Type.New
        ? wildwatermelon
        : priceType === Type.Old
        ? gray
        : priceType === Type.Discount
        ? white
        : lightBlack,
    fontWeight: priceType === Type.Old ? 'normal' : 'bold',
    fontSize,
    ...(priceType === Type.Old && {
      textDecoration: 'line-through',
    }),
    ...(priceType === Type.Discount && {
      backgroundColor: wildwatermelon,
      padding: {
        x: '0.6em',
      },
      borderRadius: 3,
    }),
  };
}

export default styled(
  connect(
    (state): ConnectStateType => ({
      culture: state.appShellData.culture,
    }),
  )((props: PropType) => {
    const { compose, current, currency, culture, microData, variant, priceType, currentPage } = props;
    const baseProps = microData
      ? {
          itemProp: 'offers',
          itemType: 'http://schema.org/Offer',
          itemScope: true,
        }
      : {};
    const priceProps = microData
      ? {
          itemProp: 'price',
          content: String(current),
        }
      : {};

    const css: Style = {
      ...getStyles(variant, priceType),
      ...(!currentPage && currentPage === Page.ProductDetails && getExtraStyles(variant)),
    };

    return currency ? (
      <styled.Span css={compose(css)} {...baseProps}>
        {microData && <meta itemProp="priceCurrency" content={currency} />}
        <Current {...priceProps}>{formatPrice(current, currency, culture)}</Current>
      </styled.Span>
    ) : (
      <span />
    );
  }),
);

const Current = styled.span({
  fontWeight: 'bold',
});
