import { useState, useEffect } from 'react';
import { findBrowserCachedResource, ResourceType, subscribeToBrowserCache } from './utils';

export default function useSubscribeToBrowserCache(originalSrc: string, enable: boolean): ResourceType {
  const [cachedSrc, setCachedSrc] = useState(findBrowserCachedResource(originalSrc));

  useEffect(() => {
    let unsubsubscribe: () => void = null;
    if (enable && !cachedSrc) {
      unsubsubscribe = subscribeToBrowserCache(originalSrc, () => {
        setCachedSrc(findBrowserCachedResource(originalSrc));
      });
    }
    return () => {
      unsubsubscribe?.();
    };
  }, [cachedSrc, enable, originalSrc]);

  return cachedSrc;
}
