import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { Style } from '@glitz/type';
import { supportsObjectFit } from 'Shared/supports';
import AspectRatio, { Ratio } from '../AspectRatio';
import Image, { ImagePropType } from '.';

export { Preset } from '.';
export { Ratio } from '../AspectRatio';

type PropType = ImagePropType & {
  ratio: Ratio;
  imageStyle?: Style;
  padding?: boolean;
};

export default styled((props: PropType & StyledProps) => {
  const { compose, style, ratio, imageStyle, ...restProps } = props;
  const imageCss = {
    ...imageStyle,
  };
  return (
    <Base ratio={ratio} style={style} css={compose()}>
      {supportsObjectFit() ? (
        <ModernImageFit lazy css={imageCss} {...restProps} />
      ) : (
        <LegacyImageFit lazy css={imageCss} {...restProps} />
      )}
    </Base>
  );
});

const Base = styled(AspectRatio, {
  position: 'relative',
});

const ModernImageFit = styled(Image, {
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0 /* shouldn't be needed, but prevents misaligned image in some circumstances */,
  objectFit: 'contain',
  objectPosition: 'center',
});

const LegacyImageFit = styled(Image, {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  height: '100%',
  width: 'auto',
});
