import { Action } from 'Shared/State';

export const UPDATE_TEXT = 'UPDATE_TEXT';
export const UPDATE_COLOR = 'UPDATE_COLOR';
export const UPDATE_FONT = 'UPDATE_FONT';
export const UPDATE_WOFFVALUE = 'UPDATE_WOFFVALUE';

export type ProductDesignerAction = Action & {
  name: string;
  value: string;
};

export function updateText(value: string) {
  return {
    type: UPDATE_TEXT,
    value,
  } as ProductDesignerAction;
}

export function updateColor(name: string, value: string) {
  return {
    type: UPDATE_COLOR,
    name,
    value,
  } as ProductDesignerAction;
}

export function updateFont(value: string) {
  return {
    type: UPDATE_FONT,
    value,
  } as ProductDesignerAction;
}

export function updateWoffValue(value: string) {
  return {
    type: UPDATE_WOFFVALUE,
    value,
  } as ProductDesignerAction;
}
