import React from 'react';
import { styled } from '@glitz/react';
import { Style } from '@glitz/type';
import { isBrowser, translate } from '@avensia/scope';
import Link from 'Shared/Link';
import MenuIcon from 'Shared/Icon/Menu';
import connect from 'Shared/connect';
import { pixelsToUnit, ZIndex, minSmallMediaQuery, white } from 'Shared/Style';
import { Basic, Appearance } from 'Shared/PageLayout';
import SiteBanner, { DEFAULT } from 'Shared/Blocks/SiteBanner/SiteBannerBlock';
import SiteBannerType from 'Shared/Blocks/SiteBanner/SiteBannerBlock.type';
import { saveToStorage, shouldComponentUpdate as shouldSiteBannerUpdate } from 'Shared/Blocks/SiteBanner/helper';
import { Order } from 'SiteLayout';
import MiniCart from 'SiteLayout/Nav/MiniCart';
import DefaultLogo from 'Shared/Icon/glyphs/vxn_logo_white.svg';
import HeaderPlain from 'SiteLayout/Header/HeaderPlain';
import { toggleMainMenu } from 'SiteLayout/MainMenu/action-creators';
import isCurrentPageCheckout from 'Checkout/Pages/Checkout/current-page-is-checkout';
import isCurrentPageOrderConfirmation from 'Checkout/Pages/OrderConfirmation/current-page-is-order-confirmation';
import isCurrentPageUnwatch from 'WatchItems/current-page-is-unwatch';
import HeaderLinkButtons from './HeaderLinkButtons';
import SearchForm from 'Search/QuickSearch';

type ConnectStateType = {
  isCheckoutPage: boolean;
  isOrderConfirmationPage: boolean;
  isUnwatchPage: boolean;
  loginPageUrl: string;
  siteBanner: SiteBannerType;
  customerServicePageUrl: string;
  headerLinkButtons: Scope.LinkItemCollection;
};

type ConnectDispatchType = {
  toggleMainMenu: (e?: React.MouseEvent<HTMLElement>) => void;
};

type PropType = ConnectStateType &
  ConnectDispatchType & {
    order: Order;
  };

type StateType = {
  bannerOpen: boolean;
  bannerHeight: number;
};

class HeaderMobileAlternateVersion extends React.PureComponent<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      bannerOpen: false,
      bannerHeight: DEFAULT,
    };
  }

  componentDidMount() {
    if (isBrowser() && this.props.siteBanner) {
      if (shouldSiteBannerUpdate(this.props.siteBanner) && !this.props.isOrderConfirmationPage) {
        this.openBanner();
      }
    }
  }

  componentDidUpdate(prevProps: PropType, prevState: StateType) {
    if (
      (!this.props.isCheckoutPage && prevProps.isCheckoutPage) ||
      (!this.props.isUnwatchPage && prevProps.isUnwatchPage) ||
      (!this.props.isOrderConfirmationPage && prevProps.isOrderConfirmationPage)
    ) {
      if (this.props.siteBanner && shouldSiteBannerUpdate(this.props.siteBanner)) {
        this.openBanner();
      }
    }
    if (this.props.isOrderConfirmationPage && !prevProps.isOrderConfirmationPage && this.state.bannerOpen) {
      this.resetBanner();
    }
  }

  toggleMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.toggleMainMenu();
  };

  updateState(newState: { [key: string]: number | boolean }) {
    this.setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  openBanner() {
    this.updateState({ bannerOpen: true });
  }

  closeBanner = () => {
    saveToStorage(this.props.siteBanner);
    this.resetBanner();
  };

  resetBanner = () => this.updateState({ bannerOpen: false, bannerHeight: DEFAULT });
  handleBannerHeightChange = (bannerHeight: number) => this.updateState({ bannerHeight });

  render() {
    const { order, siteBanner } = this.props;
    const { bannerOpen } = this.state;
    const siteBannerStyle: Style = {
      zIndex: ZIndex.SiteBanner,
      border: {
        top: {
          color: white,
          width: '1px',
          style: 'solid',
        },
      },
      padding: { x: '2.5rem', y: '1.2rem' },
      [minSmallMediaQuery]: {
        padding: { x: '10rem', y: '1.5rem' },
      },
    };
    const siteBannerUnderLinkButtonsStyle: Style = {
      zIndex: ZIndex.SiteBanner,
      border: {
        top: {
          color: white,
          width: '1px',
          style: 'solid',
        },
      },
      padding: { x: '2.5rem', y: '1.2rem' },
      [minSmallMediaQuery]: {
        padding: { x: '10rem', y: '1.5rem' },
      },
    };

    if (this.props.isCheckoutPage) {
      return <HeaderPlain order={this.props.order} goHomeText={translate('/Checkout/Header/ContinueShopping')} />;
    } else if (this.props.isUnwatchPage) {
      return <HeaderPlain />;
    }

    return (
      <Basic css={{ order }} appearance={Appearance.Full}>
        <HeaderContainer>
          <UpperHeader>
            <UpperHeaderLeft>
              <Menu onClick={this.toggleMenu}>
                <MenuIconStyled />
              </Menu>
            </UpperHeaderLeft>
            <UpperHeaderMiddle>
              <Home to="/" onClick={this.scrollToTop}>
                <DefaultLogo style={{ width: 100, height: 100, position: 'absolute', top: -32 }} />
              </Home>
            </UpperHeaderMiddle>
            <UpperHeaderRight>
              <MyCart />
            </UpperHeaderRight>
          </UpperHeader>
          <LowerHeader>
            <SearchFormStyled />
          </LowerHeader>
        </HeaderContainer>
        {this.props.headerLinkButtons !== null && <HeaderLinkButtons items={this.props.headerLinkButtons} />}

        {!!siteBanner && (
          <React.Fragment>
            <SiteBanner
              css={this.props.headerLinkButtons !== null ? siteBannerUnderLinkButtonsStyle : siteBannerStyle}
              text={siteBanner.text}
              textColor={siteBanner.textColor}
              hideTitle={siteBanner.hideTitle}
              backgroundColor={siteBanner.backgroundColor}
              bannerLink={siteBanner.bannerLink}
              discountLink={siteBanner.discountLink}
              hideDiscountCountdown={siteBanner.hideDiscountCountdown}
              open={bannerOpen}
              onClose={this.closeBanner}
              onHeightChange={this.handleBannerHeightChange}
              onUnmount={this.resetBanner}
            />
          </React.Fragment>
        )}
      </Basic>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    isCheckoutPage: isCurrentPageCheckout(state.currentPage),
    isOrderConfirmationPage: isCurrentPageOrderConfirmation(state.currentPage),
    isUnwatchPage: isCurrentPageUnwatch(state.currentPage),
    siteBanner: state.appShellData.siteBanner,
    loginPageUrl: state.appShellData.siteSettings.loginPage.url,
    customerServicePageUrl: state.appShellData.siteSettings.customerServiceMainPage?.url,
    headerLinkButtons: state.appShellData.mobileMenu.headerLinkButtons,
  }),
  (dispatch): ConnectDispatchType => ({
    toggleMainMenu() {
      return dispatch(toggleMainMenu());
    },
  }),
)(HeaderMobileAlternateVersion);

export const MENU_BAR_HEIGHT = pixelsToUnit(50);

const MenuIconStyled = styled(MenuIcon, {
  height: '100%',
  color: 'white',
  fontSize: '1.2rem',
});

const HeaderContainer = styled.div({
  height: pixelsToUnit(96),
});

const UpperHeader = styled.header({
  backgroundColor: '#eb008b',
  display: 'flex',
  height: '50px',
  justifyContent: 'space-between',
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: ZIndex.Panel,
  padding: { left: 16, right: 12 },
  [minSmallMediaQuery]: {
    padding: { x: '10rem', y: 0 },
  },
});

const UpperHeaderLeft = styled.div({
  position: 'relative',
  display: 'flex',
});

const UpperHeaderMiddle = styled.div({
  display: 'flex',
  justifyContent: 'center',
  paddingRight: 10,
});

const UpperHeaderRight = styled.div({
  position: 'relative',
  display: 'flex',
});

const SearchFormStyled = styled(SearchForm, {
  width: '100%',
});

const LowerHeader = styled.header({
  backgroundColor: '#eb008b',
  color: 'black',
  height: pixelsToUnit(52),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: '50px',
  width: '100%',
  zIndex: ZIndex.Header,
  padding: { x: 16, y: 0 },
  [minSmallMediaQuery]: {
    padding: { x: '10rem', y: 0 },
  },
});

const Menu = styled.div({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'left',
  paddingTop: 10,
});

const Home = styled(Link, {
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  width: '100%',
});

const MyCart = styled(MiniCart, {
  position: 'absolute',
  bottom: 0,
  right: 0,
  height: '2.2rem',
  width: '2.6rem',
  borderRadius: '10%',
});
