import {
  UPDATE_FIELDS,
  UPDATE_STATUS,
  ReviewsAction,
  OPEN_MODAL,
  CLOSE_MODAL,
  FETCH_REVIEWS_COMPLETED,
  INITIALIZE_REVIEWS,
} from './action-creators';
import { ProductReviewType, ReviewsListPropType } from 'Shared/State';
import { Status } from './Modal';

type ReviewsType = ReviewsListPropType & ProductReviewType;
const initialState: ReviewsType = {
  isOpen: false,
  formFields: {
    rating: {
      value: 0,
      pristine: true,
      valid: false,
    },
    name: {
      value: '',
      pristine: true,
      valid: false,
    },
    text: {
      value: '',
      pristine: true,
      valid: false,
    },
  },
  status: Status.Initial,
  allReviews: [],
  id: '',
  page: 1,
  totalCount: 0,
};

export default function(state: ReviewsType = initialState, action: ReviewsAction) {
  switch (action.type) {
    case INITIALIZE_REVIEWS: {
      return {
        ...state,
        id: action.id,
        allReviews: action.allReviews,
        page: action.page,
        totalCount: action.totalCount,
      };
    }
    case OPEN_MODAL: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case CLOSE_MODAL: {
      return {
        ...state,
        isOpen: false,
        formFields: initialState.formFields,
        status: initialState.status,
      };
    }
    case UPDATE_FIELDS: {
      return {
        ...state,
        formFields: action.formFields,
      };
    }
    case UPDATE_STATUS: {
      return {
        ...state,
        status: action.status,
      };
    }
    case FETCH_REVIEWS_COMPLETED: {
      return {
        ...state,
        allReviews: state.allReviews.concat(action.allReviews),
        id: action.id,
        page: action.page,
        totalCount: action.totalCount,
      };
    }
    default:
      return state;
  }
}
