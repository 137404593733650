import React from 'react';
import { translate } from '@avensia/scope';
import { styled } from '@glitz/react';
import { Snackbar } from 'SiteLayout/Tray';
import Button, { Variant as ButtonVariant, Appearance as ButtonAppearance } from 'Shared/Button';
import { LoadFailure } from 'Shared/State';
import { pixelsToUnit } from 'Shared/Style';

const Base = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const Action = styled(Button, {
  marginLeft: '1ch',
  padding: {
    y: 0,
    x: pixelsToUnit(15),
  },
});

type PropType = {
  reload: () => void;
  loadFailure: LoadFailure;
};

type State = {
  visible: boolean;
  dismissed: LoadFailure;
};

export default class NetworkError extends React.Component<PropType, State> {
  ignoredOfflineWarning: Date;
  retriedWhileOffline: boolean;
  scrollPositionWhenErrorShown: number;
  scrollListener: () => void;
  constructor(props: PropType) {
    super(props);
    this.state = {
      visible: this.isVisible(props),
      dismissed: null,
    };
  }
  isVisible(props: PropType) {
    if (props.loadFailure === null) {
      return false;
    } else {
      return (
        this.state &&
        this.state.dismissed !== props.loadFailure &&
        // AccountButton handles failed requests that requires auth
        props.loadFailure.status !== 401 &&
        // Don't bother the user if we're showing a full version of the page
        (!props.loadFailure.isShowingFullCachedPage || props.loadFailure.status <= 500)
      );
    }
  }
  shouldComponentUpdate(nextProps: PropType, nextState: State) {
    return nextProps.loadFailure !== this.props.loadFailure || nextState.visible !== this.state.visible;
  }
  UNSAFE_componentWillReceiveProps(nextProps: PropType) {
    const visible = this.isVisible(nextProps);

    if (visible !== this.state.visible) {
      if (
        !this.retriedWhileOffline &&
        visible &&
        !isOnline() &&
        this.ignoredOfflineWarning &&
        !oneHourHasPassedFrom(this.ignoredOfflineWarning)
      ) {
        // Don't hassle the user by telling them about offline all the time
        return;
      }

      this.setState({
        visible,
        dismissed: null,
      });
    }
  }
  onRetry() {
    if (!isOnline()) {
      // If you click Retry we don't want to show the "You're offline" message again
      this.retriedWhileOffline = true;
    }
    this.props.reload();
  }
  onDismiss() {
    if (!isOnline()) {
      this.ignoredOfflineWarning = new Date();
      this.retriedWhileOffline = false;
    }
    this.setState({ visible: false, dismissed: this.props.loadFailure });
  }
  render() {
    const showOfflineMessage = !isOnline() && !this.retriedWhileOffline;

    return (
      this.state.visible && (
        <Snackbar>
          <Base>
            <span>{showOfflineMessage ? translate('/Errors/Offline') : translate('/Errors/PageLoad')}</span>
            {!showOfflineMessage && (
              // No reason to show a try again message when we know that the user is offline
              <Action
                variant={ButtonVariant.Small}
                appearance={ButtonAppearance.Primary}
                onClick={() => this.onRetry()}
              >
                {translate('/Errors/Retry')}
              </Action>
            )}
            <Action
              variant={ButtonVariant.Small}
              appearance={ButtonAppearance.Primary}
              onClick={() => this.onDismiss()}
            >
              {translate('/Shared/Dismiss')}
            </Action>
          </Base>
        </Snackbar>
      )
    );
  }
}

function oneHourHasPassedFrom(date: Date) {
  return (new Date().valueOf() - date.valueOf()) / 1000 > 3600;
}

function isOnline() {
  return navigator.onLine === false ? false : true;
}
