import React from 'react';
import { styled } from '@glitz/react';
import { Style } from '@glitz/type';
import { isBrowser, translate } from '@avensia/scope';
import Link from 'Shared/Link';
import MenuIcon from 'Shared/Icon/Menu';
import connect from 'Shared/connect';
import { pixelsToUnit, ZIndex } from 'Shared/Style';
import { Basic, Appearance } from 'Shared/PageLayout';
import SiteBanner, { DEFAULT } from 'Shared/Blocks/SiteBanner/SiteBannerBlock';
import SiteBannerType from 'Shared/Blocks/SiteBanner/SiteBannerBlock.type';
import { saveToStorage, shouldComponentUpdate as shouldSiteBannerUpdate } from 'Shared/Blocks/SiteBanner/helper';
import { Order } from 'SiteLayout';
import MiniCart from 'SiteLayout/Nav/MiniCart';
import SiteLogo from 'SiteLayout/Header/Logo';
import HeaderPlain from 'SiteLayout/Header/HeaderPlain';
import { toggleMainMenu } from 'SiteLayout/MainMenu/action-creators';
import isCurrentPageCheckout from 'Checkout/Pages/Checkout/current-page-is-checkout';
import isCurrentPageOrderConfirmation from 'Checkout/Pages/OrderConfirmation/current-page-is-order-confirmation';
import isCurrentPageUnwatch from 'WatchItems/current-page-is-unwatch';
import HeaderLinkButtons from './HeaderLinkButtons';
import InfoSupportIcon from 'Shared/Icon/InfoSupport';
import SearchForm from 'Search/QuickSearch';

type ConnectStateType = {
  isCheckoutPage: boolean;
  isOrderConfirmationPage: boolean;
  isUnwatchPage: boolean;
  loginPageUrl: string;
  siteBanner: SiteBannerType;
  customerServicePageUrl: string;
  headerLinkButtons: Scope.LinkItemCollection;
};

type ConnectDispatchType = {
  toggleMainMenu: (e?: React.MouseEvent<HTMLElement>) => void;
};

type PropType = ConnectStateType &
  ConnectDispatchType & {
    order: Order;
  };

type StateType = {
  bannerOpen: boolean;
  bannerHeight: number;
};

class HeaderMobile extends React.PureComponent<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      bannerOpen: false,
      bannerHeight: DEFAULT,
    };
  }

  componentDidMount() {
    if (isBrowser() && this.props.siteBanner) {
      if (shouldSiteBannerUpdate(this.props.siteBanner) && !this.props.isOrderConfirmationPage) {
        this.openBanner();
      }
    }
  }

  componentDidUpdate(prevProps: PropType, prevState: StateType) {
    if (
      (!this.props.isCheckoutPage && prevProps.isCheckoutPage) ||
      (!this.props.isUnwatchPage && prevProps.isUnwatchPage) ||
      (!this.props.isOrderConfirmationPage && prevProps.isOrderConfirmationPage)
    ) {
      if (this.props.siteBanner && shouldSiteBannerUpdate(this.props.siteBanner)) {
        this.openBanner();
      }
    }
    if (this.props.isOrderConfirmationPage && !prevProps.isOrderConfirmationPage && this.state.bannerOpen) {
      this.resetBanner();
    }
  }

  toggleMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.toggleMainMenu();
  };

  updateState(newState: { [key: string]: number | boolean }) {
    this.setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  openBanner() {
    this.updateState({ bannerOpen: true });
  }

  closeBanner = () => {
    saveToStorage(this.props.siteBanner);
    this.resetBanner();
  };

  resetBanner = () => this.updateState({ bannerOpen: false, bannerHeight: DEFAULT });
  handleBannerHeightChange = (bannerHeight: number) => this.updateState({ bannerHeight });

  render() {
    const { order, siteBanner } = this.props;
    const { bannerOpen, bannerHeight } = this.state;
    const siteBannerStyle: Style = {
      zIndex: ZIndex.SiteBanner,
      padding: { x: '2.5rem', y: 0 },
    };
    const siteBannerUnderLinkButtonsStyle: Style = {
      position: 'unset',
      top: '7.9rem',
      zIndex: ZIndex.SiteBanner,
      padding: { x: '2.5rem', y: 0 },
    };

    if (this.props.isCheckoutPage) {
      return <HeaderPlain order={this.props.order} goHomeText={translate('/Checkout/Header/ContinueShopping')} />;
    } else if (this.props.isUnwatchPage) {
      return <HeaderPlain />;
    }

    return (
      <Basic css={{ order }} appearance={Appearance.Full}>
        <HeaderContainer>
          <UpperHeader>
            <UpperHeaderLeft>
              <Home to="/" onClick={this.scrollToTop}>
                <SiteLogo mobile />
              </Home>
            </UpperHeaderLeft>
            <UpperHeaderRight>
              <MyCart />
            </UpperHeaderRight>
          </UpperHeader>
          <LowerHeader>
            <Menu onClick={this.toggleMenu}>
              <MenuIconStyled />
            </Menu>
            <SearchFormStyled />
            <CustomerServiceLink to={this.props.customerServicePageUrl}>
              <InfoSupportIconStyled />
            </CustomerServiceLink>
          </LowerHeader>
        </HeaderContainer>
        {this.props.headerLinkButtons !== null && <HeaderLinkButtons items={this.props.headerLinkButtons} />}

        {!!siteBanner && (
          <React.Fragment>
            {bannerOpen && <styled.Div css={{ height: bannerHeight, position: 'absolute' }} />}
            <SiteBanner
              css={this.props.headerLinkButtons !== null ? siteBannerUnderLinkButtonsStyle : siteBannerStyle}
              text={siteBanner.text}
              textColor={siteBanner.textColor}
              hideTitle={siteBanner.hideTitle}
              backgroundColor={siteBanner.backgroundColor}
              bannerLink={siteBanner.bannerLink}
              discountLink={siteBanner.discountLink}
              hideDiscountCountdown={siteBanner.hideDiscountCountdown}
              open={bannerOpen}
              onClose={this.closeBanner}
              onHeightChange={this.handleBannerHeightChange}
              onUnmount={this.resetBanner}
            />
          </React.Fragment>
        )}
      </Basic>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    isCheckoutPage: isCurrentPageCheckout(state.currentPage),
    isOrderConfirmationPage: isCurrentPageOrderConfirmation(state.currentPage),
    isUnwatchPage: isCurrentPageUnwatch(state.currentPage),
    siteBanner: state.appShellData.siteBanner,
    loginPageUrl: state.appShellData.siteSettings.loginPage.url,
    customerServicePageUrl: state.appShellData.siteSettings.customerServiceMainPage?.url,
    headerLinkButtons: state.appShellData.mobileMenu.headerLinkButtons,
  }),
  (dispatch): ConnectDispatchType => ({
    toggleMainMenu() {
      return dispatch(toggleMainMenu());
    },
  }),
)(HeaderMobile);

export const MENU_BAR_HEIGHT = pixelsToUnit(50);

const MenuIconStyled = styled(MenuIcon, {
  height: '100%',
});

const HeaderContainer = styled.div({
  height: pixelsToUnit(89),
});

const UpperHeader = styled.header({
  backgroundColor: (theme) => theme.headerBackgroundColorMobile,
  display: 'flex',
  height: '100%',
  justifyContent: 'space-between',
  maxHeight: MENU_BAR_HEIGHT,
  position: 'fixed',
  top: 0,
  width: '100%',
  zIndex: ZIndex.Header,
});

const UpperHeaderLeft = styled.div({
  display: 'flex',
  textAlign: 'left',
  alignContent: 'flex-start',
  alignItems: 'center',
  width: '10rem',
  paddingLeft: pixelsToUnit(15),
});

const UpperHeaderRight = styled.div({
  width: 'auto',
  display: 'flex',
  paddingRight: '1rem',
});

const InfoSupportIconStyled = styled(InfoSupportIcon, {
  color: (theme) => theme.customerServiceIconColor,
  fontSize: '2rem',
  marginRight: pixelsToUnit(8),
  paddingBottom: pixelsToUnit(4),
  zIndex: 2,
});

const SearchFormStyled = styled(SearchForm, {
  width: '70%',
});

const LowerHeader = styled.header({
  backgroundColor: (theme) => theme.menuBarBackgroundColor,
  color: (theme) => theme.menuBarTextColor,
  height: pixelsToUnit(37),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: {
    x: '1rem',
    y: 0,
  },
  position: 'fixed',
  top: MENU_BAR_HEIGHT,
  width: '100%',
  zIndex: ZIndex.Header,
});

const CustomerServiceLink = styled(Link, {
  textAlign: 'center',
  zIndex: 2,
  alignSelf: 'center',
});

const Menu = styled.div({
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'left',
});

const Home = styled(Link, {});

const MyCart = styled(MiniCart, {
  height: '2.2rem',
  width: '2.8rem',
  borderRadius: '10%',
  alignSelf: 'center',
});
