import { postJson, URLX, pathCombine, getJson } from '@avensia/scope';
import { Action, Dispatch, ProductReviewFormFieldType, ReviewsListPropType } from 'Shared/State';
import { FormDataType, Status } from './Modal';
import { showSpinner, hideSpinner } from '@avensia/scope/Spinner/action-creators';
import ReviewsResultViewModel from 'Reviews/Models/ReviewsResultViewModel.type';
import ReviewViewModel from 'Reviews/Models/ReviewViewModel.type';

export const UPDATE_FIELDS = 'UPDATE_FIELDS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const FETCH_REVIEWS_COMPLETED = 'FETCH_REVIEWS_COMPLETED';
export const INITIALIZE_REVIEWS = 'INITIALIZE_REVIEWS';
const URL = '/review/add';

export type ReviewsAction = Action & {
  formFields?: ProductReviewFormFieldType;
  status?: Status;
} & Partial<ReviewsListPropType>;

export function openModal() {
  return {
    type: OPEN_MODAL,
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL,
  };
}

export function edit(formFields: ProductReviewFormFieldType) {
  return {
    type: UPDATE_FIELDS,
    formFields,
  };
}

export function submit(formData: FormDataType) {
  return async (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_STATUS,
      status: Status.InProgress,
    } as ReviewsAction);
    try {
      const result: boolean = await postJson(URL, formData);
      if (result) {
        dispatch({
          type: UPDATE_STATUS,
          status: Status.Success,
        } as ReviewsAction);
      } else {
        throw new Error(
          `catalogId: ${formData.catalogId}, productCode: ${formData.productCode}, language: ${formData.language}`,
        );
      }
    } catch (e) {
      dispatch({
        type: UPDATE_STATUS,
        status: Status.Error,
      } as ReviewsAction);
      return Promise.reject(e);
    }
  };
}

export function getReviews(url: string, id: string, page: number) {
  return async (dispatch: Dispatch) => {
    dispatch(showSpinner());
    try {
      const reviewsUrl = new URLX(pathCombine(url, 'getreviews'));
      reviewsUrl.searchParams.set('page', page.toString());
      const data: ReviewsResultViewModel = await getJson(reviewsUrl);
      if (data) {
        dispatch({
          id,
          type: FETCH_REVIEWS_COMPLETED,
          allReviews: data.reviews,
          page,
          totalCount: data.totalCount,
        } as ReviewsAction);
        dispatch(hideSpinner());
      }
    } catch (error) {
      console.log('failed in getting reviews');
      dispatch(hideSpinner());
    }
  };
}

export function initializeReviews(id: string, reviews: ReviewViewModel[], page: number, totalCount: number) {
  return async (dispatch: Dispatch) => {
    dispatch({
      id,
      type: INITIALIZE_REVIEWS,
      allReviews: reviews,
      page,
      totalCount,
    } as ReviewsAction);
  };
}
