import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { pixelsToUnit, thin, alto, vividtangerine } from 'Shared/Style';

type PropType = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  valid?: boolean;
  onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
} & StyledProps;

const TextArea = styled.textarea({
  borderRadius: 0,
  padding: {
    y: pixelsToUnit(15),
    x: pixelsToUnit(20),
  },
  resize: 'none',
  width: '100%',
});

export default styled((props: PropType) => {
  const { valid, compose, ...restProps } = props;
  return (
    <TextArea
      css={compose({
        border: {
          xy: {
            width: thin,
            style: 'solid',
            color: valid ? alto : vividtangerine,
          },
        },
      })}
      {...restProps}
    />
  );
});
