import React from 'react';
import { translate, isIOS, isIE, isEdge, Breakpoint } from '@avensia/scope';
import { styled } from '@glitz/react';
import { Style } from '@glitz/type';
import {
  connectWithFeedback,
  ConnectPropType as FeedbackButtonPropType,
  Appearance as ButtonAppearance,
  Behavior as ButtonBehavior,
} from 'Shared/Button/Feedback';
import Text from 'Shared/Fields/Text';
import Textarea from 'Shared/Fields/Textarea';
import {
  minTinyMediaQuery,
  pixelsToUnit,
  epsilon,
  large,
  lighterBlack,
  vividtangerine,
  mountainmeadow,
  carnation,
  beta,
  small,
  thin,
  alto,
} from 'Shared/Style';
import connect from 'Shared/connect';
import { ProductReviewFormFieldType } from 'Shared/State';
import WritableRatings from './Ratings/Writable';
import Icon from 'Shared/Icon/Close';
import { edit, submit } from './action-creators';
import freezeScroll from 'Shared/freeze-scroll';

export type FormDataType = {
  rating: number;
  aliasName: string;
  text: string;
  catalogId: number;
  language: string;
  productCode: string;
};

export enum Status {
  Initial,
  InProgress,
  Success,
  Error,
}

type RequiredPropType = {
  catalogId: number;
  language: string;
  productCode: string;
  close: () => void;
};

type ConnectStateType = {
  status: Status;
  formFields: ProductReviewFormFieldType;
  currentBreakpoint: number;
};

type ConnectActionType = {
  editForm: (fieldObj: ProductReviewFormFieldType) => void;
  submitForm: (formData: FormDataType) => Promise<void>;
};

type PropType = RequiredPropType & FeedbackButtonPropType & ConnectStateType & ConnectActionType;
class Modal extends React.Component<PropType> {
  unfreezeScroll: () => void;
  componentDidMount() {
    this.scrollFreeze();
    if (isIOS()) {
      document.body.setAttribute('style', 'position: fixed');
    }
  }

  componentDidUpdate(prevProps: PropType) {
    if (this.props.status === Status.Success && prevProps.status === Status.InProgress) {
      setTimeout(() => this.props.close(), CLOSE_ON_SUCCESS_TIMEOUT);
    }
  }

  componentWillUnmount() {
    this.scrollFreeze();
    if (isIOS()) {
      document.body.removeAttribute('style');
    }
  }

  scrollFreeze() {
    if (this.props.currentBreakpoint < Breakpoint.Medium) {
      if (this.unfreezeScroll) {
        this.unfreezeScroll();
      }
      this.unfreezeScroll = !this.unfreezeScroll ? freezeScroll() : null;
    }
  }

  handleInputChange = (event: React.FormEvent<HTMLElement>) => {
    const { value, name: stateProp } = event.target as HTMLInputElement;
    this.props.editForm({
      ...this.props.formFields,
      [stateProp as any]: {
        value,
        pristine: false,
        valid: Boolean(value),
      },
    });
  };

  handleRate = (value: number) => {
    this.props.editForm({
      ...this.props.formFields,
      rating: {
        value,
        pristine: false,
        valid: Boolean(value),
      },
    });
  };

  async request() {
    const {
      catalogId,
      language,
      productCode,
      formFields: { rating, name, text },
    } = this.props;
    const data = {
      rating: rating.value,
      aliasName: name.value,
      text: text.value,
      catalogId,
      language,
      productCode,
    };

    try {
      await this.props.submitForm(data);
    } catch (e) {
      const error = e.hasOwnProperty('status') ? e.status : e;
      console.error(`Submit request error: ${error}`);
      return Promise.reject(null);
    }
  }

  submit = () => this.props.feedback.push(this.request());

  render() {
    const {
      formFields: { rating, name, text },
      status,
    } = this.props;
    const message =
      status === Status.Success
        ? translate('/Product/Reviews/Modal/ReviewSuccess')
        : translate('/Errors/ReviewProduct');

    const labelErrorCss = {
      color: vividtangerine,
      textDecoration: 'underline',
    };
    const nameInvalid = !name.valid && !name.pristine;
    const ratingsLabelStyle: Style = !rating.valid && !rating.pristine && labelErrorCss;
    const nameLabelStyle: Style = !name.valid && !name.pristine && labelErrorCss;
    const commentsLabelStyle: Style = !text.valid && !text.pristine && labelErrorCss;
    const nameBorderStyle: Style = {
      border: {
        xy: {
          width: thin,
          style: 'solid',
          color: nameInvalid ? vividtangerine : alto,
        },
      },
    };
    const ratingsStyle: Style = {
      ...(status === Status.InProgress && { pointerEvents: 'none', opacity: 0.5 }),
      color: rating.valid || rating.pristine ? lighterBlack : vividtangerine,
    };
    const infoMessageStyle: Style = {
      color: status === Status.Success ? mountainmeadow : carnation,
      opacity: status === Status.Success || status === Status.Error ? 1 : 0,
    };
    const commentsPlaceholderStyle: Style = {
      color: (theme) => theme.inputPlaceholderTextColor,
      fontSize: epsilon,
      letterSpacing: pixelsToUnit(0.4),
      fontWeight: 'normal',
    };
    const submitButtonStyle: Style = {
      alignSelf: 'stretch',
      margin: { x: 0 },
      padding: { x: 0 },
      textAlign: 'center',
      [minTinyMediaQuery]: {
        alignSelf: 'center',
        padding: { x: pixelsToUnit(30) },
      },
    };

    return (
      <Base>
        <Close onClick={this.props.close} />
        <Header>{translate('/Product/Reviews/Modal/Heading')}</Header>
        <ScrollableArea>
          <Form noValidate>
            <Label css={ratingsLabelStyle}>{translate('/Product/Reviews/Modal/Label/Rating')}</Label>
            <Ratings css={ratingsStyle} rating={rating.value} onRate={this.handleRate} />
            <Label htmlFor="name" css={nameLabelStyle}>
              {translate('/Product/Reviews/Modal/Label/Name')}
            </Label>
            <Name
              id="name"
              invalid={nameInvalid}
              name="name"
              type="text"
              placeholder={translate('/Product/Reviews/Modal/Placeholder/Name')}
              value={name.value}
              disabled={status === Status.InProgress}
              onChange={this.handleInputChange}
              css={nameBorderStyle}
            />
            <Label htmlFor="review" css={commentsLabelStyle}>
              {translate('/Product/Reviews/Modal/Label/Review')}
            </Label>
            <Comments
              css={{
                '::placeholder': commentsPlaceholderStyle,
                ...((isIE() || isEdge()) && { ':-ms-input-placeholder': commentsPlaceholderStyle }),
              }}
              id="review"
              name="text"
              placeholder={translate('/Product/Reviews/Modal/Placeholder/Review')}
              value={text.value}
              valid={text.valid || text.pristine}
              disabled={status === Status.InProgress}
              onChange={this.handleInputChange}
            />
            <this.props.feedback.Button
              css={submitButtonStyle}
              type="button"
              appearance={ButtonAppearance.Secondary}
              disabled={!rating.valid || !name.valid || !text.valid}
              onClick={this.submit}
            >
              {translate('/Product/Reviews/Modal/SubmitReview')}
            </this.props.feedback.Button>
            <Message css={infoMessageStyle}>{message}</Message>
          </Form>
        </ScrollableArea>
      </Base>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    formFields: state.productReview.formFields,
    status: state.productReview.status,
    currentBreakpoint: state.currentBreakpoint,
  }),
  (dispatch): ConnectActionType => ({
    editForm(fieldObj: ProductReviewFormFieldType) {
      dispatch(edit(fieldObj));
    },
    submitForm(formData: FormDataType) {
      return dispatch(submit(formData));
    },
  }),
)(connectWithFeedback({ behavior: ButtonBehavior.SingleSuccess })(Modal));

const CLOSE_ON_SUCCESS_TIMEOUT = 1800;
const Base = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
});

const Close = styled(Icon, {
  alignSelf: 'flex-end',
  cursor: 'pointer',
  flexGrow: 0,
  flexShrink: 0,
  height: pixelsToUnit(40),
  margin: {
    top: pixelsToUnit(40),
    right: pixelsToUnit(20),
    bottom: pixelsToUnit(20),
  },
  width: pixelsToUnit(40),
  [minTinyMediaQuery]: {
    marginRight: pixelsToUnit(40),
  },
});

const Header = styled.h1({
  fontSize: beta,
  fontWeight: 'normal',
  lineHeight: 'normal',
  letterSpacing: pixelsToUnit(3),
  marginBottom: pixelsToUnit(25),
  textAlign: 'center',
  textTransform: 'uppercase',
});

const ScrollableArea = styled.div({
  overflow: 'auto',
});

const Form = styled.form({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '75%',
  margin: { x: 'auto' },
  width: '100%',
  [minTinyMediaQuery]: {
    maxWidth: '61%',
  },
});

const Ratings = styled(WritableRatings, {
  marginBottom: pixelsToUnit(20),
});

const Label = styled.label({
  color: lighterBlack,
  fontSize: epsilon,
  letterSpacing: pixelsToUnit(0.9),
  marginBottom: small,
  textDecoration: 'none',
  ':after': {
    display: 'inline-block',
    content: "' *'",
    color: '#f35a5a',
    textDecoration: 'none',
  },
});

const Name = styled(Text, {
  paddingLeft: pixelsToUnit(20),
  marginBottom: pixelsToUnit(25),
});

const Comments = styled(Textarea, {
  flexBasis: pixelsToUnit(190),
  flexGrow: 0,
  flexShrink: 0,
  marginBottom: pixelsToUnit(25),
});

const Message = styled.div({
  fontSize: large,
  letterSpacing: pixelsToUnit(0.9),
  marginTop: small,
  textAlign: 'center',
});
