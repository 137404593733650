import React from 'react';
import { styled, StyledProps, applyClassName, StyledElementProps } from '@glitz/react';
import appearance, { AppearanceType } from '../appearance';
import {
  huge,
  small,
  pixelsToUnit,
  pageMaxWidth,
  minMediumMediaQuery,
  minLargeMediaQuery,
  minSmallMediaQuery,
} from '../Style';

export enum Appearance {
  Full,
  Narrow,
  Mixed,
  Part,
  Main,
}

export enum Layout {
  OneToOne,
  TwoToThree,
  ThreeToTwo,
  OneToThree,
  ThreeToOne,
  OneToFive,
  ZeroToOne,
}

type LayoutPropType = {
  fraction: number;
  order: number;
};

// Gives a layout based on visual order for wider windows like desktop,
// but sorts columns by ratio descendingly so the greatest ratio will be
// the first child and the less ratio will be the last
//
// E.g. `[3, 5, 2]` will display a layout like:
// ________________________
// |30%___|50%_______|20%_|
//
// But DOM order and visual order för narrower windows will be:
// ________________________
// |50%_______|30%___|20%_|
function entity(layout: number[]): LayoutPropType[] {
  const total = layout.reduce((a, b) => a + b, 0);
  return layout.map((ratio, order) => ({
    order,
    fraction: ratio / total,
  }));
}

const entities = {
  [Layout.OneToOne]: entity([1, 1]),
  [Layout.TwoToThree]: entity([2, 3]),
  [Layout.ThreeToTwo]: entity([3, 2]),
  [Layout.OneToThree]: entity([1, 3]),
  [Layout.ThreeToOne]: entity([3, 1]),
  [Layout.OneToFive]: entity([1, 5]),
  [Layout.ZeroToOne]: entity([0, 1]),
};

const GUTTER_LEFT = pixelsToUnit(26);
const GUTTER_RIGHT = pixelsToUnit(34);
const Child: React.StatelessComponent<LayoutPropType> = ({ fraction, order, children }) => (
  <styled.Div
    css={{
      [minMediumMediaQuery]: {
        width: `calc(${fraction * 100}% - (${GUTTER_LEFT} - ${GUTTER_RIGHT} * ${fraction}))`,
        order,
      },
    }}
  >
    {children}
  </styled.Div>
);

const maxWidth = pixelsToUnit(pageMaxWidth);

type PropType<TElement> = {
  appearance?: AppearanceType<Appearance>;
  layout?: Layout;
  elementRef?: React.Ref<TElement>;
};

export function factory<TElement extends HTMLElement>(tagName: string, defaults: AppearanceType<Appearance> = []) {
  type FactoryPropType = PropType<TElement> & React.HTMLAttributes<TElement>;

  const Base = styled(
    applyClassName(
      class Layout extends React.Component<FactoryPropType & StyledElementProps> {
        render() {
          const restProps = Object.assign({}, this.props) as FactoryPropType;
          delete restProps.appearance;
          delete restProps.layout;
          delete restProps.elementRef;

          const Element = tagName as string;
          let children = this.props.children;

          if (typeof this.props.layout === 'number') {
            const layout = entities[this.props.layout];
            children = React.Children.map(this.props.children, (child, i) => {
              if (layout[i]) {
                return <Child {...layout[i]}>{child}</Child>;
              }
              if (process.env.NODE_ENV !== 'production') {
                console.warn(
                  `Child index ${i} was not rendered because it exceeded the number of allowed children of ${layout.length}`,
                );
              }
            });
          }

          return (
            <Element {...(restProps as any)} ref={this.props.elementRef}>
              {children}
            </Element>
          );
        }
      },
    ),
  );

  return styled(({ compose, ...restProps }: FactoryPropType & StyledProps) => {
    const appear = appearance(([] as Appearance[]).concat(defaults, restProps.appearance));
    return (
      <Base
        {...restProps}
        css={compose({
          ...((appear(Appearance.Full) || appear(Appearance.Narrow) || appear(Appearance.Mixed)) && {
            margin: {
              x: 'auto',
            },
          }),
          ...(appear(Appearance.Full) && {
            maxWidth,
            width: '100%',
          }),
          ...(appear(Appearance.Narrow) &&
            (appear(Appearance.Main)
              ? {
                  paddingLeft: small,
                  paddingRight: small,
                  [minLargeMediaQuery]: {
                    paddingLeft: huge,
                    paddingRight: huge,
                  },
                }
              : {
                  maxWidth: `calc(${maxWidth} - ${small} * 2)`,
                  width: `calc(100vw - ${small} * 2)`,
                  [minSmallMediaQuery]: {
                    maxWidth: `calc(${maxWidth} - ${huge} * 2)`,
                    width: `calc(100vw - ${huge} * 2)`,
                  },
                })),
          ...(appear(Appearance.Mixed) && {
            maxWidth,
            width: `calc(100vw - ${huge} * 2)`,
          }),
          ...(appear(Appearance.Part) && {
            marginBottom: huge,
          }),
          ...(appear(Appearance.Main) && {
            paddingTop: small,
            paddingBottom: small,
            [minMediumMediaQuery]: {
              paddingTop: huge,
              paddingBottom: huge,
            },
          }),
          ...(typeof restProps.layout === 'number' && {
            [minMediumMediaQuery]: {
              display: 'flex',
              justifyContent: 'space-between',
            },
          }),
        })}
      />
    );
  });
}

export const Basic = factory<HTMLDivElement>('div');

export const Part = factory<HTMLDivElement>('div', Appearance.Part);

export const Section = factory('section', Appearance.Part);

export const Article = factory('article', Appearance.Part);

export const Form = factory<HTMLFormElement>('form', Appearance.Part);

export const Main = factory<HTMLElement>('main', [Appearance.Main, Appearance.Full]);
