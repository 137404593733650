import { currentUrl, replaceState } from '@avensia/scope';
import { addToCart } from './action-creators';
import { Store } from 'Shared/State';

const queryParam = 'ScriptIntoCart';

export default (store: Store) => {
  const url = currentUrl();
  const queryValue = url.searchParams.get[queryParam];
  if (queryValue && typeof queryValue === 'string') {
    const add = queryValue.split('+').map(value => {
      value = value.trim();
      if (!value.includes('*')) {
        value = value + '*1'; // default quantity
      }

      const [code, quantity] = value.split('*');
      return {
        code,
        quantity: parseInt(quantity, 10) || 1,
      };
    });

    Promise.all(add.map(add => store.dispatch(addToCart(add.code, add.quantity, null, 'AddToCartFromLink'))));

    // `replaceState` because if the user refreshes the page we don't want to
    // add the item again
    delete url.searchParams.delete[queryParam];
    replaceState(url);
  }
};
