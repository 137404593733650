import { addUserLog } from '@avensia/scope';
import { Action } from 'Shared/State';
import { ItemKeyType } from './item';

export const TOGGLE_MAINMENU = 'TOGGLE_MAINMENU';
export const CURRENT_VISIBLE_KEY = 'CURRENT_VISIBLE_KEY';
export const TOGGLE_STICKY_NAVBAR = 'TOGGLE_STICKY_NAVBAR';

export function toggleMainMenu() {
  addUserLog('Toggling main menu');
  return {
    type: TOGGLE_MAINMENU,
  } as Action;
}

export function toggleStickyNavbar() {
  addUserLog('Toggling sticky nav bar');
  return {
    type: TOGGLE_STICKY_NAVBAR,
  } as Action;
}

export function navigateMainMenu(key: ItemKeyType) {
  return {
    type: CURRENT_VISIBLE_KEY,
    key,
  } as Action;
}
