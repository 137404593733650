import { styled } from '@glitz/react';
import { moderate, redRibbon } from 'Shared/Style';

export const NeutralMessage = styled.div({
  color: theme => theme.textColor,
  marginTop: moderate,
});

export const ErrorMessage = styled(NeutralMessage, {
  color: redRibbon,
});
