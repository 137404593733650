import { Action, RecentlyViewedType } from 'Shared/State';
import {
  TOGGLE_RECENTLY_VIEWED,
  FETCH_RECENTLY_VIEWED_REQUEST,
  FETCH_RECENTLY_VIEWED_SUCCESS,
  FETCH_RECENTLY_VIEWED_FAILURE,
  RecentlyViewedAction,
} from './action-creators';

export default function(state: RecentlyViewedType = null, action: Action): RecentlyViewedType {
  if (state === null) {
    state = {
      products: [],
      isFetching: false,
      hasFailed: false,
      isShown: false,
    };
  }
  switch (action.type) {
    case FETCH_RECENTLY_VIEWED_REQUEST: {
      return {
        ...state,
        isFetching: true,
        hasFailed: false,
      };
    }
    case FETCH_RECENTLY_VIEWED_SUCCESS: {
      const { payload } = action as RecentlyViewedAction;
      return {
        ...state,
        products: payload,
        isFetching: false,
        hasFailed: false,
      };
    }
    case FETCH_RECENTLY_VIEWED_FAILURE: {
      return {
        ...state,
        isFetching: false,
        hasFailed: true,
      };
    }
    case TOGGLE_RECENTLY_VIEWED: {
      return {
        ...state,
        isShown: !state.isShown,
      };
    }
    default:
      return state;
  }
}
