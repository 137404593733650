/**
 * @ComponentFor MobileMenuImageBlock
 */
import React from 'react';
import { styled } from '@glitz/react';
import Image from 'Shared/Image';
import Link from 'Shared/Link';
import { thin } from 'Shared/Style';

type PropType = {
  image: string;
  link: string;
};

const MobileMenuImageBlock = (props: PropType) => {
  return (
    <ImageWrapper to={props.link}>
      <ImageStyled src={props.image} />
    </ImageWrapper>
  );
};

export default MobileMenuImageBlock;

const ImageStyled = styled(Image, {
  flexShrink: 0,
  height: 'auto',
  width: '100%',
});

const ImageWrapper = styled(Link, {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: thin,
});
