import React from 'react';
import { pseudo } from '@glitz/core';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import { minMediumMediaQuery, kappa, letterSpacingTiny, theta, pixelsToUnit } from 'Shared/Style';
import Link from 'Shared/Link';
import LanguageSiteType from 'AppShell/LanguageSite.type';

type PropType = {
  languageSites: LanguageSiteType[];
};

const Item = styled.span({
  color: 'inherit',
  fontSize: kappa,
  letterSpacing: letterSpacingTiny,
  [minMediumMediaQuery]: {
    fontSize: theta,
    ':first-child': {
      marginRight: pixelsToUnit(10),
    },
    ...pseudo(':not(:first-child):not(:last-child):after', {
      content: '"|"',
      margin: {
        x: pixelsToUnit(10),
      },
    }),
  },
});

const Section = styled.section({
  display: 'flex',
  flexFlow: 'column nowrap',
  [minMediumMediaQuery]: {
    flexFlow: 'row nowrap',
    justifyContent: 'center',
  },
});

const LanguageSites = (props: PropType) => (
  <Section>
    <Item>{translate('/Footer/LanguageSites/MoreLanguages')}</Item>
    {props.languageSites.map(languageSite => (
      <Item key={languageSite.language}>
        <Link
          css={{
            color: 'inherit',
          }}
          to={languageSite.url}
        >
          {linkText(languageSite)}
        </Link>
      </Item>
    ))}
  </Section>
);

function linkText(languageSite: LanguageSiteType) {
  switch (languageSite.language) {
    case 'sv-SE':
      return translate('/Footer/LanguageSites/se') + languageSite.name;
    case 'nb-NO':
      return translate('/Footer/LanguageSites/no') + languageSite.name;
    case 'fi-FI':
      return translate('/Footer/LanguageSites/fi') + languageSite.name;
    case 'da-DK':
      return translate('/Footer/LanguageSites/dk') + languageSite.name;
    case 'en-US':
      return translate('/Footer/LanguageSites/en') + languageSite.name;
    default:
      return '';
  }
}

export default LanguageSites;
