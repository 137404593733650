import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { pixelsToUnit, minHugeMediaQuery, minMediumMediaQuery, minLargeMediaQuery } from 'Shared/Style';
import connect from 'Shared/connect';
import { MainMenuType } from 'Shared/State';
import Link from 'Shared/Link';
import { ItemKeyType, ROOT_KEY } from 'SiteLayout/MainMenu/item';
import { navigateMainMenu, toggleMainMenu } from 'SiteLayout/MainMenu/action-creators';
import Ul from 'Shared/Generic/Ul';
import NavigationItem from 'Shared/NavigationItem/NavigationItem.type';
import { isIE } from '@avensia/scope';

type ConnectStateType = {
  mainMenu: MainMenuType;
};

type ConnectActionType = {
  updateMenuKey: (key: ItemKeyType) => void;
  toggleMenu: () => void;
};

type PropType = ConnectStateType & ConnectActionType & StyledProps;
type MenuItemPropType = NavigationItem & {
  onClick: (url: string) => void;
  isCurrentOpen: boolean;
};

function MenuItem({ url, name, children, isCurrentOpen, onClick }: MenuItemPropType) {
  function handleItemClick(event: React.MouseEvent<HTMLSpanElement>) {
    event.stopPropagation();
    onClick(url);
  }
  return (
    <Li
      key={url}
      css={{
        ...(isIE() && {
          [minMediumMediaQuery]: {
            width: '100%',
          },
          [minLargeMediaQuery]: {
            width: 'auto',
          },
        }),
        ...(isCurrentOpen && {
          fontWeight: 'bold',
        }),
      }}
    >
      {children && children.length > 0 ? (
        <Item onClick={handleItemClick}>
          <MenuName
            css={{
              ':after': {
                // Reserve space for text in bold to get rid of jumping elements
                content: `"${name}"`,
                fontWeight: 'bold',
                visibility: 'hidden',
                overflow: 'hidden',
                display: 'block',
                height: 1,
                color: 'transparent',
              },
            }}
          >
            {name}
          </MenuName>
        </Item>
      ) : (
        <ItemLink to={url} includeAppShellData>
          <MenuName>{name}</MenuName>
        </ItemLink>
      )}
    </Li>
  );
}

function NavDesktop(props: PropType) {
  const { mainMenu, toggleMenu, updateMenuKey } = props;
  const { primaryMenuItems, isOpen, visibleKey } = mainMenu;
  const menuItems = primaryMenuItems.map((menuItem) => (
    <MenuItem
      key={menuItem.url}
      isCurrentOpen={isOpen && menuItem.url === visibleKey}
      {...menuItem}
      onClick={handleItemClick}
    />
  ));

  function handleItemClick(itemUrl: string) {
    if (visibleKey === ROOT_KEY || itemUrl === visibleKey || (itemUrl !== visibleKey && !isOpen)) {
      toggleMenu();
    }
    updateMenuKey(itemUrl);
  }

  return (
    <MenuNav css={props.compose()}>
      <Menu row bare>
        {menuItems}
      </Menu>
    </MenuNav>
  );
}

export default connect(
  (state): ConnectStateType => ({
    mainMenu: state.mainMenu,
  }),
  (dispatch): ConnectActionType => ({
    updateMenuKey(key: ItemKeyType) {
      return dispatch(navigateMainMenu(key));
    },
    toggleMenu() {
      return dispatch(toggleMainMenu());
    },
  }),
)(NavDesktop);

const Menu = styled(Ul, {
  alignItems: 'center',
  height: '100%',
  justifyContent: 'center',
});

const Li = styled.li({
  cursor: 'pointer',
  display: 'flex',
  marginRight: pixelsToUnit(15),
  justifyContent: 'center',
  ':last-of-type': {
    marginRight: pixelsToUnit(5),
  },
  [minHugeMediaQuery]: {
    marginRight: pixelsToUnit(22),
    ':last-of-type': {
      marginRight: pixelsToUnit(15),
    },
  },
});

const textStyled = styled({
  color: (theme) => theme.menuBarTextColor,
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    alignItems: 'center',
    display: 'flex',
  },
  [minHugeMediaQuery]: {
    display: 'list-item',
  },
});

const Item = textStyled(styled.Span);

const ItemLink = textStyled(Link);

const MenuName = styled.span({
  textAlign: 'center',
  [minHugeMediaQuery]: {
    textAlign: 'left',
  },
});
const MenuNav = styled.nav({
  [minMediumMediaQuery]: {
    padding: {
      X: pixelsToUnit(10),
    },
  },
  [minHugeMediaQuery]: {
    padding: {
      X: pixelsToUnit(0),
    },
  },
});
