// color names from http://chir.ag/projects/name-that-color
export const primary = '#333';
export const monochromeDark = '#d8d8d8';
export const white = '#ffffff';
export const black = '#000000';
export const mineshaft = '#2f2f2f';
export const athensGray = '#F1EFF2';
export const greyText = '#515151';
export const wildSand = '#F5F5F5';
export const concrete = '#F3F3F3';
export const gallery = '#EFEFEF';
export const mercurySolid = '#dddddd';
export const alto = '#d1d1d1';
export const lightSilver = '#CCCCCC';
export const mercury = '#e9e9e9';
export const silver = '#bebebe';
export const silverchalice = '#a6a6a6';
export const dustygray = '#9c9c9c';
export const lightGrey = '#979797';
export const gray = '#828282';
export const darkerGray = '#7E7E7E';
export const boulder = '#797979';
export const scorpion = '#595959';
export const tundora = '#434343';
export const bombay = '#b5b5bb';
export const steelgray = '#242334';
export const ebony = '#0c0c1b';
export const soapStone = '#fffcfa';
export const wafer = '#dccdc2';
export const rodeoDust = '#c8a992';
export const driftwood = '#af8751';
export const desertSand = '#f1cfb8';
export const almond = '#f1d9c7';
export const potPourri = '#f4dfd1';
export const whiteLinen = '#fbf4ef';
export const bianca = '#fdf9f7';
export const vistaWhite = '#fbf7f4';
export const amaranth = '#e82847';
export const fuzzyWuzzyBrown = '#c14855';
export const mexicanRed = '#a32331';
export const flushMahogny = '#ca3a4e';
export const wellRead = '#b5303f';
export const monza = '#be0034';
export const shiraz = '#a30a33';
export const radicalRed = '#d2093b';
export const codgray = '#171717';
export const burgundy = '#860025';
export const venetianred = '#770021';
export const emperor = '#4f4f4f';
export const wisteria = '#9064ac';
export const bossanova = '#513265';
export const bluePurple = '#351e54';
export const chateaugreen = '#3a9a59';
export const fruitSalad = '#549267';
export const mountainmeadow = '#13a970';
export const carnation = '#f35a5a';
export const vividtangerine = '#ff7e7e';
export const lightBlack = '#3b3b3b';
export const lighterBlack = '#202020';
export const wildwatermelon = '#ff4b6a';
export const neoncarrot = '#ffa638';
export const radicalred = '#f9275a';
export const whitelilac = '#fafbfd';
export const corduroy = '#646a68';
export const snuff = '#e4d9eb';
export const byzantine = '#551a8b';
export const muave = '#5b3871';
export const revolver = '#331f40';
export const sun = '#ffa412';
export const amber = '#FFBF00';
export const tiaMaria = '#d05e0e';
export const caribbeanGreen = '#00e0a0';
export const jade = '#00be72';
export const downy = '#6cd0be';
export const jungleGreen = '#2da58e';
export const surfCrest = '#D8EBDE';
export const seaMist = '#C3DACA';
export const navyBlue = '#060267';
export const fern = '#59BD78';
export const harp = '#E8F3EB';
export const babyLeaf = '#85B78E';
export const bondiBlue = '#0588AE';
export const bahamaBlue = '#057595';
export const minsk = '#34327B';
export const cornflowerBlue = '#6399f2';
export const indigo = '#3e6dba';
export const persimmon = '#FF604E';
export const sunsetOrange = '#FF5151';
export const whisper = '#ECECF5';
export const redRibbon = '#DF0945';
export const mineShaft = '#2E2E2E';
export const whiteLilacFaded = '#FAFAFC';
export const seashell = '#F2F1F1';
export const remy = '#FDF0F5';
export const classicRose = '#F6D8E0';
export const shilo = '#E8B5C4';
export const cranberry = '#DB5079';
export const razzmatazz = '#e00064';
export const maroonFlush = '#BC2451';
export const redViolet = '#C71585';
