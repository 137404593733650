import React from 'react';
import { styled } from '@glitz/react';
import Ul from 'Shared/Generic/Ul';
import { transition, theta } from 'Shared/Style';
import Link from 'Shared/Link';
import NavigationItem from 'Shared/NavigationItem/NavigationItem.type';

type PropType = {
  items: NavigationItem[];
};

type StateType = {
  height: string;
  isExpand: boolean;
};

export default class MegaMenuItem extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      height: 'auto',
      isExpand: false,
    };
  }

  render() {
    const items = this.props.items;
    return (
      <>
        <Ul
          css={{
            height: this.state.height,
            ...transition({ property: 'height' }),
          }}
          bare
        >
          {items.map(category => (
            <Li key={category.url}>
              <Name to={category.url}>{category.name}</Name>
            </Li>
          ))}
        </Ul>
      </>
    );
  }
}

const Li = styled.li({
  display: 'block',
  width: '100%',
});

const Name = styled(Link, {
  height: 'auto',
  lineHeight: '14px',
  color: '#4c4c4c',
  padding: {
    y: '6px',
    x: 0,
  },
  ':hover': {
    textDecoration: 'underline',
  },
  display: 'inherit',
  maxWidth: '210px',
  maxHeight: '24px',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontSize: theta,
});

