import React from 'react';
import { Breakpoint } from '@avensia/scope';
import { styled } from '@glitz/react';
import connect from 'Shared/connect';
import { toggleRadioPlay } from './action-creators';
import { pixelsToUnit, black, white, thin, minSmallMediaQuery } from 'Shared/Style';
import Icon from 'Shared/Icon/Close';

type RadioPlayType = {
  isOpen: boolean;
  embedRadioPlayUrl: string;
  currentBreakpoint: number;
};
type ConnectStateType = RadioPlayType;

type ConnectActionType = {
  onToggleRadioPlay: (isOpen: boolean, embedRadioPlayUrl: string) => void;
};

type PropType = RadioPlayType & ConnectActionType;

class RadioPlay extends React.Component<PropType, {}> {
  componentDidUpdate() {
    if (this.props.currentBreakpoint < Breakpoint.Medium) {
      this.closeRadioPlay();
    }
  }
  closeRadioPlay = () => {
    this.props.onToggleRadioPlay(false, '');
  };
  render() {
    return (
      <RadioPlayWrapper>
        <IframeContainer>
          <iframe
            src={this.props.embedRadioPlayUrl}
            loading="lazy"
            allow="autoplay"
            width="100%"
            height="125"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
          />
        </IframeContainer>
        <ButtonWrapper onClick={this.closeRadioPlay}>
          <Icon />
        </ButtonWrapper>
      </RadioPlayWrapper>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    isOpen: state.radioPlay.isOpen,
    embedRadioPlayUrl: state.radioPlay.embedRadioPlayUrl,
    currentBreakpoint: state.currentBreakpoint,
  }),
  (dispatch): ConnectActionType => ({
    onToggleRadioPlay(isOpen, embedRadioPlayUrl) {
      dispatch(toggleRadioPlay(isOpen, embedRadioPlayUrl));
    },
  }),
)(RadioPlay);

const RadioPlayWrapper = styled.div({
  display: 'flex',
  position: 'fixed',
  zIndex: 2,
  bottom: 0,
  width: '100%',
  maxHeight: pixelsToUnit(100),
  [minSmallMediaQuery]: {
    maxHeight: pixelsToUnit(120),
  },
  borderTop: {
    color: black,
    width: thin,
    style: 'solid',
  },
});

const IframeContainer = styled.div({
  height: '100%',
  width: 'calc(100% - 60px)',
  [minSmallMediaQuery]: {
    width: 'calc(100% - 120px)',
  },
  backgroundColor: white,
});

const ButtonWrapper = styled.div({
  width: '100%',
  maxWidth: pixelsToUnit(60),
  [minSmallMediaQuery]: {
    maxWidth: pixelsToUnit(120),
  },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  backgroundColor: white,
});
