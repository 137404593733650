import * as TrackingTypes from './tracking-types';
import State, { PageType, Store } from 'Shared/State';
import { addToCartThrottler, CodeQuantity } from './cart-throttler';
import { getLocalStorageItem, setLocalStorageItem, URLX, currentUrl } from '@avensia/scope';
import currentPageIsCheckout from 'Checkout/Pages/Checkout/current-page-is-checkout';
import { fetchProducts, cleanBackendProducts, fetchTransaction } from './Api';
import currentPageIsOrderConfirmation from 'Checkout/Pages/OrderConfirmation/current-page-is-order-confirmation';
import TrackingInformationProduct from 'TrackingInformation/TrackingInformationProduct.type';
import TrackingInformationTransaction from 'TrackingInformation/TrackingInformationTransaction.type';
import currentPageIsProduct from 'Product/current-page-is-variation';
import CartItemViewModel from 'Cart/Models/CartItemViewModel.type';
import { LoginAction } from './tracking-types';
import TrackingImpressionBlock from './TrackingImpressionBlock.type';
import { epiPropertyValue } from '@avensia/scope-episerver';
import { viewedProduct, startedCheckout, identify } from './Klaviyo';

const localStorageOrdersKey = 'gtmOrdersTracked';
let gtmEnabled = false;
let pageLoadPromise: Promise<any>;
let store: Store;

const promotionImpressionDebounceTimeMs = 200;

export function setPageLoadPromise(promise: Promise<any>) {
  pageLoadPromise = promise;
}

export async function addToDataLayer(action: any, trackAfterPageLoad = true) {
  if (gtmEnabled) {
    if (trackAfterPageLoad) {
      await pageLoadPromise;
    }
    setTimeout(() => window.dataLayer.push(action));
  }
}

export function initTagManager(s: Store) {
  store = s;
  if (!('dataLayer' in window)) {
    (window as any).dataLayer = [];
  }

  window.dataLayer.push({
    event: 'originalLocation',
    originalLocation: currentUrl().toString(),
  });

  gtmEnabled = true;
}

/**
 * Use this function to define how a remove from cart datalayer push should look like.
 * It will later be used by @see addToCartThrottler
 */
export function createRemoveFromCartTracking(
  products: TrackingInformationProduct[],
  listName: string,
): TrackingTypes.RemoveFromCartEvent {
  return {
    event: 'removeFromCart',
    ecommerce: {
      remove: {
        products,
      },
    },
  };
}

/**
 * Use this function to define how an add to cart datalayer push should look like.
 * It will later be used by @see addToCartThrottler
 */

export function createAddToCartTracking(
  products: TrackingInformationProduct[],
  listName: string,
): TrackingTypes.AddToCartEvent {
  return {
    event: 'addToCart',
    ecommerce: {
      add: {
        products,
      },
    },
  };
}

export function addToCart(products: CodeQuantity[], listName: string) {
  if (!gtmEnabled || products.length === 0) {
   return;
  }

  const cartPayload = {
    products,
    listName,
    isAdd: true,
  };

  addToCartThrottler(cartPayload);
}

export function removeFromCart(products: CodeQuantity[], listName: string) {
  if (!gtmEnabled || products.length === 0) {
    return;
  }

  const cartPayload = {
    products,
    listName,
    isAdd: false,
  };

  addToCartThrottler(cartPayload);
}

export function pageLoad(page: PageType, isDynamicPageLoad: boolean, state: State) {
  pageLoadPromise = new Promise(async (resolve) => {
    await pageLoadAsync(page, isDynamicPageLoad, state);
    resolve();
  });
}

/**
 * This function will be called on all page loads, regardless if they're scope page
 * navigations or hard browser refreshes. Add things to the datalayer that should be tracked
 * in the page load. Note that you can push more than one action to the datalayer.
 * @param page The current page that has been loaded
 * @param isDynamicPageLoad Determines whether it has been a dynamic scope page load or hard browser page load
 * @param state The Redux state, if there is something needed from Redux to track
 */
async function pageLoadAsync(page: PageType, isDynamicPageLoad: boolean, state: State) {
  const storeState = store.getState();
  const items = storeState.appShellData.cart.items;

  viewedProduct(page);
  startedCheckout(page);

  if (!gtmEnabled) {
    return;
  }
  
  if (!page.trackingInformationSection) {
    return;
  }

  let action = {} as TrackingTypes.PageLoadEvent;
  if (isDynamicPageLoad) {
    action = {
      title: (page.meta && page.meta.title) || '',
      url: page.url,
      pageType: page.trackingInformationSection,
      event: 'virtualPageLoad',
      type: 'dynamic',
    };
  } else {
    let productsWithQuantityAndCorrectPrice: TrackingInformationProduct[];
    if (items?.length > 0) {
      productsWithQuantityAndCorrectPrice = await fetchProductsWithQuantityAndPrice(items);
    }

    action = {
      title: (page.meta && page.meta.title) || '',
      url: page.url,
      pageType: page.trackingInformationSection,
      event: 'virtualPageLoad',
      type: 'hard',
      market: storeState.appShellData.market,
      currency: storeState.appShellData.cart.currency,
      language: storeState.appShellData.culture.toString(),
      cartItems: productsWithQuantityAndCorrectPrice,
    } as TrackingTypes.FirstPageLoadEvent;
  }

  if (currentPageIsProduct(page)) {
    const pageDetailAction: TrackingTypes.ProductDetailEvent = {
      event: 'productDetailView',
      ecommerce: {
        detail: {
          products: cleanBackendProducts([{ ...page.trackingProduct }]) as [TrackingInformationProduct],
        },
      },
    };

    addToDataLayer(pageDetailAction);
  }

  if (currentPageIsCheckout(page)) {
    if (items?.length > 0) {
      const productsWithQuantityAndCorrectPrice = await fetchProductsWithQuantityAndPrice(items);
      addToDataLayer(createCheckoutStepActionTracking(productsWithQuantityAndCorrectPrice, 1)); 
    }
  }

  if (currentPageIsOrderConfirmation(page)) {

    if (page.hasSentPurchaseEvent) {
      return;
    }

    const orderNumber = page.orderDetails.orderNumber;
    let orderAlreadyTracked = Boolean(
      getLocalStorageItem<string[]>(localStorageOrdersKey, []).find((s) => s === orderNumber),
    );

    if (!orderAlreadyTracked) {
      const id = new URLX(document.location.href).searchParams.get('id');
      fetchTransaction(id).then((transaction) => {
        // Need to check that we didn't send it here again, because it is async
        const ordersTracked = getLocalStorageItem<string[]>(localStorageOrdersKey, []);
        orderAlreadyTracked = Boolean(ordersTracked.find((s) => s === orderNumber));
        if (orderAlreadyTracked) {
          return;
        }

        addToDataLayer(createPurchaseActionTracking(transaction, transaction.paymentMethodName));
        setLocalStorageItem(localStorageOrdersKey, [...ordersTracked, orderNumber]);
      });
    }
  }

  addToDataLayer(action);
}

async function fetchProductsWithQuantityAndPrice(items: CartItemViewModel[]) {
  let productsWithQuantityAndCorrectPrice: TrackingInformationProduct[];

  await fetchProducts(items.map((s) => s.code)).then((products) => {
    productsWithQuantityAndCorrectPrice = products.map((p) => {
      const lineItem = items.find((item) => item.code === p.id);
      const currentPricePerItem = lineItem.totalPrice / lineItem.quantity;
      return {
        ...p,
        quantity: lineItem.quantity,
        price: formatPriceForGtm(currentPricePerItem),
      };
    });
  });

  return productsWithQuantityAndCorrectPrice;
}

function createCheckoutStepActionTracking(
  products: TrackingInformationProduct[],
  step: number,
): TrackingTypes.CheckoutStepEvent {
  return {
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: {
          step,
        },
        products,
      },
    },
  };
}

function createPurchaseActionTracking(
  transaction: TrackingInformationTransaction,
  paymentMethod: string,
): TrackingTypes.PurchaseAction {
  return {
    event: 'purchase',
    ecommerceNotForGA: {
      currencyCode: transaction.currencyCode,
      purchase: {
        actionField: {
          coupon: transaction.coupon,
          id: transaction.transactionId,
          revenue: transaction.revenue,
          shipping: transaction.shipping,
          tax: transaction.tax,
        },
        products: transaction.products,
      },
    },
    campaignCodes: transaction.coupon,
    paymentMethod,
  };
}

function formatPriceForGtm(price: number) {
  let decimals = Math.round((price % 1) * 100)
    .toString()
    .substring(0, 2);
  while (decimals.length < 2) {
    decimals = '0' + decimals;
  }
  const nonDecimals = Math.floor(price);
  return `${nonDecimals}.${decimals}`;
}

/**
 * Names of product listings, for tracking purposes
 */
export const listNames = {
  checkout: 'checkout',
  recommendations: 'recommendations',
  relatedProducts: 'related-products',
  productPage: 'product-page',
  unknown: 'unknown',
  cart: 'cart',
};

export function login(method: 'Username/Password' | 'Facebook') {
  const action = {
    event: 'login',
    eventInfo: {
      category: 'Login Method',
      action: method,
    },
  } as LoginAction;
  addToDataLayer(action);
}

export function logout() {
  // TODO Implement project specific logout event
}

export function saveEmailToLocalStorage(email: string, emailHashed: string) {
  var existing = getLocalStorageItem('emailAddress');
  if (existing != email)
  {
    identify(email);
  }
  setLocalStorageItem('emailAddress', email);
  setLocalStorageItem('emailHashed', emailHashed);
}

let queuedPromotionImpressions: TrackingTypes.Promotion[] = [];
let promotionImpressionDebouncerTimeout = -1;

export function trackPromotionImpression(trackingBlock: Scope.Property<TrackingImpressionBlock>) {
  const block = epiPropertyValue(trackingBlock);
  const creative = epiPropertyValue(block.trackingCreative);
  const id = epiPropertyValue(block.trackingId);
  const name = epiPropertyValue(block.trackingName);
  const position = epiPropertyValue(block.trackingPosition);

  const shouldTrack = Boolean(creative && id && name && position);
  if (!shouldTrack) {
    // The block doesn't have enough information populated for us to track an impression
    return;
  }

  if (
    queuedPromotionImpressions.find(
      (s) => s.creative === creative && s.id === id && s.name === name && s.position === position,
    )
  ) {
    // Don't fire the exact same impression more than once at the same time
    return;
  }

  // If we have a content area with a lot of blocks, we don't want to push several pushes
  // to the dataLayer, but instead we use a fairly quick debounce to try to catch them all
  // in the same request.
  queuedPromotionImpressions.push({ creative, id, name, position });
  if (promotionImpressionDebouncerTimeout !== -1) {
    clearTimeout(promotionImpressionDebouncerTimeout);
  }
  promotionImpressionDebouncerTimeout = setTimeout(processPromotionImpressionQueue, promotionImpressionDebounceTimeMs);
}

function processPromotionImpressionQueue() {
  promotionImpressionDebouncerTimeout = -1;

  const action: TrackingTypes.PromotionImpressionAction = {
    ecommerce: {
      promoView: {
        promotions: queuedPromotionImpressions,
      },
    },
    event: 'promotionImpression',
  };

  addToDataLayer(action);
  queuedPromotionImpressions = [];
}

export function trackPromotionClick(trackingBlock: Scope.Property<TrackingImpressionBlock>) {
  const block = epiPropertyValue(trackingBlock);
  const creative = epiPropertyValue(block.trackingCreative);
  const id = epiPropertyValue(block.trackingId);
  const name = epiPropertyValue(block.trackingName);
  const position = epiPropertyValue(block.trackingPosition);

  const action: TrackingTypes.PromotionClickAction = {
    event: 'promotionClick',
    ecommerce: {
      promoClick: {
        promotions: [{ id, creative, name, position }],
      },
    },
  };

  addToDataLayer(action);
}
