import React from 'react';
import { styled } from '@glitz/react';
import CartItemViewModel from 'Cart/Models/CartItemViewModel.type';
import { pixelsToUnit, zeta, small, epsilon, monochromeDark, thin, minMediumMediaQuery } from 'Shared/Style';
import {
  Media,
  DisplayName,
  Icon,
  UnitPrice,
  TotalPrice,
  PriceType,
  Discount,
  QuantityField,
  Variant,
} from 'Checkout/FullCart/components';
import ClampedText from 'Shared/ClampedText';

type PropType = {
  item: CartItemViewModel;
  currency: string;
  isLoading: boolean;
  update: (code: string, quantity: number) => Promise<void>;
  delete: (code: string) => Promise<void>;
};

export default function MiniCartItem(props: PropType) {
  function updateItem(quantity: number) {
    return props.update(props.item.code, quantity);
  }

  function deleteItem() {
    return props.delete(props.item.code);
  }

  return (
    <Base>
      <CartMedia>
        <Media
          productUrl={props.item.url}
          mediaSrc={props.item.imageUrl}
          alt={props.item.displayName}
          isGift={props.item.isGift}
        />
      </CartMedia>
      <Body>
        <styled.Div css={{ display: 'flex', alignItems: 'flex-start' }}>
          <Name fontSize={zeta} lines={2}>
            <DisplayName displayName={props.item.displayName} productUrl={props.item.url} isGift={props.item.isGift} />
          </Name>
          {!props.item.isGift && (
            <Delete
              css={{ pointerEvents: props.isLoading ? 'none' : 'auto', marginTop: pixelsToUnit(4) }}
              onClick={deleteItem}
            />
          )}
        </styled.Div>
        <styled.Div css={{ display: 'flex' }}>
          <UnitPrice
            css={{ opacity: props.isLoading ? 0.5 : 1.0, display: 'block', fontSize: zeta, flexGrow: 1 }}
            price={props.item.price}
            currency={props.currency}
          />
          {props.item.hasDiscount && (
            <Discount
              css={{ opacity: props.isLoading ? 0.2 : 1.0, fontSize: zeta }}
              price={props.item.totalPrice - props.item.price * props.item.quantity}
              currency={props.currency}
            />
          )}
        </styled.Div>
        <styled.Div css={{ display: 'flex' }}>
          {!props.item.isGift ? (
            <QuantityField
              css={{ flexGrow: 1, userSelect: 'none' }}
              value={props.item.quantity}
              variant={Variant.Small}
              maxQuantity={props.item.stockCount}
              update={updateItem}
              tagDesigner={props.item.tagDesigner}
            />
          ) : (
            <QuantityField
              css={{ flexGrow: 1, userSelect: 'none' }}
              value={props.item.quantity}
              variant={Variant.Small}
              maxQuantity={props.item.stockCount}
              update={updateItem}
              tagDesigner={props.item.tagDesigner}
              hideIncrementDecrement
            />
          )}
          <TotalPrice
            css={{
              fontSize: props.item.hasDiscount ? pixelsToUnit(19) : epsilon,
              letterSpacing: pixelsToUnit(props.item.hasDiscount ? 0.25 : 0.21),
              opacity: props.isLoading ? 0.2 : 1.0,
              display: 'block',
              flexGrow: 1,
              textAlign: 'right',
            }}
            price={props.item.stockCount <= 1 ? props.item.price : props.item.totalPrice}
            currency={props.currency}
            priceType={props.item.hasDiscount ? PriceType.New : PriceType.Regular}
          />
        </styled.Div>
      </Body>
    </Base>
  );
}

const Base = styled.li({
  borderBottom: {
    color: monochromeDark,
    style: 'solid',
    width: thin,
  },
  display: 'flex',
  flex: {
    basis: pixelsToUnit(112),
    grow: 0,
    shrink: 0,
  },
  padding: {
    y: pixelsToUnit(10),
  },
});

const CartMedia = styled.div({
  flex: {
    basis: pixelsToUnit(60),
    grow: 0,
    shrink: 0,
  },
  [minMediumMediaQuery]: {
    flexBasis: pixelsToUnit(90),
  },
});

const Body = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  marginLeft: pixelsToUnit(10),
});

const Name = styled(ClampedText, {
  textTransform: 'uppercase',
  fontWeight: 'bold',
  flex: {
    basis: pixelsToUnit(160),
    grow: 2,
  },
});

const Delete = styled(Icon, {
  cursor: 'pointer',
  height: small,
  width: small,
  verticalAlign: 'middle',
  ':active': {
    transform: 'translateY(1px)',
  },
});
