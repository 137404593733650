import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { isValidQuantity } from '../../Cart/action-creators';
import * as style from '../Style';
import IconMinus from 'Shared/Icon/Minus';
import IconPlus from 'Shared/Icon/Plus';
import { pixelsToUnit } from '../Style';

export { General as Variant } from '../Style';

function parse(int: string) {
  return parseInt(int, 10);
}

type PropType = StyledProps & {
  value: number;
  update: (quantity: number) => Promise<void>;
  maxQuantity?: number;
  variant?: style.General;
  tagDesigner?: boolean;
  hideIncrementDecrement?: boolean;
};

type StateType = {
  proxyValue?: any;
  invalid?: boolean;
};

const QUANTITY_DEBOUNCE_TIME = 400;

class Quantity extends React.Component<PropType, StateType> {
  isFocused = false;
  currentQuantityUpdateTimeout: number;
  constructor(props: PropType) {
    super(props);
    this.state = {
      proxyValue: props.value,
      invalid: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: PropType) {
    if (this.isFocused) {
      return;
    }

    if (this.state.proxyValue !== nextProps.value) {
      this.setState({
        proxyValue: nextProps.value,
      });
    }
  }

  change(value: string | number) {
    const parsedValue = typeof value === 'number' ? value : parse(value);
    this.setState({
      proxyValue: parsedValue || '',
    });

    return this.update(parsedValue);
  }

  decrement = () => {
    clearTimeout(this.currentQuantityUpdateTimeout);
    this.currentQuantityUpdateTimeout = setTimeout(() => {
      const newQuantity = parse(this.state.proxyValue) - 1;
      const quantity = newQuantity > this.props.maxQuantity ? this.props.maxQuantity : newQuantity;
      if (isValidQuantity(quantity)) {
        return this.change(quantity);
      }
      return Promise.reject(null);
    }, QUANTITY_DEBOUNCE_TIME);
  };

  increment = () => {
    clearTimeout(this.currentQuantityUpdateTimeout);
    this.currentQuantityUpdateTimeout = setTimeout(() => {
      const quantity = (parse(this.state.proxyValue) || 0) + 1;
      if (isValidQuantity(quantity, this.props.maxQuantity)) {
        return this.change(quantity);
      }
      return Promise.reject(null);
    }, QUANTITY_DEBOUNCE_TIME);
  };

  update(quantity: number) {
    const previousValue = this.state.proxyValue;
    return this.props
      .update(quantity)
      .then(() => {
        if (this.state.proxyValue === this.props.value && this.state.invalid) {
          this.setState({
            invalid: false,
          });
        }
      })
      .catch(() => {
        if (!this.isFocused) {
          // Reset hard if field is not focused
          this.setState({
            proxyValue: previousValue,
            invalid: true,
          });
        } else if (!this.state.invalid) {
          this.setState({
            invalid: true,
          });
        }
      });
  }

  verify(e: React.SyntheticEvent<{}>) {
    if (this.state.invalid) {
      this.setState({
        proxyValue: this.props.value,
        invalid: false,
      });
    }
  }

  render() {
    const isQuantityValid = this.props.maxQuantity > this.props.value;
    return (
      <styled.Div css={this.props.compose()}>
        {!this.props.tagDesigner && !this.props.hideIncrementDecrement && <Decrement onClick={this.decrement} />}
        <Amount>{this.state.proxyValue}</Amount>
        {!this.props.tagDesigner && !this.props.hideIncrementDecrement && (
          <Increment
            css={{
              cursor: isQuantityValid ? 'pointer' : 'default',
              opacity: isQuantityValid ? 1 : 0.35,
              pointerEvents: isQuantityValid ? 'auto' : 'none',
            }}
            onClick={this.increment}
          />
        )}
      </styled.Div>
    );
  }
}

export default styled(Quantity);

const Amount = styled.span({
  fontSize: style.epsilon,
  fontWeight: 'bold',
  color: style.tundora,
  minWidth: style.pixelsToUnit(20),
  textAlign: 'center',
  margin: {
    x: pixelsToUnit(11),
  },
});

const iconStyled = styled({
  cursor: 'pointer',
  width: pixelsToUnit(18),
  height: pixelsToUnit(18),
  verticalAlign: 'middle',
  ':active': {
    transform: 'translateY(1px)',
  },
});

const Decrement = iconStyled(IconMinus);
const Increment = iconStyled(IconPlus);
