import { breakpointReducer, appShellReducer, currentPageReducer, themeReducer, spinnerReducer } from '@avensia/scope';
import checkoutPageReducer from 'Checkout/Pages/Checkout/reducer';
import cartReducer from 'Cart/reducer';
import mainMenuReducer from 'SiteLayout/MainMenu/reducer';
import miniCartReducer from 'SiteLayout/MiniCart/reducer';
import quickSearchReducer from 'Search/reducer';
import searchPageReducer from 'Search/page-reducer';
import footerReducer from 'SiteLayout/Footer/reducer';
import currentUserReducer from 'Account/reducer';
import productReviewReducer from 'Product/Reviews/reducer';
import myReviewsReducer from 'Account/MyPages/MyReviews/reducer';
import recentlyViewedProductsReducer from 'Product/ProductRecentlyViewed/reducer';
import radioPlayReducer from 'RadioPlay/reducer';
import productDesignerReducer from 'Product/ProductDesigner/reducer';
import StateType, { Action } from './State';
import deepFreeze from './deep-freeze';

export default (state: StateType, action: Action): StateType => {
  const appShellData = appShellReducer(state.appShellData, action);
  // We deep freeze the state to make sure we don't accidentally mutate it
  // Note that freezing objects is quite expensive, so we only do it in
  // dev builds
  const currentPageState = searchPageReducer(
    checkoutPageReducer(currentPageReducer(state.currentPage, action, appShellData), action),
    action,
  );

  return deepFreeze({
    appShellData,
    currentBreakpoint: breakpointReducer(state.currentBreakpoint, action, appShellData),
    currentPage: currentPageState,
    miniCart: miniCartReducer(state.miniCart, action),
    cart: cartReducer(state.cart, action, currentPageState, appShellData),
    mainMenu: mainMenuReducer(state.mainMenu, action, appShellData),
    quickSearch: quickSearchReducer(state.quickSearch, action, currentPageState),
    footer: footerReducer(state.footer, action, appShellData),
    currentUser: currentUserReducer(state.currentUser, action, appShellData),
    currentTheme: themeReducer(state.currentTheme, state.appShellData, action),
    spinner: spinnerReducer(state.spinner, action),
    productReview: productReviewReducer(state.productReview, action),
    myReviewsSearch: myReviewsReducer(state.myReviewsSearch, action),
    recentlyViewed: recentlyViewedProductsReducer(state.recentlyViewed, action),
    radioPlay: radioPlayReducer(state.radioPlay, action, currentPageState),
    productDesigner: productDesignerReducer(state.productDesigner, action, currentPageState),
    siteBannerBlockPromotionEndDate: state.appShellData.siteBannerBlockPromotionEndDate,
  });
};
