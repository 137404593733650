import React from 'react';
import { styled } from '@glitz/react';
import LanguageSiteType from 'AppShell/LanguageSite.type';
import connect from 'Shared/connect';
import { FooterType } from 'Shared/State';
import Viewport from 'Shared/Viewport';
import { sigma, theta, minMediumMediaQuery, pixelsToUnit } from 'Shared/Style';
import { Order } from 'SiteLayout';
import DesktopFooter from './FooterDesktop';
import MobileFooter from './FooterMobile';
import PaymentLogos from './PaymentLogos';
import LanguageSites from './LanguageSites';

type ConnectStateType = {
  footer: FooterType;
  languageSites: LanguageSiteType[];
};

type PropType = ConnectStateType & {
  order: Order;
};

const MainFooter = (props: PropType) => {
  const { footer, languageSites, order } = props;
  const { paymentInfoImages: paymentLogos } = footer;

  return (
    <Viewport>
      {isCompact => (
        <Base css={{ order }}>
          {isCompact ? <MobileFooter footer={footer} /> : <DesktopFooter footer={footer} />}
          <PaymentLogos paymentLogos={paymentLogos} />
          <BelowFooter>
            <Copyright>
              {footer.copyrightText && footer.copyrightText.replace(/{year}/, new Date().getFullYear().toString())}
            </Copyright>
            {languageSites.length > 1 && <LanguageSites languageSites={languageSites} />}
          </BelowFooter>
        </Base>
      )}
    </Viewport>
  );
};

export default connect(
  (state): ConnectStateType => ({
    footer: state.footer,
    languageSites: state.appShellData.languageSites.filter((site, currentIndex, sites) => {
      const matchedIndex = sites.findIndex(innerSite => innerSite.language === site.language);
      return matchedIndex === currentIndex;
    }),
  }),
)(MainFooter);

const Base = styled.footer({
  backgroundColor: theme => theme.footerBackgroundColor,
  backgroundImage: theme => theme.footerBackgroundImage,
  color: theme => theme.footerTextColor,
});

const BelowFooter = styled.div({
  backgroundColor: theme => theme.footerBelowBackgroundColor,
  backgroundImage: theme => theme.footerBelowBackgroundImage,
  color: theme => theme.footerBelowTextColor,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  padding: {
    y: pixelsToUnit(25),
  },
  [minMediumMediaQuery]: {
    padding: {
      y: pixelsToUnit(20),
    },
  },
});

const Copyright = styled.section({
  fontSize: sigma,
  marginBottom: pixelsToUnit(15),
  [minMediumMediaQuery]: {
    fontSize: theta,
    marginBottom: pixelsToUnit(5),
  },
});
