import React from 'react';
import { translate } from '@avensia/scope';
import Item from './Item';
import itemKey from '../MainMenu/item';
import GenericUl from 'Shared/Generic/Ul';
import NavigationItemType from 'Shared/NavigationItem/NavigationItem.type';
import { animation } from 'Shared/Style';
import { styled } from '@glitz/react';
import { slideIn } from './keyframes.scss';

export function isRoot(data: NavigationItemType[] | NavigationItemType): data is NavigationItemType[] {
  return Array.isArray(data);
}

type PropType = {
  data: NavigationItemType[] | NavigationItemType;
  pushLevel: (item: NavigationItemType) => void;
};

export default class Level extends React.Component<PropType> {
  shouldComponentUpdate(nextProps: PropType) {
    return this.props.data !== nextProps.data;
  }

  render() {
    const { data } = this.props;
    const items = isRoot(data) ? data : data.children || [];

    return (
      <GenericUl column>
        {!isRoot(data) && <Item url={data.url}>{`${translate('/MainMenu/ShowAll')} ${data.name}`}</Item>}
        {items.map((item: NavigationItemType, index) => (
          <Li
            css={animation({
              name: slideIn,
              duration: `${TRANSITION_DURATION}ms`,
              timingFunction: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
              fillMode: 'forwards',
              delay: `${index * 30}ms`,
            })}
            key={itemKey(item)}
          >
            <Item item={item} pushLevel={this.props.pushLevel} />
          </Li>
        ))}
      </GenericUl>
    );
  }
}

const TRANSITION_DURATION = 600;

const Li = styled.li({
  transform: 'translateX(100%)',
});
