import { PageType } from 'Shared/State';
import { componentNames } from 'Shared/component-registry';
import OrderConfirmationPageViewModelType from './OrderConfirmationPageViewModel.type';

type OrderConfirmationType = OrderConfirmationPageViewModelType & PageType;

export default (currentPage: PageType): currentPage is OrderConfirmationType => {
  return (
    currentPage.componentName ===
    componentNames.Avensia_Common_Features_Checkout_Pages_OrderConfirmation_OrderConfirmationPageViewModel
  );
};
