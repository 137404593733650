import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { Style } from '@glitz/type';
import { media } from '@glitz/core';
import IconCart from 'Shared/Icon/Cart';
import IconShoppingBag from 'Shared/Icon/ShoppingBag';
import { CartType } from 'Shared/State';
import {
  animation,
  pixelsToUnit,
  black,
  eta,
  minMediumMediaQuery,
  minMediumMediaQueryAlt,
  zeta,
  sigma,
} from 'Shared/Style';
import connect from 'Shared/connect';
import { Theme } from 'Shared/Theming';
import Button, { Appearance } from 'Shared/Button';
import { toggleMiniCart } from 'SiteLayout/MiniCart/action-creators';
import { highlightDefault, highlightDoggie } from './keyframes.scss';
import { Breakpoint, translate } from '@avensia/scope';
import { checkoutPageUrl } from 'Shared/known-urls';
import Link from 'Shared/Link';

type RequiredPropType = {
  cart: CartType;
  active?: boolean;
};

type ConnectStateType = {
  isLoading: boolean;
  currentTheme: string;
  cart: CartType;
  culture: string;
  currentBreakpoint: number;
};

type ConnectDispatchType = {
  toggleMiniCart: (e?: React.MouseEvent<HTMLElement>) => void;
};

type PropType = RequiredPropType & ConnectStateType & ConnectDispatchType;

const MiniCart = (props: PropType & StyledProps) => {
  const isMobile = props.currentBreakpoint < Breakpoint.Medium;
  const isCartActive = props.cart.items.length > 0;
  const highlight = props.currentTheme === Theme.Default ? highlightDefault : highlightDoggie;
  const isVxnSite =
    !props.currentTheme.startsWith('dog') &&
    !props.currentTheme.startsWith('cli') &&
    !props.currentTheme.startsWith('lac') &&
    !props.currentTheme.startsWith('party');

  const baseStyle: Style = {
    backgroundColor: isCartActive ? (theme) => theme.miniCartButtonActive : (theme) => theme.miniCartBackgroundColor,
    ...(props.isLoading && animation({ name: highlight, duration: `400ms`, iterationCount: 1 })),
  };
  const countStyle: Style = props.active
    ? {
        backgroundColor: (theme) => theme.badgeColor,
      }
    : null;

  return (
    <Container>
      <LinkContainer css={isVxnSite && isMobile && { paddingBottom: 15 }}>
        <LinkInnerContainer
          css={
            props.cart.subTotal > 0 && isVxnSite
              ? { visibility: 'visible' }
              : props.cart.subTotal > 0 &&
                !isVxnSite && { left: pixelsToUnit(-85), top: pixelsToUnit(-12), visibility: 'visible' }
          }
        >
          <LinkCheckout to={checkoutPageUrl()}>{translate('/Checkout/Header/GoToCheckout')}</LinkCheckout>
          <UnderLine />
        </LinkInnerContainer>
      </LinkContainer>

      <Base
        id={'minicartButton'}
        css={props.compose(baseStyle)}
        appearance={Appearance.Bare}
        onClick={props.toggleMiniCart}
      >
        <CountAndIcon>
          <ItemCount css={countStyle}>
            {props.cart.items.reduce((total, item) => (total += item.quantity), 0)}
          </ItemCount>
          {isVxnSite && isMobile ? <IconShoppingBag /> : <Icon />}
        </CountAndIcon>
        {/* <ForExtensive>
          {props.cart.subTotal > 0 ? (
            <TotalPrice>{formatPrice(props.cart.subTotal, props.cart.currency, props.culture)}</TotalPrice>
          ) : (
            <span />
          )}
        </ForExtensive> */}
      </Base>
    </Container>
  );
};

export default styled(
  connect(
    (state): ConnectStateType => ({
      isLoading: state.cart.isLoading,
      currentTheme: state.currentTheme,
      cart: state.cart,
      culture: state.appShellData.culture,
      currentBreakpoint: state.currentBreakpoint,
    }),
    (dispatch): ConnectDispatchType => ({
      toggleMiniCart: (e?: React.MouseEvent<HTMLElement>) => {
        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }
        dispatch(toggleMiniCart());
      },
    }),
  )(MiniCart),
);

const Base = styled(Button, {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  lineHeight: 'normal',
  position: 'relative',
  justifyContent: 'center',
  [minMediumMediaQuery]: {
    paddingTop: pixelsToUnit(2),
  },
});

const CountAndIcon = styled.div({
  paddingLeft: pixelsToUnit(5),
  paddingRight: pixelsToUnit(20),
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  color: (theme) => theme.minicartButtonIconMobile,
  ...media(minMediumMediaQueryAlt, {
    color: (theme) => theme.minicartButtonIcon,
  }),
});

const ItemCount = styled.div({
  backgroundColor: black,
  borderRadius: '50%',
  color: 'white',
  fontSize: sigma,
  fontWeight: 'bold',
  left: '50%',
  lineHeight: 'normal',
  position: 'absolute',
  textAlign: 'center',
  top: 0,
  height: pixelsToUnit(18),
  width: pixelsToUnit(18),
});

const Icon = styled(IconCart, {
  width: pixelsToUnit(30),
  height: pixelsToUnit(30),
  verticalAlign: 'top',
});

const LinkCheckout = styled(Link, {
  fontSize: eta,
  fontWeight: 'bold',
  color: (theme) => theme.minicartButtonIconMobile,
  ...media(minMediumMediaQueryAlt, {
    color: (theme) => theme.minicartButtonIcon,
    fontSize: zeta,
  }),
});

const LinkContainer = styled.div({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  marginRight: pixelsToUnit(10),
  whiteSpace: 'nowrap',
});

const LinkInnerContainer = styled.div({
  visibility: 'hidden',
  position: 'absolute',
  left: pixelsToUnit(-125),
  top: 0,
  ...media(minMediumMediaQueryAlt, {
    left: pixelsToUnit(-100),
    top: pixelsToUnit(-12),
  }),
});

const UnderLine = styled.div({
  height: pixelsToUnit(3),
  backgroundColor: (theme) => theme.minicartButtonIconMobile,
  ...media(minMediumMediaQueryAlt, {
    backgroundColor: (theme) => theme.minicartButtonIcon,
  }),
});

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
});
