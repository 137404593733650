// This file has been generated by the Scope build. Do not alter
// it manually as your changes will be overwritten. Run:
// scope.cmd frontend:build-component-registry
// instead.

/* tslint:disable */

// prettier-ignore
export default {
  Avensia_Common_Features_Account_Login_LoginBlock: 'Avensia.Common.Features.Account.Login.LoginBlock',
  Avensia_Common_Features_Account_Login_LoginPage: 'Avensia.Common.Features.Account.Login.LoginPage',
  Avensia_Common_Features_Account_MyPages_ChangePassword_ChangePasswordPageViewModel: 'Avensia.Common.Features.Account.MyPages.ChangePassword.ChangePasswordPageViewModel',
  Avensia_Common_Features_Account_MyPages_MyDetails_MyDetailsPageViewModel: 'Avensia.Common.Features.Account.MyPages.MyDetails.MyDetailsPageViewModel',
  Avensia_Common_Features_Account_MyPages_MyReviews_MyReviewsPageViewModel: 'Avensia.Common.Features.Account.MyPages.MyReviews.MyReviewsPageViewModel',
  Avensia_Common_Features_Account_MyPages_OrderDetail_OrderDetailsPageViewModel: 'Avensia.Common.Features.Account.MyPages.OrderDetail.OrderDetailsPageViewModel',
  Avensia_Common_Features_Account_MyPages_OrderHistory_OrderHistoryPageViewModel: 'Avensia.Common.Features.Account.MyPages.OrderHistory.OrderHistoryPageViewModel',
  Avensia_Common_Features_Account_Register_RegisterBlock: 'Avensia.Common.Features.Account.Register.RegisterBlock',
  Avensia_Common_Features_AppShell_Partials_AppShellViewModel: 'Avensia.Common.Features.AppShell.Partials.AppShellViewModel',
  Avensia_Common_Features_Bambuser_BambuserBlockViewModel: 'Avensia.Common.Features.Bambuser.BambuserBlockViewModel',
  Avensia_Common_Features_Campaign_PromotionOverviewPageViewModel: 'Avensia.Common.Features.Campaign.PromotionOverviewPageViewModel',
  Avensia_Common_Features_Campaign_PromotionPageViewModel: 'Avensia.Common.Features.Campaign.PromotionPageViewModel',
  Avensia_Common_Features_Category_CategoryViewModel: 'Avensia.Common.Features.Category.CategoryViewModel',
  Avensia_Common_Features_Checkout_Pages_Checkout_CheckoutPageViewModel: 'Avensia.Common.Features.Checkout.Pages.Checkout.CheckoutPageViewModel',
  Avensia_Common_Features_Checkout_Pages_OrderConfirmation_OrderConfirmationPageViewModel: 'Avensia.Common.Features.Checkout.Pages.OrderConfirmation.OrderConfirmationPageViewModel',
  Avensia_Common_Features_Cms_CmsPageViewModel: 'Avensia.Common.Features.Cms.CmsPageViewModel',
  Avensia_Common_Features_CustomerService_CustomerServiceFaqPageViewModel: 'Avensia.Common.Features.CustomerService.CustomerServiceFaqPageViewModel',
  Avensia_Common_Features_CustomerService_CustomerServiceMainPage: 'Avensia.Common.Features.CustomerService.CustomerServiceMainPage',
  Avensia_Common_Features_CustomerService_CustomerServiceMainPageViewModel: 'Avensia.Common.Features.CustomerService.CustomerServiceMainPageViewModel',
  Avensia_Common_Features_CustomerService_FaqBlock: 'Avensia.Common.Features.CustomerService.FaqBlock',
  Avensia_Common_Features_CustomerService_FaqContainerBlockViewModel: 'Avensia.Common.Features.CustomerService.FaqContainerBlockViewModel',
  Avensia_Common_Features_CustomerService_LinkIconTextBlockViewModel: 'Avensia.Common.Features.CustomerService.LinkIconTextBlockViewModel',
  Avensia_Common_Features_CustomerService_QuestionsBlock: 'Avensia.Common.Features.CustomerService.QuestionsBlock',
  Avensia_Common_Features_CustomerService_RelatedQuestionsBlock: 'Avensia.Common.Features.CustomerService.RelatedQuestionsBlock',
  Avensia_Common_Features_Error_Pages_PageNotFoundViewModel: 'Avensia.Common.Features.Error.Pages.PageNotFoundViewModel',
  Avensia_Common_Features_Magazine_MagazinePageViewModel: 'Avensia.Common.Features.Magazine.MagazinePageViewModel',
  Avensia_Common_Features_Magazine_MagazineListPageViewModel: 'Avensia.Common.Features.Magazine.MagazineListPageViewModel',
  Avensia_Common_Features_Product_ProductListing_Pages_ProductListingPageViewModel: 'Avensia.Common.Features.Product.ProductListing.Pages.ProductListingPageViewModel',
  Avensia_Common_Features_Product_VariationViewModel: 'Avensia.Common.Features.Product.VariationViewModel',
  Avensia_Common_Features_ResetPassword_ForgotPasswordPage: 'Avensia.Common.Features.ResetPassword.ForgotPasswordPage',
  Avensia_Common_Features_ResetPassword_ResetPasswordPageViewModel: 'Avensia.Common.Features.ResetPassword.ResetPasswordPageViewModel',
  Avensia_Common_Features_Search_SearchPageViewModel: 'Avensia.Common.Features.Search.SearchPageViewModel',
  Avensia_Common_Features_Shared_Blocks_AdvanceImage_AdvanceImageBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.AdvanceImage.AdvanceImageBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_CountdownBanner_CountdownBannerBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.CountdownBanner.CountdownBannerBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_HorizontalScroll_HorizontalScrollContainerBlock: 'Avensia.Common.Features.Shared.Blocks.HorizontalScroll.HorizontalScrollContainerBlock',
  Avensia_Common_Features_Shared_Blocks_HorizontalScroll_HorizontalScrollItemBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.HorizontalScroll.HorizontalScrollItemBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_Html_HtmlBlock: 'Avensia.Common.Features.Shared.Blocks.Html.HtmlBlock',
  Avensia_Common_Features_Shared_Blocks_Image_ImageBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Image.ImageBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_ImageContent_ImageContentBlock: 'Avensia.Common.Features.Shared.Blocks.ImageContent.ImageContentBlock',
  Avensia_Common_Features_Shared_Blocks_Instagram_InstagramBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Instagram.InstagramBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_LatestMagazine_LatestMagazineBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.LatestMagazine.LatestMagazineBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_LatestReviews_LatestReviewsBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.LatestReviews.LatestReviewsBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_MobileMenuImageBlock_MobileMenuImageBlock: 'Avensia.Common.Features.Shared.Blocks.MobileMenuImageBlock.MobileMenuImageBlock',
  Avensia_Common_Features_Shared_Blocks_MobileMenuTextBlock_MobileMenuTextBlock: 'Avensia.Common.Features.Shared.Blocks.MobileMenuTextBlock.MobileMenuTextBlock',
  Avensia_Common_Features_Shared_Blocks_MobileRowLayout_MobileRowLayout: 'Avensia.Common.Features.Shared.Blocks.MobileRowLayout.MobileRowLayout',
  Avensia_Common_Features_Shared_Blocks_Pictogram_PictogramBlock: 'Avensia.Common.Features.Shared.Blocks.Pictogram.PictogramBlock',
  Avensia_Common_Features_Shared_Blocks_PodPlayer_PodPlayerBlock: 'Avensia.Common.Features.Shared.Blocks.PodPlayer.PodPlayerBlock',
  Avensia_Common_Features_Shared_Blocks_Products_ProductListBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.Products.ProductListBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_RowLayout_RowLayoutBlockViewModel: 'Avensia.Common.Features.Shared.Blocks.RowLayout.RowLayoutBlockViewModel',
  Avensia_Common_Features_Shared_Blocks_SiteBanner_SiteBannerBlock: 'Avensia.Common.Features.Shared.Blocks.SiteBanner.SiteBannerBlock',
  Avensia_Common_Features_Shared_Blocks_Video_VideoBlock: 'Avensia.Common.Features.Shared.Blocks.Video.VideoBlock',
  Avensia_Common_ContentTypes_Media_ImageDataModel: 'Avensia.Common.ContentTypes.Media.ImageDataModel',
  Avensia_Common_Features_Shared_Partials_PreviewViewModel: 'Avensia.Common.Features.Shared.Partials.PreviewViewModel',
  EPiServer_Core_ContentArea: 'EPiServer.Core.ContentArea',
  EPiServer_Core_XhtmlString: 'EPiServer.Core.XhtmlString',
  Avensia_Common_Features_SizeGuide_SizeGuidePageViewModel: 'Avensia.Common.Features.SizeGuide.SizeGuidePageViewModel',
  Avensia_Common_Features_Start_StartPageViewModel: 'Avensia.Common.Features.Start.StartPageViewModel',
  Avensia_Common_Features_TrackingInformation_TrackingImpressionBlock: 'Avensia.Common.Features.TrackingInformation.TrackingImpressionBlock',
  Avensia_Common_Features_WatchItems_UnregisterWatchItemPageViewModel: 'Avensia.Common.Features.WatchItems.UnregisterWatchItemPageViewModel',
  EPiServer_SpecializedProperties_LinkItemCollection: 'EPiServer.SpecializedProperties.LinkItemCollection',
};
