import { Style } from '@glitz/type';
import * as spacing from './spacing';

export function depth(): Style {
  return {
    boxShadow: theme => `0 ${spacing.tiny} ${spacing.small} 0 rgba(${theme.shadowColor}, .15), 0 ${spacing.tiny} ${
      spacing.medium
      } 0 rgba(${theme.shadowColor}, .1)`,
  };
}
