import React from 'react';
import { LinksContainer } from '@avensia/scope';
import { styled } from '@glitz/react';
import { ViewportPropType, styles } from './Shared';
import Html from 'Shared/Partials/PropertyTypes/Html';
import { pixelsToUnit, minMediumMediaQuery, zeta, theta, letterSpacingTiny } from 'Shared/Style';

type PropType = {
  safeECommerce: {
    header: string;
    body: Scope.XhtmlString;
  };
} & ViewportPropType;

const Section = styled.section({
  marginRight: 0,
  padding: {
    x: pixelsToUnit(10),
    y: 0,
  },
  [minMediumMediaQuery]: {
    marginRight: pixelsToUnit(60),
    order: 2,
    flex: {
      grow: 0,
      shrink: 1,
      basis: pixelsToUnit(300),
    },
  },
});

const Header = styled.h5(styles.header);

const Body = styled.div({
  fontSize: zeta,
  letterSpacing: letterSpacingTiny,
  lineHeight: 1.27,
  marginTop: pixelsToUnit(-10),
  [minMediumMediaQuery]: {
    ...styles.body,
    fontSize: theta,
  },
});

const SafeECommerce = (props: PropType) => (
  <Section>
    {props.safeECommerce.header && (!props.isCompact && <Header>{props.safeECommerce.header}</Header>)}
    <Body>
      {props.safeECommerce.body && (
        <LinksContainer>
          <Html className="footer-column-body" html={props.safeECommerce.body.html} />
        </LinksContainer>
      )}
    </Body>
  </Section>
);

export default SafeECommerce;
