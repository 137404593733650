import { delta, letterSpacingSmall } from 'Shared/Style';

export type ViewportPropType = {
  isCompact?: boolean;
};

export const styles = {
  header: {
    margin: {
      top: '0',
      bottom: '4px',
    },
    fontSize: delta,
    letterSpacing: letterSpacingSmall,
    lineHeight: 'normal',
  },
  body: {
    padding: {
      y: '10px',
      x: 0,
    },
  },
  anchor: {
    display: 'block',
    color: 'inherit',
    textDecoration: 'none',
    ':hover': {
      textDecoration: 'underline',
    },
  },
};
