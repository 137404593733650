import React from 'react';
import { pseudo } from '@glitz/core';
import { translate, isIE, URLX, isIOS } from '@avensia/scope';
import { ROOT_KEY, findItem, ItemKeyType, parentPrimaryKey } from '../MainMenu/item';
import BaseButton, { Appearance as ButtonAppearance } from 'Shared/Button';
import Flyout, { Position } from 'Shared/Flyout';
import { MainMenuType } from 'Shared/State';
import { black, thin, truncate, pixelsToUnit, zeta, epsilon, monochromeDark, small } from 'Shared/Style';
import Close from 'Shared/Icon/Close';
import IconBack from 'Shared/Icon/Back';
import Nav from './Nav';
import Link from 'Shared/Link';
import MobileMenuViewModel from 'Start/MobileMenuViewModel.type';
import RecentlyViewedCompact, { API_URL as RECENTLY_VIEWED_API_URL } from 'Product/ProductRecentlyViewed/Compact';
import connect from 'Shared/connect';
import { styled, StyledProps } from '@glitz/react';

type PageLinksType = {
  magazinePage: string;
  termsAndConditions: string;
  faqPage: string;
};

type ConnectStateType = {
  theme: string;
};

type ThemePropType = {
  theme: string;
};

type PropType = MainMenuType &
  ThemePropType &
  StyledProps & {
    toggleDrawer: () => void;
    navigate: (key: ItemKeyType) => void;
    pages: PageLinksType;
    mobileMenu: MobileMenuViewModel;
    recentlyViewed: {
      isShown: boolean;
      toggle: () => void;
      fetch: (url: URLX) => void;
    };
  };

class Drawer extends React.Component<PropType, ConnectStateType> {
  componentDidUpdate(prevProps: PropType) {
    const { isOpen, recentlyViewed } = this.props;
    if (isOpen !== prevProps.isOpen && isOpen && recentlyViewed.isShown) {
      this.props.recentlyViewed.fetch(new URLX(RECENTLY_VIEWED_API_URL));
    }
  }

  isRoot() {
    return this.props.visibleKey === ROOT_KEY;
  }

  back() {
    const { children, toggleDrawer, navigate, ...mainMenu } = this.props;
    this.isRoot() ? toggleDrawer() : navigate(parentPrimaryKey(mainMenu) || ROOT_KEY);
  }

  showRecentlyViewed = () => this.props.recentlyViewed.toggle();

  handleButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    return this.isRoot() ? this.props.toggleDrawer() : this.back();
  };

  render() {
    const currentItem = findItem(this.props.primaryMenuItems, this.props.visibleKey);
    const title = this.props.visibleKey === ROOT_KEY ? translate('/MainMenu/Heading') : currentItem.name || '';
    const { magazinePage, termsAndConditions, faqPage } = this.props.pages;

    return (
      <Flyout position={Position.Left} toggle={this.props.toggleDrawer} open={this.props.isOpen}>
        <Header>
          <Title>{this.props.recentlyViewed.isShown ? translate('/Product/RecentlyViewed') : title}</Title>
          {!this.props.recentlyViewed.isShown && (
            <Button
              css={this.isRoot() && isIE() && { top: 0 }}
              appearance={ButtonAppearance.Bare}
              onClick={this.handleButtonClick}
            >
              {this.isRoot() ? <Close /> : <Back />}
            </Button>
          )}
        </Header>
        <Tabs>
          <LastViewed onClick={this.showRecentlyViewed}>
            <LastViewedLabel css={{ fontWeight: this.props.recentlyViewed.isShown ? 'bold' : 'normal' }}>
              {translate('/Product/RecentlyViewed')}
            </LastViewedLabel>
          </LastViewed>
          {!!faqPage && (
            <Faq>
              <TabLink to={faqPage}>{translate('/MainMenu/CommonQuestions')}</TabLink>
            </Faq>
          )}
          {!!magazinePage && (
            <Magazine>
              <TabLink to={magazinePage}>
                {this.props.theme.startsWith('dog')
                  ? translate('/MainMenu/DoggieMagazine')
                  : this.props.theme.startsWith('cli')
                  ? translate('/MainMenu/ClitherapyMagazine')
                  : translate('/MainMenu/VuxenMagazine')}
              </TabLink>
            </Magazine>
          )}
          {!!termsAndConditions && (
            <TermsConditions>
              <TabLink to={termsAndConditions}>{translate('/MainMenu/TermsAndConditions')}</TabLink>
            </TermsConditions>
          )}
        </Tabs>
        {this.props.recentlyViewed.isShown ? (
          <RecentlyViewed navIsVisible={true} css={isIOS() && { WebkitOverflowScrolling: 'touch' }} />
        ) : (
          <NavigationMenu
            css={isIOS() && { WebkitOverflowScrolling: 'touch' }}
            primary={currentItem ? currentItem : this.props.primaryMenuItems}
            setLevel={this.props.navigate}
            mobileMenu={this.props.mobileMenu}
            currentMenuUrl={this.props.visibleKey}
          />
        )}
      </Flyout>
    );
  }
}

const Tabs = styled.ul({
  border: {
    bottom: {
      style: 'solid',
      width: thin,
      color: monochromeDark,
    },
  },
  display: 'flex',
  height: pixelsToUnit(50),
  listStyle: 'none',
});

const tabStyled = styled({
  color: black,
  display: 'flex',
  fontSize: zeta,
  flexGrow: 1,
  ...pseudo(':not(:last-child)', {
    border: {
      right: {
        style: 'solid',
        width: thin,
        color: monochromeDark,
      },
    },
  }),
});

const LastViewed = tabStyled(styled.Li, {
  flexBasis: '20%',
});
const Faq = tabStyled(styled.Li, {
  flexBasis: '23%',
});
const Magazine = tabStyled(styled.Li, {
  flexBasis: '27%',
});
const TermsConditions = tabStyled(styled.Li, {
  flexBasis: '28%',
});

const linkStyled = styled({
  alignItems: 'center',
  color: black,
  display: 'flex',
  flexBasis: '100%',
  justifyContent: 'center',
  padding: {
    x: pixelsToUnit(10),
  },
  textAlign: 'center',
});

const TabLink = linkStyled(Link);
const LastViewedLabel = linkStyled(styled.Span, {
  cursor: 'pointer',
});

const Header = styled.header({
  alignItems: 'center',
  backgroundColor: theme => theme.menuBarBackgroundColor,
  color: theme => theme.menuBarTextColor,
  display: 'flex',
  height: pixelsToUnit(50),
  justifyContent: 'center',
  position: 'relative',
  border: {
    bottom: {
      style: 'solid',
      width: thin,
      color: monochromeDark,
    },
  },
});

const Title = styled.span({
  fontSize: epsilon,
  textTransform: 'uppercase',
  ...truncate(),
});

const Button = styled(BaseButton, {
  left: 0,
  top: 0,
  position: 'absolute',
  textAlign: 'center',
  width: pixelsToUnit(50),
});

const Back = styled(IconBack, {
  height: small,
  verticalAlign: 'middle',
  color: theme => theme.menuBarTextColor,
});

const DRAWER_HEADER_TOPMENU_HEIGHT = 101;
const BACK_HEIGHT = 40;
const scrollStyled = styled({
  overflowX: 'hidden',
  overflowY: 'auto',
  overscrollBehaviorY: 'none',
});

const NavigationMenu = scrollStyled(Nav, {
  height: `calc(100% - ${DRAWER_HEADER_TOPMENU_HEIGHT}px)`,
});
const RecentlyViewed = scrollStyled(RecentlyViewedCompact, {
  height: `calc(100% - ${DRAWER_HEADER_TOPMENU_HEIGHT + BACK_HEIGHT}px)`,
});

export default styled(
  connect(
    (state): ConnectStateType => ({
      theme: state.currentTheme,
    }),
  )(Drawer),
);
