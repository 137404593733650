import React from 'react';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
import Ul from 'Shared/Generic/Ul';
import { ViewportPropType, styles } from './Shared';
import { minMediumMediaQuery, zeta, theta, letterSpacingTiny } from 'Shared/Style';
import LinkGroupViewModelType from 'Shared/Blocks/LinkGroup/LinkGroupViewModel.type';

type PropType = {
  magazineGuides: LinkGroupViewModelType;
} & ViewportPropType;

const Header = styled.h5(styles.header);
const Section = styled.section({
  marginRight: 0,
  padding: {
    y: 0,
    x: '10px',
  },
  [minMediumMediaQuery]: {
    marginRight: '30px',
    order: 4,
    flex: {
      grow: 0,
      shrink: 1,
      basis: '300px',
    },
  },
});

const Li = styled.li({
  display: 'block',
  lineHeight: 3,
  padding: {
    xy: 0,
  },
  [minMediumMediaQuery]: {
    lineHeight: 1.5,
    padding: {
      y: '5px',
      x: 0,
    },
  },
});

const Body = styled(Ul, {
  fontSize: zeta,
  letterSpacing: letterSpacingTiny,
  [minMediumMediaQuery]: {
    ...styles.body,
    fontSize: theta,
  },
});

const MagazineGuides = (props: PropType) => (
  <Section>
    {props.magazineGuides.header && (!props.isCompact && <Header>{props.magazineGuides.header}</Header>)}
    <Body bare column>
      {props.magazineGuides.links.map(link => (
        <Li key={link.title + link.url}>
          <Link css={styles.anchor} to={link.url}>
            {link.title}
          </Link>
        </Li>
      ))}
    </Body>
  </Section>
);

export default MagazineGuides;
