import { query } from '@glitz/core';
import { Breakpoint, widthFromBreakpoint } from '@avensia/scope';
import { pixelsToUnit, Unit } from './unit';

function mediaQuery(breakpoint: Breakpoint) {
  return query({
    minWidth: pixelsToUnit(widthFromBreakpoint(breakpoint)),
  });
}

export function breakpointValue(breakpoint: Breakpoint) {
  return pixelsToUnit(widthFromBreakpoint[breakpoint], Unit.Px);
}

/** Media query for minimum window width for smaller smartphones in portrait mode */
export const mediaMinMicro = mediaQuery(Breakpoint.Micro);

/** Media query for minimum window width for larger smartphones and smaller tablets in portrait mode */
export const mediaMinTiny = mediaQuery(Breakpoint.Tiny);

/** Media query for minimum window width for larger tablets in portrait mode */
export const mediaMinSmall = mediaQuery(Breakpoint.Small);

/** Media query for minimum window width for smaller tablets in landscape mode */
export const mediaMinMedium = mediaQuery(Breakpoint.Medium);

/** Media query for minimum window width for larger tablets in landscape mode, smaller laptops and desktops */
export const mediaMinLarge = mediaQuery(Breakpoint.Large);

/** Media query for minimum window width for larger laptops and desktops */
export const mediaMinHuge = mediaQuery(Breakpoint.Huge);

export const mediaMinQueries = {
  [Breakpoint.Micro]: mediaMinMicro,
  [Breakpoint.Tiny]: mediaMinTiny,
  [Breakpoint.Small]: mediaMinSmall,
  [Breakpoint.Medium]: mediaMinMedium,
  [Breakpoint.Large]: mediaMinLarge,
  [Breakpoint.Huge]: mediaMinHuge,
};

// Use these media query constants for styling
export const minMicroMediaQuery = `@media ${mediaQuery(Breakpoint.Micro)}`;
export const minTinyMediaQuery = `@media ${mediaQuery(Breakpoint.Tiny)}`;
export const minSmallMediaQuery = `@media ${mediaQuery(Breakpoint.Small)}`;
export const minMediumMediaQuery = `@media ${mediaQuery(Breakpoint.Medium)}`;
export const minLargeMediaQuery = `@media ${mediaQuery(Breakpoint.Large)}`;
export const minHugeMediaQuery = `@media ${mediaQuery(Breakpoint.Huge)}`;
export const minMediumMediaQueryAlt = mediaQuery(Breakpoint.Medium);
