import { TOGGLE_MINICART } from './action-creators';
import { PAGE_LOAD } from '@avensia/scope';
import { Action } from 'Shared/State';

type MiniCartState = { isOpen: boolean };

export default function (state: MiniCartState = { isOpen: false }, action: Action): MiniCartState {
  switch (action.type) {
    case TOGGLE_MINICART:
      return Object.assign({}, state, {
        isOpen: !state.isOpen,
      });
    case PAGE_LOAD: {
      if (state.isOpen) {
        return Object.assign({}, state, {
          isOpen: false,
        });
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}
