import { PageType } from 'Shared/State';
import { componentNames } from 'Shared/component-registry';
import UnregisterWatchItemPageViewModelType from './UnregisterWatchItemPageViewModel.type';

type UnregisterWatchItemType = UnregisterWatchItemPageViewModelType & PageType;

export default (currentPage: PageType): currentPage is UnregisterWatchItemType => {
  return (
    currentPage.componentName === componentNames.Avensia_Common_Features_WatchItems_UnregisterWatchItemPageViewModel
  );
};
