import LinkGroupLinkViewModelType from 'Shared/Blocks/LinkGroup/LinkGroupLinkViewModel.type';

type ObjType = {
  header: string;
  body?: string | Scope.XhtmlString;
  links?: LinkGroupLinkViewModelType[];
};

export default function isSectionEmpty(footerSection: ObjType) {
  if ('body' in footerSection && (!footerSection.header && !footerSection.body)) {
    // newsletter & safeecommerce columns
    return true;
  }
  if ('links' in footerSection && (!footerSection.header && !(footerSection.links.length > 0))) {
    // customerservice & magazines columns
    return true;
  }
  return false;
}
