import React, { useRef, useState, useLayoutEffect, useCallback, useEffect } from 'react';
import { styled, StyledProps } from '@glitz/react';
import { findBrowserCachedResource, useLoadResource } from './utils';
import { ImageBase, NoImage } from './components';
import noImage from './noimage';

if (__BROWSER__) {
  require('intersection-observer');
}

const MIN_ROOT_MARGIN = 400;

enum Status {
  Pending,
  Rejected,
  Fulfilled,
}

type PropType = StyledProps &
  React.ImgHTMLAttributes<HTMLImageElement> & {
    originalSrc: string;
  };

export default styled(function LazyImage({ compose, originalSrc, ...restProps }: PropType) {
  const currentSrc = restProps.src;
  const previousSrcRef = useRef<string>();
  const currentSrcSet = restProps.srcSet;
  const previousSrcSetRef = useRef<string>();

  const [status, setStatus] = useState(currentSrc ? Status.Pending : Status.Rejected);

  const loadResource = useLoadResource();

  const elementRef = useRef<HTMLImageElement>(null);
  const elementRefCallback = useCallback((el: HTMLImageElement) => (elementRef.current = el), []);

  const unsubscribeObserverRef = useRef<() => void>(null);

  const mountedRef = useRef<boolean>(true);

  useLayoutEffect(() => {
    if (previousSrcRef.current !== currentSrc || previousSrcSetRef.current !== currentSrcSet) {
      if (currentSrc) {
        setStatus(Status.Pending);

        if (unsubscribeObserverRef.current) {
          unsubscribeObserverRef.current();
        }

        let rootYMargin = window.innerHeight / 2;
        if (rootYMargin < MIN_ROOT_MARGIN) {
          rootYMargin = MIN_ROOT_MARGIN;
        }

        const observer = new IntersectionObserver(
          intersections => {
            if (!intersections.every(intersection => !intersection.isIntersecting)) {
              loadResource(originalSrc, currentSrc, currentSrcSet).then(({ isFulfilled, isCurrentLoad }) => {
                if (isCurrentLoad && mountedRef.current) {
                  setStatus(isFulfilled ? Status.Fulfilled : Status.Rejected);
                }
              });

              if (unsubscribeObserverRef.current) {
                unsubscribeObserverRef.current();
              }
            }
          },
          { rootMargin: `${rootYMargin}px 50% ${rootYMargin}px 50%` },
        );

        observer.observe(elementRef.current);

        const element = elementRef.current;

        unsubscribeObserverRef.current = () => {
          observer.unobserve(element);
          unsubscribeObserverRef.current = null;
        };
      } else {
        setStatus(Status.Rejected);
      }

      previousSrcRef.current = currentSrc;
      previousSrcSetRef.current = currentSrcSet;
    }
  }, [currentSrc, currentSrcSet, loadResource, originalSrc]);

  useEffect(
    () => () => {
      mountedRef.current = false;

      if (unsubscribeObserverRef.current) {
        unsubscribeObserverRef.current();
      }
    },
    [],
  );

  if (currentSrc) {
    if (status === Status.Fulfilled) {
      return <ImageBase {...restProps} src={currentSrc} css={compose()} ref={elementRefCallback} />;
    }

    // Prevent `srcset` to interfere cached-, placeholder- and no-image image
    delete restProps.srcSet;

    const cachedResource = findBrowserCachedResource(originalSrc);

    if (cachedResource) {
      return <ImageBase {...restProps} src={cachedResource.src} css={compose()} ref={elementRefCallback} />;
    }

    if (status === Status.Pending) {
      // Note that we are rendering a specific SVG here so we should not just compose style but have specfic style to handle this.
      return <ImageBase {...restProps} css={compose({ opacity: 0 })} ref={elementRefCallback} />;
    }
  }

  // Prevent `srcset` to interfere with no image image
  delete restProps.srcSet;

  return <NoImage {...restProps} src={noImage} css={compose()} ref={elementRefCallback} />;
});

