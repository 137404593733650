import { postJson } from '@avensia/scope';
import TrackingInformationProduct from 'TrackingInformation/TrackingInformationProduct.type';
import TrackingInformationTransaction from 'TrackingInformation/TrackingInformationTransaction.type';

const productsEndpoint = '/Tracking/GetTrackingProducts';
const transactionEndpoint = '/Tracking/GetTransaction';

export async function fetchProducts(variantCodes: string[]) {
  let trackingProducts: TrackingInformationProduct[];
  if (variantCodes?.length > 0) {
    trackingProducts = await postJson(productsEndpoint, {
      variantCodes,
    });

    trackingProducts = cleanBackendProducts(trackingProducts);
  }

  return trackingProducts;
}

export async function fetchTransaction(id: string): Promise<TrackingInformationTransaction> {
  const trackingTransaction: TrackingInformationTransaction = await postJson(transactionEndpoint, { id });
  trackingTransaction.products = cleanBackendProducts(trackingTransaction.products);
  return trackingTransaction;
}

export function cleanBackendProducts(products: TrackingInformationProduct[]): TrackingInformationProduct[] {
  return products.map((trackingProduct) => {
    for (const key in trackingProduct) {
      if (trackingProduct[key] === null) {
        delete trackingProduct[key];
      }
    }

    return trackingProduct;
  });
}
