export enum ZIndex {
  SiteBanner = 3,
  Tray,
  Header,
  Panel,
  QuickSearch,
  Overlay,
  Spinner,
  Modal,
}
