import { addToDataLayer, createRemoveFromCartTracking, createAddToCartTracking } from '.';
import { postJson } from '@avensia/scope';
import { TrackingInformationProductWithQuantity } from './tracking-types';
import { addedToCart } from './Klaviyo';

export type CodeQuantity = {
  id: string;
  quantity: number;
};

type CartThrottlerPayload = {
  products: CodeQuantity[];
  listName: string;
  isAdd: boolean;
};

let timeoutId = 0;
let cartActionsQueue: CartThrottlerPayload[] = [];
let trackingInformationProductsInQueue: CodeQuantity[] = [];

export function addToCartThrottler(payload: CartThrottlerPayload) {
  cartActionsQueue.push(payload);
  payload.products.forEach((p) => trackingInformationProductsInQueue.push(p));

  if (timeoutId) {
    clearTimeout(timeoutId);
    timeoutId = 0;
  }
  timeoutId = setTimeout(excecuteCartThrottlerQueue, 2000);
}

function excecuteCartThrottlerQueue() {
  if (!cartActionsQueue.length) {
    return;
  }
  timeoutId = 0;

  const { addedCodeQuantities, removedCodeQuantities, listName } = mergeCartAddActions();
  trackingInformationProductsInQueue = [];
  cartActionsQueue = [];

  const allCodesDistinct = [...addedCodeQuantities, ...removedCodeQuantities]
    .filter((v, i, a) => a.indexOf(v) === i)
    .map((s) => s.id);

  if (allCodesDistinct?.length) {
    postJson('/Tracking/GetTrackingProducts', { variantCodes: allCodesDistinct }).then(
      (products: TrackingInformationProductWithQuantity[]) => {
        const addedProducts = addedCodeQuantities.map((s) => mapCodeQuantityToTrackingProduct(s, products));
        const removedProducts = removedCodeQuantities.map((s) => mapCodeQuantityToTrackingProduct(s, products));

        if (addedProducts.length) {
          addedToCart(addedProducts);
          addToDataLayer(createAddToCartTracking(addedProducts, listName));
        }
        if (removedProducts.length) {
          addToDataLayer(createRemoveFromCartTracking(removedProducts, listName));
        }
      },
    );
  }
}

function mapCodeQuantityToTrackingProduct(
  codeQuantity: CodeQuantity,
  products: TrackingInformationProductWithQuantity[],
) {
  const trackingProduct = products.find((c) => c.id === codeQuantity.id) as TrackingInformationProductWithQuantity;
  trackingProduct.quantity = codeQuantity.quantity;
  return trackingProduct;
}

function mergeCartAddActions() {
  const intermediate = {};
  // If the user has done very quick modifications to the cart from different places
  // we will track as if it did all the modifications in the last place
  let lastActionListName = '';
  cartActionsQueue.forEach((payload) => {
    payload.products.forEach((product) => {
      if (!(product.id in intermediate)) {
        intermediate[product.id] = payload.isAdd ? product.quantity : -product.quantity;
      } else {
        intermediate[product.id] = payload.isAdd
          ? intermediate[product.id] + product.quantity
          : intermediate[product.id] - product.quantity;
      }
      lastActionListName = payload.listName;
    });
  });

  const addedCodeQuantities = [];
  const removedCodeQuantities = [];

  for (const code in intermediate) {
    const trackingProduct = trackingInformationProductsInQueue.find((s) => s.id === code);
    if (intermediate[code] > 0) {
      trackingProduct.quantity = intermediate[code];
      addedCodeQuantities.push(trackingProduct);
    } else if (intermediate[code] < 0) {
      trackingProduct.quantity = Math.abs(intermediate[code]);
      removedCodeQuantities.push(trackingProduct);
    }
  }

  return { addedCodeQuantities, removedCodeQuantities, listName: lastActionListName };
}
