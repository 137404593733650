import React from 'react';
import { styled } from '@glitz/react';
import { Style } from '@glitz/type';
import { isIE, isBrowser, on, offsetTop, translate, Breakpoint } from '@avensia/scope';
import { PageType } from 'Shared/State';
import { epiPropertyValue } from '@avensia/scope-episerver';
import Pictograms from 'Shared/Blocks/Pictogram/PictogramBlock';
import { MainMenuType } from 'Shared/State';
import MenuButtonItemType from 'Shared/NavigationItem/MenuButtonItem.type';
import MenuIcon from 'Shared/Icon/Menu';
import HeightTransition from 'Shared/HeightTransition';
import { slideIn } from './keyframes.scss';
import Overlay from 'Shared/Overlay';
import {
  pixelsToUnit,
  pageMaxWidth,
  minMediumMediaQuery,
  minLargeMediaQuery,
  sigma,
  eta,
  zeta,
  theta,
  animation,
} from 'Shared/Style';
import connect from 'Shared/connect';
import Link from 'Shared/Link';
import { Basic, Appearance } from 'Shared/PageLayout';
import SiteBanner, { DEFAULT } from 'Shared/Blocks/SiteBanner/SiteBannerBlock';
import SiteBannerType from 'Shared/Blocks/SiteBanner/SiteBannerBlock.type';
import { saveToStorage, shouldComponentUpdate as shouldSiteBannerUpdate } from 'Shared/Blocks/SiteBanner/helper';
import { ROOT_KEY, findFirstLevelItem } from '../MainMenu/item';
import { toggleMainMenu, toggleStickyNavbar } from '../MainMenu/action-creators';
import MegaMenu from './MegaMenu';
import SiteLogo from './Logo';
import NavDesktop from 'SiteLayout/Nav/NavDesktop';
import MiniCart from 'SiteLayout/Nav/MiniCart';
import { Order } from 'SiteLayout';
import HeaderPlain from 'SiteLayout/Header/HeaderPlain';
import isCurrentPageCheckout from 'Checkout/Pages/Checkout/current-page-is-checkout';
import isCurrentPageVariation from 'Product/current-page-is-variation';
import isCurrentPageUnwatch from 'WatchItems/current-page-is-unwatch';
import isCurrentPageOrderConfirmation from 'Checkout/Pages/OrderConfirmation/current-page-is-order-confirmation';
import SearchForm from 'Search/QuickSearch';
import CheckoutPageViewModelType from 'Checkout/Pages/Checkout/CheckoutPageViewModel.type';
import { currentUrl } from '@avensia/scope';
import InfoSupportIcon from 'Shared/Icon/InfoSupport';
import InfoSupportIconAlt from 'Shared/Icon/InfoSupportAlt';

type ConnectStateType = {
  isStickyNavbarOpen: boolean;
  isCheckoutPage: boolean;
  isVariationPage: boolean;
  isUnwatchPage: boolean;
  isOrderConfirmationPage: boolean;
  loginPageUrl: string;
  mainMenu: MainMenuType;
  mainMenuButtons: MenuButtonItemType[];
  siteBanner: SiteBannerType;
  headerContactDetails: string;
  orderConfirmationPageUrl: string;
  customerServicePageUrl: string;
  theme: string;
  currentBreakpoint: number;
};

type CheckoutPageType = CheckoutPageViewModelType & PageType;

type ConnectDispatchType = {
  toggleMainMenu: (e?: React.MouseEvent<HTMLElement>) => void;
  toggleStickyNavbar: (e?: React.MouseEvent<HTMLElement>) => void;
};

type PropType = ConnectStateType &
  ConnectDispatchType & {
    order: Order;
  };

type StateType = {
  sticky: boolean;
  bannerOpen: boolean;
  bannerHeight: number;
};

class HeaderDesktop extends React.PureComponent<PropType, StateType> {
  stickyBannerTop: number;
  menuBarTop: number;
  menuBarRef = React.createRef<HTMLDivElement>();
  unsubscribeScroll: () => void;
  subscribeDescriptionLinkClick: boolean;

  constructor(props: PropType) {
    super(props);
    this.state = {
      sticky: false,
      bannerOpen: false,
      bannerHeight: DEFAULT,
    };
    this.subscribeDescriptionLinkClick = false;
  }

  componentDidMount() {
    if (isBrowser()) {
      this.unsubscribeScroll = on('scroll', this.handlePageScroll);
      if (this.menuBarRef && this.menuBarRef.current) {
        const { current } = this.menuBarRef;
        this.menuBarTop = offsetTop(current);
        this.stickyBannerTop = 37;
      }

      if (
        this.props.siteBanner &&
        shouldSiteBannerUpdate(this.props.siteBanner) &&
        !this.props.isOrderConfirmationPage
      ) {
        this.openBanner();
      }
    }
  }

  componentDidUpdate(prevProps: PropType) {
    if (
      (!this.props.isCheckoutPage && prevProps.isCheckoutPage) ||
      (!this.props.isUnwatchPage && prevProps.isUnwatchPage) ||
      (!this.props.isOrderConfirmationPage && prevProps.isOrderConfirmationPage)
    ) {
      if (!this.unsubscribeScroll) {
        this.unsubscribeScroll = on('scroll', this.handlePageScroll);
      }
      if (
        this.props.siteBanner &&
        shouldSiteBannerUpdate(this.props.siteBanner) &&
        !this.props.isOrderConfirmationPage
      ) {
        this.openBanner();
      }
    }
    if (this.props.isOrderConfirmationPage && !prevProps.isOrderConfirmationPage && this.state.bannerOpen) {
      this.resetBanner();
    }
    if (
      (this.props.isCheckoutPage && !prevProps.isCheckoutPage) ||
      (this.props.isUnwatchPage && !prevProps.isUnwatchPage)
    ) {
      this.updateState({ sticky: false });
      if (this.unsubscribeScroll) {
        this.unsubscribeScroll();
        this.unsubscribeScroll = null;
      }
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeScroll) {
      this.unsubscribeScroll();
      this.unsubscribeScroll = null;
    }
  }

  updateState(newState: { [key: string]: number | boolean }) {
    this.setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }

  openBanner() {
    this.updateState({ bannerOpen: true });
  }

  closeBanner = () => {
    saveToStorage(this.props.siteBanner);
    this.resetBanner();
  };

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  onClickSearch() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  resetBanner = () => this.updateState({ bannerOpen: false, bannerHeight: DEFAULT });

  isSticky() {
    const verticalOffsetFromTop = Math.round(window.pageYOffset || document.documentElement.scrollTop);
    return verticalOffsetFromTop > this.menuBarTop;
  }

  handlePageScroll = () => this.updateState({ sticky: this.isSticky() });
  handleBannerHeightChange = (bannerHeight: number) => this.updateState({ bannerHeight });

  render() {
    const isMobile = this.props.currentBreakpoint < Breakpoint.Medium;
    const pictograms = !this.props.isVariationPage && <Pictograms />;
    const isVxnSite =
      !this.props.theme.startsWith('dog') &&
      !this.props.theme.startsWith('cli') &&
      !this.props.theme.startsWith('lac') &&
      !this.props.theme.startsWith('party');

    if (this.props.isCheckoutPage) {
      return (
        <React.Fragment>
          <HeaderPlain order={this.props.order} goHomeText={translate('/Checkout/Header/ContinueShopping')}>
            <ContactDetails>{this.props.headerContactDetails}</ContactDetails>
          </HeaderPlain>
          <PictogramWrapper>{pictograms}</PictogramWrapper>
        </React.Fragment>
      );
    }
    if (this.props.isUnwatchPage) {
      return (
        <React.Fragment>
          <HeaderPlain />
          {pictograms}
        </React.Fragment>
      );
    }

    const { order, mainMenu, mainMenuButtons, siteBanner } = this.props;
    const { visibleKey } = mainMenu;
    const visibleFirstLevelItem =
      visibleKey !== ROOT_KEY ? findFirstLevelItem(mainMenu.primaryMenuItems, visibleKey) : null;
    const { sticky, bannerOpen, bannerHeight } = this.state;
    const menuBarStickyStyle: Style = {
      backgroundColor: (theme) => theme.menuBarStickyBackgroundColor,
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: 7,
      fontSize: sigma,
    };
    const siteBannerStickyStyle: Style = {
      position: 'fixed',
      top: this.stickyBannerTop,
      zIndex: 4,
      ...(mainMenu.isStickyNavbarOpen && { top: pixelsToUnit(81) }),
    };
    // this boolean prevent to show campaign banner in desktop during reload in order confirmation page.
    const isHideCampaignBanner = currentUrl().pathname === this.props.orderConfirmationPageUrl;

    return (
      <Base css={{ order }}>
        <TopContent>
          <HomeNonStickyDesktop to="/" onClick={this.scrollToTop}>
            <SiteLogo />
          </HomeNonStickyDesktop>
          {!sticky && <SearchForm />}
          <MyCartContainer>
            <MyCart />
          </MyCartContainer>
        </TopContent>

        <Overlay css={{ height: MENU_BAR_HEIGHT }} enabled={mainMenu.isOpen} onClose={this.props.toggleMainMenu}>
          <FullMenuBar css={sticky && menuBarStickyStyle} elementRef={this.menuBarRef}>
            <MaxMenuBar appearance={Appearance.Full}>
              <MenuIconWrapper>
                {sticky && !mainMenu.isOpen && <MenuIconStyled onClick={this.props.toggleStickyNavbar} />}
              </MenuIconWrapper>
              {sticky && (
                <div style={{ position: 'relative' }}>
                  <Home css={{ position: 'absolute', left: pixelsToUnit(60) }} to="/" onClick={this.scrollToTop}>
                    <SiteLogo sticky={sticky} />
                  </Home>
                </div>
              )}
              {!sticky && <NavigationBar css={isIE() && { minWidth: '50%' }} />}
              <MegaMenyWrapper
                css={
                  sticky
                    ? mainMenu.isStickyNavbarOpen
                      ? { position: 'absolute', top: pixelsToUnit(79) }
                      : { position: 'absolute', top: pixelsToUnit(35) }
                    : { position: 'absolute', top: pixelsToUnit(37) }
                }
              >
                <MegaMenu isOpen={mainMenu.isOpen} item={visibleFirstLevelItem} mainMenuButtons={mainMenuButtons} />
              </MegaMenyWrapper>
              {sticky && (
                <StickySearchWrapper>
                  <SearchForm css={{ width: '50%' }} />
                </StickySearchWrapper>
              )}
              {/* {this.props.loginPageUrl && <MyAccount />} */}
              {sticky ? (
                <MyCartContainerSticky>
                  <MyCart />
                </MyCartContainerSticky>
              ) : (
                <ContactUs>
                  <CustomerServiceLink to={this.props.customerServicePageUrl}>
                    {isVxnSite && isMobile ? <InfoSupportIconAltStyled /> : <InfoSupportIconStyled />}
                  </CustomerServiceLink>
                </ContactUs>
              )}
            </MaxMenuBar>
            {sticky && (
              <HeightTransition isOpened={mainMenu.isStickyNavbarOpen} duration={0.1}>
                <StickyNavBarWrapper>
                  <NavigationBar css={isIE() && { minWidth: '50%' }} />
                </StickyNavBarWrapper>
              </HeightTransition>
            )}
          </FullMenuBar>
        </Overlay>
        {!!siteBanner && (
          <React.Fragment>
            {sticky && bannerOpen && <styled.Div css={{ height: bannerHeight }} />}
            <SiteBanner
              css={sticky && siteBannerStickyStyle}
              text={siteBanner.text}
              hideTitle={siteBanner.hideTitle}
              backgroundColor={siteBanner.backgroundColor}
              textColor={siteBanner.textColor}
              bannerLink={siteBanner.bannerLink}
              discountLink={siteBanner.discountLink}
              hideDiscountCountdown={siteBanner.hideDiscountCountdown}
              open={bannerOpen}
              onClose={this.closeBanner}
              onHeightChange={this.handleBannerHeightChange}
              onUnmount={this.resetBanner}
              hidden={isHideCampaignBanner}
            />
          </React.Fragment>
        )}
        {pictograms}
      </Base>
    );
  }
}

export default connect(
  (state): ConnectStateType => {
    const headerCheckout = state.currentPage as CheckoutPageType;
    return {
      isStickyNavbarOpen: state.mainMenu.isStickyNavbarOpen,
      isCheckoutPage: isCurrentPageCheckout(state.currentPage),
      isVariationPage: isCurrentPageVariation(state.currentPage),
      isUnwatchPage: isCurrentPageUnwatch(state.currentPage),
      isOrderConfirmationPage: isCurrentPageOrderConfirmation(state.currentPage),
      mainMenu: state.mainMenu,
      mainMenuButtons: state.appShellData.mainMenuButtons,
      siteBanner: state.appShellData.siteBanner,
      loginPageUrl: state.appShellData.siteSettings.loginPage?.url,
      headerContactDetails: headerCheckout.page ? epiPropertyValue(headerCheckout.page.headerContactDetails) : '',
      orderConfirmationPageUrl: state.appShellData.siteSettings.orderConfirmationPage?.url,
      customerServicePageUrl: state.appShellData.siteSettings.customerServiceMainPage?.url,
      theme: state.currentTheme,
      currentBreakpoint: state.currentBreakpoint,
    };
  },
  (dispatch): ConnectDispatchType => ({
    toggleMainMenu(e?: React.MouseEvent<HTMLElement>) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      return dispatch(toggleMainMenu());
    },
    toggleStickyNavbar(e?: React.MouseEvent<HTMLElement>) {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      return dispatch(toggleStickyNavbar());
    },
  }),
)(HeaderDesktop);

export const MENU_BAR_HEIGHT = pixelsToUnit(37);

const Base = styled(Basic, {
  backgroundColor: (theme) => theme.headerBackgroundColor,
  color: (theme) => theme.headerTextColor,
  display: 'flex',
  flexDirection: 'column',
});

const TopContent = styled.header({
  position: 'relative',
  zIndex: 7,
  color: (theme) => theme.headerTextColor,
  display: 'flex',
  justifyContent: 'center',
  margin: {
    y: pixelsToUnit(10),
    x: 'auto',
  },
  maxWidth: pixelsToUnit(pageMaxWidth),
  padding: {
    x: pixelsToUnit(20),
  },
  width: '100%',
  ':before': {
    content: '""',
    flex: {
      shrink: 1,
    },
  },
});

const FullMenuBar = styled(Basic, {
  fontSize: eta,
  whiteSpace: 'nowrap',
  backgroundColor: (theme) => theme.menuBarBackgroundColor,
  color: (theme) => theme.menuBarTextColor,
  height: MENU_BAR_HEIGHT,
  maxHeight: MENU_BAR_HEIGHT,
  border: {
    y: {
      width: '1px',
      style: 'solid',
      color: (theme) => theme.menuBarBorderColor,
    },
  },
  [minLargeMediaQuery]: {
    fontSize: zeta,
  },
});

const MaxMenuBar = styled(Basic, {
  display: 'flex',
  height: '100%',
});

const MegaMenyWrapper = styled.div({
  width: '100%',
  maxWidth: pixelsToUnit(1550),
  left: 0,
  right: 0,
  marginLeft: 'auto',
  marginRight: 'auto',
});

const MenuIconWrapper = styled.div({
  width: '4rem',
  position: 'absolute',
  left: 0,
  top: pixelsToUnit(6),
});

const StickyNavBarWrapper = styled.div({
  position: 'relative',
  whiteSpace: 'nowrap',
  fontSize: eta,
  backgroundColor: (theme) => theme.menuBarBackgroundColor,
  height: pixelsToUnit(45),
  [minLargeMediaQuery]: {
    fontSize: zeta,
  },
});

const MenuIconStyled = styled(MenuIcon, {
  height: '100%',
  width: '1.5rem',
  color: (theme) => theme.stickyLogoColor,
  margin: { x: pixelsToUnit(10) },
  ...animation({
    name: slideIn,
    duration: '1.5s',
    timingFunction: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
    fillMode: 'forwards',
    delay: 0,
  }),
});

const ContactUs = styled.div({
  // flex: {
  //   grow: 1,
  //   shrink: 1,
  //   basis: pixelsToUnit(280),
  // },
  // fontSize: zeta,
  // textAlign: 'right',
  // [minMediumMediaQuery]: {
  //   whiteSpace: 'nowrap',
  //   paddingLeft: pixelsToUnit(5),
  // },
});

const Home = styled(Link, {
  textAlign: 'center',
  zIndex: 2,
});

const HomeNonStickyDesktop = styled(Link, {
  position: 'absolute',
  height: pixelsToUnit(10),
  textAlign: 'center',
  zIndex: 2,
  left: pixelsToUnit(10),
});

const CustomerServiceLink = styled(Link, {
  height: '2.5rem',
  textAlign: 'center',
  zIndex: 2,
});

const InfoSupportIconStyled = styled(InfoSupportIcon, {
  color: (theme) => theme.customerServiceIconColor,
  fontSize: '2.2rem',
  width: '5rem',
  paddingBottom: '0.3rem',
  textAlign: 'center',
  zIndex: 2,
});

const InfoSupportIconAltStyled = styled(InfoSupportIconAlt, {
  color: (theme) => theme.customerServiceIconColor,
  fontSize: '2.2rem',
  width: '5.5rem',
  paddingRight: '1rem',
  paddingBottom: '0.3rem',
  textAlign: 'center',
  zIndex: 2,
});

const NavigationBar = styled(NavDesktop, {
  flexGrow: 1,
  height: '100%',
  marginLeft: '5.5rem',
});

const StickySearchWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
});

const MyCartContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  right: 0,
  top: 0,
});

const MyCartContainerSticky = styled.div({});

const MyCart = styled(MiniCart, {
  height: '100%',
  color: (theme) => theme.minicartButtonIcon,
});

const ContactDetails = styled.div({
  display: 'none',
  [minMediumMediaQuery]: {
    display: 'block',
    fontSize: theta,
    fontWeight: 'normal',
    letterSpacing: pixelsToUnit(0.5),
  },
});

const PictogramWrapper = styled.div({
  marginTop: MENU_BAR_HEIGHT,
});
