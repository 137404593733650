import React from 'react';
import { styled, applyClassName } from '@glitz/react';
import { isBrowser, Root, CurrentPage, URLX } from '@avensia/scope';
import DesktopHeader from './Header/HeaderDesktop';
import MobileHeader from './Header/HeaderMobile';
import MobileHeaderAlternateVersion from './Header/HeaderMobileAlternateVersion';
import MainFooter from './Footer';
import RadioPlay from 'RadioPlay';
import Loader from './Loader';
import NetworkError from './NetworkError';
import { TrayProvider, Tray } from './Tray';
import { toggleMainMenu, navigateMainMenu } from './MainMenu/action-creators';
import { MainMenuType, PageType } from 'Shared/State';
import connect from 'Shared/connect';
import Viewport from 'Shared/Viewport';
import { pageMaxWidth, pixelsToUnit } from 'Shared/Style';
import isCurrentPagePageNotFound from 'Error/Pages/current-page-is-page-not-found';
import { ItemKeyType } from './MainMenu/item';
import Drawer from './Drawer';
import MobileMenuViewModel from 'Start/MobileMenuViewModel.type';
import { FlyoutMiniCart as MiniCart } from './MiniCart';
import { toggleMiniCart } from './MiniCart/action-creators';
import {
  toggle as toggleRecentlyViewed,
  fetch as fetchRecentlyViewed,
} from 'Product/ProductRecentlyViewed/action-creators';

type ConnectStateType = {
  currentPage: PageType;
  mainMenu: MainMenuType;
  mobileMenuButtons: MobileMenuViewModel;
  isRecentlyViewedShown: boolean;
  showLoadingSpinner: boolean;
  isPageNotFoundPage: boolean;
  miniCartIsOpen: boolean;
  magazinePageUrl: string;
  termsAndConditionsPageUrl: string;
  faqPageUrl: string;
  radioPlayIsOpen: boolean;
  theme: string;
};

type ConnectDispatchType = {
  navigateMainMenu: (key: ItemKeyType) => void;
  toggleMainMenu: (e?: React.MouseEvent<HTMLElement>) => void;
  toggleMiniCart: (e?: React.MouseEvent<HTMLElement>) => void;
  toggleRecentlyViewed: () => void;
  fetchRecentlyViewed: (url: URLX) => void;
};

type PropType = ConnectStateType & ConnectDispatchType;

export enum Order {
  Header,
  SystemText,
  Content,
  Footer,
}

const formatHost = (host: string) => {
  const parts = host.split('.');
  if (parts.length !== 3) {
    return host;
  }
  return parts[1].substring(0, 1).toUpperCase() + parts[1].substring(1) + '.' + parts[2];
};

const isCanonicalResource = (page: {}): page is Scope.ICanonicalResource => {
  return page && 'canonicalUrl' in page;
};

const renderMeta = (currentPage: PageType) => {
  if (isBrowser()) {
    const canonicalResource: {} = currentPage;
    let canonical = document.getElementById('link-canonical') as HTMLLinkElement;
    if (isCanonicalResource(canonicalResource)) {
      if (!canonical) {
        canonical = document.createElement('link');
        canonical.rel = 'canonical';
        canonical.id = 'link-canonical';
        document.head.appendChild(canonical);
      }
      console.debug('Updating canonical to', canonicalResource.canonicalUrl);
      canonical.href = canonicalResource.canonicalUrl;
    } else if (canonical) {
      console.debug('Removing canonical');
      document.head.removeChild(canonical);
    }

    const newTitle = ((currentPage.meta && currentPage.meta.title) || '') + ' | ' + formatHost(location.host);
    if (document.title === newTitle) {
      return;
    }
    document.title = newTitle;
    Array.from(document.querySelectorAll('meta[data-dynamic]')).forEach((node) => {
      node.parentElement.removeChild(node);
    });
    Object.keys((currentPage.meta && currentPage.meta.elements) || {}).forEach((name) => {
      const metaElement = document.createElement('meta');
      metaElement.setAttribute('data-dynamic', '1');
      metaElement.setAttribute(currentPage.meta.elements[name].type, name);
      metaElement.setAttribute('content', currentPage.meta.elements[name].value);
      document.head.appendChild(metaElement);
    });
  }
};

class Layout extends React.Component<PropType> {
  render() {
    const jsonData = this.props.currentPage.jsonLd;
    const breadcrumbsJsonData = this.props.currentPage.breadcrumbsJsonLd;
    const mobileMenuButtons = this.props.mobileMenuButtons;

    const pageLinks = {
      magazinePage: this.props.magazinePageUrl,
      termsAndConditions: this.props.termsAndConditionsPageUrl,
      faqPage: this.props.faqPageUrl,
    };

    const { theme } = this.props;

    const isVxnSite =
      !theme.startsWith('dog') && !theme.startsWith('cli') && !theme.startsWith('lac') && !theme.startsWith('party');

    renderMeta(this.props.currentPage);
    return (
      <Viewport>
        {(isCompact: boolean) => (
          <TrayProvider>
            <Base>
              <Content css={{ order: Order.Content }}>
                <CurrentPage />
              </Content>
              {isCompact ? (
                isVxnSite ? (
                  <MobileHeaderAlternateVersion order={Order.Header} />
                ) : (
                  <MobileHeader order={Order.Header} />
                )
              ) : (
                <DesktopHeader order={Order.Header} />
              )}
              <MainFooter order={Order.Footer} />
              <MiniCart toggleMiniCart={this.props.toggleMiniCart} isOpen={this.props.miniCartIsOpen} />
              {this.props.radioPlayIsOpen && <RadioPlay />}
              {isCompact && !!mobileMenuButtons && (
                <Drawer
                  mobileMenu={mobileMenuButtons}
                  pages={pageLinks}
                  {...this.props.mainMenu}
                  toggleDrawer={this.props.toggleMainMenu}
                  navigate={this.props.navigateMainMenu}
                  recentlyViewed={{
                    isShown: this.props.isRecentlyViewedShown,
                    toggle: this.props.toggleRecentlyViewed,
                    fetch: this.props.fetchRecentlyViewed,
                  }}
                />
              )}
              <Loader visible={this.props.showLoadingSpinner} />
              {!this.props.isPageNotFoundPage &&
                this.props.currentPage.loadFailure !== null &&
                this.props.currentPage.loadFailure.status !== 404 && (
                  <NetworkError
                    reload={this.props.currentPage.reload}
                    loadFailure={this.props.currentPage.loadFailure}
                  />
                )}
              <Traybar />
              {!!jsonData && (
                <script
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(jsonData),
                  }}
                  type="application/ld+json"
                />
              )}

              {!!breadcrumbsJsonData && (
                <script
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(breadcrumbsJsonData),
                  }}
                  type="application/ld+json"
                />
              )}
            </Base>
          </TrayProvider>
        )}
      </Viewport>
    );
  }
}

export default connect(
  (state): ConnectStateType => ({
    isPageNotFoundPage: isCurrentPagePageNotFound(state.currentPage),
    currentPage: state.currentPage,
    mainMenu: state.mainMenu,
    showLoadingSpinner: state.spinner.isVisible,
    magazinePageUrl:
      state.appShellData.siteSettings.magazineListingPage && state.appShellData.siteSettings.magazineListingPage.url,
    termsAndConditionsPageUrl:
      state.appShellData.siteSettings.termsAndConditionsPage &&
      state.appShellData.siteSettings.termsAndConditionsPage.url,
    faqPageUrl:
      state.appShellData.siteSettings.customerServiceMainPage &&
      state.appShellData.siteSettings.customerServiceMainPage.url,
    mobileMenuButtons: state.appShellData.mobileMenu,
    miniCartIsOpen: state.miniCart.isOpen,
    isRecentlyViewedShown: state.recentlyViewed.isShown,
    radioPlayIsOpen: state.radioPlay.isOpen,
    theme: state.currentTheme,
  }),
  (dispatch): ConnectDispatchType => ({
    toggleMainMenu: () => dispatch(toggleMainMenu()),
    navigateMainMenu(key: ItemKeyType) {
      dispatch(navigateMainMenu(key));
    },
    toggleMiniCart: (e?: React.MouseEvent<HTMLElement>) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      dispatch(toggleMiniCart());
    },
    toggleRecentlyViewed() {
      dispatch(toggleRecentlyViewed());
    },
    fetchRecentlyViewed(url: URLX) {
      dispatch(fetchRecentlyViewed(url));
    },
  }),
)(Layout);

const Base = styled(applyClassName(Root), {
  backgroundColor: (theme) => theme.backgroundColor,
  backgroundImage: (theme) => theme.backgroundImage,
  backgroundAttachment: 'fixed',
  color: (theme) => theme.textColor,
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

const Content = styled.div({
  display: 'flex',
  flexGrow: 1,
});

const Traybar = styled(Tray, {
  maxWidth: pixelsToUnit(pageMaxWidth),
  margin: {
    xy: 'auto',
  },
});
