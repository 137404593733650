import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { transition } from 'Shared/Style';
import Button, { Variant, Appearance } from 'Shared/Button';

type StateType = {
  height?: number;
  shouldTranslateHeight?: boolean;
};

type PropType = {
  body?: React.ReactNode;
  header?: string | React.ReactNode;
  isOpen?: boolean;
  onToggle?: () => void;
} & StyledProps;

const DURATION = 300;
const Panel = styled.div({
  position: 'absolute',
  right: 0,
  bottom: 0,
  left: 0,
});

const Body = styled.div({
  position: 'relative',
  overflow: 'hidden',
  margin: {
    y: 0,
    x: 'auto',
  },
});

export default styled(
  class Accordion extends React.Component<PropType, StateType> {
    mounted = false;
    constructor(props: PropType) {
      super(props);
      this.state = {
        height: 0,
        shouldTranslateHeight: true,
      };
    }
    UNSAFE_componentWillReceiveProps(nextProps: PropType) {
      if (nextProps.isOpen !== this.props.isOpen) {
        this.setState({ shouldTranslateHeight: true });
      } else {
        this.setState({ shouldTranslateHeight: false });
      }
    }
    shouldComponentUpdate(nextProps: PropType, nextState: StateType) {
      return (
        this.props.isOpen !== nextProps.isOpen ||
        this.props.header !== nextProps.header ||
        this.props.onToggle !== nextProps.onToggle ||
        this.state.height !== nextState.height
      );
    }
    componentDidMount() {
      this.mounted = true;
    }
    componentWillUnmount() {
      this.mounted = false;
    }
    updateOffsetHeight(ref: HTMLElement) {
      if (ref) {
        // rAF to prevent browser freeze
        requestAnimationFrame(() => {
          const height = ref.offsetHeight;
          // Threshold to prevent flickering from loop caused by rounding errors:
          if (this.mounted && Math.abs(this.state.height - height) > 1) {
            this.setState({ height });
          }
        });
      }
    }

    render() {
      const { height, shouldTranslateHeight } = this.state;
      return (
        <styled.Div css={this.props.compose()}>
          <Button appearance={[Appearance.Full, Appearance.Bare]} onClick={this.props.onToggle} variant={Variant.None}>
            {this.props.header}
          </Button>
          <Body
            css={{
              height: this.props.isOpen && height ? `${height}px` : 0,
              ...(shouldTranslateHeight && {
                ...transition({
                  property: 'height',
                  duration: `${DURATION}ms`,
                  willChange: true,
                }),
              }),
            }}
          >
            <Panel ref={(ref: HTMLElement) => this.updateOffsetHeight(ref)}>{this.props.body}</Panel>
          </Body>
        </styled.Div>
      );
    }
  },
);
