import React, { useRef, useState, useEffect } from 'react';
import { styled, StyledProps } from '@glitz/react';
import { useLoadResource } from './utils';
import { ImageBase, Placeholder } from './components';
import noImage from './noimage';
import { ImagePropType } from '.';
import useSubscribeToBrowserCache from './use-subscribe-to-browser-cache';

enum Status {
  Pending,
  Rejected,
  Fulfilled,
}

type PropType = StyledProps &
  React.ImgHTMLAttributes<HTMLImageElement> & {
    originalSrc: string;
  } & Pick<ImagePropType, 'subscribeToCache'>;

export default styled(function EagerImage({ compose, originalSrc, subscribeToCache, ...restProps }: PropType) {
  const currentSrc = restProps.src;
  const previousSrcRef = useRef<string>();
  const currentSrcSet = restProps.srcSet;
  const previousSrcSetRef = useRef<string>();

  const [status, setStatus] = useState(currentSrc ? Status.Pending : Status.Rejected);

  const cachedResource = useSubscribeToBrowserCache(originalSrc, subscribeToCache);

  const loadResource = useLoadResource();

  const mountedRef = useRef<boolean>(true);

  if (__BROWSER__) {
    if (previousSrcRef.current !== currentSrc || previousSrcSetRef.current !== currentSrcSet) {
      if (currentSrc) {
        setStatus(Status.Pending);

        loadResource(originalSrc, currentSrc, currentSrcSet).then(({ isFulfilled, isCurrentLoad }) => {
          if (isCurrentLoad && mountedRef.current) {
            setStatus(isFulfilled ? Status.Fulfilled : Status.Rejected);
          }
        });
      } else {
        setStatus(Status.Rejected);
      }

      previousSrcRef.current = currentSrc;
      previousSrcSetRef.current = currentSrcSet;
    }
  }

  useEffect(() => () => (mountedRef.current = false), []);

  if (currentSrc) {
    if (status === Status.Fulfilled) {
      return <ImageBase {...restProps} src={currentSrc} css={compose()} />;
    }

    if (cachedResource) {
      // Prevent `srcset` to interfere with cached image
      delete restProps.srcSet;
      return <ImageBase {...restProps} src={cachedResource.src} css={compose()} />;
    }

    if (status === Status.Pending) {
      return <ImageBase {...restProps} src={currentSrc} />;
    }
  }

  // Prevent `srcset` to interfere with no image image
  delete restProps.srcSet;
  delete restProps.src;

  return <Placeholder {...restProps} src={noImage} css={compose()} />;
});
