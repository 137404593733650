import {
  get,
  post,
  postJson,
  pushState,
  URLX,
  pathCombine,
  addUserLog,
  onCrossWindow,
  emitCrossWindow,
  PageUpdateSuccessAction,
  PAGE_UPDATE,
  PAGE_UPDATE_SUCCESS,
  PAGE_UPDATE_FAILURE,
  LINK_IDENTIFIER_HEADER,
  REQUEST_ACTION_HEADER,
  ADD_TO_CART_ACTION,
  LoadFailureAction,
} from '@avensia/scope';
import deepmerge from 'deepmerge';
import { checkoutPageUrl } from 'Shared/known-urls';
import { CART_LOAD, rejectInvalidQuantity } from '../Cart/action-creators';
import {
  CART_LOAD_SUCCESS,
  CART_LOAD_FAILURE,
  CART_OPTIMISTIC_UPDATE,
  CART_CHANGES_ACCEPTED,
  isValidQuantity,
  CartAction,
  CartOptimisticUpdateAction,
  CartChangesAcceptedAction,
} from '../Cart/action-creators';
import OrdererType from './Order/OrdererViewModel.type';
import CompletePurchaseResponseType from './Pages/Checkout/CompletePurchaseResponse.type';
import currentPageIsCheckout from './Pages/Checkout/current-page-is-checkout';
import CheckoutPageViewModelType from './Pages/Checkout/CheckoutPageViewModel.type';
import State, { Store, Dispatch, Action, CartDiffType } from 'Shared/State';
import { CheckoutPageStateType, CheckoutPageDiffType } from './Pages/Checkout/reducer';
import * as savedCustomerInfo from './saved-customer-info';
import { postForm } from './Payment/post-form';
import { batchActions } from 'redux-batched-actions';
import { CartEventLocation } from '../TrackingInformation/tracking-types';
import {
  addToCart as gtmAddToCart,
  removeFromCart as gtmRemoveFromCart,
  saveEmailToLocalStorage,
} from '../TrackingInformation';

import DeliveryPoint from './Shipping/DeliveryPointViewModel.type';
import OrderConfirmationType from './Pages/OrderConfirmation/OrderConfirmationPageViewModel.type';
import ShippingDeliveryPointViewModel from './Shipping/Instabox/ShippingDeliveryPointViewModel.type';
import CheckoutPageUpdateViewModel from './Pages/Checkout/CheckoutPageUpdateViewModel.type';

export const COMPLETE_PURCHASE = 'COMPLETE_PURCHASE';
export const COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS = 'COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS';
export const COMPLETE_PURCHASE_FAILURE = 'COMPLETE_PURCHASE_FAILURE';

export const CHECKOUT_OPTIMISTIC_UPDATE = 'CHECKOUT_OPTIMISTIC_UPDATE';
export const CHECKOUT_CHANGES_ACCEPTED = 'CHECKOUT_CHANGES_ACCEPTED';

export const CHECKOUT_UPDATE_NEWSLETTER_REQUEST = 'CHECKOUT_UPDATE_NEWSLETTER_REQUEST';
export const CHECKOUT_UPDATE_NEWSLETTER_SUCCESS = 'CHECKOUT_UPDATE_NEWSLETTER_SUCCESS';
export const CHECKOUT_UPDATE_NEWSLETTER_FAILURE = 'CHECKOUT_UPDATE_NEWSLETTER_FAILURE';

let completePurchaseInProgress = false;

export type CheckoutOptimisticUpdateAction = Action & {
  diff: CheckoutPageDiffType;
};

export type CheckoutChangesAcceptedAction = Action & {
  changes: CheckoutPageDiffType[];
};

export type NewsLetterSubscriptionAction = Action & {
  payload?: boolean;
};

export function reloadCart() {
  return updateCheckoutStateAndSend({} as CheckoutPageDiffType);
}

export function setPaymentMethod(id: string) {
  addUserLog('Setting payment method to ' + id);
  return updateCheckoutStateAndSend({ selectedPaymentMethodId: id } as CheckoutPageDiffType);
}

export function setSubscribeNewsLetter(didSubscribe: boolean) {
  addUserLog('Setting newsletter subscription to ' + didSubscribe);
  return async (dispatch: Dispatch) => {
    const url = pathCombine(checkoutPageUrl(), 'updatenewsletter');
    const payload = {
      subscribeToNewsLetter: didSubscribe,
    };

    dispatch({
      type: CHECKOUT_UPDATE_NEWSLETTER_REQUEST,
    });
    try {
      const { success } = await postJson(url, payload);
      setTimeout(() => {
        // make spinner visible for a few seconds
        dispatch({
          type: success ? CHECKOUT_UPDATE_NEWSLETTER_SUCCESS : CHECKOUT_UPDATE_NEWSLETTER_FAILURE,
          ...(success && {
            payload: didSubscribe,
          }),
        } as NewsLetterSubscriptionAction);
      }, 500);
    } catch (error) {
      dispatch({
        type: CHECKOUT_UPDATE_NEWSLETTER_FAILURE,
      });
      console.error(`setSubscribeNewsLetter error: ${error}`);
    }
  };
}

export function setShippingMethod(id: string) {
  addUserLog('Setting shipping method to ' + id);
  return updateCheckoutStateAndSend({
    selectedShippingMethodId: id,
    selectedDeliveryPoint: { id: null, name: null },
  } as CheckoutPageDiffType);
}

export function setShippingDeliveryPoint(selectedDeliveryPoint: DeliveryPoint) {
  if (selectedDeliveryPoint == null) {
    selectedDeliveryPoint = {
      id: null,
      name: null,
      deliveryDateUtc: null,
      cutOffDateTimeUtc: null,
      zipCode: null,
      city: null,
      country: null,
      contact: null,
      phone: null,
      nameMobile: null,
    };
  }
  return updateCheckoutStateAndSend({ selectedDeliveryPoint } as CheckoutPageDiffType);
}

export function setOrderer(orderer: OrdererType) {
  addUserLog('Updating orderer form');
  return updateCheckoutStateAndSend({ orderer } as CheckoutPageDiffType);
}

export function updateCartItemQuantity(
  code: string,
  quantity: number,
  location: CartEventLocation,
  ticket: string = null,
  maxQuantity: number = 0,
): any {
  if (!isValidQuantity(quantity, maxQuantity)) {
    return rejectInvalidQuantity(code);
  }
  addUserLog('Updating ' + code + ' to ' + quantity + ' in the cart at the checkout');
  return updateCheckoutStateAndSend(
    {
      cart: {
        items: { [code]: { newQuantity: quantity } },
      },
    } as CheckoutPageDiffType,
    location,
    ticket,
  );
}

export function addCartItem(code: string, quantity: number, ticket: string, location: CartEventLocation) {
  addUserLog('Adding ' + quantity + ' of ' + code + ' in the cart at the checkout');
  return updateCartItemQuantity(code, quantity, location, ticket);
}

export function removeCartItem(code: string, location: CartEventLocation) {
  addUserLog('Removing ' + code + ' from the cart at the checkout');
  return updateCheckoutStateAndSend(
    { cart: { items: { [code]: { newQuantity: 0 } } } } as CheckoutPageDiffType,
    location,
  );
}

export function addDiscountCode(code: string) {
  addUserLog('Entered discount code at the checkout');
  return updateCheckoutStateAndSend({ addedDiscountCodes: [code] } as CheckoutPageDiffType);
}

export function removeDiscountCode(code: string) {
  addUserLog('Removing discount code at the checkout');
  return updateCheckoutStateAndSend({ removedDiscountCodes: [code] } as CheckoutPageDiffType);
}

function dispatchCartAndCheckoutUpdate(data: CheckoutPageViewModelType, getState: () => State, emitModified = true) {
  if (emitModified) {
    emitCrossWindow('cart-modified');
  }

  const cartLoadSuccess = () =>
    ({
      type: CART_LOAD_SUCCESS,
      cart: data.cart,
    } as CartAction);

  const checkoutUpdate = () => dispatchCheckoutUpdate(data, getState, emitModified);

  return [cartLoadSuccess(), checkoutUpdate()];
}

export function sendPendingCheckoutRequests() {
  return (dispatch: Dispatch, getState: () => State) => {
    return flushCheckoutRequests(dispatch, getState, null);
  };
}

function dispatchCheckoutUpdate(data: CheckoutPageViewModelType, getState: () => State, emitModified = true) {
  if (emitModified) {
    emitCrossWindow('checkout-modified');
  }

  // We only update the page if the user didn't navigate away from the page
  // during the request
  if (currentPageIsCheckout(getState().currentPage)) {
    return {
      page: data,
      type: PAGE_UPDATE_SUCCESS,
    } as PageUpdateSuccessAction;
  }
}

let currentCheckoutUpdate = Promise.resolve(null);

onCrossWindow<Store>(['checkout-modified', 'cart-modified'], (store) => {
  if (!currentPageIsCheckout(store.getState().currentPage)) {
    return;
  }

  store.dispatch(batchActions([{ type: CART_LOAD } as Action, { type: PAGE_UPDATE } as Action]));

  console.debug('Reloading checkout since it was modified in another window/tab');
  // Note that we don't update the cart here. That is handled in the action creators for the cart.
  const url = new URLX(checkoutPageUrl());
  currentCheckoutUpdate = currentCheckoutUpdate.then(() => {
    get(url)
      .then((r) => r.json())
      .then((json: CheckoutPageViewModelType) => {
        store.dispatch(dispatchCheckoutUpdate(json, store.getState, false));
      })
      .catch((e) => {
        console.error(e);
        store.dispatch({ type: CART_LOAD_FAILURE, error: e, url } as LoadFailureAction);
        store.dispatch({ type: PAGE_UPDATE_FAILURE, error: e, url } as LoadFailureAction);
      });
  });
});

let pendingTickets: string[] = [];

function handleCheckoutDiff(
  dispatch: Dispatch,
  getState: () => State,
  diff: CheckoutPageDiffType,
  location: CartEventLocation = null,
  ticket: string = null,
) {
  if (ticket) {
    pendingTickets.push(ticket);
  }
  diff.id = Math.random();
  const state = getState().currentPage as CheckoutPageStateType;
  diff.selectedPaymentMethodId = state.selectedPaymentMethodId;

  if (diff.cart && diff.cart.items) {
    Object.keys(diff.cart.items).forEach((code) => {
      const state = getState();
      const existingItem = state.cart.items.find((i) => i.code === code);
      if (existingItem) {
        diff.cart.items[code].previousQuantity = existingItem.quantity;
      }
    });
  }

  if (completePurchaseInProgress) {
    return Promise.reject(null);
  }

  const actions: Action[] = [{ type: PAGE_UPDATE } as Action];

  if (diff.cart) {
    const cartDiff: CartDiffType = Object.assign(diff.cart, { id: Math.random() });
    diff.cart = cartDiff;
    actions.push({ type: CART_LOAD } as Action);
    actions.push({
      type: CART_OPTIMISTIC_UPDATE,
      diff: cartDiff,
    } as CartOptimisticUpdateAction);
  }

  actions.push({
    type: CHECKOUT_OPTIMISTIC_UPDATE,
    diff,
  } as CheckoutOptimisticUpdateAction);

  dispatch(batchActions(actions));
}

function updateCheckoutStateAndSend(
  diff: CheckoutPageDiffType,
  location: CartEventLocation = null,
  ticket: string = null,
) {
  return (dispatch: Dispatch, getState: () => State) => {
    handleCheckoutDiff(dispatch, getState, diff, location, ticket);
    return flushCheckoutRequests(dispatch, getState, location);
  };
}

function updateCheckoutState(diff: CheckoutPageDiffType, location: CartEventLocation = null, ticket: string = null) {
  return (dispatch: Dispatch, getState: () => State) => {
    return handleCheckoutDiff(dispatch, getState, diff, location, ticket);
  };
}

function flushCheckoutRequests(dispatch: Dispatch, getState: () => State, location: CartEventLocation = null) {
  const url = new URLX(pathCombine(checkoutPageUrl(), 'update'));

  const sendNextRequest = (failedAttempts = 0): Promise<any> => {
    const state = getState().currentPage as CheckoutPageStateType;
    const currentPendingChanges = state.pendingChanges || [];
    if (!currentPendingChanges.length) {
      return Promise.resolve(state.updateResult);
    }

    let totalDiff = {};
    currentPendingChanges.forEach((r) => (totalDiff = deepmerge(totalDiff, r)));

    let headers: { [name: string]: string };
    if (pendingTickets.length) {
      const tickets = pendingTickets.join(',');
      pendingTickets = [];

      headers = {
        [LINK_IDENTIFIER_HEADER]: tickets,
        [REQUEST_ACTION_HEADER]: ADD_TO_CART_ACTION,
      };
    }

    return post(url, totalDiff, headers)
      .then((r) => r.json())
      .then((json: CheckoutPageViewModelType) => {
        if (json.updateResult.cartUpdateResult) {
          if (json.updateResult.cartUpdateResult.addedItems.length > 0) {
            gtmAddToCart(json.updateResult.cartUpdateResult.addedItems, location);
          }
          if (json.updateResult.cartUpdateResult.removedItems.length > 0) {
            gtmRemoveFromCart(json.updateResult.cartUpdateResult.removedItems, location);
          }
        }

        if (json.orderer.emailHashed) {
          saveEmailToLocalStorage(json.orderer.email, json.orderer.emailHashed);
        }

        dispatch(
          batchActions([
            {
              type: CART_CHANGES_ACCEPTED,
              changes: currentPendingChanges.filter((c) => c.cart != null).map((c) => c.cart),
            } as CartChangesAcceptedAction,
            {
              type: CHECKOUT_CHANGES_ACCEPTED,
              changes: currentPendingChanges,
            },
            ...dispatchCartAndCheckoutUpdate(json, getState),
          ]),
        );

        return json.updateResult;
      })
      .catch((e) => {
        console.error(e);

        dispatch(
          batchActions([
            { type: CART_LOAD_FAILURE, error: e, url } as LoadFailureAction,
            { type: PAGE_UPDATE_FAILURE, error: e, url } as LoadFailureAction,
          ]),
        );

        if (failedAttempts === 3) {
          return Promise.reject(e);
        }

        return sendNextRequest(failedAttempts + 1);
      });
  };

  currentCheckoutUpdate = currentCheckoutUpdate.then(sendNextRequest, sendNextRequest);
  return currentCheckoutUpdate;
}

export function completePurchase(modelCreator: () => CheckoutPageUpdateViewModel) {
  return (dispatch: Dispatch, getState: () => State) => {
    if (completePurchaseInProgress) {
      addUserLog('Clicked complete purchase while purchase completion was in progress');
      return Promise.reject({ errorMessage: 'Complete purchase already in progress' });
    }

    addUserLog('Clicked complete purchase');

    dispatch({
      type: COMPLETE_PURCHASE,
    });

    completePurchaseInProgress = true;
    const saveCustomer = (model: CheckoutPageUpdateViewModel) => {
      savedCustomerInfo.save(model.orderer);
      savedCustomerInfo.savePaymentMethodId(model.selectedPaymentMethodId);
      savedCustomerInfo.saveShippingMethodId(model.selectedShippingMethodId);
    };

    return currentCheckoutUpdate.then(() => {
      const model = modelCreator();
      return post(pathCombine(checkoutPageUrl(), 'completePurchase'), model)
        .then((r) => r.json())
        .then((json: CompletePurchaseResponseType) => {
          if (json.newPageData) {
            if (json.newPageData.validationResult && !json.newPageData.validationResult.success) {
              addUserLog('Got validation error when trying to complete purchase');
            } else if (json.newPageData.paymentErrorMessage) {
              addUserLog('Got payment error message: ' + json.newPageData.paymentErrorMessage);
            }
            const completeCheckoutFailure = () =>
              ({
                type: COMPLETE_PURCHASE_FAILURE,
              } as Action);

            const cartAndCheckout = dispatchCartAndCheckoutUpdate(json.newPageData, getState);
            const batchedArray = [completeCheckoutFailure, ...cartAndCheckout];

            dispatch(batchActions(batchedArray));

            completePurchaseInProgress = false;
            return Promise.reject(null);
          } else if (json.paymentRedirectUrl) {
            // We don't set `completePurchaseInProgress` to false here because
            // we don't know how long time it takes for the payment gateway
            // to load and we don't want to allow you to complete the purchase
            // again during that time.
            saveCustomer(model);
            if (json.paymentPostData) {
              postForm(json.paymentRedirectUrl, json.paymentPostData, json.paymentFormEncoding);
            } else {
              window.location.href = json.paymentRedirectUrl;
              return new Promise(() => null) as Promise<any>;
            }
          } else if (json.orderConfirmationRedirectUrl) {
            saveCustomer(model);
            return pushState(json.orderConfirmationRedirectUrl).then(() => {
              dispatch({
                type: COMPLETE_PURCHASE_WITHOUT_PAYMENT_GATEWAY_SUCCESS,
              } as Action);
              completePurchaseInProgress = false;
            });
          } else {
            console.error('completePurchase returned unknown result', json);
            dispatch({
              type: COMPLETE_PURCHASE_FAILURE,
            } as Action);
            completePurchaseInProgress = false;
            return Promise.reject(null);
          }
        })
        .catch((e) => {
          dispatch({
            type: COMPLETE_PURCHASE_FAILURE,
          } as Action);

          completePurchaseInProgress = false;

          return Promise.reject({ errorMessage: e.message });
        });
    });
  };
}

export function completeUpsellPurchase(orderConfirmation: OrderConfirmationType) {
  return (dispatch: Dispatch, getState: () => State) => {
    dispatch({
      type: PAGE_UPDATE_SUCCESS,
      page: Object.assign({}, getState().currentPage, {
        orderDetails: orderConfirmation.orderDetails,
        upsellProducts: orderConfirmation.upsellProducts,
      }),
    } as PageUpdateSuccessAction);
  };
}

export function getInstaboxDeliveryOptions(
  postalCode: string,
  isMobile?: boolean,
): Promise<ShippingDeliveryPointViewModel[]> {
  addUserLog('Loading instabox shipping options based on postal code.');

  const url = new URLX(pathCombine(checkoutPageUrl(), 'instaboxdeliveryoptions'));
  url.searchParams.set('postalCode', postalCode);
  url.hiddenParams.set('isMobile', isMobile.toString());

  return get(url)
    .then((r) => r.json())
    .then((json: ShippingDeliveryPointViewModel[]) => {
      return Promise.resolve(json);
    })
    .catch((e) => {
      return Promise.reject({ errorMessage: e.message });
    });
}

export function getBestDeliveryOptions(postalCode: string): Promise<ShippingDeliveryPointViewModel[]> {
  addUserLog('Loading best shipping options based on postal code.');

  const url = new URLX(pathCombine(checkoutPageUrl(), 'bestdeliveryoptions'));
  url.searchParams.set('postalCode', postalCode);

  return get(url)
    .then((r) => r.json())
    .then((json: ShippingDeliveryPointViewModel[]) => {
      return Promise.resolve(json);
    })
    .catch((e) => {
      return Promise.reject({ errorMessage: e.message });
    });
}

export function setIngridShippingPrice(price: number) {
  addUserLog('Setting ingrid shipping method price to ' + price);
  return updateCheckoutStateAndSend({ priceFromIngrid: price } as CheckoutPageDiffType);
}

export function setIsIngridUpdate(isIngridUpdate: boolean) {
  addUserLog('Setting boolean for Ingrid update to ' + isIngridUpdate);
  return updateCheckoutState({ isIngridUpdate } as CheckoutPageDiffType);
}

export function setShippingMethodUpdate(id: string) {
  addUserLog('Setting shipping method to ' + id);
  return updateCheckoutState({
    selectedShippingMethodId: id,
    selectedDeliveryPoint: { id: null, name: null },
  } as CheckoutPageDiffType);
}
