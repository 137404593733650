import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { ESC_KEY } from '@avensia/scope';
import freezeScroll from 'Shared/freeze-scroll';
import * as style from '../Style';
import { transition } from 'Shared/Style';

const Cover = styled.div({
  backgroundColor: 'white',
  position: 'fixed',
  zIndex: style.ZIndex.Overlay,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  height: '100%',
  width: '100%',
  ...transition({
    property: 'opacity',
    duration: '400ms',
  }),
});

type PropType = React.HTMLAttributes<HTMLDivElement> &
  StyledProps & {
    onClose: (e?: React.MouseEvent<HTMLElement>) => void;
    enabled?: boolean;
    disableScrollFreeze?: boolean;
  };

type StateType = {
  coverIsPresent: boolean;
};

export let currentOpenOverlay: Overlay = null;

export function closeOpenOverlay() {
  if (currentOpenOverlay) {
    currentOpenOverlay.close();
  }
}

class Overlay extends React.Component<PropType, StateType> {
  static defaultProps: Partial<PropType> = {
    enabled: true,
    disableScrollFreeze: false,
  };
  state = {
    coverIsPresent: false,
  };
  unfreezeScroll: () => void;
  componentWillUnmount() {
    this.disable();
  }
  componentDidMount() {
    if (this.props.enabled) {
      this.enable();
    }
  }
  enable() {
    this.setState({ coverIsPresent: true });

    // The key down event must be attached to window and not on `<Root />` for the callback to be
    // called even if active element is `<body />`
    window.addEventListener('keydown', this.keyDown);

    if (currentOpenOverlay !== this) {
      closeOpenOverlay();
    }
    currentOpenOverlay = this;

    // Don't hide overflow if there's a scroll bar. Note
    // that mobile browsers doesn't have scroll bar, so this
    // only applies to mobile browsers.
    if (window.innerWidth === document.documentElement.clientWidth && this.props.disableScrollFreeze !== true) {
      this.unfreezeScroll = freezeScroll();
    }
  }
  disable() {
    this.setState({ coverIsPresent: false });
    window.removeEventListener('keydown', this.keyDown);

    if (this.unfreezeScroll) {
      this.unfreezeScroll();
    }
    this.unfreezeScroll = null;

    if (currentOpenOverlay === this) {
      currentOpenOverlay = null;
    }
  }
  keyDown(e: KeyboardEvent) {
    if (e.keyCode === ESC_KEY && currentOpenOverlay) {
      currentOpenOverlay.close();
      currentOpenOverlay = null;
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps: PropType) {
    if (this.props.enabled !== nextProps.enabled) {
      if (nextProps.enabled) {
        this.enable();
      } else {
        this.disable();
      }
    }
    if (this.props.disableScrollFreeze !== nextProps.disableScrollFreeze) {
      if (nextProps.disableScrollFreeze) {
        if (this.unfreezeScroll) {
          this.unfreezeScroll();
        }
        this.unfreezeScroll = null;
      } else {
        freezeScroll();
      }
    }
  }
  close = () => {
    if (this.props.enabled) {
      if (this.unfreezeScroll) {
        this.unfreezeScroll();
      }
      this.unfreezeScroll = null;
      this.props.onClose();
    }
  };
  render() {
    const { compose, enabled, onClose, disableScrollFreeze, ...restProps } = this.props;
    return (
      <styled.Div {...restProps} css={compose()}>
        <Cover
          onClick={this.close}
          style={{
            opacity: this.props.enabled ? 0.75 : 0,
            ...(!enabled && {
              // Don't prevent user from click on something behind
              // the overlay while fading away
              pointerEvents: 'none',
            }),
          }}
        />

        <Content
          css={
            this.state.coverIsPresent
              ? {
                  zIndex: style.ZIndex.Overlay,
                }
              : {
                  ...transition({ property: 'z-index', duration: '300ms', timingFunction: 'step-end' }),
                }
          }
        >
          {this.props.children}
        </Content>
      </styled.Div>
    );
  }
}
const Content = styled.div({
  height: 'inherit',
  position: 'relative',
});

export default styled(Overlay, {
  position: 'relative',
});
