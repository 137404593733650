import React from 'react';
import { styled } from '@glitz/react';
import Spinner from 'Shared/Icon/Spinner';
import { ZIndex, medium, primary, white, animation } from 'Shared/Style';
import { show, hide } from './keyframes.scss';

type PropType = {
  visible: boolean;
};

export default class Loader extends React.Component<PropType> {
  shouldComponentUpdate(nextProps: PropType) {
    const shouldUpdate = this.props.visible !== nextProps.visible;
    if (!shouldUpdate) {
      console.info('Ignoring update to Loader');
    }
    return shouldUpdate;
  }
  render() {
    return (
      <Base
        css={animation({
          name: this.props.visible ? show : hide,
          duration: `${ANIMATION_DURATION}ms`,
          fillMode: 'forwards',
        })}
      >
        <Glyph />
      </Base>
    );
  }
}

const ANIMATION_DURATION = 750;
const Base = styled.div({
  position: 'fixed',
  zIndex: ZIndex.Spinner,
  top: '25vh',
  left: '50%',
  padding: {
    xy: medium,
  },
  lineHeight: 1,
  fontSize: '1.5rem',
  color: white,
  transform: 'translate(-50%, -50%)',
  pointerEvents: 'none',
  borderRadius: '50%',
  backgroundColor: primary,
});

const Glyph = styled(Spinner, {
  display: 'block',
});
