import React from 'react';
import { styled } from '@glitz/react';
import ImageNoLinkViewModelType from 'Shared/Blocks/Image/ImageNoLinkViewModel.type';

type PropType = {
  paymentLogos: ImageNoLinkViewModelType[];
};

const Section = styled.section({
  backgroundColor: theme => theme.footerPaymentsBackgroundColor,
  padding: { y: '15px', x: 0 },
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
});

const Logo = styled.img({
  maxHeight: '35px',
  margin: {
    xy: '10px',
  },
});

const PaymentLogos = ({ paymentLogos }: PropType) => (
  <Section>
    {paymentLogos.map(logo => (
      <Logo key={logo.imageUrl.url} src={logo.imageUrl.url} alt={logo.altText} title={logo.altText} />
    ))}
  </Section>
);

export default PaymentLogos;
