import { addUserLog } from '@avensia/scope';
import { Action } from 'Shared/State';

export const TOGGLE_MINICART = 'TOGGLE_MINICART';

export function toggleMiniCart() {
  addUserLog('Toggling mini cart');
  return {
    type: TOGGLE_MINICART,
  } as Action;
}
