import { PageType, Store } from 'Shared/State';
import currentPageIsProduct from 'Product/current-page-is-variation';
import currentPageIsCheckout from 'Checkout/Pages/Checkout/current-page-is-checkout';
import CartItemViewModel from 'Cart/Models/CartItemViewModel.type';
import { TrackingInformationProductWithQuantity } from './tracking-types';
import { getLocalStorageItem, setLocalStorageItem } from '@avensia/scope/local-storage';

let store: Store;

enum events {
  StartedCheckout = 'Started Checkout',
}

interface StartedCheckoutEvent {
  $value: number;
  CheckoutUrl: string;
  ItemNames: string[];
  Items: Item[];
}

interface Item {
  SKU: string;
  ProductName: string;
  Quantity: number;
  ItemPrice: number;
  $value: number;
  Url: string;
  ImageUrl: string;
}

export function startedCheckout(page: PageType) {
  if (!currentPageIsCheckout(page)) return;

  var url = window.location.origin;

  var data: StartedCheckoutEvent = {
    $value: page.totalPrice,
    ItemNames: page.cart.items.map((item: CartItemViewModel) => item.displayName),
    CheckoutUrl: url + '/cart/abandoned?id=' + store.getState().appShellData.currentUser.contactId,
    Items: page.cart.items.map((item: CartItemViewModel) : Item  => ({
      SKU: item.code,
      ProductName: item.displayName,
      Quantity: item.quantity,
      ItemPrice: item.totalPrice / item.quantity,
      $value: item.totalPrice / item.quantity,
      Url: url + item.url,
      ImageUrl: url + item.imageUrl,
    }))
  };

  addOrSave(data);
}

export function addedToCart(addedProducts: TrackingInformationProductWithQuantity[]) {
  const state = store.getState();
  const items = state.appShellData.cart.items;  
  const product = addedProducts[0];

  if (!product) return;

  addToKlaviyo(['track', 'Added to Cart', {
    $value: product.price,
    ProductName: product.name,
    SKU: product.id,
    Price: product.price,
    Categories: [addedProducts[0].category],
    Quantity: 1,
    CheckoutURL: window.location.origin + state.appShellData.siteSettings.checkoutPage.url,
    Items: items.map((item: CartItemViewModel) => ({
      SKU: item.code,
      ProductName: item.displayName,
      Quantity: item.quantity,
      ItemPrice: item.totalPrice / item.quantity,
      $value: item.totalPrice / item.quantity,
      Url: item.url,
    }))
  }]);
}

export function viewedProduct(page: PageType) {
  if (currentPageIsProduct(page) && !!page.trackingProduct) {
    let viewedProduct = {
      ProductName: page.trackingProduct.name,
      ProductID: page.trackingProduct.id,
      Categories: [page.trackingProduct.category],
      ImageUrl: window.location.origin + page.imageUrls[0],
      Url: window.location.href,
      Brand: page.brand,
      Price: page.trackingProduct.price,
      $value: page.trackingProduct.price
    };
  
    addToKlaviyo(["track", "Viewed Product", viewedProduct]);
    addToKlaviyo(["trackViewedItem", viewedProduct ]);    
  }   
}

export function identify(email: string) {
  console.log('identify');
  window.klaviyo.identify({
    'email': email
  });

  const data = getLocalStorageItem<StartedCheckoutEvent>(events.StartedCheckout);
  if (data) {
    var event = ['track', events.StartedCheckout, data ];
    console.log(event);
    setTimeout(() => {
      window.klaviyo.push(event);
    }, 5000);

    setLocalStorageItem(events.StartedCheckout, null);
  }
  else{
    console.log(data);
  }
}

export function addToKlaviyo(data: any) {
  console.log('addToKlaviyo');
  if (window.klaviyo.isIdentified && window.klaviyo.isIdentified()) {
    console.log(data);
    window.klaviyo.push(data);
  } else {
    console.log('event not tracked', data);
  }   
}

export function addOrSave(data: StartedCheckoutEvent) {
  console.log('addOrSave');
  if (window.klaviyo.isIdentified && window.klaviyo.isIdentified()) {
    console.log(data);
    window.klaviyo.push(['track', 'Started Checkout', data ]);
  } else {
    console.log('event not tracked', data);
    setLocalStorageItem(events.StartedCheckout, data);
  }
}

export function initKlaviyo(s: Store) {
  store = s;

  if (!('klaviyo' in window)) {
    window.klaviyo = [];
  }
}
