import React from 'react';
import Price, { Type } from 'Pricing/Price';
import { StyledProps, styled } from '@glitz/react';
export { Type as PriceType } from 'Pricing/Price';

type PropType = {
  price: number;
  currency: string;
  priceType?: Type;
};

const priceStyled = styled({
  fontWeight: 'bold',
  lineHeight: 'inherit',
  textTransform: 'none',
});

export const UnitPrice = priceStyled(function UPrice(props: PropType & StyledProps) {
  return <Price css={props.compose()} current={props.price} currency={props.currency} priceType={props.priceType} />;
});

export const TotalPrice = priceStyled(function TPrice(props: PropType & StyledProps) {
  return <Price css={props.compose()} current={props.price} currency={props.currency} priceType={props.priceType} />;
});

export const Discount = priceStyled(function DPrice(props: PropType & StyledProps) {
  return <Price css={props.compose()} current={props.price} currency={props.currency} priceType={Type.Discount} microData={false} />;
});
