import { styled } from '@glitz/react';
import { transition } from 'Shared/Style';

export const ImageBase = styled.img({
  maxWidth: '100%',
  ...transition({ property: 'opacity', duration: 300, timingFunction: 'ease-in' }),
});

export const Placeholder = styled(ImageBase, { backgroundColor: 'rgba(0, 0, 0, .05)' });

export const NoImage = styled(ImageBase, { backgroundColor: 'rgba(0, 0, 0, .05)' });
