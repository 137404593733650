import React from 'react';
import Collapse, { CollapseProps } from 'react-collapse';

export type PropType = CollapseProps & {
  className?: string;
  transitionFromCenter?: boolean;
};

const SPRING_CONFIG_DEFAULT = {
  stiffness: 400,
  damping: 40,
  precision: 50,
};

export default class HeightTransition extends React.PureComponent<PropType> {
  render() {
    // tslint:disable-next-line:no-unused-variable
    const { transitionFromCenter, isOpened, onRender, className, springConfig, ...restProps } = this.props;
    const SPRING_CONFIG = { ...SPRING_CONFIG_DEFAULT, ...springConfig };
    return (
      <Collapse
        theme={{
          collapse: className,
          content: '',
        }}
        isOpened={!!isOpened}
        springConfig={SPRING_CONFIG}
        {...restProps}
      />
    );
  }
}
