const currencyIntagerFormatters: { [key: string]: Intl.NumberFormat } = {};
const currencyMixedFormatters: { [key: string]: Intl.NumberFormat } = {};

export function formatPrice(price: number, currency: string, locale: string) {
  // This implementation uses `Intl.NumberFormat` which isn't supported for
  // browsers like IE10. If this is a problem, either do your own
  // implementation or polyfill it with the `intl` package.
  const isMixed = Math.floor(price) < price;
  const formatters = isMixed ? currencyMixedFormatters : currencyIntagerFormatters;
  const key = `${currency}.${locale}`;
  const formatter = (formatters[key] =
    formatters[key] ||
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      ...(!isMixed && {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    }));
  return formatter.format(price);
}
