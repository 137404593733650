import { PAGE_LOAD_FAILURE, PageLoadFailureAction } from '@avensia/scope/CurrentPage/action-creators';
import { UPDATE_APP_SHELL_DATA, UpdateAppShellDataAction } from '@avensia/scope/AppShell/action-creators';
import CurrentUserType from './CurrentUserAccountViewModel.type';
import AppShellDataType from 'AppShell/AppShellData.type';
import { Action } from 'Shared/State';

export default function(
  state: CurrentUserType = null,
  action: Action,
  appShellData: AppShellDataType,
): CurrentUserType {
  state = state || appShellData.currentUser;
  switch (action.type) {
    case PAGE_LOAD_FAILURE:
      if ((action as PageLoadFailureAction).loadFailure.status === 401 && state.isLoggedIn) {
        // The user has either been logged out because of inactivity or the account has been
        // locked out
        return Object.assign({}, state, {
          isLoggedIn: false,
        });
      }
      return state;
    case UPDATE_APP_SHELL_DATA:
      return (action as UpdateAppShellDataAction<AppShellDataType>).appShellData.currentUser;
    default:
      return state;
  }
}
