import React from 'react';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
import { pseudo } from '@glitz/core';

type PropType = { items: Scope.LinkItemCollection };

class HeaderLinkButtons extends React.Component<PropType> {
  render() {
    const items = this.props.items?.links;
    return (
      <LinksWrapper>
        {items.map((headerLink: Scope.LinkItem) => (
          <Li key={headerLink.href}>
            <LinkStyled to={headerLink.href}>{headerLink.text}</LinkStyled>
          </Li>
        ))}
      </LinksWrapper>
    );
  }
}

export default HeaderLinkButtons;

const LinksWrapper = styled.ul({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  backgroundColor: (theme) => theme.headerLinkButtonsBackgroundColor,
});

const Li = styled.li({
  textTransform: 'uppercase',
  width: '33.33%',
  textAlign: 'center',
  listStyle: 'none',
  border: { top: { style: 'solid', color: (theme) => theme.headerLinkButtonsBorderColor, width: '1px' } },

  ...pseudo(':last-child', {
    border: { left: { style: 'solid', color: (theme) => theme.headerLinkButtonsBorderColor, width: '1px' } },
  }),
  ...pseudo(':first-child', {
    border: { right: { style: 'solid', color: (theme) => theme.headerLinkButtonsBorderColor, width: '1px' } },
  }),
});

const LinkStyled = styled(Link, {
  color: (theme) => theme.headerLinkButtonsTextColor,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block',
  padding: { x: '0.5rem', y: '0.5rem' },
});
