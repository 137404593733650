import { MyReviewsSearchType } from 'Shared/State';
import { Action } from 'Shared/State';
import { UPDATE_MYREVIEWS_SEARCH_TEXT, SEARCH_MY_REVIEWS_STATUS, SearchActionType } from './action-creators';

export default function(state: MyReviewsSearchType = null, action: Action) {
  if (state === null) {
    state = {
      searchText: '',
      isLoading: false,
    };
  }
  switch (action.type) {
    case UPDATE_MYREVIEWS_SEARCH_TEXT: {
      return {
        ...state,
        searchText: (action as SearchActionType).searchText,
      };
    }

    case SEARCH_MY_REVIEWS_STATUS: {
      return {
        ...state,
        isLoading: (action as SearchActionType).isLoading,
      };
    }

    default:
      return state;
  }
}
