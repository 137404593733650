import { addUserLog } from '@avensia/scope';

export const TOGGLE_RADIOPLAY = 'TOGGLE_RADIOPLAY';

export function toggleRadioPlay(isOpen: boolean, embedRadioPlayUrl: string) {
  addUserLog('Toggle radio play');
  return {
    type: TOGGLE_RADIOPLAY,
    isOpen,
    embedRadioPlayUrl,
  };
}
