import React from 'react';
import { styled } from '@glitz/react';
import Link from 'Shared/Link';
import Ul from 'Shared/Generic/Ul';
import { ViewportPropType, styles } from './Shared';
import LinkGroupViewModelType from 'Shared/Blocks/LinkGroup/LinkGroupViewModel.type';
import { minMediumMediaQuery, letterSpacingTiny, zeta, theta } from 'Shared/Style';

type PropType = {
  customerService: LinkGroupViewModelType;
} & ViewportPropType;

const Header = styled.h5(styles.header);

const A = styled(Link, styles.anchor);

const Section = styled.section({
  marginRight: 0,
  padding: {
    y: 0,
    x: '10px',
  },
  [minMediumMediaQuery]: {
    marginRight: '30px',
    order: 3,
    flex: {
      grow: 0,
      shrink: 1,
      basis: '200px',
    },
  },
});

const Body = styled(Ul, {
  fontSize: zeta,
  letterSpacing: letterSpacingTiny,
  [minMediumMediaQuery]: {
    ...styles.body,
    fontSize: theta,
  },
});

const Li = styled.li({
  display: 'block',
  lineHeight: 3,
  padding: {
    xy: 0,
  },
  [minMediumMediaQuery]: {
    lineHeight: 1.5,
    padding: {
      y: '5px',
      x: 0,
    },
  },
});

const CustomerService = (props: PropType) => (
  <Section>
    {props.customerService?.header && (!props.isCompact && <Header>{props.customerService.header}</Header>)}
    <Body bare column>
      {props.customerService.links.map(link => (
        <Li key={link.title + link.url}>
          <A to={link.url}>{link.title}</A>
        </Li>
      ))}
    </Body>
  </Section>
);

export default CustomerService;
