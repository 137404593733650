import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { translate } from '@avensia/scope';
import { QuickSearchCompletionListType } from 'Shared/State';
import connect from 'Shared/connect';
import {
  large,
  zeta,
  alto,
  greyText,
  truncate,
  pixelsToUnit,
  white,
  transition,
  ZIndex,
  boulder,
  minMediumMediaQuery,
  medium,
  small,
  seashell,
} from 'Shared/Style';
import {
  CATEGORY_LIST_ID,
  SUGGESTIONS_LIST_ID,
  POPULAR_SEARCHES_LIST_ID,
  SEARCH_HISTORY_LIST_ID,
  CUSTOMER_SERVICE_LIST_ID,
} from '../action-creators';
import Link from 'Shared/Link';
import IconClose from 'Shared/Icon/Close';

type ConnectStateType = {
  completionsLists: QuickSearchCompletionListType[];
};

type PropType = ConnectStateType & {
  applySuggestion: (suggestionText: string) => void;
};

class Suggestons extends React.PureComponent<PropType & StyledProps> {
  render() {
    const { completionsLists } = this.props;
    // Translations needs to be mapped at compile-time
    const translations = {
      [SEARCH_HISTORY_LIST_ID]: translate('/QuickSearch/PreviousSearches'),
      [POPULAR_SEARCHES_LIST_ID]: translate('/QuickSearch/PopularSearches'),
      [SUGGESTIONS_LIST_ID]: translate('/QuickSearch/Suggestions'),
      [CATEGORY_LIST_ID]: translate('/Shared/Assortment'),
    };

    const customerService = completionsLists.filter(list => list.id === CUSTOMER_SERVICE_LIST_ID);
    const showCustomerService = customerService?.length > 0 && customerService[0].items.length > 0;

    return (
      <Base css={this.props.compose(showCustomerService && { [minMediumMediaQuery]: { width: '100%' } })}>
        <MainWrapper css={showCustomerService && { [minMediumMediaQuery]: { width: '100%' } }}>
          <CloseButton>
            <IconClose css={{ width: '100%', height: '100%' }} />
          </CloseButton>
          {completionsLists
            .filter(list => list.items.length > 0 && list.id !== CUSTOMER_SERVICE_LIST_ID)
            .map(list => (
              <div key={list.id}>
                <Label>{translations[list.id] || list.id}</Label>
                {list.items.map(i =>
                  i.url ? (
                    <ItemLink
                      key={i.url}
                      to={i.url}
                      linkIdentifier={i.ticket}
                      // tslint:disable-next-line:jsx-no-lambda
                      onClick={() => this.props.applySuggestion('')}
                    >
                      {i.name}
                      {i.parentName && <ParentName>({i.parentName})</ParentName>}
                    </ItemLink>
                  ) : (
                      <Option
                        key={i.text}
                        // tslint:disable-next-line:jsx-no-lambda
                        onClick={() => this.props.applySuggestion(i.text)}
                      >
                        {i.text}
                      </Option>
                    ),
                )}
              </div>
            ))}
          {showCustomerService && (
            <CustomerService>
              <Label>{translate('/QuickSearch/CustomerServiceHeader')}</Label>
              <ItemWrapper>
                {customerService[0].items.map(service => (
                  <ItemLink
                    key={service.key}
                    to={service.url}
                    linkIdentifier={service.ticket}
                    // tslint:disable-next-line:jsx-no-lambda
                    onClick={() => this.props.applySuggestion('')}
                  >
                    {service.title}
                  </ItemLink>
                ))}
              </ItemWrapper>
            </CustomerService>
          )}
        </MainWrapper>
      </Base>
    );
  }
}

export default styled(
  connect(
    (state): ConnectStateType => ({
      completionsLists: state.quickSearch.completionsLists,
    }),
  )(Suggestons),
);

const Base = styled.div({
  display: 'flex',
  backgroundColor: white,
  color: theme => theme.textColor,
  borderRadius: '0.15rem',
  marginTop: '0.5rem',
  paddingBottom: '1rem',
  boxShadow: theme => `0 0.2rem 0.3rem rgba(${theme.shadowColor}, 0.3)`,
  position: 'relative',
  width: '100%',
  visibility: 'hidden',
  zIndex: ZIndex.QuickSearch,
  ...transition({ property: ['visibility', 'opacity'] }),

  flexDirection: 'column',
  [minMediumMediaQuery]: {
    flexDirection: 'row',
  },
});

const Label = styled.h4({
  ...truncate(),
  padding: {
    y: pixelsToUnit(2),
    x: large,
  },
});

const CloseButton = styled.div({
  position: 'absolute',
  height: pixelsToUnit(40),
  width: pixelsToUnit(40),
  right: pixelsToUnit(14),
  top: pixelsToUnit(8),
  padding: { x: pixelsToUnit(5), y: pixelsToUnit(5) },
  [minMediumMediaQuery]: { display: 'none' },
});

const MainWrapper = styled.div({
  width: '100%',
  paddingTop: medium,
});

const CustomerService = styled.div({
  width: '100%',
  [minMediumMediaQuery]: {
    width: '100%',
    minWidth: pixelsToUnit(125),
  },
  backgroundColor: seashell,
  padding: {
    y: small,
  },
  marginTop: medium,
});

const ItemWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const textStyled = styled({
  color: greyText,
  cursor: 'pointer',
  display: 'block',
  fontSize: zeta,
  padding: {
    y: pixelsToUnit(2),
    x: large,
  },
  textDecoration: 'none',
  ...truncate(),
  ':hover': {
    backgroundColor: alto,
  },
});

const Option = textStyled(styled.Div);
const ItemLink = textStyled(Link);
const ParentName = styled.span({
  paddingLeft: pixelsToUnit(8),
  color: boulder,
});
