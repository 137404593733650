import React from 'react';
import { Breakpoint } from '@avensia/scope';
import connect from 'Shared/connect';

function isCompact(currentBreakpoint: number) {
  return currentBreakpoint < Breakpoint.Medium;
}

const connected = connect(state => ({
  currentBreakpoint: state.currentBreakpoint,
}));

type PropType = {
  currentBreakpoint: number;
  children: React.ReactElement<any> | ((isCompact: boolean) => React.ReactElement<any>);
};

export default connected(({ currentBreakpoint, children }: PropType) => {
  if (process.env.NODE_ENV) {
    if (typeof children !== 'function') {
      throw new Error('Children must be a function');
    }
  }
  return typeof children === 'function' ? children(isCompact(currentBreakpoint)) : React.Children.only(children);
});

export const ForCompact = connected((({ currentBreakpoint, children }) => {
  return isCompact(currentBreakpoint) ? React.Children.only(children) : null;
}) as React.FunctionComponent<PropType>);

export const ForExtensive = connected((({ currentBreakpoint, children }) => {
  return !isCompact(currentBreakpoint) ? React.Children.only(children) : null;
}) as React.FunctionComponent<PropType>);
