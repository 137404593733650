import React from 'react';
import { styled } from '@glitz/react';
import ArrowRight from '../../Shared/Icon/glyphs/arrow-forward.svg';
import NavigationItemType from 'Shared/NavigationItem/NavigationItem.type';
import { thin, thick, epsilon, pixelsToUnit, truncate } from 'Shared/Style';
import Link from 'Shared/Link';

type ItemPropType = {
  item: NavigationItemType;
  pushLevel?: (item: NavigationItemType) => void;
};

type LinkPropType = {
  url: string;
};

type PropType = ItemPropType | LinkPropType;

const Label = styled.span({
  color: 'black',
  display: 'inline-block',
  fontSize: epsilon,
  maxWidth: pixelsToUnit(275),
  textTransform: 'uppercase',
  ...truncate(),
});

const Wrapper = styled.div({
  alignItems: 'center',
  display: 'flex',
  height: pixelsToUnit(41),
  justifyContent: 'space-between',
  padding: {
    x: pixelsToUnit(15),
  },
});

function isLink(props: PropType): props is LinkPropType {
  return 'url' in props;
}

export default class Item extends React.Component<PropType> {
  isParent(props: PropType): props is ItemPropType {
    return (
      !isLink(this.props) && this.props.pushLevel && this.props.item.children && this.props.item.children.length > 0
    );
  }
  onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (this.isParent(this.props)) {
      e.preventDefault();
      this.props.pushLevel(this.props.item);
    }
  };
  render() {
    if (isLink(this.props)) {
      return (
        <Link to={this.props.url}>
          <Wrapper>
            <Label
              css={{
                borderBottom: {
                  width: thick,
                  style: 'solid',
                  color: (theme) => theme.primaryColor,
                },
                fontWeight: 'bold',
              }}
            >
              {this.props.children}
            </Label>
          </Wrapper>
        </Link>
      );
    } else {
      const { url, name } = this.props.item;
      return (
        <Link to={url} onClick={this.onClick}>
          <Wrapper
            css={{
              borderBottom: {
                width: thin,
                style: 'solid',
                color: '#d8d8d8',
              },
            }}
          >
            {this.props.children}
            <Label>{name}</Label>
            {this.isParent(this.props) && <ArrowRight />}
          </Wrapper>
        </Link>
      );
    }
  }
}
