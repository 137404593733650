import React from 'react';
import { styled } from '@glitz/react';
import {
  zeta,
  pageMaxWidth,
  theta,
  minTinyMediaQuery,
  minMediumMediaQuery,
  minHugeMediaQuery,
  pixelsToUnit,
  ZIndex,
} from 'Shared/Style';
import ArrowLeft from 'Shared/Icon/ArrowLeft';
import Link from 'Shared/Link';
import { Order } from 'SiteLayout';
import SiteLogo from 'SiteLayout/Header/Logo';

type PropType = {
  order?: Order;
  goHomeText?: string;
  children?: React.ReactNode;
};

export default (props: PropType) => (
  <Base css={{ order: props.order }}>
    <Header>
      <Home to="/">
        <SiteLogo css={{ textAlign: 'center' }} sticky />
      </Home>
      {props.children}
      {props.goHomeText && (
        <Back to="/">
          <BackArrow />
          <Text>{props.goHomeText}</Text>
        </Back>
      )}
    </Header>
  </Base>
);

const BackArrow = styled(ArrowLeft, {
  color: (theme) => theme.menuBarTextColor,
  marginRight: pixelsToUnit(10),
  verticalAlign: 'top',
  width: '9.2857%',
  [minMediumMediaQuery]: {
    width: pixelsToUnit(16),
  },
});

const Base = styled.div({
  position: 'fixed',
  zIndex: ZIndex.Header,
  backgroundColor: (theme) => theme.primaryColor,
  padding: {
    x: pixelsToUnit(10),
  },
  width: '100%',
  [minMediumMediaQuery]: {
    padding: {
      x: pixelsToUnit(30),
    },
  },
});

const Header = styled.header({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  margin: {
    y: 0,
    x: 'auto',
  },
  [minHugeMediaQuery]: {
    maxWidth: pixelsToUnit(pageMaxWidth),
  },
});

const Home = styled(Link, {
  height: '2.5rem',
  position: 'relative',
  width: '40%',
  [minMediumMediaQuery]: {
    width: pixelsToUnit(150),
  },
});

const Text = styled.span({
  color: (theme) => theme.menuBarTextColor,
  textTransform: 'uppercase',
  fontSize: zeta,
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(0.5),
  [minTinyMediaQuery]: {
    fontSize: theta,
  },
});

const Back = styled(Link, {
  display: 'block',
  textAlign: 'right',
  width: pixelsToUnit(180),
  [minMediumMediaQuery]: {
    width: 'auto',
  },
});
