import React, { useState } from 'react';
import { styled } from '@glitz/react';
import { translate, isIOS } from '@avensia/scope';
import Button, { Appearance } from 'Shared/Button';
import Flyout, { Position, Right } from 'Shared/Flyout';
import MiniCartItem from './Item';
import { checkoutPageUrl } from 'Shared/known-urls';
import { pseudo } from '@glitz/core';
import Ul from 'Shared/Generic/Ul';
import IconClose from 'Shared/Icon/Close';
import { slideIn } from 'SiteLayout/Drawer/keyframes.scss';
import { ANIMATION_DURATION } from 'SiteLayout/Drawer/Nav';
import {
  pixelsToUnit,
  chateaugreen,
  sigma,
  zeta,
  white,
  monochromeDark,
  thin,
  black,
  small,
  darkerGray,
  large,
  minMediumMediaQuery,
  epsilon,
  animation,
  wildwatermelon,
} from 'Shared/Style';
import connect from 'Shared/connect';
import { CartType } from 'Shared/State';
import Price from 'Pricing/Price';
import { updateCartItemQuantity, removeCartItem } from 'Cart/action-creators';
import RecentlyViewedCompact from 'Product/ProductRecentlyViewed/Compact';

type RequiredPropType = {
  toggleMiniCart: () => void;
  isOpen: boolean;
};

type ConnectStateType = {
  cart: CartType;
};

type ConnectActionType = {
  updateCartItemQuantity: (code: string, quantity: number) => Promise<void>;
  removeCartItem: (code: string) => Promise<void>;
  toggleMinicart: () => void;
};

type PropType = ConnectStateType &
  ConnectActionType & {
    isOpen: boolean;
  };

export function FlyoutMiniCart(props: RequiredPropType) {
  return (
    <Flyout
      position={Position.Right}
      positionComponent={PositionRight}
      toggle={props.toggleMiniCart}
      open={props.isOpen}
    >
      <MiniCart toggle={props.toggleMiniCart} isOpen={props.isOpen} />
    </Flyout>
  );
}

const MiniCart = connect(
  (state): ConnectStateType => ({
    cart: state.cart,
  }),
  (dispatch, { toggle }: { toggle: () => void }): ConnectActionType => ({
    updateCartItemQuantity(code: string, quantity: number) {
      return dispatch(updateCartItemQuantity(code, quantity, 'Varukorgsmodul'));
    },
    removeCartItem(code: string) {
      return dispatch(removeCartItem(code, 'Varukorgsmodul'));
    },
    toggleMinicart() {
      toggle();
    },
  }),
)(function MiniCart(props: PropType) {
  const { items = [], currency, subTotal = 0, isLoading = false } = props.cart && props.cart;
  const itemDiscounts = items.map((item) => item.totalPrice - item.price * item.quantity);
  const totalDiscount = itemDiscounts.reduce((a, b) => a + b, 0);
  const noDiscount = totalDiscount < 0;
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Base>
      <Header>
        <Title css={{ fontWeight: isExpanded ? 'normal' : 'bold' }} onClick={() => setIsExpanded(false)}>
          {translate('/MiniCart/YourCart')}
        </Title>
        <Tabs>
          <LastViewed onClick={() => setIsExpanded(!isExpanded)}>
            <LastViewedLabel css={{ fontWeight: isExpanded ? 'bold' : 'normal' }}>
              {translate('/Product/RecentlyViewed')}
            </LastViewedLabel>
          </LastViewed>
        </Tabs>
        <Close appearance={Appearance.Bare} onClick={props.toggleMinicart}>
          <IconClose css={{ color: darkerGray }} />
        </Close>
      </Header>
      {isExpanded && <RecentlyViewed navIsVisible={false} css={isIOS() && { WebkitOverflowScrolling: 'touch' }} />}
      {!isExpanded && (
        <Body
          css={animation({
            name: slideIn,
            duration: `${ANIMATION_DURATION}ms`,
            timingFunction: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
            fillMode: 'forwards',
          })}
        >
          {items.length > 0 ? (
            <Ul css={{ padding: { x: small }, width: '100%' }} column>
              {items.map((item) => (
                <MiniCartItem
                key= {`${item.code}|${item.isGift}`}
                  item={item}
                  currency={currency}
                  update={props.updateCartItemQuantity}
                  delete={props.removeCartItem}
                  isLoading={isLoading}
                />
              ))}
            </Ul>
          ) : (
            <Empty>{translate('/MiniCart/EmptyCart')}</Empty>
          )}
        </Body>
      )}

      <Footer>
        <Summary>
          <SummaryRow>
            <LabelCell>
              <div>{translate('/MiniCart/TotalDiscount')}</div>
            </LabelCell>
            <PriceCell>
              <Price
                css={{
                  opacity: isLoading ? 0.5 : 1.0,
                  color: totalDiscount < 0 ? white : monochromeDark,
                  backgroundColor: totalDiscount < 0 ? wildwatermelon : white,
                  borderRadius: pixelsToUnit(5),
                  paddingRight: noDiscount ? pixelsToUnit(5) : pixelsToUnit(0),
                  paddingLeft: totalDiscount < 0 ? pixelsToUnit(5) : pixelsToUnit(0),
                }}
                current={totalDiscount}
                currency={currency}
              />
            </PriceCell>
          </SummaryRow>
          <SummaryRow>
            <LabelCell>{translate('/MiniCart/TotalWithVat')}</LabelCell>
            <PriceCell>
              <Price css={{ opacity: isLoading ? 0.5 : 1.0 }} current={subTotal} currency={currency} />
            </PriceCell>
          </SummaryRow>
        </Summary>
        <ToCheckout
          to={checkoutPageUrl()}
          appearance={[Appearance.Bare, Appearance.Full]}
          disabled={!(items.length > 0)}
        >
          {translate('/MiniCart/ToCheckout')}
        </ToCheckout>
      </Footer>
    </Base>
  );
});

const PositionRight = styled(Right, {
  minWidth: pixelsToUnit(270),
  [minMediumMediaQuery]: {
    width: pixelsToUnit(400),
  },
});

const Base = styled.div({
  border: {
    xy: {
      color: monochromeDark,
      width: thin,
      style: 'solid',
    },
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
});

const Header = styled.div({
  alignItems: 'center',
  borderBottom: {
    color: monochromeDark,
    style: 'solid',
    width: thin,
  },
  display: 'flex',
  flex: {
    grow: 0,
    shrink: 0,
    basis: pixelsToUnit(50),
  },
  justifyContent: 'space-between',
  paddingLeft: small,
});

const linkStyled = styled({
  alignItems: 'center',
  color: black,
  display: 'flex',
  flexBasis: '100%',
  justifyContent: 'center',
  textAlign: 'center',
});

const scrollStyled = styled({
  overflowX: 'hidden',
  overflowY: 'auto',
  overscrollBehaviorY: 'none',
});

const LastViewedLabel = linkStyled(styled.Span, {
  cursor: 'pointer',
  textTransform: 'uppercase',
  fontSize: zeta,
  [minMediumMediaQuery]: {
    fontSize: epsilon,
    lineHeight: pixelsToUnit(20),
  },
});

const RecentlyViewed = scrollStyled(RecentlyViewedCompact, {
  backgroundColor: white,
  width: '100%',
  height: '100%',
  zIndex: 20,
});

const Tabs = styled.ul({
  border: {
    x: {
      style: 'solid',
      width: thin,
      color: monochromeDark,
    },
  },
  display: 'flex',
  height: pixelsToUnit(50),
  listStyle: 'none',
});

const tabStyled = styled({
  color: black,
  display: 'flex',
  fontSize: zeta,
  flexGrow: 1,
  ...pseudo(':not(:last-child)', {
    border: {
      right: {
        style: 'solid',
        width: thin,
        color: monochromeDark,
      },
    },
  }),
});

const LastViewed = tabStyled(styled.Li, {
  padding: { x: pixelsToUnit(15) },
});

const Title = styled.div({
  padding: { y: pixelsToUnit(10) },
  color: black,
  fontSize: zeta,
  fontWeight: 'bold',
  cursor: 'pointer',
  lineHeight: pixelsToUnit(18),
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    fontSize: epsilon,
    lineHeight: pixelsToUnit(20),
  },
});

const Close = styled(Button, {
  width: pixelsToUnit(45),
  textAlign: 'center',
});

const Body = styled.div({
  display: 'flex',
  flex: {
    grow: 1,
    shrink: 1,
    basis: 0,
  },
  overflow: 'auto',
});

const Empty = styled.p({
  paddingTop: large,
  textAlign: 'center',
  width: '100%',
});

const Footer = styled.div({
  borderTop: {
    color: monochromeDark,
    style: 'solid',
    width: thin,
  },
  display: 'flex',
  flexDirection: 'column',
  flex: {
    grow: 0,
    shrink: 1,
    basis: 'auto',
  },
  padding: {
    xy: pixelsToUnit(10),
  },
  textAlign: 'center',
  [minMediumMediaQuery]: {
    padding: {
      xy: small,
    },
  },
});

const Summary = styled.div({
  marginBottom: pixelsToUnit(8),
});

const SummaryRow = styled.div({
  display: 'flex',
  marginTop: pixelsToUnit(5),
});

const LabelCell = styled.div({
  flexGrow: 1,
  fontSize: zeta,
  letterSpacing: pixelsToUnit(0.14),
  lineHeight: pixelsToUnit(21),
  textAlign: 'left',
});

const PriceCell = styled.div({
  flexGrow: 1,
  fontSize: pixelsToUnit(19),
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(0.22),
  lineHeight: pixelsToUnit(22),
  textAlign: 'right',
});

const ToCheckout = styled(Button, {
  backgroundColor: chateaugreen,
  color: (theme) => theme.minicartButtonTextColor,
  fontSize: sigma,
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(0.41),
  textTransform: 'uppercase',
});
