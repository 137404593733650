/**
 * @ComponentFor MobileMenuTextBlock
 */
import React from 'react';
import { styled } from '@glitz/react';
import MobileMenuTextBlockViewModel from './MobileMenuTextBlockViewModel.type';
import Link from 'Shared/Link';
import { black, pixelsToUnit } from 'Shared/Style';

type PropType = {
  textBlocks: MobileMenuTextBlockViewModel[];
};

const MobileTextBlock = (props: PropType) => {
  return (
    <React.Fragment>
      {props.textBlocks.map((block, idx) => (
        <Wrapper key={idx}>
          <Heading>{block.heading}</Heading>
          <Description>{block.description}</Description>
          <PageLink to={block.linkUrl.url}>{block.linkText}</PageLink>
        </Wrapper>
      ))}
    </React.Fragment>
  );
};

export default MobileTextBlock;

const Wrapper = styled.div({
  padding: {
    xy: pixelsToUnit(15),
  },
});

const Heading = styled.h5({
  marginBottom: pixelsToUnit(6),
  textTransform: 'uppercase',
});

const Description = styled.p({
  marginBottom: pixelsToUnit(10),
});

const PageLink = styled(Link, {
  color: black,
  textDecoration: 'underline',
});
