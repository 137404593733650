import React, { ReactNode } from 'react';
import { ThemeProvider } from '@glitz/react';
import { Theme as ColorTheme } from '@glitz/type';
import connect from 'Shared/connect';
import {
  white,
  monza,
  mineshaft,
  codgray,
  emperor,
  chateaugreen,
  fruitSalad,
  bluePurple,
  ebony,
  bombay,
  steelgray,
  byzantine,
  muave,
  revolver,
  tundora,
  burgundy,
  caribbeanGreen,
  downy,
  jade,
  wisteria,
  black,
  alto,
  navyBlue,
  concrete,
  bondiBlue,
  bahamaBlue,
  silver,
  dustygray,
  minsk,
  persimmon,
  vividtangerine,
  boulder,
  bossanova,
  wafer,
  potPourri,
  flushMahogny,
  vistaWhite,
  mercury,
  soapStone,
  jungleGreen,
  remy,
  classicRose,
  cranberry,
  harp,
  surfCrest,
  babyLeaf,
  mercurySolid,
  shilo,
  lightSilver,
  seaMist,
  amber,
  wildwatermelon,
  mountainmeadow,
} from 'Shared/Style';

export enum Theme {
  Default = 'default',
  Pink = 'pink',
  Green = 'green',
  Gray = 'gray',
  Premium = 'premium',
  DoggieDefault = 'doggiedefault',
  BlackFriday = 'blackfriday',
  B2B = 'b2b',
  WarmGray = 'warmgray',
  WarmPink = 'warmpink',
  Blue = 'blue',
  Mint = 'mint',
  Beige = 'beige',
  WhiteAndBlack = 'whiteandblack',
  DarkGray = 'darkgray',
  Yellow = 'yellow',
  ClitherapyDefault = 'clitherapydefault',
  LacyDefault = 'lacydefault',
  PartyNinjaDefault = 'partyninjadefault',
}

export const defaultTheme: ColorTheme = {
  type: Theme.Default,
  primaryColor: white,
  secondaryColor: burgundy,
  textColor: '#2e2e2e',
  backgroundColor: vistaWhite,
  backgroundImage: 'none',
  borderColor: wafer,
  logoColor: '#eb008b',
  stickyLogoColor: black,
  customerServiceIconColor: '#eb008b',
  headerBackgroundColor: white,
  headerBackgroundColorMobile: white,
  headerLinkButtonsBackgroundColor: '#eb008b',
  headerLinkButtonsTextColor: white,
  headerLinkButtonsBorderColor: 'transparent',
  headerTextColor: '#2e2e2e',
  headerSearchButtonIconColor: white,
  headerSearchButtonIconColorMobile: white,
  filterLink: byzantine,
  footerBackgroundColor: 'transparent',
  footerBackgroundImage: `linear-gradient(to left,  #ffffff, #d6d4d5)`,
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: '#60426F',
  footerBelowBackgroundImage: 'none',
  footerTextColor: black,
  footerBelowTextColor: white,
  menuBarBackgroundColor: white,
  menuBarBorderColor: 'transparent',
  menuBarTextColor: black,
  menuBarStickyBackgroundColor: white,
  miniCartBackgroundColor: 'transparent',
  minicartButtonTextColor: white,
  minicartButtonIcon: '#eb008b',
  minicartButtonIconMobile: white,
  miniCartButtonActive: 'transparent',
  buttonBackgroundImage: 'none',
  buttonHoverBackgroundImage: 'none',
  buttonBackgroundColor: '#60426F',
  buttonHoverBackgroundColor: '#eb008b',
  buttonTextColor: white,
  buttonSecondaryBackgroundImage: 'none',
  buttonSecondaryHoverBackgroundImage: 'none',
  buttonSecondaryBackgroundColor: '#60426F',
  buttonSecondaryHoverBackgroundColor: '#735881',
  buttonSecondaryBorderColor: 'transparent',
  buttonSecondaryTextColor: white,
  buttonTertiaryBackgroundColor: '#60426F',
  buttonTertiaryHoverBackgroundColor: '#735881',
  buttonTertiaryBackgroundImage: 'none',
  buttonTertiaryHoverBackgroundImage: 'none',
  buttonTertiaryTextColor: white,
  buttonDisabledBackgroundColor: 'transparent',
  buttonDisabledBackgroundImage: `linear-gradient(to right, #757575, ${tundora})`,
  buttonDisabledTextColor: alto,
  buttonAlertBackgroundColor: 'transparent',
  buttonAlertBackgroundImage: `linear-gradient(to right, ${muave}, ${revolver})`,
  buttonSearchHeaderBackgroundColor: '#eb008b',
  buttonSearchHeaderTextColor: white,
  buyButtonProductcardMobile: jade,
  buyButtonProductcardDesktop: mountainmeadow,
  buyButtonProductPageMobile: white,
  buyButtonProductPageMobileText: '#eb008b',
  checkboxBackgroundColor: wisteria,
  inputBorderColor: white,
  inputFocusBorderColor: black,
  inputInvalidBorderColor: vividtangerine,
  inputTextColor: '#2e2e2e',
  inputPlaceholderTextColor: black,
  inputPlaceholderTextColorMobile: white,
  inputSearchHeaderBackgroundColor: white,
  inputSearchHeaderBackgroundColorMobile: '#eb008b',
  inputSearchHeaderButtonBorderColorMobile: white,
  inputSearchHeaderBorderColor: white,
  inputSearchTextColor: black,
  inputSearchTextColorMobile: white,
  inputEmailFooterBackgroundColor: white,
  inputEmailFooterBorderColor: 'transparent',
  inputEmailFooterFocusBorderColor: 'transparent',
  inputEmailFooterInvalidBorderColor: 'transparent',
  inputEmailFooterTextColor: black,
  inputEmailFooterPlaceholderTextColor: black,
  sizeGuideHeaderBackgroundImage: `linear-gradient(to right, ${muave}, ${revolver})`,
  sizeGuideHeaderBackgroundColor: 'transparent',
  campaignBackgroundImage: 'none',
  campaignBackgroundColor: '#ffffff',
  campaignBannerTextColor: '#000',
  campaignTextProductcard: black,
  discountBadgeColor: '#eb008b',
  badgeColor: chateaugreen,
  inactiveBadgeColor: black,
  cardBackgroundColor: '#ffffff',
  shadowColor: '83, 42, 17',
  pictogramBackgroundColor: white,
  megaMenuCategoryNameBackgroundColor: white,
  megaMenuBackgroundColor: white,
  bulletpoints: black,
  productMobileActionbar: '#eb008b',
};

const pinktheme: ColorTheme = {
  ...defaultTheme,
  type: Theme.Pink,
  primaryColor: cranberry,
  secondaryColor: burgundy,
  backgroundColor: remy,
  borderColor: shilo,
  logoColor: cranberry,
  stickyLogoColor: white,
  customerServiceIconColor: cranberry,
  headerBackgroundColor: 'transparent',
  footerBackgroundImage: `linear-gradient(to left, ${remy}, ${classicRose})`,
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: classicRose,
  footerBelowBackgroundImage: `linear-gradient(to left, ${remy}, ${classicRose})`,
  menuBarBackgroundColor: '#ecaabe',
  menuBarTextColor: white,
  menuBarBorderColor: 'transparent',
  menuBarStickyBackgroundColor: '#ecaabe',
  minicartButtonIcon: cranberry,
  buttonBackgroundColor: cranberry,
  buyButtonProductcardDesktop: cranberry,
  buyButtonProductcardMobile: cranberry,
  buttonSearchHeaderBackgroundColor: cranberry,
  inputPlaceholderTextColor: cranberry,
  inputSearchHeaderBackgroundColor: '#fff9fb',
  inputEmailFooterBackgroundColor: '#fff9fb',
  inputSearchHeaderBorderColor: 'transparent',
  cardBackgroundColor: '#fff9fb',
  shadowColor: '95, 12, 36',
  pictogramBackgroundColor: classicRose,
  megaMenuCategoryNameBackgroundColor: classicRose,
  megaMenuBackgroundColor: soapStone,
};

const greentheme: ColorTheme = {
  ...defaultTheme,
  type: Theme.Green,
  primaryColor: fruitSalad,
  secondaryColor: burgundy,
  backgroundColor: harp,
  borderColor: seaMist,
  logoColor: bluePurple,
  stickyLogoColor: white,
  customerServiceIconColor: fruitSalad,
  headerBackgroundColor: harp,
  footerBackgroundImage: `linear-gradient(to left, ${harp}, ${surfCrest})`,
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: surfCrest,
  footerBelowBackgroundImage: `linear-gradient(to left, ${harp}, ${surfCrest})`,
  menuBarBackgroundColor: fruitSalad,
  menuBarBorderColor: 'transparent',
  menuBarStickyBackgroundColor: fruitSalad,
  minicartButtonIcon: fruitSalad,
  buttonBackgroundColor: babyLeaf,
  buyButtonProductcardDesktop: babyLeaf,
  buyButtonProductcardMobile: babyLeaf,
  buttonSearchHeaderBackgroundColor: fruitSalad,
  inputPlaceholderTextColor: fruitSalad,
  inputSearchHeaderBackgroundColor: surfCrest,
  inputSearchHeaderBorderColor: 'transparent',
  inputEmailFooterBackgroundColor: '#f8fcf9',
  badgeColor: jade,
  cardBackgroundColor: '#f8fcf9',
  shadowColor: '40, 97, 59',
  pictogramBackgroundColor: surfCrest,
  megaMenuCategoryNameBackgroundColor: surfCrest,
  megaMenuBackgroundColor: soapStone,
};

const graytheme: ColorTheme = {
  ...defaultTheme,
  type: Theme.Gray,
  primaryColor: '#cccccc',
  secondaryColor: burgundy,
  backgroundColor: concrete,
  borderColor: lightSilver,
  logoColor: bluePurple,
  stickyLogoColor: bluePurple,
  customerServiceIconColor: '#000',
  headerBackgroundColor: 'transparent',
  footerBackgroundImage: `linear-gradient(to left, #cccccc, ${mercurySolid})`,
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: mercurySolid,
  footerBelowBackgroundImage: `linear-gradient(to left, ${silver}, ${mercurySolid})`,
  inputEmailFooterBackgroundColor: '#fbfbfb',
  menuBarBackgroundColor: '#dcdcdc',
  menuBarBorderColor: 'transparent',
  menuBarTextColor: '#000',
  menuBarStickyBackgroundColor: '#cccccc',
  buttonBackgroundColor: '#cccccc',
  buyButtonProductcardDesktop: '#cccccc',
  buyButtonProductcardMobile: '#cccccc',
  buttonTextColor: '#000',
  buttonSearchHeaderBackgroundColor: '#dcdcdc',
  inputPlaceholderTextColor: boulder,
  inputSearchHeaderBackgroundColor: '#fbfbfb',
  inputSearchHeaderBorderColor: 'transparent',
  cardBackgroundColor: '#fbfbfb',
  shadowColor: '44, 44, 44',
  pictogramBackgroundColor: 'transparent',
  megaMenuCategoryNameBackgroundColor: mercurySolid,
  megaMenuBackgroundColor: '#fbfbfb',
};

const premiumtheme: ColorTheme = {
  ...graytheme,
  type: Theme.Premium,
  logoColor: flushMahogny,
  buttonBackgroundColor: '#000',
  buyButtonProductcardMobile: '#000',
  buttonTextColor: amber,
  menuBarBackgroundColor: '#000',
  menuBarBorderColor: 'transparent',
  menuBarStickyBackgroundColor: '#000',
  stickyLogoColor: white,
  inputSearchHeaderBorderColor: 'transparent',
  minicartButtonIcon: white,
};

const themeDoggieDefault: ColorTheme = {
  ...defaultTheme,
  type: Theme.DoggieDefault,
  primaryColor: bossanova,
  secondaryColor: emperor,
  backgroundColor: white,
  borderColor: lightSilver,
  textColor: bossanova,
  logoColor: bossanova,
  stickyLogoColor: white,
  headerBackgroundColor: white,
  headerLinkButtonsBackgroundColor: bossanova,
  headerLinkButtonsBorderColor: white,
  headerSearchButtonIconColorMobile: bossanova,
  headerTextColor: mineshaft,
  filterLink: codgray,
  footerBackgroundColor: bossanova,
  footerBackgroundImage: 'none',
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: bossanova,
  footerBelowBackgroundImage: 'none',
  footerTextColor: white,
  footerBelowTextColor: white,
  menuBarBackgroundColor: bossanova,
  menuBarBorderColor: 'transparent',
  menuBarTextColor: white,
  menuBarStickyBackgroundColor: bossanova,
  miniCartBackgroundColor: 'transparent',
  minicartButtonIcon: jungleGreen,
  minicartButtonIconMobile: jungleGreen,
  buttonBackgroundImage: 'none',
  buttonHoverBackgroundImage: 'none',
  buttonBackgroundColor: jungleGreen,
  buyButtonProductcardDesktop: jungleGreen,
  buyButtonProductcardMobile: jungleGreen,
  buttonHoverBackgroundColor: downy,
  buttonSecondaryBackgroundImage: 'none',
  buttonSecondaryHoverBackgroundImage: 'none',
  buttonSecondaryBackgroundColor: '#6cd0be',
  buttonSecondaryHoverBackgroundColor: jungleGreen,
  buttonSecondaryBorderColor: 'transparent',
  buttonSecondaryTextColor: white,
  buttonTertiaryBackgroundColor: jungleGreen,
  buttonTertiaryHoverBackgroundColor: downy,
  buttonTertiaryBackgroundImage: `none`,
  buttonTertiaryHoverBackgroundImage: 'none',
  buttonTertiaryTextColor: white,
  buttonDisabledBackgroundColor: 'transparent',
  buttonDisabledBackgroundImage: `linear-gradient(to right, #757575, ${tundora})`,
  buttonDisabledTextColor: alto,
  buttonAlertBackgroundColor: 'transparent',
  buttonAlertBackgroundImage: `linear-gradient(to right, ${caribbeanGreen}, ${jade})`,
  buttonSearchHeaderBackgroundColor: '#2da58e',
  buttonSearchHeaderTextColor: white,
  checkboxBackgroundColor: jade,
  inputBorderColor: mercury,
  inputFocusBorderColor: alto,
  inputInvalidBorderColor: vividtangerine,
  inputTextColor: '#2e2e2e',
  inputPlaceholderTextColor: boulder,
  inputSearchTextColorMobile: bossanova,
  inputSearchHeaderBackgroundColor: mercury,
  inputSearchHeaderBackgroundColorMobile: mercury,
  inputSearchHeaderBorderColor: 'transparent',
  inputEmailFooterBackgroundColor: emperor,
  inputEmailFooterBorderColor: 'transparent',
  inputEmailFooterFocusBorderColor: 'transparent',
  inputEmailFooterInvalidBorderColor: 'transparent',
  inputEmailFooterTextColor: white,
  inputEmailFooterPlaceholderTextColor: white,
  sizeGuideHeaderBackgroundImage: `linear-gradient(to right, #2da58e, #2da58e)`,
  sizeGuideHeaderBackgroundColor: 'transparent',
  discountBadgeColor: bossanova,
  badgeColor: downy,
  cardBackgroundColor: '#f9f9f9',
  shadowColor: '44, 44, 44',
  pictogramBackgroundColor: mercury,
  megaMenuCategoryNameBackgroundColor: mercury,
  megaMenuBackgroundColor: white,
  customerServiceIconColor: white,
  bulletpoints: mountainmeadow,
  productMobileActionbar: bossanova,
  buyButtonProductPageMobileText: bossanova,
};

const themeBlackFriday: ColorTheme = {
  ...defaultTheme,
  type: Theme.BlackFriday,
  primaryColor: codgray,
  logoColor: codgray,
  stickyLogoColor: white,
  headerSearchButtonIconColor: codgray,
  headerBackgroundColor: ebony,
  headerTextColor: bombay,
  footerBackgroundColor: codgray,
  footerBelowBackgroundColor: black,
  footerBackgroundImage: 'none',
  menuBarBackgroundColor: monza,
  menuBarBorderColor: 'transparent',
  menuBarStickyBackgroundColor: codgray,
  inputSearchHeaderBackgroundColor: steelgray,
  inputSearchHeaderBorderColor: 'transparent',
  inputEmailFooterBackgroundColor: steelgray,
  minicartButtonIcon: white,
};

const themeB2B: ColorTheme = {
  ...defaultTheme,
  type: Theme.B2B,
  primaryColor: navyBlue,
  secondaryColor: burgundy,
  textColor: '#2e2e2e',
  logoColor: navyBlue,
  stickyLogoColor: white,
  headerBackgroundColor: white,
  headerTextColor: mineshaft,
  filterLink: concrete,
  footerBackgroundColor: concrete,
  footerBackgroundImage: 'none',
  footerPaymentsBackgroundColor: concrete,
  footerBelowBackgroundColor: navyBlue,
  footerBelowBackgroundImage: 'none',
  footerTextColor: black,
  footerBelowTextColor: white,
  menuBarBackgroundColor: navyBlue,
  menuBarBorderColor: 'transparent',
  menuBarStickyBackgroundColor: navyBlue,
  miniCartBackgroundColor: minsk,
  minicartButtonIcon: white,
  buttonBackgroundImage: 'none',
  buttonHoverBackgroundImage: 'none',
  buttonBackgroundColor: bondiBlue,
  buyButtonProductcardDesktop: bondiBlue,
  buyButtonProductcardMobile: bondiBlue,
  buttonHoverBackgroundColor: bahamaBlue,
  buttonSecondaryBackgroundImage: 'none',
  buttonSecondaryHoverBackgroundImage: 'none',
  buttonSecondaryBackgroundColor: white,
  buttonSecondaryHoverBackgroundColor: concrete,
  buttonSecondaryBorderColor: silver,
  buttonSecondaryTextColor: black,
  buttonTertiaryBackgroundColor: bondiBlue,
  buttonTertiaryHoverBackgroundColor: bahamaBlue,
  buttonTertiaryBackgroundImage: 'none',
  buttonTertiaryHoverBackgroundImage: 'none',
  buttonTertiaryTextColor: white,
  buttonDisabledBackgroundColor: silver,
  buttonDisabledBackgroundImage: 'none',
  buttonDisabledTextColor: dustygray,
  buttonAlertBackgroundColor: persimmon,
  buttonAlertBackgroundImage: 'none',
  buttonSearchHeaderBackgroundColor: 'transparent',
  buttonSearchHeaderTextColor: boulder,
  checkboxBackgroundColor: wisteria,
  inputBorderColor: silver,
  inputFocusBorderColor: black,
  inputInvalidBorderColor: persimmon,
  inputTextColor: black,
  inputPlaceholderTextColor: dustygray,
  inputSearchHeaderBackgroundColor: white,
  inputSearchHeaderBorderColor: 'transparent',
  inputEmailFooterBackgroundColor: white,
  inputEmailFooterBorderColor: silver,
  inputEmailFooterFocusBorderColor: black,
  inputEmailFooterInvalidBorderColor: persimmon,
  inputEmailFooterTextColor: black,
  inputEmailFooterPlaceholderTextColor: dustygray,
  sizeGuideHeaderBackgroundImage: 'none',
  sizeGuideHeaderBackgroundColor: navyBlue,
  badgeColor: bondiBlue,
  cardBackgroundColor: white,
  pictogramBackgroundColor: potPourri,
  megaMenuCategoryNameBackgroundColor: mercury,
  megaMenuBackgroundColor: white,
};

const warmPinkTheme: ColorTheme = {
  ...defaultTheme,
  type: Theme.WarmPink,
  primaryColor: '#f4c9c8',
  secondaryColor: '#f0a8a7',
  textColor: '#503a3a',
  backgroundColor: '#f8dddc',
  borderColor: '#f0bebd',
  logoColor: bluePurple,
  stickyLogoColor: bluePurple,
  headerBackgroundColor: 'transparent',
  footerBackgroundImage: `linear-gradient(to left, #f8dddc, #edb1b0)`,
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: '#edb1b0',
  footerBelowBackgroundImage: `linear-gradient(to left, #f8dddc, #edb1b0)`,
  inputEmailFooterBackgroundColor: '#f9eceb',
  menuBarBackgroundColor: '#f4c9c8',
  menuBarTextColor: '#000',
  menuBarStickyBackgroundColor: '#f4c9c8',
  menuBarBorderColor: 'transparent',
  buttonBackgroundColor: '#f4c9c8',
  buyButtonProductcardDesktop: '#f4c9c8',
  buyButtonProductcardMobile: '#f4c9c8',
  buttonHoverBackgroundColor: '#32ca79',
  buttonTextColor: '#000',
  buttonSecondaryBackgroundColor: '#d06c6a',
  buttonSecondaryHoverBackgroundColor: '#db4743',
  buttonSearchHeaderBackgroundColor: '#f4c9c8',
  inputSearchHeaderBorderColor: 'transparent',
  inputPlaceholderTextColor: '#000',
  inputSearchHeaderBackgroundColor: '#fff',
  badgeColor: '#e26562',
  inactiveBadgeColor: '#ed918f',
  cardBackgroundColor: '#fefbfb',
  shadowColor: '136, 23, 21',
  pictogramBackgroundColor: 'transparent',
  megaMenuCategoryNameBackgroundColor: '#edb1b0',
  megaMenuBackgroundColor: '#fafafa',
};

const beigeTheme: ColorTheme = {
  ...defaultTheme,
  type: Theme.Beige,
  primaryColor: '#f1cfb8',
  secondaryColor: '#f0c5a8',
  textColor: '#4f4239',
  backgroundColor: '#f6e1d3',
  borderColor: '#e6c2aa',
  logoColor: flushMahogny,
  stickyLogoColor: flushMahogny,
  headerBackgroundColor: '#f4d9c6',
  customerServiceIconColor: '#e26562',
  footerBackgroundImage: `linear-gradient(to left, #f6e1d3, #edc8af)`,
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: '#edc8af',
  footerBelowBackgroundImage: `linear-gradient(to left, #f6e1d3, #edc8af)`,
  menuBarBackgroundColor: '#f1cfb8',
  menuBarBorderColor: 'transparent',
  menuBarTextColor: '#000',
  menuBarStickyBackgroundColor: '#f1cfb8',
  minicartButtonIcon: '#e26562',
  buttonBackgroundColor: '#f1cfb8',
  buyButtonProductcardDesktop: '#f1cfb8',
  buyButtonProductcardMobile: '#f1cfb8',
  buttonHoverBackgroundColor: '#32ca79',
  buttonTextColor: '#000',
  buttonSecondaryBackgroundColor: '#d1946b',
  buttonSecondaryHoverBackgroundColor: '#db8144',
  buttonSearchHeaderBackgroundColor: '#e26562',
  inputPlaceholderTextColor: '#f1cfb8',
  inputSearchHeaderBackgroundColor: '#fff',
  inputSearchHeaderBorderColor: 'transparent',
  inputEmailFooterBackgroundColor: '#fff',
  badgeColor: '#e26562',
  inactiveBadgeColor: '#d1946b',
  cardBackgroundColor: '#fdfbf9',
  shadowColor: '129, 73, 35',
  pictogramBackgroundColor: '#f4d9c6',
  megaMenuCategoryNameBackgroundColor: '#edc8af',
  megaMenuBackgroundColor: '#fafafa',
};

const warmGrayTheme: ColorTheme = {
  ...defaultTheme,
  type: Theme.WarmGray,
  primaryColor: '#dcd8c8',
  secondaryColor: '#cac6b5',
  textColor: '#444239',
  backgroundColor: '#eae8de',
  backgroundImage: `linear-gradient(180deg, #eae8de, #fafafa)`,
  borderColor: '#d0cec4',
  logoColor: flushMahogny,
  stickyLogoColor: white,
  customerServiceIconColor: '#000',
  headerBackgroundColor: 'transparent',
  footerBackgroundImage: `linear-gradient(to left, #eae8de, #cac6b5)`,
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: '#cac6b5',
  footerBelowBackgroundImage: `linear-gradient(to left, #eae8de, #cac6b5)`,
  menuBarBackgroundColor: '#dcd8c8',
  menuBarBorderColor: 'transparent',
  menuBarTextColor: '#000',
  menuBarStickyBackgroundColor: '#dcd8c8',
  buttonBackgroundColor: '#dcd8c8',
  buyButtonProductcardDesktop: '#dcd8c8',
  buyButtonProductcardMobile: '#dcd8c8',
  buttonHoverBackgroundColor: '#32ca79',
  buttonTextColor: '#000',
  buttonSecondaryBackgroundColor: '#bbb9b0',
  buttonSecondaryHoverBackgroundColor: '#db4743',
  buttonSearchHeaderBackgroundColor: '#dcd8c8',
  inputPlaceholderTextColor: '#000',
  inputSearchHeaderBackgroundColor: '#fff',
  inputSearchHeaderBorderColor: 'transparent',
  inputEmailFooterBackgroundColor: '#fcfbfa',
  badgeColor: '#e26562',
  inactiveBadgeColor: '#cac6b5',
  cardBackgroundColor: '#fcfbfa',
  shadowColor: '97, 94, 80',
  pictogramBackgroundColor: 'transparent',
  megaMenuCategoryNameBackgroundColor: '#cac6b5',
  megaMenuBackgroundColor: '#fafafa',
};

const blueTheme: ColorTheme = {
  ...defaultTheme,
  type: Theme.Blue,
  primaryColor: '#abd9f1',
  secondaryColor: '#a8d8f0',
  textColor: '#39484f',
  backgroundColor: '#cbe5f3',
  borderColor: '#bddff0',
  logoColor: '#351e54',
  stickyLogoColor: '#351e54',
  customerServiceIconColor: '#eb008b',
  headerBackgroundColor: 'transparent',
  footerBackgroundImage: `linear-gradient(to left, #cbe5f3, #afd9ed)`,
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: '#afd9ed',
  footerBelowBackgroundImage: `linear-gradient(to left, #cbe5f3, #afd9ed)`,
  inputEmailFooterBackgroundColor: '#edf2f5',
  menuBarBackgroundColor: white,
  menuBarBorderColor: 'transparent',
  menuBarTextColor: '#000',
  menuBarStickyBackgroundColor: white,
  minicartButtonIcon: '#eb008b',
  buttonBackgroundColor: '#b2d8ec',
  buyButtonProductcardDesktop: '#b2d8ec',
  buyButtonProductcardMobile: '#b2d8ec',
  buttonHoverBackgroundColor: '#32ca79',
  buttonTextColor: '#000',
  buttonSecondaryBackgroundColor: '#6bafd1',
  buttonSecondaryHoverBackgroundColor: '#44a9db',
  buttonSearchHeaderBackgroundColor: '#eb008b',
  inputPlaceholderTextColor: '#000',
  inputSearchHeaderBackgroundColor: white,
  inputSearchHeaderBorderColor: black,
  badgeColor: '#62b8e3',
  inactiveBadgeColor: '#8eceed',
  cardBackgroundColor: '#f9fbfd',
  shadowColor: '16, 84, 119',
  pictogramBackgroundColor: 'transparent',
  megaMenuCategoryNameBackgroundColor: '#edb1afd9edb0',
  megaMenuBackgroundColor: '#fafafa',
};

const mintTheme: ColorTheme = {
  ...defaultTheme,
  type: Theme.Mint,
  primaryColor: '#b7e8d6',
  secondaryColor: '#9fddc6',
  textColor: bluePurple,
  backgroundColor: '#caeddf',
  borderColor: '#c6e5d9',
  logoColor: bluePurple,
  stickyLogoColor: black,
  customerServiceIconColor: '#eb008b',
  headerBackgroundColor: 'transparent',
  footerBackgroundImage: `linear-gradient(to left, #d7efe6, #b8e6d5)`,
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: '#b8e6d5',
  footerBelowBackgroundImage: `linear-gradient(to left, #d7efe6, #b8e6d5)`,
  inputEmailFooterBackgroundColor: '#fafafa',
  menuBarBackgroundColor: white,
  menuBarBorderColor: 'transparent',
  menuBarTextColor: bluePurple,
  menuBarStickyBackgroundColor: white,
  minicartButtonIcon: '#eb008b',
  buttonBackgroundColor: '#b7e8d6',
  buyButtonProductcardDesktop: '#b7e8d6',
  buyButtonProductcardMobile: '#b7e8d6',
  buttonHoverBackgroundColor: '#d3f1e1',
  buttonTextColor: '#351e54',
  buttonSecondaryBackgroundColor: '#7cd4b4',
  buttonSecondaryHoverBackgroundColor: '#4ccb9c',
  buttonSearchHeaderBackgroundColor: '#eb008b',
  inputPlaceholderTextColor: '#351e54',
  inputSearchHeaderBackgroundColor: '#fff',
  inputSearchHeaderBorderColor: black,
  badgeColor: '#66cfa9',
  inactiveBadgeColor: '#a7dbc8',
  cardBackgroundColor: '#fafcfb',
  shadowColor: '20, 123, 85',
  pictogramBackgroundColor: 'transparent',
  megaMenuCategoryNameBackgroundColor: '#b8e6d5',
  megaMenuBackgroundColor: '#fafafa',
};

const whiteAndBlackTheme: ColorTheme = {
  ...defaultTheme,
  type: Theme.WhiteAndBlack,
  primaryColor: '#000',
  secondaryColor: '#dadada',
  textColor: '#000',
  backgroundColor: '#f5f5f5',
  borderColor: '#f0f0f0',
  logoColor: '#000',
  stickyLogoColor: '#f4f4f4',
  headerBackgroundColor: '#f4f4f4',
  customerServiceIconColor: '#000',
  footerBackgroundImage: `linear-gradient(to left, #dcdcdc, #eaeaea)`,
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: '#dcdcdc',
  footerBelowBackgroundImage: `linear-gradient(to left, #dcdcdc, #eaeaea)`,
  menuBarBackgroundColor: '#111',
  menuBarBorderColor: 'transparent',
  menuBarTextColor: '#fff',
  menuBarStickyBackgroundColor: '#222',
  buttonBackgroundColor: '#000',
  buyButtonProductcardDesktop: '#000',
  buyButtonProductcardMobile: '#000',
  buttonHoverBackgroundColor: '#444',
  buttonTextColor: '#fff',
  buttonSecondaryBackgroundColor: '#777',
  buttonSecondaryHoverBackgroundColor: '#555',
  buttonSearchHeaderBackgroundColor: '#111',
  inputPlaceholderTextColor: '#bbb',
  inputSearchHeaderBackgroundColor: '#fff',
  inputSearchHeaderBorderColor: 'transparent',
  inputEmailFooterBackgroundColor: '#fff',
  badgeColor: '#333',
  inactiveBadgeColor: '#999',
  cardBackgroundColor: '#fff',
  shadowColor: '99, 99, 99',
  pictogramBackgroundColor: '#fff',
  megaMenuCategoryNameBackgroundColor: '#dadafa',
  megaMenuBackgroundColor: '#fafafa',
};

const darkGrayTheme: ColorTheme = {
  ...defaultTheme,
  type: Theme.DarkGray,
  primaryColor: '#333',
  secondaryColor: '#3f3f3f',
  textColor: '#999',
  backgroundColor: '#444',
  borderColor: '#3f3f3f',
  logoColor: '#777',
  stickyLogoColor: '#ccc',
  headerBackgroundColor: '#222',
  footerBackgroundImage: `linear-gradient(to left, #555, #444)`,
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: '#555',
  footerBelowBackgroundImage: `linear-gradient(to left, #555, #444)`,
  menuBarBackgroundColor: '#333',
  menuBarBorderColor: 'transparent',
  menuBarTextColor: '#ccc',
  menuBarStickyBackgroundColor: '#333',
  minicartButtonIcon: white,
  buttonBackgroundColor: '#1f1f1f',
  buyButtonProductcardDesktop: '#1f1f1f',
  buyButtonProductcardMobile: '#1f1f1f',
  buttonHoverBackgroundColor: '#d07100',
  buttonTextColor: '#ccc',
  buttonSecondaryBackgroundColor: '#222',
  buttonSecondaryHoverBackgroundColor: '#000',
  buttonSearchHeaderBackgroundColor: '#333',
  inputPlaceholderTextColor: '#333',
  inputSearchHeaderBackgroundColor: '#111',
  inputSearchHeaderBorderColor: 'transparent',
  badgeColor: '#d07100',
  inactiveBadgeColor: '#555',
  cardBackgroundColor: '#282828',
  shadowColor: '77, 77, 77',
  pictogramBackgroundColor: '#222',
  megaMenuCategoryNameBackgroundColor: '#bbb',
  megaMenuBackgroundColor: '#ccc',
};

const yellowTheme: ColorTheme = {
  ...defaultTheme,
  type: Theme.Yellow,
  primaryColor: '#f1f1b7',
  secondaryColor: '#edec9e',
  textColor: bluePurple,
  backgroundColor: '#f1f1b7',
  borderColor: '#f1f1b7',
  logoColor: bluePurple,
  stickyLogoColor: bluePurple,
  customerServiceIconColor: bluePurple,
  headerBackgroundColor: 'transparent',
  footerBackgroundImage: `linear-gradient(to left, #f1f1b7, #fff)`,
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: '#edec9e',
  footerBelowBackgroundImage: 'none',
  inputEmailFooterBackgroundColor: '#fafafa',
  menuBarBackgroundColor: '#edec9e',
  menuBarBorderColor: 'transparent',
  menuBarTextColor: bluePurple,
  menuBarStickyBackgroundColor: '#edec9e',
  minicartButtonIcon: '#351e54',
  buttonBackgroundColor: '#edec9e',
  buyButtonProductcardDesktop: '#edec9e',
  buyButtonProductcardMobile: '#edec9e',
  buttonHoverBackgroundColor: '#66cfa9',
  buttonTextColor: '#351e54',
  buttonSecondaryBackgroundColor: '#edec9e',
  buttonSecondaryHoverBackgroundColor: '#66cfa9',
  buttonSecondaryTextColor: '#351e54',
  buttonSearchHeaderBackgroundColor: bluePurple,
  inputPlaceholderTextColor: '#351e54',
  inputSearchHeaderBackgroundColor: '#fff',
  inputSearchHeaderBorderColor: 'transparent',
  badgeColor: '#66cfa9',
  inactiveBadgeColor: '#a7dbc8',
  cardBackgroundColor: '#fff',
  shadowColor: '77, 77, 77',
  pictogramBackgroundColor: 'transparent',
  megaMenuCategoryNameBackgroundColor: '#b8e6d5',
  megaMenuBackgroundColor: '#fff',
};

const clitherapyDefaultTheme: ColorTheme = {
  ...defaultTheme,
  type: Theme.ClitherapyDefault,
  primaryColor: '#fff',
  secondaryColor: '#f3f3f3',
  textColor: '#000',
  backgroundColor: '#fff',
  backgroundImage: 'none',
  borderColor: '#f3f3f3',
  logoColor: '#ccc',
  stickyLogoColor: '#ccc',
  headerBackgroundColor: '#fff',
  headerSearchButtonIconColor: '#555',
  headerSearchButtonIconColorMobile: '#555',
  footerBackgroundImage: 'none',
  footerBackgroundColor: '#f5f5f5',
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: '#f5f5f5',
  footerBelowBackgroundImage: 'none',
  menuBarBackgroundColor: '#fff',
  menuBarBorderColor: 'transparent',
  menuBarTextColor: '#000',
  menuBarStickyBackgroundColor: '#fff',
  miniCartBackgroundColor: '#ccc',
  minicartButtonTextColor: '#fff',
  minicartButtonIcon: '#000',
  buttonBackgroundColor: '#f5f5f5',
  buyButtonProductcardMobile: '#4ccb9c',
  buyButtonProductcardDesktop: '#4ccb9c',
  buttonHoverBackgroundColor: '#77e4bc',
  buttonTextColor: '#000',
  buttonSecondaryBackgroundColor: '#000',
  buttonSecondaryHoverBackgroundColor: '#555',
  buttonTertiaryBackgroundColor: '#000',
  buttonTertiaryHoverBackgroundColor: '#555',
  buttonSearchHeaderBackgroundColor: '#f3f3f3',
  cardBackgroundColor: '#f5f5f5',
  inputPlaceholderTextColor: '#ccc',
  inputSearchHeaderBackgroundColor: '#f5f5f5',
  inputSearchHeaderBackgroundColorMobile: '#f5f5f5',
  headerLinkButtonsBackgroundColor: '#f5f5f5',
  headerLinkButtonsBorderColor: white,
  headerLinkButtonsTextColor: '#000',
  inputSearchHeaderBorderColor: 'transparent',
  inputEmailFooterBackgroundColor: '#fff',
  badgeColor: '#4ccb9c',
  inactiveBadgeColor: '#555',
  shadowColor: '77, 77, 77',
  pictogramBackgroundColor: '#f7f7f7',
  megaMenuCategoryNameBackgroundColor: '#bbb',
  megaMenuBackgroundColor: '#f5f5f5',
  customerServiceIconColor: '#000',
  discountBadgeColor: wildwatermelon,
  minicartButtonIconMobile: black,
  productMobileActionbar: black,
  buyButtonProductPageMobileText: black,
};

const lacyDefaultTheme: ColorTheme = {
  ...defaultTheme,
  type: Theme.ClitherapyDefault,
  primaryColor: '#fff',
  secondaryColor: '#f3f3f3',
  textColor: '#000',
  backgroundColor: '#fff',
  backgroundImage: 'none',
  borderColor: '#f3f3f3',
  logoColor: '#ccc',
  stickyLogoColor: '#ccc',
  headerBackgroundColor: '#fff',
  headerLinkButtonsBorderColor: white,
  headerLinkButtonsTextColor: '#000',
  headerSearchButtonIconColor: '#555',
  headerSearchButtonIconColorMobile: '#555',
  footerBackgroundImage: 'none',
  footerBackgroundColor: '#f5f5f5',
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: '#f5f5f5',
  footerBelowBackgroundImage: 'none',
  menuBarBackgroundColor: '#fff',
  menuBarBorderColor: 'transparent',
  menuBarTextColor: '#000',
  menuBarStickyBackgroundColor: '#fff',
  miniCartBackgroundColor: '#ccc',
  minicartButtonIcon: white,
  buttonBackgroundColor: '#f5f5f5',
  buyButtonProductcardDesktop: '#fff',
  buyButtonProductcardMobile: '#fff',
  buttonHoverBackgroundColor: '#4ccb9c',
  buttonTextColor: '#000',
  buttonSecondaryBackgroundColor: '#000',
  buttonSecondaryHoverBackgroundColor: '#555',
  buttonTertiaryBackgroundColor: '#000',
  buttonTertiaryHoverBackgroundColor: '#555',
  buttonSearchHeaderBackgroundColor: '#f3f3f3',
  cardBackgroundColor: '#f5f5f5',
  inputPlaceholderTextColor: '#ccc',
  inputSearchHeaderBackgroundColor: '#f5f5f5',
  inputSearchHeaderBorderColor: 'transparent',
  inputEmailFooterBackgroundColor: '#fff',
  badgeColor: '#4ccb9c',
  inactiveBadgeColor: '#555',
  shadowColor: '77, 77, 77',
  pictogramBackgroundColor: '#f7f7f7',
  megaMenuCategoryNameBackgroundColor: '#bbb',
  megaMenuBackgroundColor: '#f5f5f5',
  customerServiceIconColor: '#000',
  discountBadgeColor: wildwatermelon,
  productMobileActionbar: white,
  buyButtonProductPageMobileText: '#000',
};

const partyNinjaDefaultTheme: ColorTheme = {
  ...defaultTheme,
  type: Theme.Yellow,
  primaryColor: '#f1f1b7',
  secondaryColor: '#edec9e',
  textColor: black,
  backgroundColor: '#f1f1b7',
  borderColor: '#f1f1b7',
  logoColor: bluePurple,
  stickyLogoColor: bluePurple,
  customerServiceIconColor: black,
  headerBackgroundColor: 'transparent',
  headerLinkButtonsBackgroundColor: '#edec9e',
  headerLinkButtonsTextColor: black,
  footerBackgroundImage: `linear-gradient(to left, #f1f1b7, #fff)`,
  footerPaymentsBackgroundColor: white,
  footerBelowBackgroundColor: '#edec9e',
  footerBelowBackgroundImage: 'none',
  inputEmailFooterBackgroundColor: '#fafafa',
  menuBarBackgroundColor: '#edec9e',
  menuBarBorderColor: 'transparent',
  menuBarTextColor: black,
  menuBarStickyBackgroundColor: '#edec9e',
  minicartButtonIcon: black,
  minicartButtonIconMobile: black,
  buttonBackgroundColor: '#edec9e',
  buyButtonProductcardDesktop: '#edec9e',
  buyButtonProductcardMobile: '#edec9e',
  buttonHoverBackgroundColor: '#66cfa9',
  buttonTextColor: '#351e54',
  buttonSecondaryBackgroundColor: '#edec9e',
  buttonSecondaryHoverBackgroundColor: '#66cfa9',
  buttonSecondaryTextColor: '#351e54',
  buttonSearchHeaderBackgroundColor: black,
  inputPlaceholderTextColor: '#351e54',
  inputSearchHeaderBackgroundColor: '#fff',
  inputSearchHeaderBorderColor: 'transparent',
  inputSearchHeaderBackgroundColorMobile: white,
  inputSearchTextColorMobile: black,
  badgeColor: '#66cfa9',
  inactiveBadgeColor: '#a7dbc8',
  cardBackgroundColor: '#fff',
  shadowColor: '77, 77, 77',
  pictogramBackgroundColor: 'transparent',
  megaMenuCategoryNameBackgroundColor: '#b8e6d5',
  megaMenuBackgroundColor: '#fff',
};

type ConnectStateType = {
  currentTheme: string;
};

type ConnectStateType2 = {
  children: ReactNode;
  currentTheme: string;
};

export const getTheme = (themeName: string) => {
  let theme = defaultTheme;
  switch (themeName) {
    case Theme.Pink:
      theme = pinktheme;
      break;
    case Theme.Green:
      theme = greentheme;
      break;
    case Theme.Gray:
      theme = graytheme;
      break;
    case Theme.Premium:
      theme = premiumtheme;
      break;
    case Theme.DoggieDefault:
      theme = themeDoggieDefault;
      break;
    case Theme.BlackFriday:
      theme = themeBlackFriday;
      break;
    case Theme.B2B:
      theme = themeB2B;
      break;
    case Theme.WarmPink:
      theme = warmPinkTheme;
      break;
    case Theme.Beige:
      theme = beigeTheme;
      break;
    case Theme.WarmGray:
      theme = warmGrayTheme;
      break;
    case Theme.Blue:
      theme = blueTheme;
      break;
    case Theme.Mint:
      theme = mintTheme;
      break;
    case Theme.WhiteAndBlack:
      theme = whiteAndBlackTheme;
      break;
    case Theme.DarkGray:
      theme = darkGrayTheme;
      break;
    case Theme.Yellow:
      theme = yellowTheme;
      break;
    case Theme.ClitherapyDefault:
      theme = clitherapyDefaultTheme;
      break;
    case Theme.LacyDefault:
      theme = lacyDefaultTheme;
      break;
    case Theme.PartyNinjaDefault:
      theme = partyNinjaDefaultTheme;
      break;
    default:
      break;
  }
  return theme;
};

export default connect(
  (state): ConnectStateType => ({
    currentTheme: state.currentTheme,
  }),
)(({ children, currentTheme }: ConnectStateType2) => {
  const theme = getTheme(currentTheme);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
});
