import React from 'react';
import { isIOS, isIE, isEdge } from '@avensia/scope';
import { styled, StyledProps } from '@glitz/react';
import { Style } from '@glitz/type';
import { thin, epsilon, general, transition, General, pixelsToUnit, minMediumMediaQuery } from '../Style';
export { General as Variant } from '../Style';

export enum Appearance {
  Bare,
}

type PropType = React.InputHTMLAttributes<HTMLInputElement> &
  StyledProps & {
    invalid?: boolean;
    variant?: General;
    appearance?: Appearance;
    elementRef?: React.RefObject<HTMLInputElement>;
  };

export default styled((props: PropType) => {
  const { variant = General.Default, compose, invalid = false, elementRef, ...restProps } = props;
  const placeholderStyle: Style = {
    color: (theme) => theme.inputPlaceholderTextColor,
    fontSize: epsilon,
    letterSpacing: pixelsToUnit(0.4),
    fontWeight: 'normal',
  };

  const defaultStyle: Style = {
    border: {
      xy: {
        width: thin,
        style: 'solid',
        color: (theme) => theme.inputBorderColor,
      },
      right: { style: 'none' },
    },
    borderRadius: 0,
    color: (theme) => theme.inputTextColor,
    display: 'inline-block',
    fontWeight: 'normal',
    margin: {
      xy: 0,
    },
    lineHeight: 'inherit',
    textAlign: 'left',
    width: '100%',
    WebkitAppearance: 'textfield',
    ...transition({ property: 'width' }),
    '::placeholder': placeholderStyle,
    ':invalid': {
      boxShadow: 'none',
    },
    [minMediumMediaQuery]: {
      border: {
        xy: {
          width: thin,
          style: 'solid',
        },
      },
    },
  };

  return (
    <styled.Input
      ref={elementRef}
      css={compose({
        ...defaultStyle,
        ...general({ type: variant }),
        ...(isIOS() && {
          fontSize: epsilon, // We set font size to 16px because if it's less, iOS will zoom the field
        }),
        ...((isIE() || isEdge()) && {
          ':-ms-input-placeholder': placeholderStyle,
        }),
        ...(invalid && {
          border: {
            xy: {
              width: thin,
              style: 'solid',
              color: (theme: { inputInvalidBorderColor: any }) => theme.inputInvalidBorderColor,
            },
          },
        }),
        ...(props.appearance === Appearance.Bare && {
          border: {
            xy: {
              style: 'none',
            },
          },
        }),
      })}
      {...restProps}
    />
  );
});
