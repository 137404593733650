import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { PageType, AppShellDataType } from 'Shared/State';

let appInsights: ApplicationInsights | undefined = undefined;

export function initApplicationInsights(appShellData: AppShellDataType) {
    if (appShellData.siteSettings.applicationInsightsInstrumentationKey) {
        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: appShellData.siteSettings.applicationInsightsInstrumentationKey,
                disableAjaxTracking: false,
                disableFetchTracking: false,
            },
        });

        appInsights.loadAppInsights();
        appInsights.context.application.ver = CURRENT_VERSION;

        const setAIUser = () => {
            const currentUser = appShellData.currentUser;
            if (currentUser) {
                if (currentUser.isLoggedIn) {
                    appInsights.context.user.setAuthenticatedUserContext(currentUser.id);
                } else {
                    appInsights.context.user.clearAuthenticatedUserContext();
                }
            }
        };
        setAIUser();
    }
}

export async function trackException(e: Error) {
    if (appInsights) {
        appInsights.trackException({ exception: e });
    }
}

export async function trackPageview(page: PageType) {
    if (appInsights) {
        appInsights.trackPageView({ pageType: page.componentName });
    }
}
