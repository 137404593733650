import React from 'react';
import { styled, StyledProps, StyledComponent } from '@glitz/react';
import { Style } from '@glitz/type';
import { media } from '@glitz/core';
import {
  currentUrl,
  pushState,
  clickedLink,
  on,
  URLX,
  equalsUrl,
  loadPage,
  translate,
  isIOS,
  isIE,
} from '@avensia/scope';
import connect from 'Shared/connect';
import {
  hideQuickSearch,
  showQuickSearch,
  loadQuickSearch,
  SEARCH_QUERY_NAME,
  SEARCH_PAGE_LOAD_QUERY_NAME,
  SEARCH_QUERY_SORTBY,
} from '../action-creators';
import { searchPageUrl } from 'Shared/known-urls';
import { QuickSearchType } from 'Shared/State';
import SearchPageViewModelType from 'Search/SearchPageViewModel.type';
import { QuickSearchCompletionListType } from 'Shared/State';
import TextVxn from 'Shared/Fields/TextInputFieldQuicksearch';
import Text, { Variant } from 'Shared/Fields/Text';
import { pixelsToUnit, sigma, thin, white, black, minMediumMediaQueryAlt } from 'Shared/Style';
import Button, { Appearance, ButtonNotLinkPropType as ButtonPropType } from 'Shared/Button';
import Icon from 'Shared/Icon/Search';
import IconLookingGlass from 'Shared/Icon/LookingGlass';
import Suggestions from './Suggestions';

type ConnectStateType = {
  quickSearch: QuickSearchType;
  completionsLists: QuickSearchCompletionListType[];
  theme: string;
};

type ConnectDispatchType = {
  loadPage: (
    url: URLX,
    showSpinner: boolean,
    shouldUseResult?: (page: SearchPageViewModelType) => boolean,
  ) => Promise<any>;
  loadQuickSearch: (searchText: string) => Promise<any>;
  hideQuickSearch: (setFirstSuggestionAsSearchText?: boolean) => void;
  showQuickSearch: () => void;
};

type PropType = {
  formFieldStyle?: Style;
  formSuggestionsStyle?: Style;
  actionButtonComponent?: StyledComponent<ButtonPropType>;
  formInputFocus?: boolean;
} & ConnectStateType &
  ConnectDispatchType &
  StyledProps;

const DEBOUNCE_TIME = 500;

class QuickSearch extends React.Component<PropType> {
  unbindRootClick: () => void;
  previousSearch: string;
  previousSearchWasPreview: boolean = false;
  currentSearchTimeout: number;
  updateSearchUrlTimeout: number;
  inputRef: React.RefObject<HTMLInputElement>;

  constructor(props: PropType) {
    super(props);
    this.inputRef = React.createRef<HTMLInputElement>();
  }

  componentDidMount() {
    this.unbindRootClick = on('click', (e) => {
      if (this.props.quickSearch.isOpen) {
        this.props.hideQuickSearch(false);
      }

      // We don't want to load a result if the user clicks on a product link
      if (clickedLink(e.nativeEvent)) {
        return;
      }
    });
  }

  componentDidUpdate(prevProps: PropType) {
    if (this.props.formInputFocus) {
      if (this.props.quickSearch.isOpen) {
        this.inputRef.current.focus();
      }
      if (!this.props.quickSearch.isOpen && prevProps.quickSearch.isOpen) {
        this.inputRef.current.blur();
      }
    }
  }

  componentWillUnmount() {
    this.unbindRootClick();
    this.clearSearchTimeOuts();
  }

  applySuggestion = (phrase: string) => {
    this.search(phrase);
  };

  getAllCompletions() {
    return this.props.quickSearch.completionsLists.reduce((a, b) => a.concat(b.items), []);
  }

  searchUrl(searchText: string, preview: boolean) {
    const url = new URLX(searchPageUrl());
    url.searchParams.set(SEARCH_QUERY_NAME, searchText);
    if (preview) {
      url.hiddenParams.set(SEARCH_PAGE_LOAD_QUERY_NAME, String(true));
    }
    return url;
  }

  clearSearchTimeOuts() {
    clearTimeout(this.currentSearchTimeout);
    clearTimeout(this.updateSearchUrlTimeout);
  }

  setSearchTimeout(searchText: string) {
    this.clearSearchTimeOuts();
    this.currentSearchTimeout = setTimeout(() => {
      const query = (this.props.quickSearch.suggestion || searchText).trim();

      if (this.previousSearch !== query) {
        this.props.loadPage(
          this.searchUrl(query, true),
          false,
          (searchPage) => !searchPage.products || searchPage.products.totalProductCount !== 0,
        );
        this.previousSearch = query;
        this.previousSearchWasPreview = true;
      }
    }, DEBOUNCE_TIME);

    this.updateSearchUrlTimeout = setTimeout(() => {
      const query = (this.props.quickSearch.suggestion || searchText).trim();
      this.go(query);
    }, DEBOUNCE_TIME * 4);
  }

  loadResults(useSuggestion: boolean) {
    this.clearSearchTimeOuts();
    const query = useSuggestion
      ? this.props.quickSearch.suggestion || this.props.quickSearch.searchText
      : this.props.quickSearch.searchText;

    const newUrl = this.searchUrl(query, true);

    this.previousSearch = query;

    if (equalsUrl(currentUrl(), newUrl)) {
      this.props.loadPage(newUrl, false);
    } else {
      this.go(query);
    }
  }

  go(searchText: string) {
    if (searchText.length) {
      this.previousSearch = searchText;
      this.previousSearchWasPreview = false;
      const newUrl = this.searchUrl(searchText, false);
      const sortBy = currentUrl().searchParams.get(SEARCH_QUERY_SORTBY);

      if (sortBy) {
        newUrl.searchParams.set(SEARCH_QUERY_SORTBY, sortBy);
      }

      if (!equalsUrl(currentUrl(), newUrl)) {
        pushState(newUrl);
      }
    }
  }

  async search(query: string) {
    this.clearSearchTimeOuts();
    this.props.hideQuickSearch();

    if (query.length > 0) {
      await this.go(query);
    }
  }

  onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    this.props.hideQuickSearch(false);
    this.go(this.props.quickSearch.searchText);
    this.loadResults(false);
  };

  onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = event.currentTarget.value;

    // IE11 and below fires an `input` on focus even if the user didn't type anything
    if (searchText === this.props.quickSearch.searchText) {
      return;
    }

    this.props.loadQuickSearch(searchText);
    this.props.showQuickSearch();
  };

  focusSearchInput = (event: React.FocusEvent<HTMLInputElement>) => {
    this.previousSearch = this.props.quickSearch.searchText;
    this.props.loadQuickSearch(this.props.quickSearch.searchText);
    this.props.showQuickSearch();
  };

  onClickInput = (event: React.MouseEvent<HTMLInputElement>) => {
    event.stopPropagation(); // We stop propagation because we close the quick search on clicks outside the form
    event.preventDefault();
  };

  render() {
    const isVxnSite =
      !this.props.theme.startsWith('dog') &&
      !this.props.theme.startsWith('cli') &&
      !this.props.theme.startsWith('lac') &&
      !this.props.theme.startsWith('party');
    const suggestionsStyle: Style = {
      ...this.props.formSuggestionsStyle,
      ...(this.props.completionsLists.length > 0 && this.props.quickSearch.isOpen
        ? { opacity: 1.0, visibility: 'visible' }
        : { opacity: 0, visibility: 'hidden' }),
      position: 'absolute',
      top: isVxnSite ? pixelsToUnit(44) : pixelsToUnit(29),
      left: 0,
      width: '100vw',
      ...media(minMediumMediaQueryAlt, {
        backgroundColor: white,
        width: '50%',
        maxWidth: pixelsToUnit(850),
        top: pixelsToUnit(55),
        left: 0,
        right: 0,
        margin: { xy: 'auto' },
      }),
    };
    const inputStyle: Style = {
      ...(isIOS() && {
        WebkitAppearance: 'none',
      }),
      ...(isIE() && {
        '::-ms-clear': {
          display: 'none',
          width: 0,
          height: 0,
        },
        '::-ms-reveal': {
          display: 'none',
          width: 0,
          height: 0,
        },
      }),
    };
    const SearchButton = this.props.actionButtonComponent || ActionButton;

    return (
      <Form css={this.props.compose()} action={searchPageUrl()} onSubmit={this.onSubmit}>
        <Field css={this.props.formFieldStyle}>
          {isVxnSite ? (
            <InputVxn
              css={inputStyle}
              type="search"
              name="q"
              autoComplete="off"
              autoCorrect="off"
              spellCheck={false}
              variant={Variant.Medium}
              value={this.props.quickSearch.searchText}
              placeholder={translate('/QuickSearch/Placeholder')}
              onClick={this.onClickInput}
              onFocus={this.focusSearchInput}
              onChange={this.onChangeInput}
              elementRef={this.inputRef}
            />
          ) : (
            <Input
              css={inputStyle}
              type="search"
              name="q"
              autoComplete="off"
              autoCorrect="off"
              spellCheck={false}
              variant={Variant.Medium}
              value={this.props.quickSearch.searchText}
              placeholder={translate('/QuickSearch/Placeholder')}
              onClick={this.onClickInput}
              onFocus={this.focusSearchInput}
              onChange={this.onChangeInput}
              elementRef={this.inputRef}
            />
          )}
          <SearchButton
            css={{ textAlign: 'center' }}
            appearance={Appearance.Bare}
            variant={Variant.Medium}
            type="submit"
          >
            {isVxnSite ? <LookingGlassIcon /> : <SearchIcon />}
          </SearchButton>
        </Field>
        <Suggestions css={suggestionsStyle} applySuggestion={this.applySuggestion} />
      </Form>
    );
  }
}

export default styled(
  connect(
    (state): ConnectStateType => ({
      quickSearch: state.quickSearch,
      completionsLists: state.quickSearch.completionsLists.filter((l) => l.items.length > 0),
      theme: state.currentTheme,
    }),
    (dispatch): ConnectDispatchType => ({
      loadPage(url: URLX, showSpinner: boolean, shouldUseResponse?: (page: SearchPageViewModelType) => boolean) {
        return dispatch(loadPage({ url, options: { hideSpinner: !showSpinner }, shouldUseResponse }));
      },
      loadQuickSearch(searchText: string) {
        return dispatch(loadQuickSearch(searchText));
      },
      hideQuickSearch(setFirstSuggestionAsSearchText = true) {
        dispatch(hideQuickSearch(setFirstSuggestionAsSearchText));
      },
      showQuickSearch() {
        dispatch(showQuickSearch());
      },
    }),
  )(QuickSearch),
  {
    width: '50%',
    height: pixelsToUnit(33),
  },
);

const Form = styled.form({
  backgroundColor: 'transparent',
  textAlign: 'left',
  borderRadius: '0.15rem',
});

const Field = styled.div({
  display: 'flex',
  borderRadius: '0.15rem',
  width: '100%',
});

const Input = styled(Text, {
  backgroundColor: (theme) => theme.inputSearchHeaderBackgroundColorMobile,
  color: (theme) => theme.inputSearchTextColorMobile,
  lineHeight: '2rem',
  borderRadius: '1rem 0 0 1rem',
  height: '2rem',
  font: {
    family: '"Nunito", "PT Sans", "sans-serif"',
    size: '16px',
  },
  paddingLeft: pixelsToUnit(17),
  '::placeholder': {
    fontSize: sigma,
    fontWeight: 'bold',
  },
  ...media(minMediumMediaQueryAlt, {
    backgroundColor: (theme) => theme.inputSearchHeaderBackgroundColor,
    height: '2.2rem',
    lineHeight: '2.2rem',
    borderRadius: '2.2rem',
  }),
});

const InputVxn = styled(TextVxn, {
  backgroundColor: (theme) => theme.inputSearchHeaderBackgroundColorMobile,
  color: white,
  lineHeight: '2rem',
  borderRadius: '1rem 0 0 1rem',
  height: '2rem',
  font: {
    family: '"Nunito", "PT Sans", "sans-serif"',
    size: '16px',
  },
  paddingLeft: pixelsToUnit(17),
  '::placeholder': {
    fontSize: sigma,
    fontWeight: 'bold',
  },
  ...media(minMediumMediaQueryAlt, {
    backgroundColor: (theme) => theme.inputSearchHeaderBackgroundColor,
    color: black,
    height: '2.2rem',
    lineHeight: '2.2rem',
    borderRadius: '2.2rem',
  }),
});

const ActionButton = styled(Button, {
  backgroundColor: (theme) => theme.inputSearchHeaderBackgroundColorMobile,
  borderRadius: '0 1rem 1rem 0',
  height: '2rem',
  width: '2.4rem',
  lineHeight: 0,
  border: {
    xy: {
      width: thin,
      style: 'solid',
      color: (theme) => theme.inputSearchHeaderButtonBorderColorMobile,
    },
    left: { style: 'none' },
  },
  ...media(minMediumMediaQueryAlt, {
    backgroundColor: (theme) => theme.buttonSearchHeaderBackgroundColor,
    borderRadius: '100%',
    marginLeft: '0.4rem',
    color: (theme) => theme.menuBarTextColor,
    border: { xy: { style: 'none' } },
    height: '2.2rem',
    width: '2.4rem',
  }),
});

const SearchIcon = styled(Icon, {
  color: (theme) => theme.headerSearchButtonIconColorMobile,
  width: pixelsToUnit(19),
  lineHeight: 0,
  ':active': {
    transform: 'translateY(-1px)',
  },
  ...media(minMediumMediaQueryAlt, {
    color: (theme) => theme.headerSearchButtonIconColor,
  }),
});

const LookingGlassIcon = styled(IconLookingGlass, {
  color: (theme) => theme.headerSearchButtonIconColorMobile,
  width: pixelsToUnit(19),
  lineHeight: 0,
  ':active': {
    transform: 'translateY(-1px)',
  },
  ...media(minMediumMediaQueryAlt, {
    color: (theme) => theme.headerSearchButtonIconColor,
  }),
});
