import { Style } from '@glitz/type';
import { pixelsToUnit } from './unit';

// Used to make sure some elements are styled the same, e.g. buttons and fields
export enum General {
  None,
  Default,
  Tiny,
  Small,
  Medium,
  Large,
  Huge,
}

export const generals = {
  [General.None]: {
    height: 'inherit',
    lineHeight: 'inherit',
  },
  [General.Default]: {
    height: pixelsToUnit(50),
    lineHeight: pixelsToUnit(50),
  },
  [General.Tiny]: {
    height: pixelsToUnit(30),
    lineHeight: pixelsToUnit(30),
  },
  [General.Small]: {
    height: pixelsToUnit(40),
    lineHeight: pixelsToUnit(40),
  },
  [General.Medium]: {
    height: pixelsToUnit(45),
    lineHeight: pixelsToUnit(45),
  },
  [General.Large]: {
    height: pixelsToUnit(50),
    lineHeight: pixelsToUnit(50),
  },
  [General.Huge]: {
    height: pixelsToUnit(60),
    lineHeight: pixelsToUnit(60),
  },
};

type PropType = {
  type?: General;
};

export function general(props: PropType = {}): Style {
  const { type = General.Default } = props;
  const { height, lineHeight } = generals[type];
  return {
    height,
    lineHeight,
  };
}
