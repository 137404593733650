import React from 'react';
import { isFF, isIE } from '@avensia/scope';
import { styled, StyledProps } from '@glitz/react';
import { textEllipsis, textFadeOut, white } from 'Shared/Style';

type PropType = {
  lines: number;
  fontSize: string;
  bgColor?: string;
  children: React.ReactNode;
} & StyledProps;

export default styled((props: PropType) => (
  <ClampedText
    css={props.compose(
      isFF() || isIE()
        ? textFadeOut(props.lines, props.fontSize, props.bgColor ? props.bgColor : white)
        : textEllipsis(props.lines, props.fontSize),
    )}
  >
    {props.children}
  </ClampedText>
));

const ClampedText = styled.div({
  width: '100%',
});
