// This file has been generated by the Scope build. Do not alter
// it manually as your changes will be overwritten. Run:
// scope.cmd frontend:build-component-registry
// instead.

/* tslint:disable */

// prettier-ignore
import { setComponentRegistry, setThemeContextCreatorRegistry } from '@avensia/scope/resolve-component';
// prettier-ignore
export { default as componentNames } from './component-names';

// prettier-ignore
const componentRegistry: {[name: string]: (theme: string) => Promise<{component: any, theme: string}>} = {
  'Avensia.Common.Features.Account.Login.LoginBlock': (theme: string) => import('../Account/Login/LoginBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Account.Login.LoginPage': (theme: string) => import('../Account/Login/LoginPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Account.MyPages.ChangePassword.ChangePasswordPageViewModel': (theme: string) => import('../Account/MyPages/ChangePassword/ChangePasswordPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Account.MyPages.MyDetails.MyDetailsPageViewModel': (theme: string) => import('../Account/MyPages/MyDetails').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Account.MyPages.MyReviews.MyReviewsPageViewModel': (theme: string) => import('../Account/MyPages/MyReviews').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Account.MyPages.OrderDetail.OrderDetailsPageViewModel': (theme: string) => import('../Account/MyPages/OrderDetail/OrderDetailsPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Account.MyPages.OrderHistory.OrderHistoryPageViewModel': (theme: string) => import('../Account/MyPages/OrderHistory').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Account.Register.RegisterBlock': (theme: string) => import('../Account/Register/RegisterBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.AppShell.Partials.AppShellViewModel': (theme: string) => import('../AppShell/Partials/AppShell').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Bambuser.BambuserBlockViewModel': (theme: string) => import('../Bambuser/BambuserBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Campaign.PromotionOverviewPageViewModel': (theme: string) => import('../Campaign/PromotionOverviewPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Campaign.PromotionPageViewModel': (theme: string) => import('../Campaign/PromotionPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Category.CategoryViewModel': (theme: string) => import('../Category/Category').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Checkout.Pages.Checkout.CheckoutPageViewModel': (theme: string) => import('../Checkout/Pages/Checkout/CheckoutPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Checkout.Pages.OrderConfirmation.OrderConfirmationPageViewModel': (theme: string) => import('../Checkout/Pages/OrderConfirmation/OrderConfirmationPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Cms.CmsPageViewModel': (theme: string) => import('../Cms/CmsPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.CustomerService.CustomerServiceFaqPageViewModel': (theme: string) => import('../CustomerService/CustomerServiceFaqPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.CustomerService.CustomerServiceMainPage': (theme: string) => import('../CustomerService/CustomerServiceMainPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.CustomerService.CustomerServiceMainPageViewModel': (theme: string) => import('../CustomerService/CustomerServiceMainPageViewModel').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.CustomerService.FaqBlock': (theme: string) => import('../CustomerService/FaqBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.CustomerService.FaqContainerBlockViewModel': (theme: string) => import('../CustomerService/FaqBlockContainer').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.CustomerService.LinkIconTextBlockViewModel': (theme: string) => import('../CustomerService/LinkIconTextBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.CustomerService.QuestionsBlock': (theme: string) => import('../CustomerService/QuestionsBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.CustomerService.RelatedQuestionsBlock': (theme: string) => import('../CustomerService/RelatedQuestionsBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Error.Pages.PageNotFoundViewModel': (theme: string) => import('../Error/Pages/PageNotFound').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Magazine.MagazinePageViewModel': (theme: string) => import('../Magazine/MagazinePage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Magazine.MagazineListPageViewModel': (theme: string) => import('../Magazine/MagazinePageList').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Product.ProductListing.Pages.ProductListingPageViewModel': (theme: string) => import('../Product/ProductListing/Pages/ProductListingPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Product.VariationViewModel': (theme: string) => import('../Product/Variation').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.ResetPassword.ForgotPasswordPage': (theme: string) => import('../ResetPassword/ForgotPasswordPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.ResetPassword.ResetPasswordPageViewModel': (theme: string) => import('../ResetPassword/ResetPasswordPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Search.SearchPageViewModel': (theme: string) => import('../Search/SearchPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.AdvanceImage.AdvanceImageBlockViewModel': (theme: string) => import('./Blocks/AdvanceImage/AdvanceImageBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.CountdownBanner.CountdownBannerBlockViewModel': (theme: string) => import('./Blocks/CountdownBanner/CountdownBannerBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.HorizontalScroll.HorizontalScrollContainerBlock': (theme: string) => import('./Blocks/HorizontalScroll/HorizontalScrollContainerBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.HorizontalScroll.HorizontalScrollItemBlockViewModel': (theme: string) => import('./Blocks/HorizontalScroll/HorizontalScrollItemBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Html.HtmlBlock': (theme: string) => import('./Blocks/Html/HtmlBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Image.ImageBlockViewModel': (theme: string) => import('./Blocks/Image/ImageBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.ImageContent.ImageContentBlock': (theme: string) => import('./Blocks/ImageContent/ImageContentBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Instagram.InstagramBlockViewModel': (theme: string) => import('./Blocks/Instagram/InstagramBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.LatestMagazine.LatestMagazineBlockViewModel': (theme: string) => import('./Blocks/LatestMagazine').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.LatestReviews.LatestReviewsBlockViewModel': (theme: string) => import('./Blocks/LatestReviews').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.MobileMenuImageBlock.MobileMenuImageBlock': (theme: string) => import('./Blocks/MobileMenuImageBlock/MobileMenuImageBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.MobileMenuTextBlock.MobileMenuTextBlock': (theme: string) => import('./Blocks/MobileMenuTextBlock/MobileMenuTextBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.MobileRowLayout.MobileRowLayout': (theme: string) => import('./Blocks/MobileRowLayout').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Pictogram.PictogramBlock': (theme: string) => import('./Blocks/Pictogram/PictogramBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.PodPlayer.PodPlayerBlock': (theme: string) => import('./Blocks/PodPlayer/PodPlayerBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Products.ProductListBlockViewModel': (theme: string) => import('./Blocks/Products/ProductsBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.RowLayout.RowLayoutBlockViewModel': (theme: string) => import('./Blocks/RowLayout/RowLayoutBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.SiteBanner.SiteBannerBlock': (theme: string) => import('./Blocks/SiteBanner/SiteBannerBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Blocks.Video.VideoBlock': (theme: string) => import('./Blocks/Video/VideoBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.ContentTypes.Media.ImageDataModel': (theme: string) => import('./Partials/ImageDataModel').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Shared.Partials.PreviewViewModel': (theme: string) => import('./Partials/Preview').then(m => ({component: m.default, theme: null})),
  'EPiServer.Core.ContentArea': (theme: string) => import('./Partials/PropertyTypes/ContentArea').then(m => ({component: m.default, theme: null})),
  'EPiServer.Core.XhtmlString': (theme: string) => import('./Partials/PropertyTypes/Html').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.SizeGuide.SizeGuidePageViewModel': (theme: string) => import('../SizeGuide/SizeGuidePage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.Start.StartPageViewModel': (theme: string) => import('../Start/StartPage').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.TrackingInformation.TrackingImpressionBlock': (theme: string) => import('../TrackingInformation/TrackingImpressionBlock').then(m => ({component: m.default, theme: null})),
  'Avensia.Common.Features.WatchItems.UnregisterWatchItemPageViewModel': (theme: string) => import('../WatchItems').then(m => ({component: m.default, theme: null})),
  'EPiServer.SpecializedProperties.LinkItemCollection': (theme: string) => import('@avensia/scope-episerver/PropertyTypes/LinkItemCollection').then(m => ({component: m.default, theme: null})),
};

// prettier-ignore
setComponentRegistry(componentRegistry);
// prettier-ignore
export default componentRegistry;

// prettier-ignore
const themeContextCreatorRegistry = {};
// prettier-ignore
setThemeContextCreatorRegistry(themeContextCreatorRegistry);
