import React from 'react';
import { isIE, URLX, translate } from '@avensia/scope';
import { styled, StyledProps } from '@glitz/react';
import { pseudo } from '@glitz/core';
import Ul from 'Shared/Generic/Ul';
import { small, pixelsToUnit, monochromeDark, thin, zeta, animation, truncate } from 'Shared/Style';
import Link from 'Shared/Link';
import Image, { Preset, Ratio } from 'Shared/Image/Ratio';
import connect from 'Shared/connect';
import { Left } from 'Shared/Icon/Arrow';
import { slideIn } from 'SiteLayout/Drawer/keyframes.scss';
import { ANIMATION_DURATION } from 'SiteLayout/Drawer/Nav';
import Loader from 'SiteLayout/Loader';
import LastViewedProductViewModel from 'LastViewedProducts/LastViewedProductViewModel.type';
import { fetch, toggle } from './action-creators';

type ConnectStateType = {
  products: LastViewedProductViewModel[];
  isFetching: boolean;
};

type ConnectDispatchType = {
  fetchProducts: () => void;
  toggle: () => void;
};

type PropType = ConnectStateType &
  ConnectDispatchType &
  StyledProps & {
    isVisible?: boolean;
    navIsVisible?: boolean;
  };

export const API_URL = '/lastviewedproducts';
class CompactRecentlyViewed extends React.Component<PropType> {
  componentDidMount() {
    this.props.fetchProducts();
  }

  componentDidUpdate(prevProps: PropType) {
    if (this.props.isVisible && !prevProps.isVisible) {
      this.props.fetchProducts();
    }
  }

  showNavigationMenu = () => this.props.toggle();

  render() {
    const { compose, isFetching, products, navIsVisible } = this.props;
    return (
      <React.Fragment>
        <Loader visible={isFetching} />
        {navIsVisible && (
          <Navigation>
            <Back onClick={this.showNavigationMenu}>
              <ArrowLeft />
              {translate('/MainMenu/Back')}
            </Back>
          </Navigation>
        )}
        <Products css={compose()} column>
          {products.length > 0 ? (
            products.map((product, index) => (
              <Product
                key={product.code}
                css={animation({
                  name: slideIn,
                  duration: `${ANIMATION_DURATION}ms`,
                  timingFunction: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
                  delay: `${index * 30}ms`,
                  fillMode: 'forwards',
                })}
                imageUrl={product.imageUrls.length > 0 ? product.imageUrls[0] : PLACEHOLDER}
                productUrl={product.url || PLACEHOLDER}
                brand={product.brand || PLACEHOLDER}
                displayName={product.displayName || PLACEHOLDER}
              />
            ))
          ) : (
            <NoProducts>{translate('/MainMenu/NoProducts')}</NoProducts>
          )}
        </Products>
      </React.Fragment>
    );
  }
}

const Products = styled(Ul, {
  padding: {
    x: small,
  },
});

const Navigation = styled.div({
  display: 'flex',
  height: pixelsToUnit(40),
  justifyContent: 'flex-end',
  paddingRight: pixelsToUnit(12),
});

const Back = styled.div({
  alignItems: 'center',
  cursor: 'pointer',
  display: 'flex',
  fontSize: zeta,
});

const ArrowLeft = styled(Left, {
  marginRight: pixelsToUnit(12),
  width: pixelsToUnit(14),
  height: pixelsToUnit(14),
});

const ITEM_HEIGHT = 80;
const NoProducts = styled.li({
  alignItems: 'center',
  alignSelf: 'center',
  display: 'flex',
  flexBasis: pixelsToUnit(ITEM_HEIGHT),
  fontSize: zeta,
});

export default styled(
  connect(
    (state): ConnectStateType => ({
      products: state.recentlyViewed.products,
      isFetching: state.recentlyViewed.isFetching,
    }),
    (dispatch): ConnectDispatchType => ({
      fetchProducts() {
        dispatch(fetch(new URLX(API_URL)));
      },
      toggle() {
        dispatch(toggle());
      },
    }),
  )(CompactRecentlyViewed),
);

type ItemRequiredPropType = {
  imageUrl: string;
  productUrl: string;
  brand: string;
  displayName: string;
};

type ConnectedProductStateType = {
  isImageCloaked: boolean;
};

type ItemPropType = ItemRequiredPropType & ConnectedProductStateType;

const Product = styled(
  connect<ConnectedProductStateType>(
    (state): ConnectedProductStateType => ({
      isImageCloaked: state.appShellData.cloakProductImages,
    }),
  )(ProductFunc),
);

function ProductFunc(props: ItemPropType & StyledProps) {
  const preset = !props.imageUrl.includes('.gif') ? Preset.Thumb : undefined;
  return (
    <Base css={props.compose(isIE() && { flexBasis: 'auto', height: pixelsToUnit(ITEM_HEIGHT) })}>
      <ProductLink to={props.productUrl}>
        <Media src={props.imageUrl} alt={props.displayName} preset={preset} ratio={Ratio.OneToOne} />
        <BrandAndDisplayName css={isIE() && { width: '100%' }}>
          <Brand>{props.brand}</Brand>
          <DisplayName>{props.displayName}</DisplayName>
        </BrandAndDisplayName>
      </ProductLink>
    </Base>
  );
}

const PLACEHOLDER = '';
const IMAGE_WIDTH = 60;
const GUTTER = 10;
const Base = styled.li({
  flexBasis: pixelsToUnit(ITEM_HEIGHT),
  flexShrink: 0,
  ...pseudo(':not(:first-child)', {
    borderTop: {
      color: monochromeDark,
      width: thin,
      style: 'solid',
    },
  }),
  padding: {
    y: pixelsToUnit(10),
  },
  transform: 'translateX(100%)',
});

const ProductLink = styled(Link, {
  display: 'flex',
  height: '100%',
});

const Media = styled(Image, {
  flexBasis: pixelsToUnit(IMAGE_WIDTH),
  flexShrink: 0,
});

const BrandAndDisplayName = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  justifyContent: 'center',
  marginLeft: pixelsToUnit(GUTTER),
  maxWidth: `calc(100% - ${IMAGE_WIDTH}px - ${GUTTER}px)`,
});

const textStyled = styled({
  color: (theme) => theme.textColor,
  fontSize: zeta,
  lineHeight: pixelsToUnit(21),
  maxWidth: '100%',
  ...truncate(),
});

const Brand = textStyled(styled.Span);
const DisplayName = textStyled(styled.Span);
