/**
 * @ComponentFor PictogramBlock
 */
import React from 'react';
import { styled } from '@glitz/react';
import { pseudo } from '@glitz/core';
import Ul from 'Shared/Generic/Ul';
import Html from 'Shared/Partials/PropertyTypes/Html';
import connect from 'Shared/connect';
import { thin, sigma, greyText } from 'Shared/Style';
import PictogramsType from './PictogramViewModel.type';
import { Breakpoint } from '@avensia/scope';

type ConnectStateType = {
  currentBreakpoint: Breakpoint;
  pictograms: PictogramsType[];
};

type PropType = ConnectStateType;

function PictogramBlock(props: PropType) {
  if (props.currentBreakpoint < Breakpoint.Medium) {
    return null;
  }
  return (
    <Pictograms bare row>
      {props.pictograms.map(
        ({ icon, title }) =>
          icon && (
            <Pictogram key={icon.url}>
              <Img src={icon.url} />
              <Title>
                <Html html={title.html} />
              </Title>
            </Pictogram>
          ),
      )}
    </Pictograms>
  );
}

export default connect(
  (state): ConnectStateType => ({
    currentBreakpoint: state.currentBreakpoint,
    pictograms: state.appShellData.pictograms,
  }),
)(PictogramBlock);

const Pictograms = styled(Ul, {
  alignItems: 'center',
  backgroundColor: (theme) => theme.pictogramBackgroundColor,
  display: 'flex',
  justifyContent: 'center',
});

const Pictogram = styled.li({
  alignItems: 'center',
  color: greyText,
  display: 'flex',
  position: 'relative',
  border: {
    xy: {
      color: (theme) => theme.borderColor,
      width: thin,
    },
  },
  listStyle: 'none',
  ...pseudo(':not(:last-child)', {
    borderRightStyle: 'solid',
    marginRight: '20px',
    paddingRight: '20px',
  }),
  maxHeight: '60px',
});

const Img = styled.img({
  fontSize: '45px',
  marginRight: '13px',
  maxWidth: '45px',
  maxHeight: '40px',
});

const Title = styled.div({
  fontSize: sigma,
  maxWidth: '138px',
});
