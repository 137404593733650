import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { Style } from '@glitz/type';

export type PropType = React.HTMLAttributes<HTMLParagraphElement> & {
  noSpacing?: boolean;
};

export default styled(({ compose, noSpacing, ...restProps }: StyledProps & PropType) => {
  const defaultStyle: Style = {
    fontWeight: 'inherit',
    lineHeight: 'inherit',
    marginTop: noSpacing ? 0 : '15px',
    marginBottom: noSpacing ? 0 : '15px',
  };

  return <styled.P {...restProps} css={compose(defaultStyle)} />;
});
