import React from 'react';
import { Style } from '@glitz/type';
import { styled } from '@glitz/react';
import { FooterType } from 'Shared/State';
import Accordion from 'Shared/Accordion';
import { Up, Down } from 'Shared/Icon/Arrow';
import { pixelsToUnit, epsilon } from 'Shared/Style';
import { NewsLetter, SafeECommerce, CustomerService, MagazineGuides } from './components';
import isSectionEmpty from './utils';

type PropType = {
  footer: FooterType;
};

type StateType = {
  openGroupIndex?: number;
};

const Base = styled.div({
  padding: {
    y: pixelsToUnit(20),
    x: 0,
  },
});

const Header = styled.div({
  height: pixelsToUnit(40),
  padding: {
    y: 0,
    x: pixelsToUnit(10),
  },
});

const Title = styled.span({
  float: 'left',
  fontSize: epsilon,
  lineHeight: pixelsToUnit(40),
});

const iconCss: Style = {
  float: 'right',
  height: '100%',
  width: pixelsToUnit(13),
};

class FooterMobile extends React.Component<PropType, StateType> {
  constructor(props: PropType) {
    super(props);
    this.state = {
      openGroupIndex: null,
    };
  }

  setOpenGroup(groupIndex: number) {
    this.setState({
      openGroupIndex: this.state.openGroupIndex !== groupIndex ? groupIndex : null,
    });
  }

  renderHeader(value: string, groupIndex: number) {
    return (
      <Header>
        <Title>{value}</Title>
        {this.state.openGroupIndex === groupIndex ? <Up css={iconCss} /> : <Down css={iconCss} />}
      </Header>
    );
  }

  render() {
    const {
      newsletterTitle = '',
      newsletterText = '',
      secondColumnTitle = '',
      secondColumnText,
      thirdColumnLinks,
      fourthColumnLinks,
    } = this.props.footer;

    const newsLetter = { header: newsletterTitle, body: newsletterText };
    const safeECommerce = { header: secondColumnTitle, body: secondColumnText };
    const [customerService = { header: '', links: [] }] = thirdColumnLinks; // get first element in array, set default values
    const [magazineGuides = { header: '', links: [] }] = fourthColumnLinks; // get first element in array, set default values
    const accordionContents = [
      {
        header: secondColumnTitle,
        body: !isSectionEmpty(safeECommerce) && <SafeECommerce safeECommerce={safeECommerce} isCompact />,
      },
      {
        header: customerService.header,
        body: !isSectionEmpty(customerService) && <CustomerService customerService={customerService} isCompact />,
      },
      {
        header: magazineGuides.header,
        body: !isSectionEmpty(magazineGuides) && <MagazineGuides magazineGuides={magazineGuides} isCompact />,
      },
    ];

    return (
      <Base>
        {!isSectionEmpty(newsLetter) && <NewsLetter newsLetter={newsLetter} />}
        {accordionContents
          .filter(content => content.header || content.body)
          .map((content, index) => (
            <Accordion
              css={{
                minHeight: pixelsToUnit(40),
                padding: {
                  y: 0,
                  x: pixelsToUnit(7),
                },
              }}
              key={index}
              body={content.body}
              header={this.renderHeader(content.header, index)}
              isOpen={index === this.state.openGroupIndex}
              onToggle={() => this.setOpenGroup(index)}
            />
          ))}
      </Base>
    );
  }
}

export default FooterMobile;
