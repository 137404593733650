import { Style } from '@glitz/type';
import { pixelsToUnit, Unit } from './unit';
import consts from './constants';

const { BASE_FONT_SIZE, BASE_TEXT_COLOR, BASE_LINE_HEIGHT, BASE_STYLE_UNIT } = consts;

export const baseFontSize = BASE_FONT_SIZE;
export const baseTextColor = BASE_TEXT_COLOR;
export const baseLineHeight = BASE_LINE_HEIGHT;
export const baseUnit = BASE_STYLE_UNIT;
export const alternativeFontFamily = 'serif';

export const relativeLineHeight = pixelsToUnit(baseLineHeight, Unit.Em);

// Large font sizes
export const giga = pixelsToUnit(72);
export const mega = pixelsToUnit(54);
export const kilo = pixelsToUnit(36);

// Normal font sizes
export const alpha = pixelsToUnit(32);
export const omega = pixelsToUnit(28);
export const beta = pixelsToUnit(26);
export const iota = pixelsToUnit(22);
export const gamma = pixelsToUnit(20);
export const delta = pixelsToUnit(18);
export const delison = pixelsToUnit(17);
export const epsilon = pixelsToUnit(16);
export const theta = pixelsToUnit(15);
export const sigma = pixelsToUnit(14);
export const zeta = pixelsToUnit(13);

// Small font sizes
export const kappa = pixelsToUnit(12);
export const eta = pixelsToUnit(11);
export const milli = pixelsToUnit(10);
export const micro = pixelsToUnit(8);

export function truncate(overflow = 'ellipsis'): Style {
  return {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: overflow,
  };
}

export function textFadeOut(lines: number, fontSize = epsilon, backgroundColor: string): Style {
  const size = parseFloat(fontSize.replace(/[^0-9\.]+/g, ''));
  const height = lines * size + lines * (size * 0.5);
  return {
    display: 'block',
    position: 'relative',
    overflow: 'hidden',
    height: `${height}rem`,
    fontSize,
    ':after': {
      content: '""',
      textAlign: 'right',
      position: 'absolute',
      bottom: 0,
      right: 0,
      width: '100%',
      height: `${height / lines}rem`,
      backgroundImage: `linear-gradient(to right,transparent,${backgroundColor})`,
    },
  };
}
export function textEllipsis(lines: number, fontSize = epsilon): Style {
  const size = parseFloat(fontSize.replace(/[^0-9\.]+/g, ''));
  const height = lines * size + lines * (size * 0.5);
  return {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: lines,
    WebkitBoxOrient: 'vertical',
    height: `${height}rem`,
    fontSize,
    ':after': {
      display: 'none',
      backgroundImage: 'none',
    },
  };
}
