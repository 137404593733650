import React from 'react';
import { styled } from '@glitz/react';
import { isIE } from '@avensia/scope';
import factory from './icon';
import * as style from '../Style';

class Glyph extends React.Component<React.SVGAttributes<SVGElement>> {
  render() {
    return (
      <styled.Svg
        viewBox="25 25 50 50"
        {...this.props}
        css={style.animation({
          name: {
            '100%': {
              transform: 'rotate(360deg)',
            },
          },
          duration: isIE() ? 2000 : 2000,
          timingFunction: 'linear',
          iterationCount: 'infinite',
        })}
      >
        <styled.Circle
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke="currentColor"
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeDasharray={isIE() ? '1, 200' : '30, 200'}
          strokeDashoffset="0"
          strokeLinecap="round"
          css={
            !isIE() &&
            style.animation({
              name: {
                '0%': {
                  strokeDasharray: '1px, 200px',
                  strokeDashoffset: '0px',
                },
                '50%': {
                  strokeDasharray: '89px, 200px',
                  strokeDashoffset: '-35px',
                },
                '100%': {
                  strokeDasharray: '89px, 200px',
                  strokeDashoffset: '-124px',
                },
              },
              duration: 1500,
              timingFunction: 'ease-in-out',
              iterationCount: 'infinite',
            })
          }
        />
      </styled.Svg>
    );
  }
}

export default factory(Glyph as any);
