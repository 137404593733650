import * as React from 'react';
import { URLX, Link, currentUrl, equalsUrl, pushState, replaceState, scrollToHash } from '@avensia/scope';
import { applyClassName, styled } from '@glitz/react';

export function isInternalUrl(url: HTMLAnchorElement | Location | URL | URLX) {
  if (!['http:', 'https:'].includes(url.protocol)) {
    return false;
  }
  if ((url.port && !location.port && url.host === `${location.host}:${url.port}`) || url.host === location.host) {
    return true;
  }
  return false;
}

export function isExternalLink(link: HTMLAnchorElement) {
  return link.target === '_blank' || !isInternalUrl(new URLX(link.href));
}

export type LinkProps = Pick<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  Exclude<keyof React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>
> & {
  to: URLX | string;
  linkIdentifier?: string;
  replaceState?: boolean;
  skipPartialCache?: boolean;
  onPageLoaded?: (pageData: any) => void;
  onPageLoadFailed?: (error: any) => void;
  includeAppShellData?: boolean;
};

export async function onLinkClick(
  link: HTMLAnchorElement,
  e: React.MouseEvent<HTMLAnchorElement> | MouseEvent,
  linkProps?: LinkProps,
  deferLoadPromise?: Promise<any> | null,
) {
  if (window.IS_IN_EDIT_MODE || !/^http[s]?:/.test(link.href) || !isNormalClick(e) || isExternalLink(link)) {
    return Promise.resolve();
  }

  e.preventDefault();

  const options = {
    linkIdentifier: linkProps && linkProps.linkIdentifier,
    skipPartialCache: linkProps && linkProps.skipPartialCache,
    deferLoadPromise,
    includeAppShellData: linkProps && linkProps.includeAppShellData,
  };

  const promise =
    equalsUrl(currentUrl(), new URLX(link.href), true) || (linkProps && linkProps.replaceState)
      ? await replaceState(link.href, options)
      : await pushState(link.href, options);

  if (link.hash) {
    scrollToHash(link.hash);
  }

  return promise;
}

function isNormalClick(e: React.MouseEvent<HTMLAnchorElement> | MouseEvent) {
  return !(e.button > 1 || e.metaKey || e.ctrlKey || e.shiftKey || e.altKey);
}

export default styled(applyClassName(Link), {
  textDecoration: 'none',
});
