import React from 'react';
import { Style } from '@glitz/type';
import { styled, StyledProps } from '@glitz/react';
import {
  pixelsToUnit,
  minHugeMediaQuery,
  minMediumMediaQuery,
  small,
  huge,
  ZIndex,
  minLargeMediaQuery,
} from 'Shared/Style';
import connect from 'Shared/connect';
import DefaultLogo from 'Shared/Icon/Logo';
import MobileDefaultLogo from 'Shared/Icon/VxnLogo';
import DoggieLogo from 'Shared/Icon/Doggielogo';
import ClitherapyLogo from 'Shared/Icon/LacyLogo';
import LacyLogo from 'Shared/Icon/LacyLogo';
import PartyNinjaLogo from 'Shared/Icon/PartyNinjaLogo';
import { Theme } from 'Shared/Theming';

type RequiredPropType = {
  sticky?: boolean;
  mobile?: boolean;
};

type ConnectStateType = {
  theme: string;
};

type DesktopPropType = {
  sticky: boolean;
} & MobilePropType;

type MobilePropType = {
  theme: string;
};

type PropType = StyledProps & RequiredPropType & ConnectStateType;

const DESKTOP_HEADER_HEIGHT = pixelsToUnit(60);

const defaultMobileLogoStyle: Style = {
  color: (theme) => theme.logoColor,
  width: '5rem',
  height: '5rem',
};

const defaultDesktopLogoStyle: Style = {
  position: 'absolute',
  top: pixelsToUnit(-30),
  left: pixelsToUnit(-40),
  height: '8rem',
  width: '10rem',
  zIndex: ZIndex.Header,
  [minLargeMediaQuery]: {
    left: pixelsToUnit(0),
  },
};

const defaultDesktopStickyLogoStyle: Style = {
  position: 'absolute',
  color: (theme) => theme.stickyLogoColor,
  width: '7.2rem',
  height: '7.2rem',
  top: pixelsToUnit(-25),
  [minLargeMediaQuery]: {
    height: '8rem',
    width: '8rem',
  },
};

const desktopStickyDoggieLogoStyle: Style = {
  color: (theme) => theme.stickyLogoColor,
  width: 'auto',
  height: '60%',
  marginTop: pixelsToUnit(2),
};

const desktopStickyClitherapyLogoStyle: Style = {
  color: (theme) => theme.stickyLogoColor,
  width: 'auto',
  height: '60%',
  marginTop: pixelsToUnit(0),
};

const desktopDoggieLogoStyle: Style = {
  color: (theme) => theme.logoColor,
  width: '100%',
  height: '100%',
  paddingBottom: pixelsToUnit(15),
};

const mobileDoggieLogoStyle: Style = {
  height: '100%',
  color: (theme) => theme.logoColor,
  width: '5.5rem',
  display: 'flex',
  alignItems: 'center',
};

const mobileClitherapyLogoStyle: Style = {
  color: (theme) => theme.logoColor,
  width: '7rem',
  display: 'flex',
  alignItems: 'center',
};

const DefaultLogoWrapper = styled.div({
  height: DESKTOP_HEADER_HEIGHT,
  marginLeft: small,
  width: pixelsToUnit(120),
  zIndex: 3,
  [minHugeMediaQuery]: {
    marginLeft: huge,
  },
});

const PartyNinjaLogoWrapper = styled.div({
  height: pixelsToUnit(45),
  marginLeft: small,
  width: pixelsToUnit(200),
  zIndex: 3,
  [minHugeMediaQuery]: {
    marginLeft: huge,
  },
});

const ClitherapyLogoWrapper = styled.div({
  height: pixelsToUnit(50),
  zIndex: 3,

  [minMediumMediaQuery]: {
    marginRight: pixelsToUnit(10),
  },
});

const LacyLogoWrapper = styled.div({
  height: DESKTOP_HEADER_HEIGHT,
  marginLeft: small,
  width: '8.5rem',
  zIndex: 3,
  [minHugeMediaQuery]: {
    marginLeft: huge,
  },
});

const Mobile = styled.div({
  paddingTop: pixelsToUnit(7),
});

const MobileWrapper = styled.div({
  position: 'absolute',
  top: pixelsToUnit(-15),
  left: pixelsToUnit(-12),
});

const MobileDoggieWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
});
const MobileClitherapyWrapper = styled(MobileDoggieWrapper, {});

const DesktopLogo = styled((props: DesktopPropType & StyledProps) => {
  if (props.theme === Theme.DoggieDefault) {
    return (
      <DefaultLogoWrapper css={props.compose()}>
        <DoggieLogo css={props.sticky ? desktopStickyDoggieLogoStyle : desktopDoggieLogoStyle} />
      </DefaultLogoWrapper>
    );
  } else if (props.theme.startsWith('cli')) {
    return (
      <ClitherapyLogoWrapper css={props.compose()}>
        <ClitherapyLogo css={props.sticky ? desktopStickyClitherapyLogoStyle : desktopDoggieLogoStyle} />
      </ClitherapyLogoWrapper>
    );
  } else if (props.theme.startsWith('lac')) {
    return (
      <LacyLogoWrapper>
        <LacyLogo css={props.sticky ? desktopStickyClitherapyLogoStyle : desktopDoggieLogoStyle} />
      </LacyLogoWrapper>
    );
  } else if (props.theme.startsWith('party')) {
    return (
      <PartyNinjaLogoWrapper>
        <PartyNinjaLogo css={props.sticky ? desktopStickyClitherapyLogoStyle : desktopDoggieLogoStyle} />
      </PartyNinjaLogoWrapper>
    );
  } else {
    return (
      <div>
        <DefaultLogo css={props.sticky ? defaultDesktopStickyLogoStyle : defaultDesktopLogoStyle} />
      </div>
    );
  }
});

const MobileLogo = styled((props: MobilePropType & StyledProps) => (
  <Mobile
    css={props.compose(
      props.theme === Theme.Default
        ? {
            margin: {
              y: 0,
              x: 'auto',
            },
          }
        : {
            display: 'flex',
            justifyContent: 'center',
          },
    )}
  >
    {props.theme === Theme.DoggieDefault && (
      <MobileDoggieWrapper>
        <DoggieLogo css={mobileDoggieLogoStyle} />
      </MobileDoggieWrapper>
    )}
    {props.theme === Theme.ClitherapyDefault && (
      <MobileClitherapyWrapper>
        <ClitherapyLogo css={mobileClitherapyLogoStyle} />
      </MobileClitherapyWrapper>
    )}
    {props.theme === Theme.LacyDefault && (
      <MobileWrapper>
        <LacyLogo css={mobileClitherapyLogoStyle} />
      </MobileWrapper>
    )}
    {props.theme === Theme.PartyNinjaDefault && <PartyNinjaLogo css={mobileClitherapyLogoStyle} />}
    {props.theme !== Theme.DoggieDefault &&
      !props.theme.startsWith('cli') &&
      !props.theme.startsWith('lac') &&
      !props.theme.startsWith('party') && (
        <MobileWrapper>
          <MobileDefaultLogo css={defaultMobileLogoStyle} />
        </MobileWrapper>
      )}
  </Mobile>
));

const SiteLogo = (props: PropType) =>
  props.mobile ? (
    <MobileLogo css={props.compose()} theme={props.theme} />
  ) : (
    <DesktopLogo css={props.compose()} sticky={props.sticky} theme={props.theme} />
  );

export default styled(
  connect(
    (state): ConnectStateType => ({
      theme: state.currentTheme,
    }),
  )(SiteLogo),
);
