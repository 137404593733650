import { Breakpoint, useCurrentBreakpoint, widthFromBreakpoint } from '@avensia/scope';

export enum Size {
  /** Image preset of 60px width */
  Thumb,
  Micro,
  /** Image preset of 320px width */
  Tiny,
  /** Image preset of 640px width */
  Small,
  /** Image preset of 960px width */
  Medium,
  /** Image preset of 1280px width */
  Large,
  /** Image preset of 1920px width */
  Huge,
  /** Image preset of 3200px width */
  Gigantic,
}

type SizesType = { [key: number]: number };

export const imageSizes: SizesType = {
  [Size.Thumb]: 60,
  [Size.Tiny]: 320,
  [Size.Small]: 640,
  [Size.Medium]: 960,
  [Size.Large]: 1280,
  [Size.Huge]: 1920,
  [Size.Gigantic]: 3200,
};

export const getSizes = (): SizesType => ({
  [Size.Thumb]: 60,
  [Size.Micro]: 220,
  [Size.Tiny]: 320,
  [Size.Small]: 640,
  [Size.Medium]: 960,
  [Size.Large]: 1280,
  [Size.Huge]: 1920,
  [Size.Gigantic]: 3200,
});

export function getPresetFromWidth(estimatedWidth: number, availableSizes: SizesType = imageSizes): Size {
  return parseInt(
    Object.keys(availableSizes).find((sizeKey) => availableSizes[sizeKey] >= estimatedWidth),
    10,
  );
}

function widthFromBreakpointSafe(breakpoint: Breakpoint) {
  const breakpointKeys = Object.keys(Breakpoint)
    .map(Number)
    .filter((k) => typeof k === 'number' && !isNaN(k));
  const highestBreakpoint = breakpointKeys[breakpointKeys.length - 1];
  return widthFromBreakpoint(Math.min(breakpoint, highestBreakpoint));
}

export function getPresetFromBreakpoint(breakpoint: Breakpoint, width?: number) {
  const maxWidthFromBreakpoint = widthFromBreakpointSafe(breakpoint + 1);

  if (typeof width === 'number' && maxWidthFromBreakpoint > width) {
    return null;
  }
  return getPresetFromWidth(maxWidthFromBreakpoint, imageSizes);
}

export function usePresetForShareOfViewport(sharePercent: number) {
  const breakpoint = useCurrentBreakpoint();

  if (sharePercent === 100 || breakpoint <= Breakpoint.Small) {
    return getPresetFromBreakpoint(breakpoint);
  }

  const maxWithFromBreakpoint = widthFromBreakpointSafe(breakpoint + 1);
  const imageWidthForShare = imageSizes[getPresetFromWidth(maxWithFromBreakpoint * (sharePercent / 100))];
  return getPresetFromWidth(imageWidthForShare);
}
