import deepmerge from 'deepmerge';
import {
  get,
  post,
  URLX,
  addUserLog,
  onCrossWindow,
  emitCrossWindow,
  LoadFailureAction,
  LINK_IDENTIFIER_HEADER,
  REQUEST_ACTION_HEADER,
  ADD_TO_CART_ACTION,
} from '@avensia/scope';
import CartViewModel from '../Cart/Models/CartViewModel.type';
import State, { Action, Dispatch, CartType, CartDiffType } from 'Shared/State';
import { batchActions } from 'redux-batched-actions';
import { addToCart as gtmAddToCart, removeFromCart as gtmRemoveFromCart } from '../TrackingInformation';
import { CartEventLocation } from '../TrackingInformation/tracking-types';
import DesignTagProperties from '../Cart/Models/DesignTagProperties.type';
export const CART_LOAD = 'CART_LOAD';
export const CART_LOAD_SUCCESS = 'CART_LOAD_SUCCESS';
export const CART_LOAD_FAILURE = 'CART_LOAD_FAILURE';
export const CART_INVALID_QUANTITY = 'CART_INVALID_QUANTITY';
export const CART_OPTIMISTIC_UPDATE = 'CART_OPTIMISTIC_UPDATE';
export const CART_CHANGES_ACCEPTED = 'CART_CHANGES_ACCEPTED';

export type CartAction = Action & {
  cart: CartViewModel;
};

export type CartOptimisticUpdateAction = Action & {
  diff: CartDiffType;
};

export type CartChangesAcceptedAction = Action & {
  changes: CartDiffType[];
};

export function rejectInvalidQuantity(code: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: CART_INVALID_QUANTITY,
      code,
    } as Action);
    return Promise.reject(null);
  };
}

export function addToCart(
  code: string,
  quantity: number,
  ticket: string,
  location: CartEventLocation,
  isCheckoutPage: boolean = false,
  designTagProperties: DesignTagProperties = null,
) {
  if (!isValidQuantity(quantity)) {
    return rejectInvalidQuantity(code);
  }
  addUserLog('Adding ' + quantity + ' of ' + code + ' to the cart');
  return sendCartRequest(
    (cart) => {
      const existingItem = cart.items.find((i) => i.code === code && !i.isGift);
      const actualQuantity = (existingItem && existingItem.quantity + quantity) || quantity;

      return {
        items: {
          [code]: {
            newQuantity: actualQuantity,
            previousQuantity: existingItem ? existingItem.quantity : 0,
            designTagProperties,
          },
        },
      } as CartDiffType;
    },
    location,
    ticket,
    isCheckoutPage,
  );
}

export function updateCartItemQuantity(code: string, quantity: number, location: CartEventLocation) {
  if (!isValidQuantity(quantity)) {
    return rejectInvalidQuantity(code);
  }
  addUserLog('Updating ' + code + ' to ' + quantity + ' in the cart');
  return sendCartRequest((cart) => {
    const existingItem = cart.items.find((i) => i.code === code && !i.isGift);
    return { items: { [code]: { newQuantity: quantity, previousQuantity: existingItem.quantity } } } as CartDiffType;
  }, location);
}

export function removeCartItem(code: string, location: CartEventLocation) {
  addUserLog('Removing ' + code + ' from the cart');
  return sendCartRequest((cart) => {
    const existingItem = cart.items.find((i) => i.code === code && !i.isGift);
    return { items: { [code]: { newQuantity: 0, previousQuantity: existingItem.quantity } } } as CartDiffType;
  }, location);
}

let currentCartUpdate = Promise.resolve(null);

onCrossWindow(['cart-modified', 'checkout-modified'], (store) => {
  store.dispatch({
    type: CART_LOAD,
  } as Action);

  const updateCart = () => {
    console.debug('Reloading cart since it was modified in another window/tab');
    const url = new URLX('/cart');
    get(url)
      .then((r) => r.json())
      .then((json: CartViewModel) => {
        store.dispatch({
          type: CART_LOAD_SUCCESS,
          cart: json,
        } as CartAction);
      })
      .catch((e) => {
        console.error(e);
        store.dispatch({ type: CART_LOAD_FAILURE, error: e, url } as LoadFailureAction);

        return Promise.reject(e);
      });
  };
  currentCartUpdate = currentCartUpdate.then(updateCart, updateCart);
});

let pendingTickets: string[] = [];

function sendCartRequest(
  diffOrCreator: CartDiffType | ((cart: CartType) => CartDiffType),
  location: CartEventLocation,
  ticket: string = null,
  isCheckoutPage: boolean = false,
) {
  if (ticket) {
    pendingTickets.push(ticket);
  }
  return (dispatch: Dispatch, getState: () => State) => {
    let diff: CartDiffType;
    if (typeof diffOrCreator === 'function') {
      diff = (diffOrCreator as (cart: CartType) => CartDiffType)(getState().cart);
    } else {
      diff = diffOrCreator as CartDiffType;
    }
    diff.id = Math.random();

    dispatch(
      batchActions([
        {
          type: CART_OPTIMISTIC_UPDATE,
          diff,
        } as CartOptimisticUpdateAction,
        {
          type: CART_LOAD,
        } as Action,
      ]),
    );

    const sendNextRequest = () => {
      const state = getState().cart;
      const currentPendingChanges = state.pendingChanges;
      if (!currentPendingChanges.length) {
        return Promise.resolve();
      }

      let totalDiff: { items?: Object; id?: number; isCheckoutPage?: boolean } = {};
      currentPendingChanges.forEach((r: any) => (totalDiff = deepmerge(totalDiff, r)));

      if (isCheckoutPage) {
        totalDiff.isCheckoutPage = true;
      }

      let headers: { [name: string]: string };
      if (pendingTickets.length) {
        const tickets = pendingTickets.join(',');
        pendingTickets = [];

        headers = {
          [LINK_IDENTIFIER_HEADER]: tickets,
          [REQUEST_ACTION_HEADER]: ADD_TO_CART_ACTION,
        };
      }

      const url = new URLX('/cart/update');

      return post(url, totalDiff, headers)
        .then((r) => r.json())
        .then((json: CartViewModel) => {
          if (json.cartUpdateResult.addedItems.length > 0) {
            gtmAddToCart(json.cartUpdateResult.addedItems, location);
          }
          if (json.cartUpdateResult.removedItems.length > 0) {
            gtmRemoveFromCart(json.cartUpdateResult.removedItems, location);
          }

          if (json.cartUpdateResult.errorItems.length > 0) {
            const errorItem = json.cartUpdateResult.errorItems[0];
            throw errorItem.errorMessage;
          }

          dispatch(
            batchActions([
              {
                type: CART_CHANGES_ACCEPTED,
                changes: currentPendingChanges,
              } as CartChangesAcceptedAction,
              {
                type: CART_LOAD_SUCCESS,
                cart: json,
              } as CartAction,
            ]),
          );

          emitCrossWindow('cart-modified');
        })
        .catch((e) => {
          console.error(e);
          dispatch({ type: CART_LOAD_FAILURE, error: e, url } as LoadFailureAction);

          return Promise.reject(e);
        });
    };
    currentCartUpdate = currentCartUpdate.then(sendNextRequest, sendNextRequest);
    return currentCartUpdate;
  };
}

export function isValidQuantity(quantity: number, maxQuantity?: number) {
  if (typeof quantity !== 'number') {
    return false;
  }
  return quantity >= 0 && quantity <= (maxQuantity || 99);
}
