import { Action } from 'Shared/State';

export type SearchActionType = Action & {
  searchText?: string;
  isLoading?: boolean;
};

export const UPDATE_MYREVIEWS_SEARCH_TEXT = 'UPDATE_MYREVIEWS_SEARCH_TEXT';
export const SEARCH_MY_REVIEWS_STATUS = 'SEARCH_MY_REVIEWS_STATUS';

export function changeSearchText(searchText: string) {
  return {
    type: UPDATE_MYREVIEWS_SEARCH_TEXT,
    searchText,
  } as Action;
}

export function searchRequestStatus(isLoading: boolean) {
  return {
    type: SEARCH_MY_REVIEWS_STATUS,
    isLoading,
  } as Action;
}
