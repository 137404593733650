import { PageType } from 'Shared/State';
import { componentNames } from 'Shared/component-registry';
import CheckoutPageViewModelType from './CheckoutPageViewModel.type';

type CheckoutPageType = CheckoutPageViewModelType & PageType;

export default (currentPage: PageType): currentPage is CheckoutPageType => {
  return (
    currentPage.componentName === componentNames.Avensia_Common_Features_Checkout_Pages_Checkout_CheckoutPageViewModel
  );
};
