import React from 'react';
import { Style } from '@glitz/type';
import { styled, StyledProps } from '@glitz/react';
import RippleEffect, { rippleContainerStyle } from '../RippleEffect';
import { General, white, transition, general, thin } from '../Style';
import appearanceFunc, { AppearanceType } from '../appearance';
import { LinkProps as LinkPropType } from 'Shared/Link';
import Link from 'Shared/Link';

export { General as Variant } from '../Style';
export enum Appearance {
  Primary,
  Secondary,
  Tertiary,
  Bare,
  Full,
  Half,
  Upsell,
  Alert,
  MobileSmall,
}

type BodyPropType = {
  variant?: General;
  appearance?: AppearanceType<Appearance>;
  noRippleEffect?: boolean;
};

export type ButtonLinkPropType = LinkPropType & BodyPropType;

export type ButtonNotLinkPropType = React.ButtonHTMLAttributes<HTMLButtonElement> & BodyPropType;

// Should be union typed when this is within reach:
// https://github.com/Microsoft/TypeScript/issues/13526
export type PropType = Partial<ButtonLinkPropType> & ButtonNotLinkPropType;

function isLink(props: ButtonLinkPropType | ButtonNotLinkPropType): props is ButtonLinkPropType {
  return 'to' in props;
}

const ButtonLink = styled(
  ({
    compose,
    variant,
    appearance,
    // Disable ripple effect per default because links often disappears when they are clicked
    noRippleEffect = true,
    children,
    ...restProps
  }: StyledProps & ButtonLinkPropType) => (
    <RippleEffect>
      {(ripples: Array<React.ReactElement<any>>, push: (e: React.MouseEvent<HTMLElement>) => Promise<void>) => (
        <Link
          {...restProps}
          css={compose()}
          onClick={e => {
            if (!noRippleEffect) {
              push(e);
            }
            if (restProps.onClick) {
              restProps.onClick(e);
            }
          }}
        >
          {children}
          {ripples}
        </Link>
      )}
    </RippleEffect>
  ),
);

const ButtonNotLink = styled(
  ({
    compose,
    type = 'button',
    variant,
    appearance,
    noRippleEffect,
    children,
    ...restProps
  }: StyledProps & ButtonNotLinkPropType) => (
    <RippleEffect>
      {(ripples: Array<React.ReactElement<any>>, push: (e: React.MouseEvent<HTMLElement>) => Promise<void>) => (
        <styled.Button
          {...restProps}
          type={type}
          css={compose()}
          onClick={e => {
            if (!noRippleEffect) {
              push(e);
            }
            if (restProps.onClick) {
              restProps.onClick(e);
            }
          }}
        >
          {children}
          {ripples}
        </styled.Button>
      )}
    </RippleEffect>
  ),
);

export default styled(function Button({ compose, ...restProps }: StyledProps & PropType) {
  const { variant = General.Default } = restProps;
  const appear = appearanceFunc(restProps.appearance);
  const css: Style = {
    font: {
      family: 'inherit',
      size: 'inherit',
      style: 'inherit',
      variant: 'inherit',
      weight: 'inherit',
    },
    border: {
      xy: {
        width: 0,
      },
    },
    borderRadius: '0.15rem',
    display: 'inline-block',
    padding: { xy: 0 },
    userSelect: 'none',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    textDecoration: 'none',
    textAlign: 'inherit',
    textTransform: 'inherit',
    ':focus': {
      outlineWidth: 0,
    },
    ...(!restProps.disabled && {
      cursor: 'pointer',
    }),
    ...general({ type: variant }),
    ...transition({ property: ['color', 'background', 'opacity'] }),
    ...(appear(Appearance.Bare) && {
      color: 'inherit',
      backgroundImage: 'none',
      backgroundColor: 'transparent',
    }),
    ...(appear(Appearance.Half) && {
      width: '50%',
    }),
    ...(appear(Appearance.MobileSmall) && {
      width: '1.5rem',
      height: '1.5rem',
      borderRadius: '100%',
      backgroundColor: 'green',
    }),
    ...(appear(Appearance.Full) && {
      width: '100%',
    }),
    ...((appear(Appearance.Half) || appear(Appearance.Full)) && {
      textAlign: 'center',
    }),
    // Don't apply other themes if it's disabled
    ...(restProps.disabled
      ? {
          backgroundColor: theme => theme.buttonDisabledBackgroundColor,
          backgroundImage: theme => theme.buttonDisabledBackgroundImage,
          color: theme => theme.buttonDisabledTextColor,
          fontWeight: 'bold',
          textTransform: 'uppercase',
          pointerEvents: 'none',
        }
      : {
          // default appearance
          ...((appear(Appearance.Primary) || !appear(Appearance.Bare)) && {
            color: theme => theme.buttonTextColor,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            ...transition({ property: 'background' }),
            backgroundImage: theme => theme.buttonBackgroundImage,
            backgroundColor: theme => theme.buttonBackgroundColor,
            ':hover': {
              backgroundImage: theme => theme.buttonHoverBackgroundImage,
              backgroundColor: theme => theme.buttonHoverBackgroundColor,
            },
            ':active': {
              boxShadow: 'none',
            },
          }),

          ...(appear(Appearance.Secondary) && {
            backgroundImage: theme => theme.buttonSecondaryBackgroundImage,
            backgroundColor: theme => theme.buttonSecondaryBackgroundColor,
            border: {
              xy: {
                width: thin,
                style: 'solid',
                color: theme => theme.buttonSecondaryBorderColor,
              },
            },
            color: theme => theme.buttonSecondaryTextColor,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            boxShadow: 'none',
            ':hover': {
              backgroundImage: theme => theme.buttonSecondaryHoverBackgroundImage,
              backgroundColor: theme => theme.buttonSecondaryHoverBackgroundColor,
            },
            ':active': {
              boxShadow: 'none',
            },
          }),

          ...(appear(Appearance.Tertiary) && {
            backgroundColor: theme => theme.buttonTertiaryBackgroundColor,
            backgroundImage: theme => theme.buttonTertiaryBackgroundImage,
            color: theme => theme.buttonTertiaryTextColor,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            ':hover': {
              backgroundColor: theme => theme.buttonTertiaryHoverBackgroundColor,
              backgroundImage: theme => theme.buttonTertiaryHoverBackgroundImage,
            },
            ':active': {
              boxShadow: 'none',
            },
          }),

          ...(appear(Appearance.Upsell) && {
            color: white,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            backgroundImage: 'linear-gradient(270deg, #6AA62D 0%, #86BD49 100%)',
            ':hover': {
              backgroundImage: 'linear-gradient(270deg, #86BD49 0%,  #6AA62D 100%)',
            },
            ':active': {
              boxShadow: 'none',
            },
          }),

          ...(appear(Appearance.Alert) && {
            backgroundColor: theme => theme.buttonAlertBackgroundColor,
            backgroundImage: theme => theme.buttonAlertBackgroundImage,
            ':hover': {
              backgroundColor: theme => theme.buttonAlertBackgroundColor,
              backgroundImage: theme => theme.buttonAlertBackgroundImage,
            },
          }),
        }),
    ...(!restProps.noRippleEffect && rippleContainerStyle),
  };

  return isLink(restProps) ? (
    <ButtonLink {...restProps} css={compose(css)} />
  ) : (
    <ButtonNotLink {...restProps} css={compose(css)} />
  );
});
