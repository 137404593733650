import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { Star, StarOutline } from 'Shared/Icon/Star';
import { pixelsToUnit } from 'Shared/Style';

type PropType = {
  rating: number;
  onRate: (index: number) => void;
} & StyledProps;

const RATINGS = [1, 2, 3, 4, 5];
const starCss = {
  width: pixelsToUnit(26),
  height: pixelsToUnit(24.7),
  cursor: 'pointer',
  ':not(:first-child)': {
    marginLeft: pixelsToUnit(10),
  },
};

const DefaultStar = styled(StarOutline, starCss);
const FullStar = styled(Star, starCss);

export default styled((props: PropType) => (
  <styled.Div css={props.compose({})}>
    {RATINGS.map((rating, index) => {
      const StarElem: any = index < props.rating ? FullStar : DefaultStar;
      // tslint:disable-next-line: jsx-no-lambda
      return <StarElem key={rating} onClick={() => props.onRate(rating !== props.rating ? rating : 0)} />;
    })}
  </styled.Div>
));
