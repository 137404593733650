/**
 * @ComponentFor EPiServer.Core.XhtmlString
 */
import React from 'react';
import { Html, registerDecorator } from '@avensia/scope-episerver';
import Image from '../../Image';
import { Size } from '../../image-sizes';
import A from 'Shared/Generic/A';
import P from 'Shared/Generic/P';
import { styled } from '@glitz/react';
import { pixelsToUnit } from 'Shared/Style';


registerDecorator('img', (item, i, props, componentProps) => {
  let size = Size.Large;
  if (props['data-size']) {
    size = props['data-size'];
  }
  if (componentProps.keepImageSizes !== true) {
    if (props.width) {
      delete props.width;
    }
    if (props.height) {
      delete props.height;
    }
  }
  return <Image key={i} {...props} preset={size} />;
});

const decoratorFactory = (Component: React.ReactType, mapProps?: (props: any) => any) => {
  const C = (item: any, i: number, props: any, componentProps: any, children: React.ReactNode | React.ReactNode[]) => {
    return <Component key={i} {...(mapProps ? mapProps(props) : props)}>{children}</Component>;
  };
  return C;
};

const CheckoutUl = styled.ul({
  paddingLeft: pixelsToUnit(30),
});

const { Consumer: CheckoutPageHtmlConsumer, Provider: CheckoutPageHtmlProvider } = React.createContext<boolean>(false);

export { CheckoutPageHtmlProvider };

registerDecorator('ul', (item, i, props, componentProps, children) => {
  return (
    <CheckoutPageHtmlConsumer key={i}>
      {isCheckout => (isCheckout ? <CheckoutUl {...props} >{children}</CheckoutUl> : <ul {...props}>{children}</ul>)}
    </CheckoutPageHtmlConsumer>
  );
});

registerDecorator('li', (item, i, props, componentProps, children) => {
  return (
    <li key={i}>{children}</li>
  );
});

registerDecorator('a', decoratorFactory(A));
registerDecorator('p', decoratorFactory(P));

export default Html;