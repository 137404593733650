import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { isIOS, on } from '@avensia/scope';
import { object as PropTypeObject } from 'prop-types';
import { Basic } from 'Shared/PageLayout';
import { primary, pixelsToUnit, ZIndex, white } from 'Shared/Style';
import { transition } from 'Shared/Style';

type ItemPropType = {
  children?: React.ReactNode;
};

type TrayStateType = {
  actionbar: {
    key: number;
    child: React.ReactNode;
    isMounted?: boolean;
  };
  snackbars: {
    [key: number]: React.ReactNode;
  };
};

class TrayState {
  actionbarQueue = Promise.resolve();
  state: TrayStateType = {
    actionbar: null,
    snackbars: {},
  };
  updaters: Array<(state: Partial<TrayStateType>) => void> = [];
  update(state: Partial<TrayStateType>) {
    for (const key in state) {
      this.state[key] = state[key];
    }
    for (const updater of this.updaters) {
      updater(state);
    }
  }
  onUpdate(updater: (state: TrayStateType) => void) {
    this.updaters.push(updater);
    return () => {
      this.updaters.splice(this.updaters.indexOf(updater), 1);
    };
  }
  async setActionbar(child: React.ReactNode, key: number) {
    await this.actionbarQueue;
    const isMounted = !this.state.actionbar || this.state.actionbar.key !== key ? false : true;
    this.update({
      actionbar: {
        key,
        child,
        isMounted,
      },
    });
  }
  async pushRemoveActionbar() {
    await this.actionbarQueue;
    this.update({
      actionbar: {
        ...this.state.actionbar,
        isMounted: false,
      },
    });
  }
  removeActionbar(key: number) {
    if (this.state.actionbar && key === this.state.actionbar.key) {
      this.actionbarQueue = this.pushRemoveActionbar();
    }
  }
  updateSnackbar(child: React.ReactNode, key: number) {
    this.update({
      snackbars: {
        ...this.state.snackbars,
        [key]: child,
      },
    });
  }
  removeSnackbar(child: React.ReactNode, key: number) {
    const snackbars = { ...this.state.snackbars };
    delete snackbars[key];
    this.update({ snackbars });
  }
}

function snackbarKey(key: string) {
  return `s${key}`;
}

function actionbarKey(key: number) {
  return `a${key}`;
}

type ContextType = {
  tray: TrayState;
};

const contextTypes = {
  tray: PropTypeObject,
};

export class TrayProvider extends React.Component {
  static childContextTypes = contextTypes;
  childContext: ContextType = { tray: new TrayState() };
  getChildContext() {
    return this.childContext;
  }
  render() {
    return React.Children.only(this.props.children);
  }
}

type StylesType = {
  key: string;
  data: { isActionbar: boolean; child: React.ReactNode };
  style: { translateY?: number; opacity: number; height?: number };
};

type StateType = TrayStateType & {
  heights: { [key: number]: number };
};

type PropType = StyledProps;

class TrayContainer extends React.Component<PropType, StateType> {
  static contextTypes = contextTypes;
  context: ContextType;
  unsubscribeUpdate: () => void;
  constructor(props: PropType, context: ContextType) {
    super(props, context);
    this.state = {
      heights: {},
      ...context.tray.state,
    };
    this.unsubscribeUpdate = this.context.tray.onUpdate((trayState) => {
      this.setState(trayState);
    });
  }
  componentDidUpdate(prevProps: PropType, prevstate: StateType) {
    if (!prevstate.actionbar || (prevstate.actionbar && prevstate.actionbar.key !== this.state.actionbar.key)) {
      requestAnimationFrame(() => {
        this.animateMount();
      });
    }
  }
  componentWillUnmount() {
    this.unsubscribeUpdate();
  }
  animateMount = () => {
    this.setState({
      actionbar: {
        ...this.state.actionbar,
        isMounted: true,
      },
    });
  };
  snackbarRef = (key: string) => (el: HTMLDivElement) => {
    if (el && el.offsetHeight !== this.state.heights[key]) {
      this.setState((state) => ({ heights: { ...this.state.heights, [key]: el.offsetHeight } }));
    }
  };

  render() {
    const bars = [
      ...(this.state.actionbar
        ? [
            {
              key: actionbarKey(this.state.actionbar.key),
              data: {
                isActionbar: true,
                child: this.state.actionbar.child,
              },
              style: this.state.actionbar.isMounted
                ? {
                    translateY: 0,
                    opacity: 1,
                  }
                : {
                    translateY: 100,
                    opacity: 0,
                  },
            } as StylesType,
          ]
        : []),
      ...Object.keys(this.state.snackbars)
        // Reversing to make sure new snackbars enters on top
        .reverse()
        .map(
          (key): StylesType => ({
            key: snackbarKey(key),
            data: {
              isActionbar: false,
              child: this.state.snackbars[key],
            },
            style: {
              opacity: 1,
              height: this.state.heights[snackbarKey(key)] || 0,
            },
          }),
        ),
    ];
    const actionbars = bars.filter((entry) => entry.data.isActionbar);
    const snackbars = bars.filter((entry) => !entry.data.isActionbar);
    return (
      (actionbars.length > 0 || snackbars.length > 0) && (
        <styled.Div css={this.props.compose()}>
          {actionbars.map(({ key, data, style }) => (
            <ActionbarItem
              key={key}
              css={{
                ...(isIOS() && { left: '50%' }),
                opacity: style.opacity,
                transform: `translateY(${style.translateY}px)${isIOS() ? ' translateX(-50%)' : ''}`,
              }}
            >
              {data.child}
            </ActionbarItem>
          ))}
          {snackbars.length > 0 && (
            <SnackbarList>
              {snackbars.map(({ key, data, style }) => (
                <SnackbarOuter
                  key={key}
                  style={{
                    opacity: style.opacity,
                    height: style.height,
                  }}
                >
                  <SnackbarInner elementRef={this.snackbarRef(key)}>{data.child}</SnackbarInner>
                </SnackbarOuter>
              ))}
            </SnackbarList>
          )}
        </styled.Div>
      )
    );
  }
}

export const Tray = styled(TrayContainer, {
  alignItems: 'flex-start',
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  left: 0,
  position: 'fixed',
  right: 0,
  width: '100%',
  zIndex: ZIndex.Tray,
});

const SnackbarList = styled.div({
  margin: {
    x: 0,
    y: 'auto',
  },
});

const SnackbarOuter = styled.div({
  backgroundColor: primary,
  overflow: 'hidden',
});

const SnackbarInner = styled(Basic, {
  color: white,
  padding: {
    xy: pixelsToUnit(8),
  },
});

const ActionbarItem = styled.div({
  position: 'absolute',
  // Negative z-index to animate it behind the snackbars
  zIndex: -1,
  bottom: '100%',
  display: 'flex',
  width: '100%',
  ...transition({ property: 'all', duration: '400ms' }),
});

function scrollReachedPageEnd() {
  return Math.ceil(window.innerHeight + window.pageYOffset) >= document.documentElement.offsetHeight;
}

let nextKey = 0;

export class Actionbar extends React.Component<ItemPropType> {
  static contextTypes = contextTypes;
  disabled: boolean = false;
  unsubscribeScroll: () => void;
  key: number;
  constructor(props: ItemPropType) {
    super(props);
    this.key = nextKey;
    nextKey++;
  }
  componentDidMount() {
    this.update(this.props.children);
    this.unsubscribeScroll = on('scroll', this.togglePageEnd);
  }
  componentDidUpdate(prevProps: ItemPropType) {
    if (this.props.children !== prevProps.children && !this.disabled) {
      this.update(this.props.children);
    }
  }
  componentWillUnmount() {
    this.unsubscribeScroll();
    if (!this.disabled) {
      this.remove();
    }
  }
  togglePageEnd = () => {
    const disabled = scrollReachedPageEnd();
    if (this.disabled !== disabled) {
      disabled ? this.remove() : this.update(this.props.children);
    }
    this.disabled = disabled;
  };
  update(children: React.ReactNode) {
    (this.context as ContextType).tray.setActionbar(children, this.key);
  }
  remove() {
    (this.context as ContextType).tray.removeActionbar(this.key);
  }
  render(): null {
    return null;
  }
}

export class Snackbar extends React.Component<ItemPropType> {
  static contextTypes = contextTypes;
  key: number;
  constructor(props: ItemPropType) {
    super(props);
    this.key = nextKey;
    nextKey++;
  }
  UNSAFE_componentWillMount() {
    (this.context as ContextType).tray.updateSnackbar(this.props.children, this.key);
  }
  UNSAFE_componentWillReceiveProps(nextProps: ItemPropType) {
    (this.context as ContextType).tray.updateSnackbar(nextProps.children, this.key);
  }
  componentWillUnmount() {
    (this.context as ContextType).tray.removeSnackbar(this.props.children, this.key);
  }
  render(): null {
    return null;
  }
}
