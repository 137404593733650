import React from 'react';
import { Swipeable, SwipeCallback } from 'react-swipeable';
import { styled, StyledComponentWithRef } from '@glitz/react';
import { on } from '@avensia/scope';
import Overlay from 'Shared/Overlay';
import { depth, white, gigantic } from 'Shared/Style';
import { transition } from 'Shared/Style';

const DELTA_THRESHOLD = 200;

export enum Position {
  Left,
  Right,
}

type PropType = {
  open: boolean;
  position: Position;
  positionComponent?: StyledComponentWithRef<any, HTMLDivElement>;
  toggle: () => void;
  children?: React.ReactNode | ((promise: () => Promise<{}>) => React.ReactNode);
};

type StateType = {
  translateX: number;
};

export default class Flyout extends React.Component<PropType, StateType> {
  mounted = false;
  isSwiping = false;
  previousDeltaX = 0;
  width: number;
  element: HTMLDivElement;
  unsubscribeResize: () => void;
  state: StateType = {
    translateX: 0,
  };
  componentDidMount() {
    this.mounted = true;
    this.updateWidthTranslateX();
    this.unsubscribeResize = on('resize', this.updateWidthTranslateX);
  }
  componentWillUnmount() {
    this.mounted = false;
    this.unsubscribeResize();
  }
  UNSAFE_componentWillReceiveProps(nextProps: PropType) {
    const translateX = nextProps.open ? (nextProps.position === Position.Left ? this.width : -this.width) : 0;
    if (this.state.translateX !== translateX) {
      this.setState({ translateX });
    }
  }
  updateWidthTranslateX = () => {
    if (this.element) {
      this.width = this.element.offsetWidth;
      const translateX = this.props.open ? (this.props.position === Position.Left ? this.width : -this.width) : 0;
      if (this.mounted && this.state.translateX !== translateX) {
        this.setState({ translateX });
      }
    }
  };
  onSwiping: SwipeCallback = (props) => {
    const translateX = this.state.translateX + this.previousDeltaX - props.deltaX;

    this.previousDeltaX = props.deltaX;
    this.isSwiping = true;

    if (
      ((this.props.position === Position.Left && translateX < this.width) ||
        (this.props.position === Position.Right && translateX > -this.width)) &&
      translateX !== this.state.translateX
    ) {
      this.setState({
        translateX,
      });
    }
  };
  onSwiped: SwipeCallback = (props) => {
    this.previousDeltaX = 0;
    this.isSwiping = false;

    if (
      (this.props.position === Position.Left && props.deltaX > DELTA_THRESHOLD) ||
      (this.props.position === Position.Right && props.deltaX < -DELTA_THRESHOLD)
    ) {
      this.props.toggle();
    } else {
      this.setState({
        translateX: this.props.position === Position.Left ? this.width : -this.width,
      });
    }
  };
  stopPropagation = (e: React.MouseEvent<HTMLElement>) => e.stopPropagation();
  ref = (el: HTMLDivElement) => (this.element = el);
  render() {
    const Base = this.props.positionComponent || (this.props.position === Position.Left ? Left : Right);

    return (
      <Overlay onClose={this.props.toggle} enabled={this.props.open} css={{ zIndex: 19 }}>
        <Swipeable onSwiping={this.onSwiping} onSwiped={this.onSwiped} delta={DELTA_THRESHOLD}>
          <Base
            onClick={this.stopPropagation}
            ref={this.ref}
            css={this.state.translateX !== 0 && { transform: `translateX(${this.state.translateX}px)` }}
          >
            {this.props.children}
          </Base>
        </Swipeable>
      </Overlay>
    );
  }
}

const flyoutStyled = styled({
  backgroundColor: white,
  height: '100%',
  width: '23rem',
  maxWidth: `calc(100% - ${gigantic})`,
  position: 'fixed',
  top: 0,
  willChange: 'transform',
  ...transition({ property: 'transform', duration: '300ms' }),
  ...depth(),
});

export const Left = flyoutStyled(styled.Div, { right: '100%' });
export const Right = flyoutStyled(styled.Div, { left: '100%' });
export const RightWide = flyoutStyled(styled.Div, { width: '50rem' });
