import SiteSettingsType from 'AppShell/SiteSettingsViewModel.type';
let siteSettings: SiteSettingsType = null;

export function setKnownUrls(settings: SiteSettingsType) {
  siteSettings = settings;
}

export function searchPageUrl() {
  return siteSettings.searchPage.url;
}

export function forgotPasswordPage() {
  return siteSettings.forgotPasswordPage.url;
}

export function checkoutPageUrl() {
  return siteSettings.checkoutPage.url;
}

export function loginPage() {
  return siteSettings.loginPage.url;
}

export function termsAndConditionPage() {
  return siteSettings.termsAndConditionsPage.url;
}
