import * as React from 'react';
import { styled, StyledProps } from '@glitz/react';
export enum Ratio {
  OneToOne,
  ThreeToTwo,
  SixteenToNine,
}

type PropType = {
  ratio: Ratio;
};

export default styled(
  ({
    compose,
    ratio = Ratio.OneToOne,
    ...restProps
  }: StyledProps & React.HTMLAttributes<HTMLDivElement> & PropType) => {
    let paddingTop;
    switch (ratio) {
      case Ratio.OneToOne:
        paddingTop = '100%';
        break;

      case Ratio.ThreeToTwo:
        paddingTop = '66.66%';
        break;
      case Ratio.SixteenToNine:
        paddingTop = `${(9 / 16) * 100}%`;
        break;
      default:
    }
    return (
      <styled.Div
        {...restProps}
        css={compose({
          overflow: 'hidden',
          ':before': {
            content: '""',
            float: 'left',
            paddingTop,
          },
        })}
      />
    );
  },
);
