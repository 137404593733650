import React from 'react';
import Link from 'Shared/Link';
import Image, { Ratio as ImageRatio, Preset as ImagePreset } from 'Shared/Image/Ratio';

type PropType = {
  productUrl: string;
  mediaSrc: string;
  alt: string;
  isGift: boolean;
};

export default function Media(props: PropType) {
  const image = <Image src={props.mediaSrc} alt={props.alt} ratio={ImageRatio.OneToOne} preset={ImagePreset.Tiny} css={{ mixBlendMode: 'multiply' }} />;
  return props.isGift ? image : <Link to={props.productUrl}>{image}</Link>;
}
