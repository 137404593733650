import { isFF, isIE, isSF } from '@avensia/scope';

function version(match: RegExp) {
  return () => {
    const version = navigator.userAgent.match(match);
    return version ? +version[1] : 0;
  };
}

const ffVersion = version(/Firefox\/(\d+\.\d+)/);

const chromeVersion = version(/Chrome\/(\d+\.\d+)/);
function isChrome() {
  return /Chrome/.test(navigator.userAgent);
}

const operaVersion = version(/Opera\/(\d+\.\d+)/);
function isOpera() {
  return /Opera/.test(navigator.userAgent);
}

const safariVersion = version(/Version\/(\d+\.\d+)/);

const edgeVersion = version(/Edge\/(\d+\.\d+)/);
function isEdge() {
  return /Edge/.test(navigator.userAgent);
}

// Will return `false` if `CSS.supports` is unsupported
const supports: typeof CSS.supports =
  typeof CSS !== 'undefined' && typeof CSS.supports === 'function' ? CSS.supports.bind(CSS) : () => false;

export default supports;

export function supportsGrid() {
  return (
    supports('display', 'grid') ||
    (isFF()
      ? ffVersion() >= 52
      : isChrome()
      ? chromeVersion() >= 57
      : isOpera()
      ? operaVersion() >= 44
      : isSF()
      ? safariVersion() >= 10.1
      : false)
  );
}

export function supportsObjectFit() {
  return (
    (supports('object-fit', 'contain') && supports('object-position', 'center')) ||
    !(isIE() || (isEdge() && edgeVersion() <= 15) || (isSF() && safariVersion() <= 9.2))
  );
}
