import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import itemKey, { ROOT_KEY, ItemKeyType } from '../MainMenu/item';
import Level, { isRoot } from './Level';
import NavigationItemType from 'Shared/NavigationItem/NavigationItem.type';
import MobileMenuViewModel from 'Start/MobileMenuViewModel.type';
import { thin, pixelsToUnit, revolver, white, animation } from 'Shared/Style';
import Link from 'Shared/Link';
import MobileMenuTextBlock from 'Shared/Blocks/MobileMenuTextBlock/MobileMenuTextBlock';
import MobileMenuImageBlock from 'Shared/Blocks/MobileMenuImageBlock/MobileMenuImageBlock';
import { slideIn } from './keyframes.scss';

type PropType = {
  primary: NavigationItemType[] | NavigationItemType;
  setLevel: (key: ItemKeyType) => void;
  mobileMenu: MobileMenuViewModel;
  currentMenuUrl: string;
} & StyledProps;

export const ANIMATION_DURATION = 600;

export default styled(
  class Nav extends React.Component<PropType> {
    pushing = false;
    enumerate = 0;
    currentKey: string;
    UNSAFE_componentWillReceiveProps(nextProps: PropType) {
      if (this.props.primary !== nextProps.primary) {
        this.pushing = this.isPushing(this.props.primary, nextProps.primary);
        this.enumerate++;
      }
    }
    isPushing(
      currentPrimary: NavigationItemType[] | NavigationItemType,
      nextPrimary: NavigationItemType[] | NavigationItemType,
    ) {
      if (isRoot(currentPrimary)) {
        return true;
      }
      if (isRoot(nextPrimary)) {
        return false;
      }
      return (
        currentPrimary.children &&
        currentPrimary.children.length > 0 &&
        !currentPrimary.children.every(item => item !== nextPrimary)
      );
    }
    enumeratedKey(key: ItemKeyType) {
      return `${key}${this.enumerate}`;
    }
    pushLevel = (item: NavigationItemType) => {
      this.props.setLevel(itemKey(item));
    };

    renderAdditionalContent = (isMenuRoot: boolean) => {
      if (isMenuRoot) {
        return (
          <styled.Div
            css={animation({
              name: slideIn,
              duration: `${ANIMATION_DURATION}ms`,
              timingFunction: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
              fillMode: 'forwards',
              delay: `${1 * 30}ms`,
            })}
          >
            {!!this.props.mobileMenu.imageUrl ? (
              <MobileMenuImageBlock
                image={this.props.mobileMenu.imageUrl.url}
                link={this.props.mobileMenu.imageLink.url}
              />
            ) : (
              <MobileMenuTextBlock textBlocks={this.props.mobileMenu.textBlocks} />
            )}
          </styled.Div>
        );
      }
      return null;
    };

    render() {
      const { mainMenuButtons, buttonBackgroundColor, buttonTextColor } = this.props.mobileMenu;
      const isMenuRoot = this.props.currentMenuUrl === ROOT_KEY;
      const entryKey = (this.currentKey = this.enumeratedKey(
        isRoot(this.props.primary) ? ROOT_KEY : itemKey(this.props.primary),
      ));
      const entryData = {
        level: this.props.primary,
        children: this.props.children,
      };

      return (
        <Base css={this.props.compose()}>
          <Entry key={entryKey}>
            <Level data={entryData.level} pushLevel={this.pushLevel} />
            {entryData.children}
            {this.renderAdditionalContent(isMenuRoot)}
            {!!mainMenuButtons &&
              mainMenuButtons.map((menu, idx) => (
                <NavButton
                  key={idx}
                  css={animation({
                    name: {
                      from: {
                        transform: 'translateX(100%)',
                      },
                      to: {
                        transform: 'translateX(0%)',
                      },
                    },
                    duration: `${ANIMATION_DURATION}ms`,
                    timingFunction: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
                    delay: `${idx * 30}ms`,
                    fillMode: 'forwards',
                  })}
                >
                  <MainMenuButton
                    css={{
                      backgroundColor: !!buttonBackgroundColor ? buttonBackgroundColor : revolver,
                      color: !!buttonTextColor ? buttonTextColor : white,
                      visibility: isMenuRoot ? 'visible' : 'hidden',
                      marginBottom: thin,
                    }}
                    to={menu.url}
                  >
                    {menu.name}
                  </MainMenuButton>
                </NavButton>
              ))}
          </Entry>
        </Base>
      );
    }
  },
);

const MainMenuButton = styled(Link, {
  alignItems: 'center',
  display: 'flex',
  height: pixelsToUnit(41),
  paddingLeft: pixelsToUnit(15),
  textTransform: 'uppercase',
  width: '100%',
});

const Base = styled.nav({
  position: 'relative',
});

const Entry = styled.div({
  position: 'absolute',
  width: '100%',
  top: 0,
});

const NavButton = styled.div({
  marginBottom: pixelsToUnit(1),
});
