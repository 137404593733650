import NavigationItemType from 'Shared/NavigationItem/NavigationItem.type';
import { MainMenuType } from 'Shared/State';

export type ItemKeyType = string;
export const ROOT_KEY = '/';

export default function itemKey(item: NavigationItemType): ItemKeyType {
  return item.url;
}

export function findItem(items: NavigationItemType[], key: ItemKeyType): NavigationItemType {
  for (const item of items) {
    if (itemKey(item) === key) {
      return item;
    }
    if (item.children && item.children.length > 0) {
      const foundItem = findItem(item.children, key);

      if (foundItem) {
        return foundItem;
      }
    }
  }
}

export function findFirstLevelItem(items: NavigationItemType[], key: ItemKeyType): NavigationItemType {
  for (const item of items) {
    if (itemKey(item) === key) {
      return item;
    }
    if (item.children && item.children.length > 0 && findItem(item.children, key)) {
      return item;
    }
  }
}

export function parentPrimaryKey({ primaryMenuItems, visibleKey }: MainMenuType): ItemKeyType {
  for (const item of primaryMenuItems) {
    const currentKey = itemKey(item);
    if (currentKey === visibleKey) {
      return ROOT_KEY;
    }

    const parentKey = findParentKey(item, visibleKey);
    if (parentKey) {
      return parentKey;
    }
  }
}

function findParentKey(item: NavigationItemType, key: ItemKeyType): ItemKeyType {
  if (item.children && item.children.length > 0) {
    for (const child of item.children) {
      if (itemKey(child) === key) {
        return itemKey(item);
      }

      const parentKey = findParentKey(child, key);
      if (parentKey) {
        return parentKey;
      }
    }
  }
}
