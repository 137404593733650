import { styled } from '@glitz/react';
import factory from './icon';
import Svg from './glyphs/arrow-down.svg';

export const Down = factory(Svg);
export const Right = styled(Down, {
  transform: 'rotate(-90deg)',
});
export const Left = styled(Down, {
  transform: 'rotate(90deg)',
});
export const Up = styled(Down, {
  transform: 'rotate(180deg)',
});
