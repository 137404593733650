import { Options } from '@glitz/core';
import transformers from '@glitz/transformers';
import { mediaMinMicro, mediaMinTiny, mediaMinSmall, mediaMinMedium, mediaMinLarge, mediaMinHuge } from 'Shared/Style';

const mediaQueries = [mediaMinMicro, mediaMinTiny, mediaMinSmall, mediaMinMedium, mediaMinLarge, mediaMinHuge];

function mediaOrder(a: string, b: string) {
  const indexA = mediaQueries.indexOf(a);
  const indexB = mediaQueries.indexOf(b);
  return indexA - indexB;
}

export const glitzCoreOptions: Options = {
  mediaOrder,
  transformer: transformers({
    numberToLengthOptions: {
      animationDelay: 'ms',
      animationDuration: 'ms',
      transitionDelay: 'ms',
      transitionDuration: 'ms',
    },
  }),
};
