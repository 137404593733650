/**
 * @ComponentFor SiteBannerBlock
 */
import React from 'react';
import { throttle } from 'lodash';
import { translate, isBrowser, on } from '@avensia/scope';
import { epiPropertyValue } from '@avensia/scope-episerver';
import { styled, StyledProps } from '@glitz/react';
import {
  white,
  pixelsToUnit,
  delta,
  kappa,
  milli,
  thin,
  minMediumMediaQuery,
  minSmallMediaQuery,
  minTinyMediaQuery,
  sigma,
  minLargeMediaQuery,
  transition,
  eta,
} from 'Shared/Style';
import Icon from 'Shared/Icon/Close';
import DefaultLink from 'Shared/Link';
import { Basic, Appearance } from 'Shared/PageLayout';
import SiteBannerBlockType from './SiteBannerBlock.type';
import { Style } from '@glitz/type';
import connect from 'Shared/connect';

type RequiredPropType = SiteBannerBlockType & {
  open: boolean;
  onClose: () => void;
  onHeightChange: (height: number) => void;
  onUnmount: () => void;
  hidden?: boolean;
};

type ConnectedPropType = {
  promotionEndDate?: string;
};

type PropType = RequiredPropType & StyledProps & ConnectedPropType;

type StateType = {
  height: number;
  mounted: boolean;
  intervalId: number;
  promotionEndDate: number;
  daysRemaining: number;
  hoursRemaining: number;
  minutesRemaining: number;
  countdownIsInitialized: boolean;
  countdownHasExpired: boolean;
};

class SiteBannerBlock extends React.PureComponent<PropType, StateType> {
  ref = React.createRef<HTMLDivElement>();
  unsubscribeResize: () => void;
  constructor(props: PropType) {
    super(props);
    this.state = {
      height: DEFAULT,
      mounted: false,
      intervalId: DEFAULT,
      promotionEndDate:!!props.promotionEndDate ? new Date(props.promotionEndDate.replace(/-/g, '/')).valueOf() : 0,
      daysRemaining: DEFAULT,
      hoursRemaining: DEFAULT,
      minutesRemaining: DEFAULT,
      countdownIsInitialized: false,
      countdownHasExpired: false,
    };
  }

  componentDidUpdate(prevProps: PropType, prevState: StateType) {
    if (this.props.open !== prevProps.open) {
      let stateToUpdate;
      if (this.props.open) {
        this.addListener();
        stateToUpdate = { mounted: true };
      } else {
        this.removeListener();
        stateToUpdate = { height: DEFAULT };
      }
      this.updateState(stateToUpdate);
    }

    if (this.state.mounted !== prevState.mounted) {
      if (this.state.mounted && this.hasRef()) {
        this.updateState({ height: this.ref.current.offsetHeight });
      }
    }
  }

  componentDidMount() {
    if (this.state.promotionEndDate !== 0 || !this.state.countdownHasExpired) {
      const intervalId = setInterval(() => {
        this.initializeCountdown();
      }, 60 * 1000);
      this.setState({ intervalId: intervalId });
    }

    if (!this.state.countdownIsInitialized && this.state.promotionEndDate !== 0) {
      this.initializeCountdown();
      this.setState({ countdownIsInitialized: true });
    }
  }

  initializeCountdown = () => {
    const nowDate = new Date().getTime();
    const dateDuration = this.state.promotionEndDate - nowDate;
    if (dateDuration <= 60000) {
      this.setState({ countdownHasExpired: true });
    } else {
      const days = Math.floor(dateDuration / (1000 * 60 * 60 * 24));
      const hours = Math.floor((dateDuration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((dateDuration % (1000 * 60 * 60)) / (1000 * 60));
      this.setState({
        daysRemaining: days,
        hoursRemaining: hours,
        minutesRemaining: minutes,
      });
    }
  };

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    this.removeListener();
    this.props.onUnmount();
  }

  addListener() {
    if (isBrowser()) {
      this.unsubscribeResize = on(
        'resize',
        throttle(() => {
          if (this.state.mounted && this.hasRef()) {
            this.updateState({ height: this.ref.current.offsetHeight });
          }
        }, TIME_DELAY),
      );
    }
  }

  removeListener() {
    if (this.unsubscribeResize) {
      this.unsubscribeResize();
      this.unsubscribeResize = null;
    }
  }

  hasRef() {
    return this.ref && this.ref.current;
  }

  close = () => {
    this.props.onClose();
  };

  updateState(newState: { [key: string]: number | boolean }) {
    this.setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }

  handleTransitionEnd = () => {
    let stateToUpdate;
    if (this.props.open) {
      stateToUpdate = { showButton: true };
    } else {
      stateToUpdate = { mounted: false };
    }
    this.updateState(stateToUpdate);

    // update banner placeholder height
    if (this.props.onHeightChange) {
      this.props.onHeightChange(this.state.height);
    }
  };

  render() {
    if (!this.state.mounted) {
      return null;
    }

    const { hideTitle, text, bannerLink, compose, backgroundColor, textColor } = this.props;
    const { height } = this.state;
    const textStyling: Style = {
      color: !!epiPropertyValue(textColor) ? epiPropertyValue(textColor) : white,
      ':hover': {
        borderBottomColor: !!epiPropertyValue(textColor) ? epiPropertyValue(textColor) : white,
      },
    };

    const textNoLinkStyling: Style = {
      color: !!epiPropertyValue(textColor) ? epiPropertyValue(textColor) : white,
    };
    return (
      <SiteBanner
        css={compose({
          height,
          ...(this.props.hidden && { visibility: 'hidden' }),
          display: 'flex',
          alignItems: 'center',
          backgroundColor: !!epiPropertyValue(backgroundColor)
            ? epiPropertyValue(backgroundColor)
            : (theme) => theme.campaignBackgroundColor,
          color: !!epiPropertyValue(textColor) ? epiPropertyValue(textColor) : white,
        })}
        onTransitionEnd={this.handleTransitionEnd}
      >
        <Content appearance={Appearance.Full} elementRef={this.ref}>
          {!!epiPropertyValue(bannerLink) ? (
            <Text to={epiPropertyValue(bannerLink).url} css={textStyling}>
              {!epiPropertyValue(hideTitle) && <Label>{`${translate('/Product/Badge/Campaign')} - `}</Label>}
              <span>{epiPropertyValue(text)}</span>
            </Text>
          ) : (
            <TextNoLink css={textNoLinkStyling}>
              {!epiPropertyValue(hideTitle) && <Label>{`${translate('/Product/Badge/Campaign')} - `}</Label>}
              <span>{epiPropertyValue(text)}</span>
            </TextNoLink>
          )}

          <div>
            {this.state.promotionEndDate !== 0 &&
              !epiPropertyValue(this.props.hideDiscountCountdown) &&
              !this.state.countdownHasExpired && (
                <SpanStyled>
                  {translate('/CountdownBanner/EndsOn') + ' :'} {this.state.daysRemaining + ' '}
                  {this.state.daysRemaining === 1
                    ? translate('/CountdownBanner/Day') + ' '
                    : translate('/CountdownBanner/Days') + ' '}
                  {this.state.hoursRemaining + ' '}
                  {this.state.hoursRemaining === 1
                    ? translate('/CountdownBanner/Hour') + ' '
                    : translate('/CountdownBanner/Hours') + ' '}
                  {this.state.minutesRemaining + ' '}
                  {this.state.minutesRemaining === 1
                    ? translate('/CountdownBanner/Minute') + ' '
                    : translate('/CountdownBanner/Minutes') + ' '}
                </SpanStyled>
              )}
          </div>

          <React.Fragment>
            <IconClose onClick={this.close} />
          </React.Fragment>
        </Content>
      </SiteBanner>
    );
  }
}

export default styled(
  connect(
    (state): ConnectedPropType => ({
      promotionEndDate: state.appShellData.siteBannerBlockPromotionEndDate,
    }),
  )(SiteBannerBlock),
);

export const DEFAULT = 0;
const TIME_DELAY = 1000;
const SiteBanner = styled(Basic, {
  color: white,
  width: '100%',
  ...transition({ property: 'height', duration: 150 }),
});

const Content = styled(Basic, {
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  textAlign: 'center',
  [minMediumMediaQuery]: {
    padding: {
      xy: pixelsToUnit(4),
    },
  },
});

const Link = styled(DefaultLink, {
  color: white,
});

const SpanStyled = styled.span({
  fontSize: milli,
  [minTinyMediaQuery]: {
    fontSize: kappa,
  },
  [minSmallMediaQuery]: {
    fontSize: sigma,
  },
});

const Text = styled(Link, {
  borderBottom: {
    width: thin,
    color: 'transparent',
    style: 'solid',
  },
  cursor: 'pointer',
  display: 'inline-block',
  fontSize: eta,
  letterSpacing: pixelsToUnit(1.2),
  maxWidth: '88%',
  textTransform: 'uppercase',
  ':hover': {
    borderBottomColor: white,
  },
  [minTinyMediaQuery]: {
    fontSize: kappa,
  },
  [minSmallMediaQuery]: {
    fontSize: sigma,
    maxWidth: '75%',
    verticalAlign: 'text-top',
  },
  [minMediumMediaQuery]: {
    fontSize: delta,
    maxWidth: '75%',
    verticalAlign: 'text-top',
  },
  [minLargeMediaQuery]: {
    maxWidth: '80%',
  },
  ...transition({ property: 'opacity' }),
});

const TextNoLink = styled.div({
  borderBottom: {
    width: thin,
    color: 'transparent',
    style: 'solid',
  },
  display: 'inline-block',
  fontSize: eta,
  letterSpacing: pixelsToUnit(1.2),
  maxWidth: '88%',
  textTransform: 'uppercase',
  [minTinyMediaQuery]: {
    fontSize: kappa,
  },
  [minSmallMediaQuery]: {
    fontSize: sigma,
    maxWidth: '75%',
    verticalAlign: 'text-top',
  },
  [minMediumMediaQuery]: {
    fontSize: delta,
    maxWidth: '75%',
    verticalAlign: 'text-top',
  },
  [minLargeMediaQuery]: {
    maxWidth: '80%',
  },
  ...transition({ property: 'opacity' }),
});

const Label = styled.span({
  fontWeight: 'bold',
});

const IconClose = styled(Icon, {
  cursor: 'pointer',
  position: 'absolute',
  width: '1.2rem',
  top: '50%',
  right: '-20px',
  transform: 'translate(-50%, -50%)',
  [minSmallMediaQuery]: {
    width: '1.5rem',
    right: '3%',
  },
});
