import { addUserLog, pathCombine, get, URLX } from '@avensia/scope';
import { searchPageUrl } from 'Shared/known-urls';
import { Dispatch, Action, QuickSearchCompletionListType } from 'Shared/State';
import SearchTextResultViewModel from './SearchTextResultViewModel.type';

export const SHOW_QUICKSEARCH = 'SHOW_QUICKSEARCH';
export const HIDE_QUICKSEARCH = 'HIDE_QUICKSEARCH';
export const UPDATE_QUICKSEARCH_TEXT = 'UPDATE_QUICKSEARCH_TEXT';
export const UPDATE_QUICKSEARCH_COMPLETIONS = 'UPDATE_QUICKSEARCH_COMPLETIONS';
export const UPDATE_SELECTED_QUICKSEARCH_COMPLETION = 'UPDATE_SELECTED_QUICKSEARCH_COMPLETION';

export const SEARCH_QUERY_NAME = 'q';
export const SEARCH_QUERY_SORTBY = 'sortBy';
export const SEARCH_PAGE_LOAD_QUERY_NAME = 'preview';
export const SEARCH_HISTORY_LIST_ID = 'searchHistory';
export const POPULAR_SEARCHES_LIST_ID = 'popular';
export const SUGGESTIONS_LIST_ID = 'suggestions';
export const CATEGORY_LIST_ID = 'categories';
export const CUSTOMER_SERVICE_LIST_ID = 'customerService';

export type UpdateQuicksearchTextAction = Action & {
  searchText: string;
};

export type HideQuicksearchAction = Action & {
  setFirstSuggestionAsSearchText: boolean;
};

export type UpdateSelectedQuicksearchCompletionAction = Action & {
  index: number;
};

export type UpdateQuicksearchCompletionsAction = Action & {
  lists: QuickSearchCompletionListType[];
};

const autocompletionCount = 5;
let lastRequestId = 0;

export function hideQuickSearch(setFirstSuggestionAsSearchText = true): HideQuicksearchAction {
  addUserLog('Hiding quick search');
  return {
    type: HIDE_QUICKSEARCH,
    setFirstSuggestionAsSearchText,
  };
}

export function showQuickSearch() {
  addUserLog('Showing quick search');
  return {
    type: SHOW_QUICKSEARCH,
  } as Action;
}

export function updateSelectedCompletion(index: number) {
  return {
    type: UPDATE_SELECTED_QUICKSEARCH_COMPLETION,
    index,
  } as UpdateSelectedQuicksearchCompletionAction;
}

export function loadQuickSearch(searchText: string) {
  addUserLog('Loading quick search for: ' + searchText);

  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_QUICKSEARCH_TEXT,
      searchText,
    } as UpdateQuicksearchTextAction);

    const requestId = (lastRequestId = Math.random());
    const url = new URLX(pathCombine(searchPageUrl(), 'searchText'));
    url.searchParams.set(SEARCH_QUERY_NAME, searchText);
    url.searchParams.set('count', String(autocompletionCount));
    return get(url)
      .then(r => r.json())
      .then((json: SearchTextResultViewModel) => {
        if (requestId === lastRequestId) {
          dispatch({
            type: UPDATE_QUICKSEARCH_COMPLETIONS,
            lists: [
              {
                id: POPULAR_SEARCHES_LIST_ID,
                sortIndex: 1,
                items: json.suggestions || [],
              },
              {
                id: SUGGESTIONS_LIST_ID,
                sortIndex: 2,
                items: json.completions || [],
              },
              {
                id: CATEGORY_LIST_ID,
                sortIndex: 3,
                items: json.searchCategories || [],
              },
              {
                id: CUSTOMER_SERVICE_LIST_ID,
                sortIndex: 4,
                items: json.searchCustomerServices || [],
              },
            ],
          } as UpdateQuicksearchCompletionsAction);
        }
      });
  };
}

export function searchTextToWords(searchText: string) {
  return searchText.replace(/\s+/, ' ').split(' ');
}
