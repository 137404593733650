import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { imageSizes, Size as Preset } from '../image-sizes';
import EagerImage from './Eager';
import LazyImage from './Lazy';
import { URLX, relativeUrl } from '@avensia/scope';
import noImage from './noimage';
import { Ratio } from 'Shared/AspectRatio';
import { Placeholder } from './components';

export { Size as Preset } from '../image-sizes';

// ImageResizer responds with 500 error if any of the
// resolution axis exceeds this value
const IMAGERESIZER_LIMIT = 3200;

export enum ImageMode {
  padding = 'pad',
  max = 'max',
}

export enum Format {
  JPG = 'jpg',
  GIF = 'gif',
  PNG = 'png',
}

// `srcSet` density descriptors
const DENSITY_DESCRIPTORS = [1.5, 2];

type Merge<T, S> = Pick<T, Exclude<keyof T, keyof S>> & S;

export type ImagePropType = StyledProps &
  Merge<
    React.ImgHTMLAttributes<HTMLImageElement>,
    {
      src?: Scope.UrlViewModel | string;
      preset?: Preset;
      lazy?: boolean;
      format?: Format;
      mode?: ImageMode;
      padRatio?: Ratio;
      subscribeToCache?: boolean;
    }
  >;

function Image({ compose, src, preset, format, lazy, mode, padRatio, ...restProps }: ImagePropType) {
  function getUrl(url: Scope.UrlViewModel | string) {
    if (isUrlViewModel(url)) {
      return url.url;
    }

    return url;
  }
  function isUrlViewModel(url: Scope.UrlViewModel | string): url is Scope.UrlViewModel {
    const viewModel = url as Scope.UrlViewModel;
    return !!viewModel && !!viewModel.url;
  }
  const internalPreset = preset;

  if (!src) {
    return (
      <>
        <Placeholder {...restProps} src={noImage} css={compose()} />
      </>
    );
  }

  if (internalPreset && !('srcSet' in restProps)) {
    const srcSetAttribute = srcSet(getUrl(src), internalPreset, format, mode);
    if (srcSetAttribute) {
      restProps.srcSet = srcSetAttribute;
    }
  }

  const ImgComponent = lazy ? LazyImage : EagerImage;

  return (
    <>
      <ImgComponent
        {...restProps}
        src={imageUrl(getUrl(src), internalPreset, format, mode, undefined, padRatio)}
        originalSrc={getUrl(src)}
        css={compose()}
      />
    </>
  );
}

export default styled(Image);

function imageUrl(
  src: string,
  preset?: Preset,
  format?: Format,
  mode?: ImageMode,
  width?: number,
  padRatio: number = 1,
  density = 1,
): string {
  if (src) {
    const size = Math.round(imageSizes[preset] * density);

    if (size > IMAGERESIZER_LIMIT || size > width) {
      return null;
    }

    const url = new URLX(src);

    if (typeof preset === 'number') {
      url.searchParams.set('w', String(size));

      if (mode) {
        url.searchParams.set('mode', mode);

        if (mode === ImageMode.padding) {
          url.searchParams.set('h', String(Math.round(size * padRatio)));
        }
      }
    }

    if (format) {
      url.searchParams.set('format', format);
    }

    return relativeUrl(url);
  }

  return null;
}

function srcSet(src: string, preset?: Preset, format?: Format, mode?: ImageMode, width?: number): string {
  return DENSITY_DESCRIPTORS.map((density) => {
    const url = imageUrl(src, preset, format, mode, width, undefined, density);
    if (url) {
      return `${url} ${density}x`;
    }
  })
    .filter((url) => !!url)
    .join();
}
