import { RadioPlayType } from 'Shared/State';
import { PageType, Action } from 'Shared/State';
import { TOGGLE_RADIOPLAY } from './action-creators';

type ActionType = Action & RadioPlayType;

export default function(state: RadioPlayType = null, action: Action, currentPage: PageType): RadioPlayType {
  if (state === null) {
    state = {
      isOpen: false,
      embedRadioPlayUrl: '',
    };
  }
  switch (action.type) {
    case TOGGLE_RADIOPLAY: {
      const { isOpen, embedRadioPlayUrl } = action as ActionType;
      return { ...state, isOpen, embedRadioPlayUrl };
    }
    default:
      break;
  }
  return state;
}
