import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import Link from 'Shared/Link';

type PropType = {
  isGift: boolean;
  displayName: string;
  productUrl: string;
};

function DisplayName(props: PropType & StyledProps) {
  return (
    <styled.Div css={props.compose()}>
      <NameLink to={props.productUrl}>{props.displayName}</NameLink>
    </styled.Div>
  );
}

export default styled(DisplayName);

const NameLink = styled(Link, {
  color: theme => theme.textColor,
  ':hover': {
    textDecoration: 'underline',
  },
});
