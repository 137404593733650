import SiteBannerBlockType from './SiteBannerBlock.type';
import { getLocalStorageItem, setLocalStorageItem } from '@avensia/scope';
import { epiPropertyValue } from '@avensia/scope-episerver';

const KEY = 'sitebanner';

export function saveToStorage(siteBanner: SiteBannerBlockType) {
  setLocalStorageItem(KEY, {
    text: siteBanner.hasOwnProperty('text') ? epiPropertyValue(siteBanner.text) : '',
    bannerLink:
      siteBanner.hasOwnProperty('bannerLink') && !!epiPropertyValue(siteBanner.bannerLink)
        ? epiPropertyValue(siteBanner.bannerLink).url
        : '',
  });
}

export function shouldComponentUpdate(current: SiteBannerBlockType) {
  const previous = getLocalStorageItem<{ text: string; bannerLink: string }>(KEY);
  let isNew = false;
  const newbannerLink = !!epiPropertyValue(current.bannerLink) ? epiPropertyValue(current.bannerLink).url : '';
  if (previous) {
    isNew = previous.text !== epiPropertyValue(current.text) || previous.bannerLink !== newbannerLink;
  }
  if (!previous || isNew) {
    return true;
  }
  return false;
}
