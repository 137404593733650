import { ProductDesignerType, PageType } from 'Shared/State';
import { UPDATE_COLOR, UPDATE_FONT, UPDATE_WOFFVALUE, UPDATE_TEXT, ProductDesignerAction } from './action-creators';
import { Action } from 'redux';
import isVariationPage from 'Product/current-page-is-variation';

export default function (state: ProductDesignerType = null, action: Action, currentPage: PageType): ProductDesignerType {
  if (state === null) {
    let colorName = 'White';
    let color = '#ffffff';
    let font = 'Arial';
    let woffValue = 'none';
    if (isVariationPage(currentPage)) {
      if (currentPage.designTagColors && currentPage.designTagColors.length > 0) {
        color = currentPage.designTagColors[0].value;
        colorName = currentPage.designTagColors[0].name;
      }
      if (currentPage.designTagFonts && currentPage.designTagFonts.length > 0) {
        font = currentPage.designTagFonts[0].name;
      }
      if (currentPage.designTagFonts && currentPage.designTagFonts.length > 0) {
        woffValue = currentPage.designTagFonts[0].woffValue;
      }
    }
    state = {
      colorName: colorName,
      color: color,
      font: font,
      woffValue: woffValue,
      text: 'Din text',
    };
  }
  switch (action.type) {
    case UPDATE_COLOR: {
      return {
        ...state,
        colorName: (action as ProductDesignerAction).name,
        color: (action as ProductDesignerAction).value,
      };
    }

    case UPDATE_FONT: {
      return { ...state, font: (action as ProductDesignerAction).value };
    }

    case UPDATE_WOFFVALUE: {
      return { ...state, woffValue: (action as ProductDesignerAction).value };
    }

    case UPDATE_TEXT: {
      return { ...state, text: (action as ProductDesignerAction).value };
    }

    default:
      return state;
  }
}
