import React from 'react';
import { NeutralMessage, ErrorMessage } from 'Shared/FeedbackMessage';
import { StyledProps, styled } from '@glitz/react';
import CartItemMessage from 'Cart/Models/CartItemMessage.type';

type PropType = {
  message: CartItemMessage;
};

function Message(props: PropType & StyledProps) {
  const StyledComponent = props.message.severity === 'error' ? ErrorMessage : NeutralMessage;
  return <StyledComponent css={props.compose()}>{props.message.message}</StyledComponent>;
}

export default styled(Message, {
  display: 'inline-block',
  lineHeight: 'normal',
});
